<style lang="scss" scoped>
$primary-color: #d86472;
button.cat {
  background: #ffffff;
  border: 0.5px solid #d86472;
  color: #d86472;
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 25px;
  @apply shadow-lg;
  &.active {
    background: $primary-color;
    color: #ffffff;
  }
}
</style>
<template>
  <div id="mainContent" class="contactus row">
    <div class="mobilePadding clearfix col-xs-12">
      <!-- <Header title="Contact us" /> -->
      <Headerv2 title="Contact us" />
      <div class="text-md font-black mt-4">
        <p>Your feedback is valuable to us!</p>
      </div>
      <p class="text-sm">
        Please select a category and enter your message in the space below
      </p>

      <p class="parentColor mt-5 mb-4 font-bold text-md">Select Category</p>
      <div class="row">
        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
          <button
            type="button"
            class="btn cat"
            name="button"
            :class="{
              active: selection.category == 'Suggestions to improve commute',
            }"
            @click="setCategory('Suggestions to improve commute')"
          >
            Suggestions to improve commute
          </button>
        </div>
        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
          <button
            type="button"
            class="btn cat"
            name="button"
            :class="{
              active: selection.category == 'Request for administration',
            }"
            @click="setCategory('Request for administration')"
          >
            Request for administration
          </button>
        </div>
        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
          <button
            type="button"
            class="btn cat"
            name="button"
            :class="{ active: selection.category == 'Suggest new feature' }"
            @click="setCategory('Suggest new feature')"
          >
            Suggest new feature
          </button>
        </div>
        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
          <button
            type="button"
            class="btn cat"
            name="button"
            :class="{ active: selection.category == 'Report an issue' }"
            @click="setCategory('Report an issue')"
          >
            Report an issue
          </button>
        </div>
      </div>
      <div class="clearfix">
        <textarea
          name="name"
          placeholder="Enter message"
          rows="4"
          cols="80"
          class="form-control rounded-lg"
          maxlength="500"
          v-model="selection.message"
        ></textarea>
      </div>
      <div class="text-center mt-4">
        <van-button
          size="small"
          class="btn btn-black !px-8"
          :disabled="!selection.category"
          @click="submit()"
          >Submit</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Headerv2 from "@/components/Headerv2";

export default {
  name: "contactus",
  components: {
    Headerv2,
  },
  data() {
    return {
      selection: {},
    };
  },
  methods: {
    setCategory(category) {
      this.selection.category = category;
      this.$forceUpdate();
    },
    submit() {
      ApiService.staticContentApi
        .submitFeedback(this.selection)
        .then((response) => {
          if (response.status == 200) {
            this.$notify({
              message: "Feedback sent",
              type: "success",
            });
            this.$router.push("home");
          }
        });
    },
  },
};
</script>
