<template>
  <div>
    <div class="myjourneys clearfix">
      <div class="clearfix" style="clear: both">
        <Headerv2
          title="What is your School run journey like?"
          :showBack="false"
        />
      </div>
      <div class="col-xs-12">
        <div class="mialert lightGreen">
          <p class v-if="!staffJourneyRequired">
            <b>We’re on a mission to improve your school run.</b>
            <br />Choose the journey that best matches how your child normally
            gets to school, so that we can keep improving your HomeRun
            experience.
            <span class="link">Your responses will not be shared.</span>
          </p>
          <p class v-if="staffJourneyRequired">
            We’re on a mission to improve school commutes.

            <br />Choose the journey that best matches how you normally get to
            school, so that we can keep improving the HomeRun experience.
            <span class="link">Your responses will not be shared.</span>
          </p>
        </div>
        <!-- <hr> -->
      </div>
      <div
        v-if="term"
        class="col-xs-12 termtext"
        :class="getTermClass"
        style="margin-top: 15px; margin-bottom: 10px"
      >
        Your {{ term.toUpperCase() }} journeys to school
      </div>
      <div class="journeycontainer">
        <div class="col-xs-12 form-row text-left" v-if="!staffJourneyRequired">
          <div class="form-row__title">Select child</div>
          <div data-cy="children_selection_wrapper">
            <div
              :key="child.id"
              class="child"
              v-for="child in childrenAvailablePerRow"
            >
              <div
                class="smallImg"
                @click="toggleChild(child)"
                :data-cy-single="child.first_name"
              >
                <div
                  v-if="selectedChildren.indexOf(child.id) > -1"
                  class="active"
                >
                  <i class="fa fa-check fa-xl" aria-hidden="true"></i>
                </div>
                <img :src="child.profile_photo_url" />
              </div>
              <span class="im">
                {{
                  child.first_name.length >= 7
                    ? child.first_name.substr(0, 7) + ".."
                    : child.first_name
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 form-row text-left no-margin">
          <div
            class="form-row__title"
            v-if="
              selectedChildren.length ||
              selectedChildName ||
              staffJourneyRequired
            "
          >
            <span v-if="staffJourneyRequired" data-cy="staff_journey"
              >Staff</span
            >
            <span
              v-if="!staffJourneyRequired && selectedChildren.length > 0"
              data-cy="parent_journey"
            >
              {{ selectedJourneyChild }}'s</span
            >
            Journey
            <span class="journey-count" data-cy="journey_count"
              >({{ journeyInfo }})</span
            >
          </div>

          <div
            class="journey-info mialert lightGreen !text-xs !font-medium !mb-0"
            style="width: 250px"
          >
            <div class="source">
              <i class="fal fa-house text-xl"></i>
              <div class="circle">A</div>
              Home
            </div>
            <img
              class="arrow"
              src="@/assets/flow/journey-arrow.svg"
              style="filter: brightness(0)"
            />
            <div class="destination">
              <i class="fal fa-school text-xl"></i>
              <div class="circle">B</div>
              <span data-cy="school_name_text">{{
                currentSchool || "School"
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 form-row"
          :class="{ notselected: selectedMode == '' }"
        >
          <div class="form-row__title">Select mode</div>
          <div class="travel-modes">
            <div
              class="car mode-button"
              :class="{
                active: selectedMode == 'car' && vehicle_type == '',
                disabled: disabledModes.indexOf('car') > -1,
              }"
              @click="
                vehicle_type = '';
                setMode('car');
                showCarModal();
              "
              data-cy="open_car_modal_btn"
            >
              <i class="fal fa-car text-2xl"></i>
              <span>CAR</span>
            </div>
            <div
              class="taxi mode-button"
              :class="{
                active: selectedMode == 'taxi',
                disabled: disabledModes.indexOf('taxi') > -1,
              }"
              @click="
                setMode('car');
                vehicle_type = 'taxi';
                // showParkingModal();
                showCarModal();
              "
              data-cy="open_taxi_modal_btn"
            >
              <i class="fal fa-taxi text-2xl"></i>
              <span>TAXI</span>
            </div>
            <div
              v-if="term"
              class="carpool mode-button"
              data-cy="open_carpool_mode_btn"
              :class="{
                active: selectedMode == 'carpool',
                disabled: disabledModes.indexOf('carpool') > -1,
              }"
              @click="
                vehicle_type = '';
                showCarModal();
                setMode('carpool');
              "
            >
              <i class="fal fa-cars text-2xl"></i>
              <span>CARPOOL</span>
            </div>
            <div
              class="bus mode-button"
              :class="{
                active:
                  selectedMode == 'bus' ||
                  selectedMode == 'school_bus' ||
                  selectedMode == 'public_bus',
                disabled: disabledModes.indexOf('bus') > -1,
              }"
              data-cy="open_bus_modal_btn"
              @click="
                setMode('bus');
                showBusModal();
              "
            >
              <i class="fal fa-bus text-2xl"></i>
              <span>BUS</span>
            </div>
            <div
              class="train mode-button"
              :class="{
                active: selectedMode == 'train',
                disabled: disabledModes.indexOf('train') > -1,
              }"
              data-cy="select_train_btn"
              @click="setMode('train')"
            >
              <i class="fal fa-train-tunnel text-2xl"></i>
              <span>TRAIN</span>
            </div>
            <div
              class="walk mode-button"
              :class="{
                active: selectedMode == 'walk',
                disabled: disabledModes.indexOf('walk') > -1,
              }"
              data-cy="select_walk_btn"
              @click="setMode('walk')"
            >
              <i class="fal fa-person-walking text-2xl"></i>
              <span>WALK</span>
            </div>
            <div
              class="bicycle mode-button"
              :class="{
                active: selectedMode == 'bicycle',
                disabled: disabledModes.indexOf('bicycle') > -1,
              }"
              data-cy="select_bicycle_btn"
              @click="setMode('bicycle')"
            >
              <i class="fal fa-person-biking text-2xl"></i>
              <span>BICYCLE</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 form-action-row">
        <button
          class="btn greyBtn van-button van-button--info van-button--normal van-button--plain"
          @click="$router.go(-1)"
          data-cy="go_back_btn"
        >
          Go Back
        </button>
        <button
          class="btn btn-black"
          data-cy="submitButton"
          :disabled="!selectedMode && selectedMode != 'bus'"
          @click="submit()"
        >
          Continue
        </button>
      </div>
    </div>
    <modal
      class="modal-flow"
      name="carDetailsModal"
      :adaptive="true"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="false"
    >
      <ModalHeader
        flow
        title="Your car details"
        @hideModal="
          hideModal('carDetailsModal');
          resetSelection();
          registrationNo = '';
        "
        data-cy="car_details_modal_close_btn"
      />
      <div class="carModal" data-cy="car_details_modal">
        <div>
          <div
            v-if="!vehicle && vrnSearchAvailable"
            class="mialert lightGreen text-center"
          >
            <p>
              Please let us know your <b>VRN if your car is UK-registered</b> so
              we can calculate school run CO2 emissions. Your VRN will be kept
              confidential.
            </p>
          </div>
          <div class="row" v-if="!existingCar || showCarSearch">
            <div class="col-xs-12 reg-search" v-if="vrnSearchAvailable">
              <input
                type="text"
                class="van-field__control van-cell__value !rounded-2xl"
                v-model="registrationNo"
                @input="registrationNo = $event.target.value.toUpperCase()"
                placeholder="Enter Registration no."
                data-cy="registration_no_input"
                name
                value
              />
              <button
                class="btn btn-black"
                type="info"
                name="button"
                data-cy="get_car_data_btn"
                @click.prevent="getCarData()"
                style="height: 36px; padding: 7px 40px"
              >
                LookUp
              </button>
            </div>
            <div class="col-xs-12" v-if="!registrationNo || !vehicle">
              <div v-if="showVehicleError == true" class="mialert lightRed">
                VRN not found - Try again or select car engine type and size
              </div>
              <p class="mialert lightGray text-center">
                <b>Alternatively</b>, Please select your engine type and vehicle
                size
              </p>
            </div>

            <br />
            <div class="col-xs-12" v-if="!registrationNo || !vehicle">
              <button
                class="btn btn-black float-right"
                type="info"
                name="button"
                data-cy="show_basic_vehicle_modal_btn"
                @click.prevent="showBasicVehicleModal()"
              >
                Select
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="existingCar && !showCarSearch"
          class="alert alert-warning text-center"
          data-cy="existing_car_section"
        >
          <h4>
            <small>Choose your car</small>
          </h4>
          <button
            class="btn btn-black"
            @click="showParkingModal()"
            data-cy="select_existing_car_btn"
          >
            <strong>{{ existingCar.name }}</strong>
          </button>
          <div style="padding: 10px 0">or</div>
          <button
            class="btn btn-grey"
            @click="showCarSearch = true"
            data-cy="change_car_btn"
          >
            click here to change your car
          </button>
        </div>
        <!-- <div v-if='!vehicle && !existingCar'>
      <button class='btn btn-link' @click='getCarData("standard")'>Or continue without car details</button>
        </div>-->
        <div v-if="showVehicleError == false" style="margin-bottom: 20px"></div>
        <!-- <div v-if="!vehicle">
          <p class="text-center">
            <button
              class="btn btn-grey"
              @click.prevent="
                vehicle_type = 'taxi';
                showParkingModal();
              "
            >
              <span>
                <i
                  class="fa fa-taxi"
                  aria-hidden="true"
                  style="font-size: 25px; color: #ffb405"
                ></i>
              </span>
              <span v-if="!staffJourneyRequired">
                My child takes a
                <b>Taxi</b> to school
              </span>
              <span v-if="staffJourneyRequired">I take a taxi to school</span>
            </button>
          </p>
        </div> -->
        <div class v-if="vehicle">
          <div class="row">
            <div class="col-sm-12 text-center marginTop5">
              <button
                class="btn btn-black"
                @click.prevent="
                  saveCarData();
                  setParking();
                "
                data-cy="set_parking_btn"
              >
                Continue
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="label">Current Details</span>
              <span class="big"
                >{{ vehicle.name || "" }} {{ vehicle.model || "" }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="label">Colour</span>
              <span class="big">{{
                vehicle.vehicle_color || vehicle.color || ""
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="label">Year</span>
              <span class="big">{{ vehicle.vehicle_year || "" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="label">Fuel Type</span>
              <span class="big">{{ vehicle.fuel_type || "" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="label">Miles Per Gallon</span>
              <span class="big">{{ vehicle.mpg || "" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <span class="label">CO2 Emissions</span>
              <span class="big">{{ vehicle.co2_emission || "" }}</span>
            </div>
            <br />
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal-flow"
      name="parkingModal"
      :adaptive="true"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="false"
    >
      <ModalHeader
        flow
        title="Select Drop Off Point"
        @hideModal="hideModal('parkingModal')"
        data-cy="car_parking_modal_close_btn"
      />
      <div class="col-xs-12">
        <div class="mialert lightGray marginVertical20">
          <p>
            Please drag the
            <strong style="color: red">red pin</strong> to where you normally
            park
            <span v-if="!staffJourneyRequired">or drop off your children</span>.

            <strong
              >This helps us to improve child safety around your school
              site.</strong
            >
          </p>
        </div>
        <GmapMap
          class="marginVertical20"
          :key="schoolMarker.position.lat"
          :center="schoolMarker.position"
          :zoom="zoomLevel"
          style="width: 100%; height: 300px"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false,
          }"
        >
          <GmapMarker
            :key="parkingMarker.position.lat"
            :position="parkingMarker.position"
            :clickable="true"
            :draggable="true"
            :zIndex="9999"
            @dragend="dragEnd"
            style="z-index: 999"
          />
          <gmap-custom-marker
            :key="schoolMarker.position.lat"
            :marker="schoolMarker.position"
            :zIndex="-10"
          >
            <img
              style="height: 50px; z-index: -9"
              src="@/assets/school_pin.png"
            />
          </gmap-custom-marker>
        </GmapMap>
        <div class="row text-center" style="margin: 10px 0">
          <div class="col-sm-12">
            <button
              class="btn btn-black"
              @click="
                submitParking();
                showCarSearch = false;
              "
              data-cy="submit_parking_btn"
            >
              Continue
            </button>
            <!-- <button type="button" class='btn primaryButton' name="button" @click='submit()'>I Don't park</button> -->
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal-flow"
      name="busTypeModal"
      :adaptive="true"
      :height="'auto'"
      :scrollable="true"
      data-cy="bus_selection_modal"
    >
      <ModalHeader
        flow
        title="Select bus type"
        icon="fa fa-bus"
        @hideModal="
          hideModal('busTypeModal');
          resetSelection();
        "
        data-cy="bus_selection_modal_close_btn"
      />
      <div class="row">
        <div class="col-xs-12 bus-selection">
          <button
            class="btn busicon"
            :class="{ active: selectedMode == 'public_bus' }"
            @click="setMode('public_bus')"
            data-cy="public_bus_btn"
          >
            <i class="fa fa-bus" aria-hidden="true"></i>

            <span>Public bus</span>
          </button>
          <button
            class="btn busicon"
            :class="{ active: selectedMode == 'school_bus' }"
            @click="setMode('school_bus')"
            data-cy="private_bus_btn"
          >
            <i class="fa fa-bus" style="color: #ffb405" aria-hidden="true"></i>

            <span>School bus service</span>
          </button>
        </div>
        <div class="text-center marginTop15">
          <button
            class="btn btn-black"
            type="info"
            :disabled="
              selectedMode != 'public_bus' && selectedMode != 'school_bus'
            "
            @click="submit()"
            data-cy="submit_bus_choice_btn"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </modal>
    <modal
      class="modal-flow"
      name="basicVehicleModal"
      :adaptive="true"
      :height="'auto'"
      :scrollable="true"
      data-cy="basic_vehicle_modal"
    >
      <ModalHeader
        flow
        :title="
          basicVehicleModalStep == 'engine_type'
            ? 'Select engine type'
            : 'Select vehicle size'
        "
        icon="fa fa-car"
        @hideModal="
          hideModal('basicVehicleModal');
          resetSelection();
        "
        data-cy="basic_vehicle_modal_close_btn"
      />
      <div
        class="row pb-10"
        v-if="basicVehicleModalStep == 'engine_type'"
        data-cy="engine_type_section"
      >
        <div class="flex justify-evenly mb-5">
          <button
            class="btn engineTypeBtn petrol"
            :class="{ active: basicVehicle.engine_type == 'petrol' }"
            @click="
              basicVehicle.engine_type = 'petrol';
              basicVehicleModalStep = 'vehicle_size';
            "
            data-cy="petrol_engine_btn"
          >
            <i class="fal fa-gas-pump" aria-hidden="true"></i>

            <span>Petrol</span>
          </button>
          <button
            class="btn engineTypeBtn diesel"
            :class="{ active: basicVehicle.engine_type == 'diesel' }"
            @click="
              basicVehicle.engine_type = 'diesel';
              basicVehicleModalStep = 'vehicle_size';
            "
            data-cy="diesel_engine_btn"
          >
            <i class="fal fa-gas-pump" aria-hidden="true"></i>

            <span>Diesel</span>
          </button>
        </div>
        <div class="flex justify-evenly mb-5">
          <button
            class="btn engineTypeBtn electric"
            :class="{ active: basicVehicle.engine_type == 'electric' }"
            @click="
              basicVehicle.engine_type = 'electric';
              basicVehicleModalStep = 'vehicle_size';
            "
            data-cy="electric_engine_btn"
          >
            <i class="fal fa-bolt" aria-hidden="true"></i>

            <span>Electric</span>
          </button>
          <button
            class="btn engineTypeBtn hybrid"
            :class="{ active: basicVehicle.engine_type == 'hybrid' }"
            @click="
              basicVehicle.engine_type = 'hybrid';
              basicVehicleModalStep = 'vehicle_size';
            "
            data-cy="hybrid_engine_btn"
          >
            <i class="fal fa-car-circle-bolt" aria-hidden="true"></i>

            <span>Hybrid</span>
          </button>
        </div>
        <!-- <div class="text-center marginTop15">
          <button
            class="btn btn-black"
            type="info"
            :disabled="!basicVehicle.engine_type"
            @click="basicVehicleModalStep = 'vehicle_size'"
            data-cy="basic_vehicle_modal_select_size_btn"
          >
            Next
          </button>
        </div> -->
      </div>
      <div class="row" v-if="basicVehicleModalStep == 'vehicle_size'">
        <div class="col-xs-12 flex justify-center mb-5">
          <button
            class="btn engineTypeBtn small"
            :class="{ active: basicVehicle.size == 'small' }"
            @click="
              basicVehicle.size = 'small';
              saveBasicCarData();
            "
            data-cy="small_vehicle_btn"
          >
            <i
              class="fas fa-car-side"
              aria-hidden="true"
              style="font-size: 25px"
            ></i>

            <span>Small</span>
          </button>
        </div>
        <div class="col-xs-12 flex justify-center mb-5">
          <button
            class="btn engineTypeBtn medium"
            :class="{ active: basicVehicle.size == 'medium' }"
            @click="
              basicVehicle.size = 'medium';
              saveBasicCarData();
            "
            data-cy="medium_vehicle_btn"
          >
            <i class="fas fa-car-side" aria-hidden="true"></i>

            <span>Medium</span>
          </button>
        </div>
        <div class="col-xs-12 flex justify-center mb-5">
          <button
            class="btn engineTypeBtn large"
            :class="{ active: basicVehicle.size == 'large' }"
            @click="
              basicVehicle.size = 'large';
              saveBasicCarData();
            "
            data-cy="large_vehicle_btn"
          >
            <i class="fas fa-van-shuttle" aria-hidden="true"></i>

            <span>Large</span>
          </button>
        </div>
        <div class="col-xs-12 text-center marginTop15 flex">
          <button
            class="btn greyBtn van-button van-button--info van-button--normal van-button--plain"
            style="margin-bottom: 0px !important"
            @click="basicVehicleModalStep = 'engine_type'"
            data-cy="go_back_to_engine_type_btn"
          >
            Go Back
          </button>
          <!-- <button
            class="btn btn-black"
            type="info"
            :disabled="!basicVehicle.size"
            @click="saveBasicCarData()"
            data-cy="submit_vehicle_type_btn"
          >
            Continue
          </button> -->
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import ModalHeader from "@/components/ModalHeader";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import Headerv2 from "../../components/Headerv2.vue";
import { AVATARS_URL } from "../../store/constants";

export default {
  name: "journeys",
  components: {
    ModalHeader,
    Headerv2,
    "gmap-custom-marker": GmapCustomMarker,
  },
  data() {
    return {
      staffJourneyRequired: false,
      currentSchool: null,
      selectedChildren: [],
      selectedChildName: "",
      childrenAvailablePerRow: [],
      disabledModes: [],
      selectedMode: "",
      vehicle: null,
      basicVehicle: { size: null, engine_type: null },
      basicVehicleModalStep: "engine_type",
      existingCar: null,
      showCarSearch: false,
      showVehicleError: false,
      registrationNo: "",
      zoomLevel: 16,
      busVehicleType: null,
      smjourneyInfo: null,
      vrnSearchAvailable: true,
      schoolMarker: {
        position: {
          lat: 1,
          lng: 0,
        },
      },
      parkingMarker: {
        position: {
          lat: 1,
          lng: 0,
        },
      },
      usedStudentsIds: [],
      totalChildren: [],
      vehicle_type: null,
      currentSchoolAddress: {},
      organization_id: null,
      userChoice: null,
      userRoleId: null,
    };
  },
  computed: {
    lastSelectedChild() {
      return (
        this.childrenAvailablePerRow.find(
          (x) => x.id === this.selectedChildren?.slice(-1).pop()
        )?.first_name || this.selectedChildName
      );
    },
    journeyInfo() {
      return `${this.selectedChildrenCount} of ${
        this.childrenAvailablePerRow.length + this.staffJourneyRequired ? 1 : 0
      }`;
    },
    getTermClass() {
      return "termcolor_" + this.$store.state.general.term;
    },
    term() {
      return this.$store.state.general.term;
    },
    selectedChildrenCount() {
      return this.selectedChildren.length === 0
        ? 1
        : this.selectedChildren.length;
    },
    selectedJourneyChild() {
      return this.selectedChildrenCount === 0
        ? ""
        : // : this.lastSelectedChild;
          this.childrenAvailablePerRow
            .filter((itm) => this.selectedChildren.indexOf(itm.id) > -1)
            .map((itm) => itm.first_name)
            .join(",");
    },
  },
  methods: {
    getCarData(type) {
      this.$store.dispatch("loading", true);
      this.showVehicleError = false;

      ApiService.generalApi.getCarData(this.registrationNo).then((response) => {
        this.$store.dispatch("loading", false);
        if (response.status == 200) {
          this.vehicle = response.data.data;

          if (!this.vehicle) {
            this.showVehicleError = true;
          }

          if (this.vehicle.regDate) {
            var split = this.vehicle.regDate.split("-");
            this.vehicle.vehicle_year = split[0];
          }

          if (type == "standard") {
            this.setParking();
          }
        }
      });
    },
    setParking() {
      if (this.vehicle || this.basicVehicle.size) {
      } else {
        alert("Error occured");
        return;
      }
      setTimeout(() => {
        this.vehicle = "";
      }, 400);
      this.showParkingModal();
    },
    _capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    saveBasicCarData() {
      this.hideModal("basicVehicleModal");
      const paramsBySize = {
        small_petrol: { co2: 127, mpg: 41.36 },
        small_diesel: { co2: 131, mpg: 54.01 },
        small_electric: { co2: 0, mpg: 0 },
        small_hybrid: { co2: 74, mpg: 66.94 },

        medium_petrol: { co2: 172, mpg: 33.55 },
        medium_diesel: { co2: 176, mpg: 42.16 },
        medium_electric: { co2: 0, mpg: 0 },
        medium_hybrid: { co2: 130, mpg: 54.32 },

        large_petrol: { co2: 239, mpg: 28.22 },
        large_diesel: { co2: 228, mpg: 33.67 },
        large_electric: { co2: 0, mpg: 0 },
        large_hybrid: { co2: 182, mpg: 45.71 },
      };
      const carData = {
        name:
          this._capitalizeFirstLetter(this.basicVehicle.size) +
          " " +
          this._capitalizeFirstLetter(this.basicVehicle.engine_type) +
          " Car",
        co2_emission:
          paramsBySize[
            this.basicVehicle.size + "_" + this.basicVehicle.engine_type
          ].co2,
        mpg: paramsBySize[
          this.basicVehicle.size + "_" + this.basicVehicle.engine_type
        ].mpg,
        fuel_type: this.basicVehicle.engine_type,
        regDate: "",
        vrn: "",
        color: "",
        sim_car: 1,
      };

      ApiService.generalApi
        .saveCarData(carData)
        .then((response) => {
          if (response.status == 200) {
            this.hideModal("carDetailsModal");
            this.showParkingModal();
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    saveCarData() {
      if (!this.vehicle) {
        this.vehicle = {
          name: "Default Car",
          model: "",
          mpg: 52,
          co2_emission: 144,
          short_name: "CAR",
          vrn: null,
          color: null,
          fuel_type: null,
          regDate: null,
        };
      }
      var carData = {
        name: this.vehicle.name + " " + this.vehicle.model,
        co2_emission: this.vehicle.co2_emission,
        mpg: this.vehicle.mpg,
        fuel_type: this.vehicle.fuel_type,
        regDate: this.vehicle.regDate,
        vrn: this.vehicle.reg,
        color: this.vehicle.color,
      };
      if (!this.vehicle.fuel_type) {
        this.showParkingModal();
        this.hideModal("carDetailsModal");
        return;
      }

      ApiService.generalApi
        .saveCarData(carData)
        .then((response) => {
          if (response.status == 200) {
            this.showParkingModal();
            this.hideModal("carDetailsModal");
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getPendingJourneys() {
      this.$store.dispatch("loading", true);
      ApiService.generalApi
        .pendingJourneys()
        .then((response) => {
          if (response.status == 200) {
            if (!this.existingCar) {
              this.existingCar = response.data.vehicle;
            }
            if (response.data.vrn_available === false) {
              this.vrnSearchAvailable = false;
            }
            // this.homeAddress = response.data.user.address;

            if (response.data?.completed == true) {
              ApiService.userApi.termUpdated().then(() => {
                this.$store.dispatch("termUpdated");
                if (this.$store.state.general.show_congrats_page) {
                  this.$store.dispatch("setShowCongratsPage", false);
                  if (this.$store.state.general.native == false) {
                    this.$router.push("/flow/congratulations");
                    return;
                  }
                }
                setTimeout(() => {
                  ApiService.userApi.accountStatusRedirect(true);
                }, 150);
              });
              return;
            }

            // Staff journey only
            if (response.data.pending_staff_journeys) {
              const staffJourney = response.data.pending_staff_journeys[0];
              this.organization_id = staffJourney.organization_id;
              // const staffaddressId = staffJourney.org_address_id;
              this.currentSchool = staffJourney.name;
              this.currentSchoolAddress = {
                id: staffJourney.org_address_id,
                latitude: parseFloat(staffJourney.destination_latitude),
                longitude: parseFloat(staffJourney.destination_longitude),
                org_address_id: staffJourney.org_address_id,
                organization_id: staffJourney.organization_id,
              };
              this.organization_id = staffJourney.organization_id;
              this.userRoleId = staffJourney.user_role_id;
              this.staffJourneyRequired = true;
              // setModes(true);
            } else {
              this.staffJourneyRequired = false;
            }

            if (response.data.pending_parent_journeys) {
              this.totalChildren = response.data.pending_parent_journeys;
              this.smjourneyInfo = this.totalChildren.length + " Journeys left";
              this.children = this.getChildren(
                response.data.pending_parent_journeys
              );
            }
          }
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          alert(e);
          this.$store.dispatch("loading", false);
        });
    },
    getChildren(pendingJourneys) {
      let destinationId = null;
      this.childrenAvailablePerRow = [];
      for (var y = 0; y < pendingJourneys.length; y++) {
        let currentRow = pendingJourneys[y];
        if (y == 0) {
          destinationId = currentRow.org_address_id;
          this.currentSchoolAddress = {
            id: currentRow.org_address_id,
            latitude: parseFloat(currentRow.destination_latitude),
            longitude: parseFloat(currentRow.destination_longitude),
            org_address_id: currentRow.org_address_id,
          };
          this.userRoleId = currentRow.user_role_id;
          this.currentSchool = currentRow.name;
          this.organization_id = currentRow.organization_id;
        } else if (y > 0) {
          if (destinationId != currentRow.org_address_id) {
            continue;
          }
        }
        this.childrenAvailablePerRow.push({
          first_name: currentRow.first_name,
          id: currentRow.student_id,
          profile_photo_url: AVATARS_URL + currentRow.profile_photo,
        });
        this.selectedChildren.push(currentRow.student_id);
      }
    },
    // getChildren1(data) {
    //   var skipIteration = false;

    //   var row;
    //   this.childrenAvailablePerRow = [];
    //   if (!data || data.length == 0) return;

    //   for (var i = 0; i < 1; i++) {
    //     // take only first row: data.length

    //     row = data[i];

    //     this.currentSchoolAddress = row.address;
    //     if (row.address.school) {
    //       this.currentSchool = row.address.school.name;
    //     }

    //     for (var y = 0; y < row.children.length; y++) {
    //       // if not in array
    //       this.selectedChildren.push(row.children[y].id);

    //       if (
    //         this.usedStudentsIds.indexOf(i + "-" + row.children[y].id) == -1
    //       ) {
    //         this.childrenAvailablePerRow.push(row.children[y]);
    //         this.usedStudentsIds.push(row.children[y].id);
    //         skipIteration = true;
    //       }
    //     }
    //     this.currentJourney = i + 1;
    //     if (skipIteration == true) {
    //       break;
    //     }
    //   }
    // },
    toggleChild(child) {
      var index = this.selectedChildren.indexOf(child.id);
      this.selectedChildName = child.first_name;

      if (index > -1) {
        this.selectedChildren.splice(index, 1);
      } else {
        this.selectedChildren.push(child.id);
      }
    },
    setMode(mode) {
      this.selectedMode = mode;
    },
    showCarModal() {
      // this.center = [
      //   this.currentSchoolAddress.latitude,
      //   this.currentSchoolAddress.longitude
      // ];
      this.$set(this.schoolMarker, "position", {
        lat: parseFloat(this.currentSchoolAddress.latitude),
        lng: parseFloat(this.currentSchoolAddress.longitude),
      });

      this.$set(this.parkingMarker, "position", {
        lat:
          parseFloat(this.currentSchoolAddress.latitude) +
          (Math.floor(Math.random() * 0.00101) - 0.00051),
        lng:
          parseFloat(this.currentSchoolAddress.longitude) +
          (Math.floor(Math.random() * 0.00101) - 0.00041),
      });

      if (this.vehicle_type == "taxi") {
        this.showParkingModal();
        return;
      }

      if (!this.vrnSearchAvailable && !this.existingCar) {
        this.showBasicVehicleModal();
        return;
      }
      // this.schoolMarker = {
      //   position: {
      //     lat: parseFloat(this.currentSchoolAddress.latitude),
      //     lng: parseFloat(this.currentSchoolAddress.longitude),
      //   },
      // };

      this.$modal.show("carDetailsModal");
    },
    showBusModal() {
      this.$modal.show("busTypeModal");
    },
    showParkingModal() {
      this.$modal.show("parkingModal");
    },
    showBasicVehicleModal() {
      this.$modal.show("basicVehicleModal");
    },
    hideModal(modalName) {
      if (modalName === "basicVehicleModal") {
        this.basicVehicleModalStep = "engine_type";
      }
      this.$modal.hide(modalName);
    },
    resetSelection() {
      this.selectedMode = "";
      this.vehicle_type = "";
      this.busVehicleType = undefined;
      if (this.existingCar) {
        this.showCarSearch = false;
      }
    },
    dragEnd(position) {
      const latlng = { lat: position.latLng.lat(), lng: position.latLng.lng() };
      //  toastr.success(latlng, 'Position changed!');
      this.parkingMarker.position = latlng;
    },
    submitParking() {
      if (this.selectedMode == "carpool") {
        // overwrite for carpool only
        // this.submit();
        // return;
      }
      this.$store.dispatch("loading", true);
      const payload = {
        parking: {
          lat: this.parkingMarker.position.lat,
          lng: this.parkingMarker.position.lng,
        },
        organization_id: this.organization_id,
        destination_address_id: this.currentSchoolAddress.org_address_id,
      };
      ApiService.generalApi.saveParking(payload).then((response) => {
        this.submit();
      });
      // this.getDrivingParkingJourney();
    },
    getDrivingParkingJourney() {
      var destination = {
        lat: this.currentSchoolAddress.latitude,
        lng: this.currentSchoolAddress.longitude,
      };
      var home = {
        lat: this.homeAddress.latitude,
        lng: this.homeAddress.longitude,
      };
      var parking = {
        lat: this.parkingMarker.position.lat,
        lng: this.parkingMarker.position.lng,
      };

      // Get journey with parking
      ApiService.generalApi
        .getParkingJourney(destination, home, parking) // isSubAccount
        .then((response) => {
          if (response.status == 200) {
            this.selectedMode =
              this.selectedMode == "carpool" ? "carpool_parking" : "parking";
            this.submit();
          }
        })
        .catch(() => {
          this.$store.dispatch("loading", false);
        });
    },
    submit() {
      this.$store.dispatch("loading", true);
      //  event.preventDefault();
      if (this.selectedChildren.length == 0 && !this.staffJourneyRequired) {
        this.$notify({
          message: "Please select a child to continue",
          type: "warning",
        });
        this.$store.dispatch("loading", false);
        return;
      }
      var travelInfo = this.getRoute();
      var obj = {
        children: this.selectedChildren,
        journey_type: this.selectedChildren.length > 0 ? "STUDENT" : "STAFF",
        user_type: this.selectedChildren.length > 0 ? "PARENT" : "STAFF",
        // destination:
        //   this.currentSchoolAddress.address_line_1 +
        //   " " +
        //   this.currentSchoolAddress.address_line_2,
        direction: "to",
        organization_id: this.organization_id,
        // origin:
        //   this.homeAddress.address_line_1 +
        //   " " +
        //   this.homeAddress.address_line_2,
        destination_address_id: this.currentSchoolAddress.org_address_id,
        user_choice: this.selectedMode,
        // total_travel_time_text: travelInfo.total_travel_time,
        // travel_modes: travelInfo.travel_modes,
        // rawSteps: travelInfo.rawSteps,
      };
      this.saveDefaultJourney(obj);
    },

    saveDefaultJourney(payload) {
      this.$store.dispatch("loading", true);
      ApiService.generalApi
        .saveJourney(payload)
        .then((response) => {
          this.$store.dispatch("loading", false);
          if (response.status == 200) {
            this.hideModal("carDetailsModal");
            this.hideModal("busTypeModal");
            this.hideModal("parkingModal");
            this.selectedMode = "";
            this.selectedChildren = [];
            this.vehicle_type = "";
            this.busVehicleType = undefined;
            this.getPendingJourneys();
            // this.closeModal();
          }
        })
        .catch((error) => {
          alert("Error");
          alert(error.response);
          this.$store.dispatch("loading", false);
        });
    },
    getRoute() {
      var returnObj = {
        travel_modes: [],
        total_travel_time: "",
      };
      if (this.selectedMode == "walk") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "WALKING",
          percentage: "1",
          travel_time: 0,
        });
      } else if (this.selectedMode == "bicycle") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "BICYCLING",
          percentage: "1",
          travel_time: 0,
        });
      } else if (this.selectedMode == "car") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "CAR",
          percentage: "1",
          travel_time: 0,
          vehicle_type: this.vehicle_type ? this.vehicle_type : "",
        });
      } else if (this.selectedMode == "carpool") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "CARPOOL",
          percentage: "1",
          travel_time: 0,
          vehicle_type: this.vehicle_type ? this.vehicle_type : "",
        });
      } else if (this.selectedMode == "parking") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "CAR",
          percentage: "1",
          travel_time: 0,
          vehicle_type: this.vehicle_type ? this.vehicle_type : "",
        });

        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "WALKING",
          percentage: "1",
          travel_time: 0,
        });
      } else if (this.selectedMode == "carpool_parking") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "CARPOOL",
          percentage: "1",
          travel_time: 0,
          vehicle_type: this.vehicle_type ? this.vehicle_type : "",
        });

        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "WALKING",
          percentage: "1",
          travel_time: 0,
        });
      } else if (this.selectedMode == "train") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "TRAIN",
          percentage: "1",
          travel_time: 0,
        });
      } else if (this.selectedMode == "bus") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "BUS",
          percentage: "1",
          travel_time: 0,
          vehicle_type: this.busVehicleType ? this.busVehicleType : "",
        });
      } else if (this.selectedMode == "schoolBus") {
        returnObj.travel_modes.push({
          distance_in_miles: 0,
          name: "BUS",
          percentage: "1",
          travel_time: 0,
          vehicle_type: "school",
        });
      }

      return returnObj;
    },
  },
  mounted() {
    this.$emit("progress-step", 4);
    this.getPendingJourneys();
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #21acf5;
$parent-primary-color: #99cc00;
$staff-primary-color: #33cccc;

.circleBtn {
  width: 62px;
  height: 62px;
  border: 1px solid #c2c2c2;
  color: #c2c2c2;
  font-size: 8px;
  font-weight: 500;
  border-radius: 32px;
  i {
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 25px;
  }
}
.circleBtn.carpoolMode:hover {
  background: #d86472;
  color: white;
}

.travel-modes {
  display: flex;
  align-items: center;

  .mode-button {
    width: 55px;
    height: 55px;
    text-align: center;
    border: 1px solid;
    border-radius: 28px;
    margin-top: 4px;
    &:hover,
    &:active,
    &.active {
      color: white !important;
    }
    i {
      padding-top: 4px;
    }
    span {
      font-size: 7px;
      display: block;
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  div {
    cursor: pointer;
    margin-right: 5px;
  }

  .car {
    border-color: #ff66cc;
    color: #ff66cc;
  }
  .car:hover,
  .car.active {
    background: #ff66cc;
  }
  .carpool {
    border-color: #009900;
    color: #009900;
  }
  .carpool:hover,
  .carpool.active {
    background: #009900;
  }
  .taxi {
    color: #d86472;
    border-color: #d86472;
  }
  .taxi:hover,
  .taxi.active {
    background: #d86472;
  }
  .bus {
    border-color: #33cccc;
    color: #33cccc;
  }
  .bus:hover,
  .bus.active {
    background: #33cccc;
  }
  .train {
    border-color: #9999ff;
    color: #9999ff;
  }
  .train:hover,
  .train.active {
    background: #9999ff;
  }
  .walk {
    border-color: #99cc00;
    color: #99cc00;
  }
  .walk:hover,
  .walk.active {
    background: #99cc00;
  }
  .bicycle {
    border-color: #ff9900;
    color: #ff9900;
  }
  .bicycle:hover,
  .bicycle.active {
    background: #ff9900;
  }
}

.walkMode {
  background: #e70e80;
}

.travelModes.notselected div {
  opacity: 1;
}

.travelModes div.active {
  opacity: 1;
}

.travelModes div:hover img {
  animation: fadeInLeft 1s;
}

.bus-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.engineTypeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 115px;
  height: 115px;
  // background: #f9f9f9;
  border: 2px solid #c2c2c2;
  color: #c2c2c2;
  border-radius: 100%;
}
.engineTypeBtn i {
  display: block;
  font-size: 38px;
  margin-bottom: 4px;
}
.engineTypeBtn {
  color: white;
}
.engineTypeBtn {
  &.petrol {
    background-color: #9999ff;
    border-color: #9999ff;
  }
  &.diesel {
    background-color: #ff9900;
    border-color: #ff9900;
  }
  &.electric {
    background-color: #99cc00;
    border-color: #99cc00;
  }
  &.hybrid {
    background-color: #33cccc;
    border-color: #33cccc;
  }
  &.small,
  &.medium,
  &.large {
    background-color: #99cc00;
    border-color: #99cc00;
  }
}
.busicon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 3px solid #f9f9f9;
  border-radius: 100%;
  color: black;
  outline: none;
  font-weight: 500;
  /*transition: 0.5s all ease;*/

  &:hover {
    background: #fafafa;
    color: #000;
  }
  i {
    font-size: 35px;
  }
}

.busicon + .busicon {
  margin-left: 10px;
}

.busicon.active {
  border: 3px solid $parent-primary-color;
  background: #fff;
}

.staff-theme .busicon.active {
  border: 3px solid $staff-primary-color;
}

.smallImg {
  height: 47px;
  width: 47px;
  overflow: hidden;
  margin: auto;
  cursor: pointer;
  position: relative;

  img {
    height: 100% !important;
  }

  i {
    color: white;
  }
}

.child span {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 500;
}

.smallImg .active {
  background: rgba(96, 210, 235, 0.77);
  line-height: 48px;
  height: 47px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  border-radius: 30px;
}

.smallImg .active i {
  font-size: 24px;
}

.child {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 50px;
  z-index: 9;
}

.carModal span.label {
  display: block;
  color: #aaa;
  text-align: left;
  margin-top: 20px;
}

.carModal span.big {
  font-size: 14px;
}

.column {
  height: 100px;
}

.column img {
  margin-top: 10px;
  height: 72% !important;
}

.smcolumn {
  height: 50px;
}

.smcolumn img {
  height: 65% !important;
}

.fromto div {
  font-size: 18px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.child-name {
  font-size: 14px;
  font-weight: 500;
}

.journey-count {
  color: #cc33cc;
}

.journey-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;

  .arrow {
    margin: 5px;
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    padding: 5px;
    margin: 4px auto 5px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;

    &.destination {
      background: #cc33cc;
    }
  }
}

.reg-search {
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
    padding: 10px 40px;
  }
}
</style>
