var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"block cursorPointer flex items-center p-2 font-rubik",class:{ 'self-block': _vm.parent.noChat }},[_c('div',{staticClass:"notice-avatar-container self-start"},[_c('img',{staticClass:"notice-avatar border-2 border-solid border-green-dark p-0",class:_vm.expanded ? 'imgexpanded' : '',attrs:{"src":_vm.parent.profile_photo,"alt":""},on:{"click":_vm.toggleExpand}})]),_c('div',{staticClass:"middle flex-1 self-start"},[_c('div',{staticClass:"name flex flex-wrap items-center"},[_c('span',{staticClass:"font-medium mr-2"},[_vm._v(" "+_vm._s(_vm.parent.first_name + " " + _vm.parent.last_name)+" ")]),(_vm.parent.is_pta)?_c('span',{staticClass:"role"},[_vm._v(" PTA ")]):_vm._e(),(_vm.parent.staff_type)?_c('div',{staticClass:"role"},[(_vm.parent.staff_type == 1)?_c('span',[_vm._v("Head Teacher")]):_vm._e(),(_vm.parent.staff_type == 2)?_c('span',[_vm._v("Teacher")]):_vm._e(),(_vm.parent.staff_type == 3)?_c('span',[_vm._v("School Office")]):_vm._e()]):_vm._e()]),(
          (_vm.parent.journey_preference == 1 ||
            _vm.parent.journey_preference == 3) &&
          (_vm.page == 'journey_share' || _vm.page == 'parent' || _vm.page == 'staff')
        )?_c('i',{staticClass:"fa fa-link inlineBlock"}):_vm._e(),(
          (_vm.parent.journey_preference == 2 ||
            _vm.parent.journey_preference == 3) &&
          (_vm.page == 'travel_buddy' || _vm.page == 'parent' || _vm.page == 'staff')
        )?_c('i',{staticClass:"fa fa-child journeyIcon"}):_vm._e(),(_vm.parent.years)?_c('div',{staticClass:"childrenInfo"},[_vm._v(" Child"),(_vm.parent.years.split(',').length > 1)?_c('span',[_vm._v("ren")]):_vm._e(),_vm._v(" in Year"),(_vm.parent.years.split(',').length > 1)?_c('span',[_vm._v("s")]):_vm._e(),_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.parent.years))])])]):_vm._e(),(_vm.parent.journey_preference != 0 && !_vm.parent.noChat)?_c('div',{staticClass:"text-xs text-gray"},[(_vm.parent.distance <= 0.2)?_c('span',[_vm._v("Under 0.2")]):_vm._e(),(_vm.parent.distance > 0.2)?_c('span',[_vm._v(_vm._s(_vm.parent.distance.toFixed(2)))]):_vm._e(),_vm._v(" Miles from you ")]):_vm._e()]),(_vm.parent.noChat)?_c('div',{staticClass:"right"},[_c('i',{staticClass:"far fa-user fa-lg"}),_c('div',{staticClass:"text-xs font-semibold"},[_vm._v("Me")])]):_vm._e(),(!_vm.parent.noChat)?_c('div',{staticClass:"chat-row",on:{"click":_vm.showConversation}},[_c('i',{staticClass:"far fa-message fa-lg"}),_c('div',{staticClass:"text-xs font-normal"},[_vm._v("Message")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }