<style lang="scss" scoped>
.commentsView {
  overflow: hidden;
  overflow-y: scroll;
  height: 0px;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 0px;
  /* padding-left: 20px; */
}
.commentsView.active {
  height: 250px;
  padding-top: 5px;
  /* padding: 20px; */
  margin-bottom: 8px;
}
hr {
  margin: 0px;
}

.notice-footer {
  @apply text-black text-xs font-normal flex py-2 mx-2;
  @apply border-t border-gray-200;

  &:empty {
    display: none;
  }
}

.notice-footer-actions {
  @apply flex flex-auto items-center;

  .action {
    @apply ml-2 mr-3;

    &:hover {
      @apply cursor-pointer;
      opacity: 0.9;
    }
  }

  .action i {
    @apply mr-1;
  }
}

.notice-footer-logo img {
  @apply rounded-2xl;
  width: 20px;
  height: 20px;
  align-self: flex-end;
  object-fit: contain;
}
</style>
<template>
  <div class="notice-footer">
    <div class="notice-footer-actions">
      <button
        class="action like"
        v-if="message.user_type != 5"
        @click.stop="likeEvent"
      >
        <i class="fal fa-thumbs-up fa-xl"></i>
        <span v-if="message.total_likes > 0">{{
          message.total_likes || ""
        }}</span>
        Likes
      </button>
      <button
        class="action comments"
        v-if="message.allow_comments"
        @click.stop="openComments"
      >
        <i class="fal fa-comment-dots fa-xl"></i>
        <span v-if="message.user_type != 5">{{ message.total_comments }}</span>
        Comments
      </button>
      <button
        class="action message"
        v-if="
          !disableChat &&
          message.user_id != user_id &&
          (message.user_type == 6 ||
            message.user_type == 7 ||
            message.user_type == 2)
        "
        @click.stop="openChat"
      >
        <i class="fal fa-message fa-xl"></i> Message
      </button>
    </div>

    <div
      class="notice-footer-logo"
      v-if="message.user_type != 3 && message.user_type != 5"
    >
      <img v-if="message.school_logo" :src="message.school_logo" alt />
    </div>
    <div class="clearfix">
      <div
        v-if="expanded"
        class="commentsView"
        :style="fullheight"
        :class="expand ? 'active' : ''"
      >
        <NewCommentBox
          :noticeUserId="message.id"
          @newCommentAdded="updateComments"
        />
        <hr v-if="comments.length > 0" />
        <!-- <div :key="comment.id" :comment="comment" v-for="comment in comments">
          {{ comment.id }}
        </div>-->
        <table class="table" border="0" ref="commentsBox">
          <tbody>
            <SingleComment
              :key="comment.id"
              :user_id="0"
              :comment="comment"
              v-for="comment in comments"
            />
          </tbody>
        </table>
        <div v-if="moreComments" style="text-align: center">
          <van-button
            type="info"
            size="small"
            plain
            style="border: 0px !important"
            >Show More</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import ApiService from "@/services/ApiService";
import SingleComment from "@/components/noticeTypes/other/SingleComment";
import NewCommentBox from "@/components/noticeTypes/other/NewCommentBox";
export default {
  name: "blockTitle",
  props: {
    message: Object,
    user_id: Number,
    disableChat: Boolean,
  },
  components: {
    SingleComment,
    NewCommentBox,
  },
  data() {
    return {
      expanded: false,
      expand: false,
      moreComments: false,
      comments: [],
      fullheight: {
        height: "100px",
      },
    };
  },
  methods: {
    openComments() {
      this.$emit("noticeEvent", "showComments");
      return;
      // if (!this.expanded) {
      //   this.getComments();
      // } else {
      //   this.expand = !this.expand;
      //   this.fullheight.height = 0 + "px";
      //   setTimeout(() => {
      //     this.expanded = !this.expanded;
      //   }, 250);
      // }
    },
    getComments() {
      this.$store.dispatch("loading", true);
      ApiService.generalApi
        .getSingleMessage(this.message.id)
        .then((response) => {
          this.$store.dispatch("loading", false);
          if (response.data.comments.length > 2) {
            this.moreComments = true;
          }
          this.comments = response.data.comments.slice(0, 2);

          this.expanded = !this.expanded;

          setTimeout(() => {
            this.expand = !this.expand;
            this.fullheight.height =
              this.$refs.commentsBox.clientHeight + 100 + "px";
          }, 150);
        })
        .catch(() => {
          this.$store.dispatch("loading", false);
        });
    },
    updateComments() {
      this.message.total_comments++;
      this.getComments();
    },
    testMethod() {
      alert("testas");
    },
    likeEvent() {
      this.$emit("noticeEvent", "noticeLike");
    },
    openChat() {
      this.$emit("noticeEvent", "showChatUserId");
    },
    openNotice() {
      this.$emit("noticeEvent", "showNotice");
    },
  },
  mounted() {
    // console.log("Message", this.message);
  },
};
</script>
