import generalApi from "./apiCalls/generalApi";
import authApi from "./apiCalls/authApi";
import userApi from "./apiCalls/userApi";
import staticContentApi from "./apiCalls/staticContentApi";
import conversationsApi from "./apiCalls/conversationsApi";

export default {
  authApi: authApi,
  generalApi: generalApi,
  userApi: userApi,
  staticContentApi: staticContentApi,
  conversationsApi: conversationsApi,
};
