var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrapper"}},[_c('div',{staticClass:"notice-title items-center py-1",attrs:{"id":"topConversation"}},[_c('i',{staticClass:"fa fa-angle-left fa-2xl text-pink-dark mr-5 cursor-pointer",on:{"click":function($event){return _vm.$emit('close')}}}),_c('img',{staticClass:"notice-avatar relative border-notice-theme",attrs:{"src":_vm.imageUrl,"alt":""}}),_c('div',{staticClass:"notice-user-details flex-1"},[_c('span',{staticClass:"username"},[_vm._v(_vm._s(_vm.chatTitle))])]),_c('button',{staticClass:"btn btn-link pull-right text-xs text-grey hover:text-gray-300 self-start",on:{"click":function($event){return _vm.report()}}},[_vm._v(" Report ")])]),_c('ul',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({ always: true, smooth: true }),expression:"{ always: true, smooth: true }"}],attrs:{"id":"middleConversation"}},_vm._l((_vm.conversations),function(singleChatMessage){return _c('li',{key:singleChatMessage.id,staticClass:"chatline",class:{
      myChatMessage: _vm.myUserId == singleChatMessage.user_id,
    }},[_c('span',{staticClass:"chatMessage"},[_c('span',{domProps:{"innerHTML":_vm._s(singleChatMessage.message)}}),_c('span',{staticClass:"timeAgo flex items-center"},[_c('timeago',{attrs:{"datetime":singleChatMessage.created_timestamp * 1000}}),(
            singleChatMessage.is_read == 0 &&
            _vm.myUserRoleId != singleChatMessage.user_role_id
          )?_c('i',{staticClass:"far fa-envelop"}):_vm._e(),(
            singleChatMessage.is_read == 1 &&
            _vm.myUserRoleId != singleChatMessage.user_role_id
          )?_c('i',{staticClass:"far fa-envelope-open"}):_vm._e()],1)])])}),0),_c('div',{staticClass:"bg-white rounded-t-lg",attrs:{"id":"bottomConversation"}},[_c('div',[_c('div',{staticClass:"container",staticStyle:{"max-width":"600px"}},[_c('div',{staticClass:"row bottomChatDiv",attrs:{"id":"bottomChatDiv"}},[(_vm.allow_parents_reply == 1)?_c('form',{staticClass:"flex mx-5",on:{"submit":function($event){return _vm.sendConversation()}}},[_c('div',{staticClass:"flex-1 pr-4"},[_c('van-field',{ref:"chatMessage",staticClass:"chat-textarea",attrs:{"label":"","type":"textarea","placeholder":"Type a message","rows":"1","autosize":{ maxHeight: 100 }},on:{"click":_vm.focusInput},model:{value:(_vm.newChatMessage),callback:function ($$v) {_vm.newChatMessage=$$v},expression:"newChatMessage"}})],1),_c('div',[_c('button',{staticClass:"rounded-full p-3 bg-pink-dark text-white flex items-center",on:{"click":function($event){$event.preventDefault();return _vm.sendConversation.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-paper-plane",staticStyle:{"display":"block","font-size":"18px"}})])])]):_vm._e(),(_vm.allow_parents_reply == 0 && !_vm.isStaff)?_c('div',{staticClass:"alert alert-info"},[_vm._v(" This user has selected not to enable replies from parents. ")]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }