<template>
  <h1 class="text-center mb-3 !text-xl text-black !font-normal">
    <button v-if="showBack" class="px-3 absolute left-0" @click="goBack">
      <i class="fas fa-angle-left parentColor text-3xl"></i></button
    >{{ title }}
  </h1>
</template>
<style scoped></style>
<script>
export default {
  name: "Headerv2",
  props: {
    title: String,
    // icon: String,
    showMobile: Boolean,
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
