<style lang="scss" scoped>
.imageDiv {
  margin-right: 10px;
  height: 50px;
}

.imageDiv i {
  font-size: 30px;
  margin-top: 5px;
}

.imageDiv img {
  max-height: 50px;
}

.typelabel {
  font-size: 14px;
  font-weight: 700;
  color: #aaa;
}

.zoomer {
  width: 100%;
  height: 500px;
  border: solid 1px #f9f9f9;
}

.zoomer img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

span.from {
  display: inline-block;
  margin-top: 0px;
}

.galleryCloseButton {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99999;
  color: orange;
  background: none !important;
  border: none;
  padding: 10px;
}

#noticeImages img {
  width: 100%;
}

#galleryButtons {
  height: 40px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: white;
  z-index: 9999;

  button {
    padding: 5px;
    margin: 5px;
    background: none;
    color: orange;
    border: none;
    font-size: 18px;

    i {
      vertical-align: bottom;
    }
  }
}

#galleryDiv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.float {
  position: fixed;
  top: 10px;
  right: 10px;
  background: none !important;
}

.comments-title {
  @apply text-black ml-3 my-2;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.v--modal-overlay {
  @media screen and (max-width: 767px) {
    @apply pb-5;
  }
}

.notice-avatar.travel {
  border: 1px solid;
}
</style>
<template>
  <div class="modal-body font-rubik" :class="`notice-theme-${notice.category}`">
    <div class="modal-header">
      <div class="notice-header py-3 px-0 mx-3">
        <div class="notice-title">
          <img v-if="notice.user_type === 6 || notice.user_type === 7" class="notice-avatar relative"
            :src="notice.image_url" />
          <i v-if="notice.user_type === 8" class="fas fa-graduation-cap mr-2"></i>
          <i v-if="notice.user_type === 2" class="fas fa-school mr-2"></i>
          <i v-if="notice.user_type === 9" class="fas fa-book-reader mr-2"></i>
          <img class="adminLogo notice-avatar relative" v-if="notice.user_type === 5"
            src="@/assets/logo-small-black.png" />
          <img class="adminLogo notice-avatar relative" v-if="notice.user_type == 1 || notice.user_type == 3"
            :src="notice.image_url" />
          <img class="adminLogo notice-avatar relative" v-if="notice.user_type > 20" :src="notice.image_url" />

          <div class="notice-user-details">
            <span class="username colorNavy from" v-if="notice.user_type !== 8 && notice.user_type !== 9">{{
              notice.from_label }}</span>
            <span class="username" v-if="notice.user_type === 8 || notice.user_type === 9">{{ notice.salutation + " " +
              notice.last_name || "" }}</span>

            <span class="user-type" v-if="notice.user_type === 2">School Office</span>
            <span class="user-type" v-if="notice.user_type === 6">Parent</span>
            <span class="user-type" v-if="notice.user_type === 7">Parent & PTA</span>
            <span class="user-type" v-if="notice.user_type === 8">Head Teacher</span>
            <span class="user-type" v-if="notice.user_type === 9">Teacher</span>
            <span class="user-type" v-if="notice.user_type === 5">Admin</span>
            <span class="user-type" v-if="notice.user_type === 3">Council</span>
            <span class="user-type" v-if="notice.user_type === 1">School</span>
            <span class="user-type" v-if="notice.user_type === 21">School Info</span>
            <span class="user-type" v-if="notice.user_type === 22">Community Support</span>
          </div>
        </div>
        <div class="flex items-center">
          <span class="time-ago ml-0 mr-4 text-grey">
            <timeago :datetime="notice.created_timestamp * 1000"></timeago>
          </span>
          <i class="fa fa-times close-icon fa-xl cursor-pointer" @click="close()" />
        </div>
      </div>
    </div>

    <div class="modal-content clearfix">
      <div v-if="notice.type === 'article'" id="noticeArticle">
        <div class="mainTitle" v-if="notice.type !== 'user_post'">
          {{ notice.title }}
        </div>
        <div v-if="notice.articleImageUrl" class="articleImage">
          <img :src="notice.articleImageUrl" alt />
        </div>
      </div>
      <div v-if="notice.extras && notice.extras.date" id="eventInfo">
        <div class="blockText noticeText eventMiddle notice-body">
          <p class="text-left">
            <b style="font-size: 20px; padding-bottom: 5px; display: block">{{
              notice.title
              }}</b>
            <i class="far fa-calendar-alt" style="font-size: 15px"></i>&nbsp;
            <b>{{ notice.extras.date }}</b>
          </p>
        </div>
      </div>
      <div v-if="notice.message" class="notice-body">
        <v-runtime-template :template="notice.message"></v-runtime-template>
      </div>
      <div v-if="
        notice.type !== 'poll' &&
        notice.images.length > 0 &&
        notice.images[0].type !== 'mp4'
      " id="noticeImages" class="noSideMargin">
        <div class="relativeDiv px-3" v-for="(image, index) in notice.images" :key="index">
          <img @click="viewGallery(index)" class="rounded-lg" :src="image.path" style="margin-bottom: 4px" alt />
        </div>
      </div>
      <div v-if="showGallery" id="galleryDiv">
        <button @click="hideGallery" type="button" class="galleryCloseButton">
          <i class="fas fa-window-close"></i>
        </button>
        <v-zoomer-gallery ref="gallery" style="width: 100vw; height: 100vh; z-index: 9999" :list="images"
          v-model="selIndex" :max-scale="2"></v-zoomer-gallery>
        <div id="galleryButtons">
          <button :disabled="selIndex === 0" @click="selIndex -= 1">
            <i class="fas fa-angle-left"></i>
          </button>
          <span>{{ selIndex + 1 }} / {{ notice.images.length }}</span>
          <button :disabled="selIndex === notice.images.length - 1" @click="selIndex += 1">
            <i class="fas fa-angle-right"></i>
          </button>
          <button @click="$refs.gallery.reset()">reset</button>
          <button @click="$refs.gallery.zoomIn()">+</button>
          <button @click="$refs.gallery.zoomOut()">-</button>
        </div>
      </div>
      <!-- <div v-if="notice.message && notice.type != 'noticeimages'">
        <v-runtime-template :template="notice.message"></v-runtime-template>
      </div>-->
      <div v-if="notice.allow_comments">
        <div class="comments-title">Comments</div>
        <NewCommentBox :id="notice.id" @newCommentAdded="newCommentAdded" />
        <div>
          <SingleComment :key="comment.id" :user_id="user_id" :comment="comment" @removeMyComment="removeMyComment"
            v-for="comment in comments" />

          <button class="pull-left btn-link btn text-grey text-xs ml-3 mt-1 mb-3 hover:text-gray-400" @click="report()">
            Report
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
\
<script>
import VRuntimeTemplate from "v-runtime-template";
import SingleComment from "@/components/noticeTypes/other/SingleComment";
import ReportModal from "@/components/modals/ReportModal.vue";
import NewCommentBox from "@/components/noticeTypes/other/NewCommentBox";

import ApiService from "@/services/ApiService";

export default {
  name: "singlenotice",
  components: {
    VRuntimeTemplate,
    NewCommentBox,
    SingleComment,
  },
  props: {
    noticeId: Number,
    notice: Object,
    comments: Array,
  },
  data() {
    return {
      message: {
        images: [],
      },
      newComment: "",
      currentUser: {
        profile_photo_url: "none",
      },
      returnData: {
        views: 1,
        comments: 0,
      },
      selIndex: 0,
      showGallery: false,
    };
  },
  methods: {
    openNativeWebModal(event, url) {
      if (this.$store.state.general.native) {
        event.preventDefault();
        window.postMessage("native_modal__" + url, "*");
      }
    },
    newCommentAdded(data) {
      this.comments.unshift(data);
    },
    another() { },
    viewGallery(imageIndex) {
      this.showGallery = true;
      this.selIndex = imageIndex;
    },
    hideGallery() {
      this.showGallery = false;
    },
    autoExpand() { },
    testMethod() {
    },
    report() {
      const params = {
        notice_id: this.notice.id,
        reported_user_id: this.notice.user_id,
        subject: "notice_id",
      };
      this.$modal.show(
        ReportModal,
        {
          params,
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          height: "auto",
          adaptive: true,
          pivotY: 0,
          reset: true,
          classes: "v--modal-box v--modal report-modal",
        }
      );
    },
    removeMyComment(id) {
      var index;
      for (var i = 0; i < this.comments.length; i++) {
        if (this.comments[i].id == id) {
          index = i;
        }
      }
      ApiService.generalApi.removeComment(id).then(() => {
        this.comments.splice(index, 1);
      });
    },
    submitComment() {
      if (this.newComment == "") {
        this.$notify({
          type: "warning",
          message: "Comment is required to proceed",
        });
        return;
      }
      var _payload = {
        comment: this.newComment,
        notice_id: this.noticeId,
        user_role_id: this.currentUserRoleId,
      };

      ApiService.generalApi
        .saveComment(_payload)
        .then((data) => {
          // loading(false);
          this.newComment = "";
          this.comments.unshift(data.data);
          this.returnData.comments++;
        })
        .catch(function () {
          // loading(false);
        });
    },
    close() {
      this.$parent.$emit("newAvatar", this.returnData);
      this.$emit("close");
    },
    redirect(route) {
      this.$router.push(route);
      this.close();
    },
  },
  computed: {
    images: function () {
      return this.notice.images.map((obj) => obj.path);
    },
    user_id: function () {
      return parseInt(this.$store.state.general.user_id);
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
  },
  mounted() {
    if (this.notice && this.notice.extras && this.notice.extras.date) {
      var d = new Date(this.notice.extras.date);
    }
  },
};
</script>
