<style>
#schoolNetwork {
  /* margin-right: 25px; */
  /* margin-left: 25px; */
  padding-bottom: 5px;
  overflow: hidden;
}
.bigButton {
  height: 94% !important;
  width: 90% !important;
  background: hsla(0, 0%, 100%, 0.15);
  position: relative;
  transition: all 1s ease;
}
.featuresPage .bigButton div {
  width: auto;
  height: auto;
  float: none;
}
.bigButton h4 {
  font-size: 22px !important;
  padding: 14px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  padding-top: 12px;
  line-height: 30px;
  margin-top: 0;
  /* font-family: Palanquin Dark; */
}
/* .bigButtons div.line {
    border-bottom: 1px solid #fff;
    bottom: 52px;
    width: 80%;
    margin: 10%;
    margin-top: 5%;
    position: absolute;
} */
.bigButton div.imageBlock {
  height: 60px;
  text-align: center;
  margin-bottom: 15px;
}
.bigButton div.imageBlock img {
  height: 100%;
  opacity: 0.5;
}
.bigButtons .col-xs-6 {
  padding-right: 7px;
  padding-left: 7px;
}
.bigButton div.smallText {
  /* position: absolute; */
  width: 95%;
  margin: 2%;
  line-height: 14px;
  bottom: 10px;
  text-align: center;
  font-size: 13px;
}
.bigButton div.line {
  border-bottom: 1px solid #fff;
  /* bottom: 52px; */
  width: 80%;
  margin: 10%;
  /* margin-top: 5%; */
  /* position: absolute; */
}
@media (min-width: 768px) {
  #wrapper {
    height: 100%;
  }
  .featuresPage {
    padding-top: 20px;
    min-height: 100vh;
  }
  #absoluteContentMobile {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .featuresPage {
    /* background: rgb(176,176,0); */
    /* padding-top: 10px; */
    /* min-height: 100vh; */
    /* height: 85vh; */
    height: calc(100vh - 100px);
    overflow: hidden;
    background: linear-gradient(60deg, #b0b000, #0180aa, #dc0492);
    background-size: 600% 600%;
    margin-left: -15px;
    margin-right: -15px;
  }
  .featuresPage.native {
    height: calc(100vh - 54px);
  }
}
@media (max-width: 340px) {
  .bigButtons h4 {
    font-size: 17px !important;
    padding-bottom: 5px;
  }
  .bigButtons {
    margin-top: 5px;
  }
  .bigButtons:first-child {
    margin-top: 0px;
  }
  .bigButtons div.col-xs-6 .bigButton {
    height: 230px;
  }
}
.imageBlock {
  position: relative;
}
.fa-bell {
  color: #ff8d00;
  border-radius: 10px;
  top: 0;
  position: absolute;
  right: 6px;
  top: 5px;
  font-size: 23px;
}
#absoluteContentMobile.bgFade {
  /* background: rgba(192,192,192,0.3); */
  /* opacity: 0.3; */
}
.smallText {
  color: rgba(255, 255, 255, 0.85) !important;
}
#featuresPage {
  width: 100%;
}
.switchLabel {
  display: inline-block;
  line-height: 35px;
  vertical-align: top;
  /* font-weight: bold; */
}
.switchLabel.active {
  font-weight: bold;
}
.featuresPage div {
  width: 50%;
  height: 50%;
  float: left;
}
#div1 {
  background: linear-gradient(
    35deg,
    rgba(145, 0, 92, 1) 0%,
    rgb(255, 114, 173) 100%
  );
  overflow: hidden;
}
#div2 {
  background: linear-gradient(
    75deg,
    rgba(10, 88, 0, 1) 0%,
    rgb(146, 189, 46) 100%
  );

  overflow: hidden;
}
#div3 {
  background: linear-gradient(
    155deg,
    rgba(181, 109, 0, 1) 0%,
    rgb(255, 227, 86) 100%
  );
  overflow: hidden;
}
#div4 {
  background: linear-gradient(
    110deg,
    rgb(19, 76, 162) 0%,
    rgba(35, 204, 220) 100%
  );
  overflow: hidden;
}
#div1 .bigButton {
  margin-top: 10%;
  margin-left: 10%;
}
#div2 .bigButton {
  margin-top: 10%;
  margin-right: 10%;
}
#div3 .bigButton {
  margin-bottom: 10%;
  margin-left: 10%;
}
#div4 .bigButton {
  margin-bottom: 10%;
  margin-right: 10%;
}
div.parentOnlyDiv {
  margin-top: -140px;
  width: 100%;
  color: white;
  font-size: 15px;
}
div.redTheme {
  background: rgb(145, 0, 92);
  background: linear-gradient(
    135deg,
    rgba(145, 0, 92, 1) 0%,
    rgba(255, 209, 228, 1) 100%
  );
}
div.greenTheme {
  background: rgb(215, 241, 156);
  background: linear-gradient(
    135deg,
    rgba(9, 64, 0, 1) 0%,
    rgba(215, 241, 156, 1) 100%
  );
}
div.blueTheme {
  background: rgb(0, 20, 51);
  background: linear-gradient(
    135deg,
    rgba(0, 20, 51, 1) 0%,
    rgba(35, 204, 220) 100%
  );
}
div.orangeTheme {
  background: rgb(181, 109, 0);
  background: linear-gradient(
    135deg,
    rgba(181, 109, 0, 1) 0%,
    rgba(252, 229, 109, 1) 100%
  );
}
</style>
<template>
  <div class="featuresPage" :class="{ native: native }">
    <!-- <van-switch v-model="value" @change="change"></van-switch> -->
    <div id="div1">
      <div
        v-if="is_staff === 0"
        class="bigButton"
        @click="redirectTo('myChats')"
      >
        <span v-if="currentStats.chats" class="fas fa-bell"></span>
        <h4 class="title">
          School
          <br />Messenger
        </h4>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/messages.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          View and message
          <br />parents from <br />your school
        </div>
      </div>
      <div
        v-if="is_staff === 1"
        class="bigButton"
        @click="redirectTo('myChats')"
      >
        <span v-if="currentStats.chats" class="fas fa-bell"></span>
        <h4 class="title">
          School
          <br />Messenger
        </h4>
        <div
          style="
            position: absolute;
            margin-top: -10px;
            position: absolute;
            margin-top: -22px;
            right: 0;
            left: 0;
            text-align: center;
            font-size: 10px;
            color: #fff;
          "
        >
          Staff edition
        </div>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/messages.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          View and message
          <br />colleagues and parents <br />from your school
        </div>
      </div>
    </div>
    <div id="div2">
      <div
        v-if="is_staff === 0"
        class="bigButton"
        @click="redirectTo('searchParents', 'journey_share')"
      >
        <h4 class="title">
          Journey
          <br />Share
        </h4>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/journeyshare.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          Connect with nearby
          <br />parents looking to <br />journey share
        </div>
      </div>
      <div
        v-if="is_staff === 1"
        class="bigButton"
        @click="redirectTo('searchParents', 'staff_journey_share')"
      >
        <h4 class="title">
          Journey
          <br />Share
        </h4>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/journeyshare.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          Connect with nearby
          <br />school employees looking <br />to journey share
        </div>
      </div>
    </div>
    <div id="div3">
      <div
        v-if="is_staff === 0"
        class="bigButton"
        @click="redirectTo('searchParents', 'travel_buddy')"
      >
        <h4 class="title">
          Travel
          <br />Buddies
        </h4>
        <div class="imageBlock">
          <img
            src="@/assets/bigMenu/buddy.png"
            style="height: 80%; margin-top: 10px"
            alt
          />
        </div>
        <div class="line"></div>
        <div class="smallText">
          Find suitable buddies
          <br />so your child does <br />not travel alone
        </div>
      </div>
      <div v-if="is_staff === 1" class="bigButton" style="opacity: 0.3">
        <h4 class="title">
          Travel
          <br />Buddies
        </h4>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/buddy.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          Find suitable buddies
          <br />so your child does <br />not travel alone
        </div>
      </div>
      <div v-if="is_staff === 1" class="parentOnlyDiv">Parent access only</div>
    </div>
    <div id="div4">
      <div v-if="is_staff === 0" class="bigButton" @click="redirectJourney()">
        <span v-if="activeJourneys" class="fas fa-bell"></span>
        <h4 class="title">
          Independent
          <br />Travel
        </h4>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/track.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          If your child has a
          <br />smartphone, monitor <br />their school journeys
        </div>
        <div v-if="is_staff === 1" class="parentOnlyDiv">
          Parent access only
        </div>
      </div>
      <div v-if="is_staff === 1" class="bigButton" style="opacity: 0.3">
        <h4 class="title">
          Independent
          <br />Travel
        </h4>
        <div class="imageBlock">
          <img src="@/assets/bigMenu/track.png" alt />
        </div>
        <div class="line"></div>
        <div class="smallText">
          If your child has a
          <br />smartphone, monitor <br />their school journeys
        </div>
      </div>
      <div v-if="is_staff === 1" class="parentOnlyDiv">Parent access only</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HeaderTitle from "@/components/"

export default {
  name: "features",
  components: {},
  data() {
    return {
      menuChoice: "parent",
      isStaff: true,
      isParent: true,
      currentStats: {
        chats: 0,
      },
      activeJourneys: null,
      showBus: false,
      animate: false,
      value: false,
    };
  },
  computed: {
    native() {
      return this.$store.state.general.native;
    },
    is_staff() {
      return this.$store.state.general.user.is_staff;
    },
  },
  methods: {
    change() {
      this.menuChoice = this.value ? "parent" : "staff";
    },
    redirectTo(routeName, param) {
      if (param) {
        this.$router.push({ name: routeName, params: { type: param } });
      } else {
        this.$router.push({ name: routeName });
      }
    },
    redirectJourney() {
      // return;
      if (this.$store.state.general.native == true) {
        var version = parseInt(
          String(this.$store.state.general.version).split(".")[0]
        );
        if (version >= 7) {
          window.postMessage("showJourneys", "*");
        } else {
          this.$dialog.alert({
            title: "Independent Travel",
            message:
              "To use this feature, please update your App to the latest version from the App Store or Play Store.",
          });
        }
      } else {
        this.$dialog.alert({
          title: "Independent Travel",
          message: "This feature is only available on the smartphone app",
        });
        // alert("This feature is only available on the smartphone app");
        // _parent.goto("main.trackMyChildren", {childId: student_id}, "main.home");
      }
    },
  },
  mounted() {
    this.animate = true;
  },
  beforeDestroy() {
    this.animate = false;
  },
};
</script>
