<style>
#fields .van-field__control:disabled {
  color: black !important;
  -webkit-text-fill-color: black;
}
</style>
<style scoped>
span.label {
  height: 46px;
  line-height: 46px;
  min-width: 100px;
  flex-grow: 0;
  text-align: left;
}
div.rowFlex {
  display: flex;
}
</style>
<template>
  <div id="registrationMiWrapper" class="clearfix">
    <div class="" style="min-height: 80px">
      <MiFlowHeader
        page_title="Are your details correct?"
        :logo="schoolInfo.logo"
      />
    </div>
    <div id="flexDiv" style="min-height: 600px">
      <div>
        <p class="mialert lightGreen">
          Please confirm that the below information from the school management
          information system is correct, or update it if not.
        </p>
      </div>
      <div id="fields" class="row">
        <div class="col-xs-12 col-sm-6 rowFlex">
          <span class="label">Title</span>
          <select
            data-cy="title_input"
            class="form-control roundInput flex-grow-1"
            v-model="title"
            style="height: 46px; margin-bottom: 7px"
            placeholder="Select your title"
          >
            <option value="" disabled selected>Select your title</option>
            <option>Mrs</option>
            <option>Mr</option>
            <option>Ms</option>
            <option>Miss</option>
            <option>Dr</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 rowFlex">
          <span class="label">First name</span>
          <van-field
            data-cy="first_name_input"
            name="First name"
            label=""
            placeholder="First name"
            type="text"
            v-model="first_name"
            :error-message="!first_name ? 'Required' : ''"
            maxlength="15"
            style="margin-bottom: 7px"
            class="roundInput flex-grow-1"
          ></van-field>
        </div>
        <div class="col-xs-12 col-sm-6 rowFlex">
          <span class="label">Last name</span>
          <van-field
            data-cy="last_name_input"
            name="Last name"
            label=""
            placeholder="Last name"
            type="text"
            v-model="last_name"
            maxlength="15"
            style="margin-bottom: 7px"
            :error-message="!last_name ? 'Required' : ''"
            class="roundInput flex-grow-1"
          ></van-field>
        </div>
      </div>
      <div class="row">
        <div style="margin-bottom: 5px" class="text-left col-xs-12">
          <b>Your Password</b>
        </div>
        <div class="col-xs-12 col-sm-6 rowFlex">
          <span class="label">Password</span>
          <van-field
            data-cy="password_input"
            label=""
            name="Password"
            placeholder="Password"
            type="password"
            v-model="password"
            :error-message="
              password.length > 0 && password.length < 8
                ? 'Minimum 8 characters required'
                : ''
            "
            minlength="8"
            maxlength="25"
            style="margin-bottom: 7px"
            class="roundInput"
          ></van-field>
        </div>
        <div class="col-xs-12 col-sm-6 rowFlex">
          <span class="label" style="line-height: 20px; padding-top: 4px"
            >Confirm Password</span
          >
          <van-field
            data-cy="confirm_password_input"
            label=""
            name="Password"
            placeholder="Confirm Password"
            type="password"
            v-model="confirm_password"
            :error-message="
              password.length > 0 && password != confirm_password
                ? 'Passwords do not match'
                : ''
            "
            minlength="8"
            maxlength="25"
            style="margin-bottom: 7px"
            class="roundInput"
          ></van-field>
        </div>
      </div>
      <p class="mialert lightGray">
        To run our service, we may occasionally send you email updates as part
        of our service. You can unsubscribe from these at any time.
      </p>
      <div>
        <Checkbox
          id="agreed"
          color="#21acf5"
          data-cy="agreed_check"
          v-model="agreed"
          :size="20"
          style="float: left; width: 40px"
        ></Checkbox>
        <p
          style="padding-top: 12px"
          class="text-left"
          @click="agreed = !agreed"
        >
          Please tick this box to confirm your agreement to our
          <a
            target="_blank"
            href="https://homerun-app.com/eula"
            class="primaryColor"
            >EULA</a
          >
          and
          <a
            target="_blank"
            href="https://homerun-app.com/privacy"
            class="primaryColor"
            >Privacy Policy</a
          >.
        </p>
      </div>
      <div class="row col-xs-12 form-action-row" style="flex-grow: 0">
        <!-- <van-button type="info" @click="$router.go(-1)" plain>Go Back</van-button> -->
        <van-button
          type="info"
          name="button"
          data-cy="submit_btn"
          class="blackBtn"
          @click="submit()"
          :disabled="
            !password ||
            password.length < 8 ||
            password != confirm_password ||
            !first_name ||
            !last_name ||
            !title ||
            !agreed
          "
          >Submit</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import Checkbox from "vue-material-checkbox";
import MiFlowHeader from "@/components/registration_mi/MiFlowHeader.vue";

export default {
  name: "enterEmail",
  components: {
    Checkbox,
    MiFlowHeader,
    // Header,
    // ModalHeader,
  },
  data() {
    return {
      duplicateErrorModal: false,
      unrecognisedEmailModal: false,
      title: "",
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      agreed: false,
      schoolInfo: {
        name: "",
        logo: "",
      },
    };
  },
  methods: {
    submit() {
      
      if (
        !this.title ||
        !this.first_name ||
        !this.last_name ||
        !this.password ||
        this.password.length < 8
      ) {
        this.$notify({
          type: "warning",
          message: "Please fill all the required fields",
        });
        return;
      }
      if(this.agreed == false){
        this.$notify({
            message: "Please accept to proceed",
            type: "danger",
          });
          return;
      }
      let codes = [];
      let hash = null;
      if (sessionStorage.getItem("hash")) {
        hash = sessionStorage.getItem("hash");
      } else if (sessionStorage.getItem("registration_codes")) {
        codes = JSON.parse(sessionStorage.getItem("registration_codes"));
      } else {
        alert("No codes provided");
      }

      const data = {
        password: this.password,
        title: this.title,
        first_name: this.first_name,
        last_name: this.last_name,
        codes: codes,
        hash: hash,
        main_school_id: sessionStorage.getItem("main_school_id"),
        school_id: sessionStorage.getItem("school_id"),
      };

      if (sessionStorage.getItem("invitation_token")) {
        data.invitation_token = sessionStorage.getItem("invitation_token");
      }

      ApiService.generalApi.registration(data).then((resp) => {
        // sessionStorage.clear();
        if (resp.data?.status == "ok") {
          this.$store.dispatch("setToken", resp.data.token);
          this.$store.dispatch("setShowCongratsPage", true);
          setTimeout(() => {
            sessionStorage.clear();

            this.$router.push("/redirect");
          }, 700);
        }
      });
    },
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    hideModal(modalName) {
      this.$modal.hide(modalName);
    },
    login() {
      this.$router.push("/login");
    },
  },
  mounted() {
    if (!sessionStorage.getItem("first_name")) {
      // redirect
      alert("Token not found. Please restart your registration");
      this.$router.push("/");
    }

    this.first_name = sessionStorage.getItem("first_name");
    this.last_name = sessionStorage.getItem("last_name");
    this.title = sessionStorage.getItem("title");
    const currentAcctype = this.$router.currentRoute.params.type;
    this.accType = currentAcctype;
    if (sessionStorage.getItem("schoolName")) {
      this.schoolInfo.name = sessionStorage.getItem("schoolName");
      this.schoolInfo.logo = sessionStorage.getItem("logo");
    }
  },
};
</script>
