<template>
  <!-- Dynamic -->
  <div class="dynamicDiv">
    <block-title
      @noticeEvent="noticeEvent"
      :message="message"
      noHiding="true"
    />
    <div class="classLabel seeMoreLabel" v-if="message.user_type != 5">
      <span v-if="message.class_label">
        Class:
        <b>{{ message.class_label }}</b>
      </span>
      <span v-if="message.years && !message.class_label">
        Years:
        <b>{{ message.years }}</b>
      </span>
    </div>
    <!-- <div class='leftBlock'> -->
    <div
      class="text-bold marginLeft40 positionRelative"
      style="padding-top: 14px"
    >
      <strong class="questionLabel"
        >{{ message.title }} - Action required</strong
      >
    </div>
    <div v-if="message.message != ''">
      <p class="blockText noticeText polltext" v-html="message.message"></p>
    </div>
    <div v-if="message.extras.action_type === 'children_year'">
      <div
        class="childRow col-xs-12"
        :key="$index"
        v-for="(child, $index) in children"
      >
        <div class="col-xs-4">
          <img class="smallAvatar" :src="child.profile_photo_url" />
        </div>
        <div class="col-xs-8">
          <b>{{ child.first_name }} {{ child.last_name }}</b>
          <br />Year:
          <span v-if="child.current_year > 0">{{ child.current_year }}</span>
          <span v-if="child.current_year == 0">Reception</span>
          <span v-if="child.current_year == -1">Nursary</span>
        </div>
      </div>
    </div>
    <div v-if="message.extras.answers" class="positionRelative">
      <ul class="pollChoices">
        <li :key="$index" v-for="(answer, $index) in message.extras.answers">
          <!-- <span v-if="dynamicNoticeIdSelected === message.extras.dynamic_notice_id">

          <span v-if='dynamicNoticeAnswerId === $index' class="fas fa-circle"></span>
        </span>
          <span v-if='dynamicNoticeAnswerId !== $index' class="far fa-circle"></span>-->
          <button
            v-if="message.user_id != $root.user_id"
            class="btn btn-default rounded25"
            @click="dynamicNoticeAnswer($index)"
            :class="{
              active: $index == answerIndex ? true : false,
            }"
          >
            <strong style="color: black"></strong>
            <i class="far fa-check-circle"></i>
            {{ answer.answer }}
          </button>
          <!-- <span v-if='message.viewed_status || message.user_id == $root.user_id' class="answerIcon"><strong><i
          class="fas fa-check-circle"></i></strong> {{ answer.answer }}</span>-->
        </li>
      </ul>
    </div>
    <div
      v-if="
        message.extras.text_input == 'true' && message.user_id != $root.user_id
      "
      style="margin-top: 15px"
    >
      <textarea
        class="form-control"
        maxlength="180"
        trim="true"
        placeholder="Respond here (180 character limit)"
        style="margin-bottom: 10px"
        v-model="textAnswer"
      ></textarea>
    </div>
    <div
      class="marginLeft40 positionRelative"
      v-if="!message.viewed_status && message.user_id != $root.user_id"
    >
      <!-- <van-button
        class="btn btn-default disabled rounded25"
        v-if="
          (dynamicNoticeIdSelected != message.extras.dynamic_notice_id &&
            !message.extras.text_input) ||
            (message.extras.text_input == 'true' &&
              dynamicNoticeAnswerText == '' &&
              dynamicNoticeIdSelected == message.extras.dynamic_notice_id)
        "
        title="Please select an answer to submit"
      >
        Submit
      </van-button>-->
      <van-button
        type="default"
        size="normal"
        class="round25 bgTrump"
        :disabled="answerIndex === null && textAnswer === null"
        @click="submitDynamicNoticeAnswer"
        >Submit</van-button
      >
    </div>

    <NoticeFooter @noticeEvent="noticeEvent" :message="message" />
  </div>
  <!-- End Poll -->
</template>
<style scoped>
.childRow {
  margin-bottom: 5px;
}
</style>
<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
import NoticeFooter from "@/components/noticeTypes/other/NoticeFooter";
export default {
  name: "dynamic",
  components: {
    BlockTitle,
    NoticeFooter,
  },
  data() {
    return {
      answerIndex: null,
      children: [],
      textAnswer: null,
    };
  },
  props: {
    message: Object,
  },
  methods: {
    dynamicNoticeAnswer($index) {
      this.answerIndex = $index;
      this.$forceUpdate();
    },
    noticeEvent(type) {
      this.$emit("noticeEvent", {
        action: type,
        id: this.message.id,
        user_id: this.message.user_id,
      });
    },
    submitDynamicNoticeAnswer() {
      if (this.message.extras.action_type === "children_year") {
        // alert(this.answerIndex);
        this.$emit("noticeEvent", {
          action: "hideNotice",
          id: this.message.id,
        });
        this.$router.push({ name: "myChildren" });
      }
      // alert("submitting");
    },
  },
  mounted() {
    if (this.message.extras.action_type === "children_year") {
      this.children = this.$store.state.general.children;
    }
  },
};
</script>
