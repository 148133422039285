<template>
  <div>
    <div>
      <img
        id="schoolLogo"
        class="school-logo"
        :src="school_logo"
        alt
        data-cy="school_logo"
      />
      <div class="col-xs-12 form-heading" data-cy="school_name_text">
        Welcome to the <strong>{{ school_name }}</strong> HomeRun Community!
      </div>
      <div class="col-xs-12">
        <div class="mialert lightGreen" data-cy="message_text">
          This setup wizard will guide you through our simple registration
          process and tailor the service to your needs.
          <br />
          This will only take a few minutes.
        </div>
      </div>

      <div class="col-xs-12 clearfix">
        <div class="form-row">
          <div class="form-row__title no-border">Your Details</div>
          <div>
            <van-cell title="Title">
              <div class="form-select-wrapper half">
                <select
                  name="salutation"
                  :class="
                    $v.salutation.$error && showErrors ? 'errorInput' : ''
                  "
                  class="form-select"
                  placeholder="Select your title"
                  v-model="$v.salutation.$model"
                  data-cy="salutation_input"
                >
                  <option value="" disabled selected>Select your title</option>
                  <option>Mrs</option>
                  <option>Mr</option>
                  <option>Ms</option>
                  <option>Miss</option>
                  <option>Dr</option>
                </select>
              </div>
            </van-cell>
          </div>
          <div>
            <van-field
              label="First name"
              name="first_name"
              required
              data-cy="first_name_input"
              v-model="$v.first_name.$model"
              :class="$v.first_name.$error && showErrors ? 'errorInput' : ''"
            ></van-field>
          </div>
          <div>
            <van-field
              label="Last name"
              name="last_name"
              required
              data-cy="last_name_input"
              v-model="$v.last_name.$model"
              :class="$v.last_name.$error && showErrors ? 'errorInput' : ''"
            ></van-field>
          </div>
          <div>
            <van-field
              label="Email"
              type="email"
              data-cy="email_input"
              name="email"
              required
              v-model="email"
              :class="$v.email.$error && showErrors ? 'errorInput' : ''"
            ></van-field>
          </div>
          <div
            v-if="accountExists"
            class="mialert lightRed"
            data-cy="account-exists-text"
          >
            This email address has already created an account. login here to
            finish your registration.
            <div class="marginTop15">
              <button
                data-cy="try_login_btn"
                @click="tryLogin()"
                class="btn-black"
              >
                Click here to Login
              </button>
              <!-- <button
                class="btn btn-success"
                ui-sref="login({email: vm.user.email })"
              >Click here to Login</button>-->
            </div>
          </div>
          <div
            class="text-danger"
            data-cy="show_check_your_email_info"
            v-if="showErrors && $v.email.$error"
          >
            Check your email
          </div>
        </div>
        <div class="form-row">
          <div class="form-row__title no-border">Your Password</div>
          <div>
            <van-field
              label="Password"
              name="password"
              placeholder="Min 8 characters"
              type="password"
              required
              autocomplete="off"
              minlength="8"
              data-cy="password_input"
              v-model="$v.password.$model"
              :class="$v.password.$error && showErrors ? 'errorInput' : ''"
            >
              <van-button type="default">
                <i class="fa fa-eye"></i>
              </van-button>
            </van-field>
            <div
              class="text-danger"
              v-if="showErrors && !$v.password.minLength"
              data-cy="password_too_short_text"
            >
              Password have to be at least 8 characters long
            </div>
          </div>
          <div>
            <van-field
              label="Confirm Password"
              name="confirm_password"
              type="password"
              data-cy="confirm_password_input"
              required
              minlength="8"
              v-model="$v.confirm_password.$model"
              :class="
                $v.confirm_password.$error && showErrors ? 'errorInput' : ''
              "
            >
            </van-field>
            <div
              class="text-danger"
              v-if="showErrors && !$v.confirm_password.sameAsPassword"
              data-cy="passwords_not_same_text"
            >
              Passwords have to be identical
            </div>
          </div>
        </div>
      </div>
      <div v-if="isStaff" class="staffSchoolChoice col-xs-12">
        <div class="form-row--small">
          <div class="form-row__title">
            Do you have children that attend {{ school_name }}?
          </div>
          <div>
            <van-cell>
              <div class="form-select-wrapper">
                <select
                  class="form-select"
                  v-model="staffHasChildren"
                  data-cy="does_staff_has_children_input"
                >
                  <option value="0" selected>No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </van-cell>
          </div>
        </div>
      </div>
      <div class="col-xs-12 text-left">
        <van-button
          class="view-policy-btn"
          type="default"
          size="small"
          data-cy="viewOurDataPolicyButton"
          @click="toggleViewOurData"
        >
          <span v-if="!showViewOurData" class="fa fa-plus"></span>
          <span v-if="showViewOurData" class="fa fa-minus"></span> View our data
          policy
        </van-button>
        <div
          v-if="showViewOurData"
          class="data-policy-content"
          data-cy="data-policy-text"
        >
          <ul>
            <li>
              We do not access any personal data without your explicit consent.
            </li>
            <li>
              We do not share or sell personal data to third parties, under any
              circumstances.
            </li>
            <li>
              We promote your rights to access and control your personal data.
            </li>
            <li>
              We do not store or transfer any personal data outside of the EEA.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="agreement-text flex">
          <Checkbox
            id="mycheck1"
            v-model="agreement"
            :value="50"
            data-cy="agreement_btn"
            class="flex-1"
          >
          </Checkbox>
          <div class="flex-auto">
            Please tick this box to confirm your agreement to our
            <a
              target="_blank"
              href="https://homerun-app.com/eula"
              class="primaryColor"
              >EULA</a
            >
            and
            <a
              target="_blank"
              href="https://homerun-app.com/privacy"
              class="primaryColor"
              >Privacy Policy</a
            >.
          </div>
        </div>
        <div class="text-center mt-6 mb-10">
          <button
            class="btn btn-black"
            type="info"
            data-cy="confirm_btn"
            :disabled="!agreement"
            @click="confirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
    <div v-if="council_logo" class="row">
      <div class="col-xs-12 council-logo">
        <small>Supported by: </small>
        <img :src="council_logo" style="height: 50px" alt="" />
      </div>
    </div>
    <modal
      class="modal-flow"
      name="confirmModal"
      :adaptive="true"
      :height="'auto'"
    >
      <ModalHeader
        flow
        title=""
        data-cy="hideConfirmModalButton"
        @hideModal="hideModal()"
      />
      <div class="confirmModal loaded text-center" data-cy="confirm_modal">
        <div class="text-center">
          <h3 style="font-size: 17px">
            <strong>{{ email }}</strong>
          </h3>
          <p
            style="
              text-align: justify;
              padding: 10px;
              font-size: 14px;
              color: #333;
            "
          >
            Please ensure that you have entered your email address correctly.
            This will be your username and required to validate your account
            from time to time.
          </p>
        </div>
        <div class="form-action-row">
          <button
            @click="hideModal()"
            data-cy="editModalButton"
            style="margin-right: 20px"
            class="btn greyBtn van-button van-button--info van-button--normal van-button--plain"
          >
            Edit
          </button>
          <button data-cy="submit_btn" @click="submit" class="btn btn-black">
            Confirm
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import ModalHeader from "@/components/ModalHeader";
import Checkbox from "vue-material-checkbox";
import {
  required,
  minLength,
  sameAs,
  // maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "register",
  components: {
    Checkbox,
    ModalHeader,
  },
  data() {
    return {
      school_logo: "",
      school_name: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      salutation: "",
      council_logo: "",
      selection: {},
      showViewOurData: false,
      checkbox: [],
      agreement: false,
      showErrors: false,
      accountExists: false,
      staffHasChildren: 0,
      isStaff: false,
    };
  },
  validations() {
    var validations = {};

    validations.first_name = {
      required,
      minLength: minLength(2),
    };

    validations.last_name = {
      required,
      minLength: minLength(2),
    };

    validations.salutation = {
      required,
      minLength: minLength(2),
    };

    validations.email = {
      required,
      email,
      minLength: minLength(5),
    };

    validations.password = {
      required,
      minLength: minLength(8),
    };

    validations.confirm_password = {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs("password"),
    };

    if (this.staffRegistration) {
      validations.staffHasChildren = {
        required,
      };
    }

    return validations;
  },
  methods: {
    toggleViewOurData() {
      this.showViewOurData = !this.showViewOurData;
    },
    setCategory(category) {
      this.selection.category = category;
      this.$forceUpdate();
    },
    hideModal() {
      this.$modal.hide("confirmModal");
    },
    tryLogin() {
      this.$router.push({ name: "login", params: { email: this.email } });
    },
    confirm() {
      this.showErrors = true;
      this.$v.$touch();
      this.accountExists = false;

      if (
        this.password != this.confirm_password &&
        this.password &&
        this.password.length > 7
      ) {
        this.$notify({
          message: "Passwords do not match.",
          type: "warning",
        });
        return;
      }

      if (!this.password || this.password.length < 8) {
        //match("^[.a-zA-Z0-9]*$\W")
        this.$notify({
          message: "Password has to be at least 8 characters.",
          type: "warning",
        });
        return;
      }

      if (!this.salutation) {
        this.$notify({
          message: "Salutation is required.",
          type: "warning",
        });
        return;
      }
      if (this.$v.$invalid) {
        return;
      }
      this.$modal.show("confirmModal");
    },
    checkCode() {
      ApiService.generalApi
        .check_school_code(this.$store.state.general.schoolCode)
        .then((response) => {
          if (response.status == 200 && response.data.status != 0) {
            this.school_name = response.data.name;
            this.school_logo = response.data.logo_url;
            this.council_logo = response.data.council_logo;
            if (response.data.staff) {
              this.isStaff = true;
            }
          } else {
            // this.codeExists = false;
          }
        })
        .catch(function () {
          // vm.error = response.data.error.message;
          // vm.loading = false;
        });
    },
    submit() {
      this.$v.$touch();
      if(this.agreement == false){
        this.$notify({
            message: "Please accept to proceed",
            type: "danger",
          });
        return;
      }
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch("loading", true);
      let payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        salutation: this.salutation,
        password: this.password,
        confirm_password: this.confirm_password,
        code: this.$store.state.general.schoolCode,
      };

      if (this.isStaff) {
        payload.staffHasChildren = this.staffHasChildren;
      }
      ApiService.userApi
        .registerUser(payload)
        .then((response) => {
          if (response.status == 200) {
            this.$store.dispatch("register", response.data);
            // setTimeout(() => {
            const parentToken = this.$store.state.general.parent2_token;
            ApiService.userApi
              .getUserOrganizations(null, parentToken)
              .then(() => {
                this.$router.push({ name: "details" });
              });
            // }, 100);
          }
        })
        .catch((error) => {
          this.$store.dispatch("loading", false);
          if (
            (error.response.data.errors &&
              error.response.data.errors.indexOf(
                "The email has already been taken."
              )) ||
            (error.response.data.error &&
              error.response.data.error.indexOf(
                "The email has already been taken."
              ) > -1)
          ) {
            this.hideModal();
            this.$notify({
              message: "This email has already been taken",
              type: "danger",
            });
            this.accountExists = true;
          }
        });
    },
  },
  mounted() {
    this.checkCode();
    this.$emit("progress-step", 1);
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #21acf5;

.mint-cell-text {
  color: $primary-color;
}

p {
  font-size: 15px;
  color: #979797;
}

button.cat {
  background: #ffffff;
  border: 1px solid #cccccc;
  color: #979797;
  width: 100%;
  padding: 10px;
  margin-bottom: 4px;
  border-radius: 25px;

  &.active {
    background: $primary-color;
    color: #ffffff;
  }
}

.agreement-text {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  color: #666666;
  text-align: left;
  line-height: 13px;
  letter-spacing: 0.02em;
}

.school-logo {
  position: relative;
  height: 50px;
  margin-top: 10px;
}

.intro {
  position: relative;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.4;
  margin: 15px 0;
}

.view-policy-btn {
  color: $primary-color;
  border: 0;
  margin: 5px 0 10px -10px;
}

.data-policy-content {
  margin-bottom: 15px;
  font-weight: 300;
}

.council-logo {
  position: relative;
  margin-top: 15px;
}
</style>
