import dbConnection from "../dbConnection";

const authActions = {
    requestPasswordReset: function (payload) {
        return dbConnection.post("api/auth/request-password-reset", payload);
    },
    passResetTokenCheck: function (payload) {
        return dbConnection.post("api/auth/reset-token-check", payload);
    },
    changePassword: function (payload) {
        return dbConnection.post("api/auth/change-password", payload);
    },
};

export default authActions;
