<template>
  <ol v-if="route !== 'Home' && route !== 'schoolRun'" class="breadcrumb">
    <li>
      <router-link data-cy="bread_home_button" to="/main/home"
        >Home</router-link
      >
    </li>
    <li
      :key="index"
      v-for="(item, index) in parts"
      :class="{ active: parts.length - 1 === index }"
    >
      <span class="divider">></span>
      {{ item }}
    </li>
  </ol>
</template>
<script>
export default {
  name: "breadcrumb",
  data() {
    return {
      parts: [],
    };
  },
  props: {
    route: String,
    params: String,
  },
  watch: {
    route: function (newVal) {
      this.convertToParts(newVal);
    },
    params: function () {
      this.convertToParts(this.route);
    },
  },
  methods: {
    convertToParts: function (value) {
      if (value == "schoolRun") {
        this.parts = ["Travel Features"];
      } else if (value == "myChildren") {
        this.parts = ["My Children"];
      } else if (value == "mySchools") {
        this.parts = ["My Schools"];
      } else if (value == "myGroups") {
        this.parts = ["My Groups"];
      } else if (value == "invitePartner") {
        this.parts = ["Invite Partner"];
      } else if (value == "faqs") {
        this.parts = ["FAQ"];
      } else if (value == "contactus") {
        this.parts = ["Contact Us"];
      } else if (value == "searchParents") {
        this.parts = ["Travel Features", "Search Parents"];
        let routeType = null;
        switch (this.$route.params.type) {
          case "journey_share":
            routeType = "Journey Share";
            break;

          default:
            routeType = "";
            break;
        }
        if (routeType) {
          this.parts.push(routeType);
        }
      } else if (value === "myChats") {
        this.parts = ["School Messanger"];
      }
    },
  },
  mounted() {
    this.convertToParts(this.route);
  },
};
</script>
<style scoped>
ol {
  /* background: rgb(230, 230, 230); */
  /* box-shadow: #ccc 2px 2px 2px; */
  text-align: left;
  margin-bottom: 0px;
  /* max-width: 300px; */
}
li {
  display: inline-block;
  margin: 0.5em 10px;
}
li.active {
  font-weight: bold;
}
span.divider {
  margin-right: 10px;
}
</style>
