<template>
  <!-- Poll -->
  <div class="pollDiv notice-theme-poll">
    <div class="notice-header">
      <block-title @noticeEvent="noticeEvent" :message="message" />
      <div class="notice-category bg-notice-theme">
        <i class="fal fa-chart-simple"></i>
        <div class="category-details">
          <div>
            <span class="uppercase">Poll</span>
          </div>
          <div class="category-years">
            <span
              class="yearBlock pull-right"
              v-if="message.years && yearNames == ''"
            >
              Years:
              {{ message.years }}
            </span>

            <span
              class="yearBlock pull-right"
              v-if="!message.years && !message.class_label"
            >
              Years: All
            </span>
            <span class="yearBlock pull-right" v-if="message.class_label">
              {{ message.class_label }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class='leftBlock'> -->
    <div class="notice-body">
      <div v-if="message.message.length > 5">
        <p class="blockText noticeText polltext" v-html="message.message"></p>
      </div>
      <div class="text-bold marginLeft40 positionRelative">
        <strong class="questionLabel">Q: {{ message.title }}</strong>
      </div>
      <div v-if="message.extras.answers" class="positionRelative">
        <p class="marginLeft40" style="font-size: 10px">
          (All responses are anonymous)
        </p>
        <ul class="pollChoices mt-2">
          <li :key="$index" v-for="(answer, $index) in message.extras.answers">
            <button
              v-if="!message.viewed_status && message.user_id != user_id"
              class="btn-white text-left px-3 text-sm font-regular my-1"
              @click="pollAnswer($index)"
              :class="{ '!bg-slate-300': answerIndex == $index ? true : false }"
            >
              <strong style="color: black" class="mr-2">
                <i class="far fa-comment"></i>
              </strong>
              {{ answer.answer }}
            </button>
            <span
              v-if="message.viewed_status || message.user_id == user_id"
              class="answerIcon"
            >
              <strong>
                <i class="fas fa-check-circle"></i>
              </strong>
              {{ answer.answer }}
            </span>
            <div
              class="pollResultLine"
              v-if="
                (message.viewed_status || message.user_id == user_id) &&
                answer.value
              "
              :style="{
                width: (answer.value * 100) / message.extras.total_votes + '%',
              }"
            >
              <span>
                {{
                  parseInt(
                    (answer.value * 100) / parseInt(message.extras.total_votes)
                  )
                }}%
              </span>
            </div>
            <div
              v-if="
                (message.viewed_status || message.user_id == user_id) &&
                !answer.value &&
                message.extras.total_votes === 0
              "
            >
              <span>0%</span>
            </div>
            <div
              class="pollResultLine"
              v-if="
                (message.viewed_status || message.user_id == user_id) &&
                !answer.value
              "
              :style="{ width: '40px' }"
            >
              <span>0%</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div v-if='message.extras.poll_type.type != "fixed" && message.extras.poll_type != "" ' style='margin-top: 15px;'>
      <p style="font-size: 10px;">(All responses are anonymous)</p>
      <textarea class='form-control' ng-maxlength="180" ng-trim='true' maxlength='180' placeholder="Respond here (180 character limit)" style='margin-bottom:10px' ng-model='pollAnswerText' ng-change='pollIdSelected = message.extras.poll_id'></textarea>
      </div>-->
      <!-- <div
        class="marginLeft40 positionRelative"
        v-if="!message.viewed_status && message.user_id != user_id"
      >
        <button
          class="btn-black px-3 py-2 mt-2"
          :disabled="answerIndex > -1 ? false : true"
          title="Please select an answer to submit"
          @click="pollSubmit()"
        >
          Submit
        </button>
      </div> -->
      <span class="seeMoreLabel nomargin" v-if="message.user_id == user_id">
        Total votes:
        <span>{{ message.extras.total_votes || 0 }}</span>
      </span>
    </div>
    <NoticeFooter @noticeEvent="noticeEvent" :message="message" />
  </div>
  <!-- End Poll -->
</template>
<style scoped></style>
<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
import NoticeFooter from "@/components/noticeTypes/other/NoticeFooter";
import ApiService from "@/services/ApiService";

export default {
  name: "poll",
  components: {
    BlockTitle,
    NoticeFooter,
  },
  data() {
    return {
      answerIndex: -1,
      pollAnswerText: "",
      yearNames: "",
    };
  },
  props: {
    message: Object,
    pollIdSelected: Number,
    user_id: Number,
    // title: String,
    // icon: String
  },
  methods: {
    noticeEvent(type) {
      this.$emit("noticeEvent", {
        action: type,
        id: this.message.id,
        user_id: this.message.user_id,
      });
    },
    pollAnswer(index) {
      this.answerIndex = index;
      this.pollSubmit();
    },
    pollSubmit() {
      var textAnswer = this.pollAnswerText != "" ? this.pollAnswerText : null;

      ApiService.generalApi
        .pollAnswer(
          this.message.extras.poll_id,
          this.answerIndex,
          this.message.id,
          textAnswer
        )
        .then((response) => {
          this.message.is_read = 1;
          this.message.viewed_status = 1;
          this.message.extras = JSON.parse(response.data.poll_data);
        });
    },
  },
};
</script>
