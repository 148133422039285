<script>
import ApiService from "@/services/ApiService";
export default {
  name: "autoLogin",
  components: {},
  data() {
    return {};
  },
  methods: {
    init() {
      const token = this.$route.query.t;
      this.$store.dispatch("setToken", token);
      this.getUserInfo();
    },
    async getUserInfo() {
      try {
        const result = await ApiService.userApi.getUserInfo();
        this.$store.dispatch("updateUserInfo", result.data);
        setTimeout(() => {
          this.$router.push({ name: "home" });
        }, 300);
      } catch (error) {
        if (error.response.status === 401) {
          // this.$router.push({ name: "login" });
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
