export default {
  avatars_url: "https://s3-eu-west-1.amazonaws.com/home-run/avatars/",
  s3url: "https://s3-eu-west-1.amazonaws.com/home-run/",
  avatars: [
    1, 5, 9, 11, 14, 15, 22, 23, 35, 39, 40, 48, 37, 12, 24, 31, 36, 38,
  ],
  alphabet: [
    "None",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
};
