<template>
  <div>
    <div id="mainContent" class="mydetails clearfix row" style="border: none">
      <Headerv2 title="My Schools" />
      <div class="col-md-12"></div>
      <div class="col-xs-12">
        <div
          v-if="!loading && schools.length === 0"
          class="alert alert-danger round40"
          style="margin-top: 15px"
        >
          <p style="font-size: 14px; text-align: center">
            In order to use HomeRun you need at least one active school
          </p>
        </div>
        <div class="alert-mira mt-2 mb-2">
          <p class="text-sm">
            <b>Do you have children at other schools?</b><br />
            You can add other schools to your profile so long as they are signed
            up with HomeRun - and providing you have a child at that school or
            are a staff member.
          </p>
        </div>
      </div>
      <div class="col-xs-12 schoolBlock">
        <transition-group name="fade" tag="div">
          <div
            :key="school.id + school.type"
            class="row currentSchoolRow clearfix"
            v-for="school in schools"
          >
            <div class="col-xs-3">
              <img
                class="smallLogo my-3"
                :src="school.organization.logo_url"
                alt
              />
            </div>
            <div class="col-xs-9" style="text-align: left; padding-top: 10px">
              <van-button
                @click="
                  removeSchool(school.organization.id, school.user_role_id)
                "
                type="info"
                size="mini"
                class="btn-black pull-right ml-2"
                >Remove</van-button
              >
              <span class="schoolName">
                <span class="!font-medium">{{ school.organization.name }}</span>
                <br />
                <span
                  class="font-bold text-sm"
                  :class="
                    getRoleSlug(school.user_role_id) != 'STAFF'
                      ? 'parentColor'
                      : 'staffColor'
                  "
                  >{{ getRoleSlug(school.user_role_id) }}</span
                >
              </span>
            </div>
          </div>
        </transition-group>
        <div class="clearfix" style="clear: both"></div>
        <van-button
          type="default"
          size="small"
          plain
          style="border: 0"
          class="pull-left marginVertical20 blackColor font-bold !text-sm !mt-3"
          @click="showCodeSection = !showCodeSection"
          v-if="!showCodeSection"
        >
          <span class="text-md">
            <i
              class="fa fa-plus parentBg text-white text-sm w-5 h-5 inline-block rounded-full p-0.5 mr-1.5"
            ></i>
            Add a new School or Role
          </span>
        </van-button>
        <div class="clearfix" style="clear: both"></div>
      </div>
      <transition name="fade">
        <div
          v-if="showCodeSection"
          class="clearfix addCodeSection text-center p-3 shadow-xl"
          style="margin: 20px 15px; clear: both"
        >
          <h3 class="text-center colorBlack">
            Add your School's
            <br />Parent or Staff Code below
          </h3>
          <p class="alert-mira mt-2 mb-3">
            Codes can be found in your
            <br />school's registration emails
          </p>
          <input
            type="text"
            v-model="newSchoolCode"
            name="code"
            placeholder="Enter Code"
            value
            class="round25 text-black"
            style="
              border: 1px solid #000000;
              padding: 10px;
              margin-bottom: 15px;
              text-align: center;
            "
          />
          <br />
          <van-button type="info" class="btn-black" @click="checkSchoolCode()"
            >Submit</van-button
          >
        </div>
      </transition>
      <modal
        name="confirmAddSchoolmodal"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
      >
        <ModalHeader
          title="Confirm School and Role"
          @hideModal="closeModal()"
        />
        <div class="col-xs-12" style="text-align: center">
          <div class="text-center">
            <img
              v-if="newSchool"
              :src="newSchool.logo_url"
              style="height: 50px"
              alt
            />
          </div>
          <h2
            v-if="newSchool && newSchool.name"
            class="text-lg mb-2 mt-2 leading-5"
          >
            {{ newSchool.name }}
          </h2>
          <div>
            <span v-if="newSchool.staff" class="staffColor font-bold"
              >STAFF ROLE</span
            >
            <span v-if="!newSchool.staff" class="parentColor font-bold"
              >PARENT ROLE</span
            >
          </div>
          <hr />
          <div class="text-center padding30 mt-4">
            <div class="col-xs-4">
              <van-button
                class="greyBtn rounded25"
                type="info"
                plain
                @click="closeModal()"
              >
                <strong>Cancel</strong>
              </van-button>
            </div>
            <div class="col-xs-offset-2 col-xs-4">
              <van-button
                class="btn-black"
                type="info"
                name="button"
                style="width: 150px"
                @click="addOrganization()"
              >
                <strong>Add School</strong>
              </van-button>
            </div>
          </div>
        </div>
      </modal>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import Headerv2 from "@/components/Headerv2.vue";
export default {
  name: "mySchools",
  components: {
    // Header,
    Headerv2,
    ModalHeader,
  },
  data() {
    return {
      schools: [],
      roles: [],
      children: [],
      newSchool: {},
      newSchoolCode: "",
      showCodeSection: false,
    };
  },
  methods: {
    getMySchools() {
      ApiService.userApi.getUserOrganizations().then((response) => {
        if (response.data == "No Schools") {
          this.$notify({
            message: "You have no schools",
            type: "warning",
          });
        } else {
          this.schools = response.data.user_organizations;
          this.roles = response.data.roles;
          this.loading = false;
          this.$store.dispatch("loading", false);
        }
      });
    },
    getRoleSlug(user_role_id) {
      if (this.roles.length > 0) {
        return (
          this.roles.find((itm) => itm.user_role_id == user_role_id)?.slug ?? ""
        );
      } else {
        return "";
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$modal.hide("confirmAddSchoolmodal");
    },
    removeSchool(school_id, user_role_id) {
      this.$dialog
        .confirm({
          title: "Confirm",
          message:
            "Are you sure you want to remove this school role from your profile?",
          confirmButtonText: "Yes",
          // confirmButtonColor: "red"
        })
        .then(() => {
          ApiService.generalApi
            .removeOrganization(school_id, user_role_id)
            .then(() => {
              if (this.$store.state.general.native) {
                this.$notify({
                  message: "Reloading...",
                  type: "success",
                });
                window.postMessage("refreshUserData", "*");
              } else {
                this.getMySchools();
              }
              try {
                  ApiService.userApi.getUserInfo().then((result) => {
                    this.$store.dispatch("updateUserInfo", result.data);
                  });
                } catch (error) {
                  
                }
            });
        });
    },
    checkSchoolCode() {
      ApiService.generalApi
        .check_school_code(this.newSchoolCode)
        .then((response) => {
          if (response.data.name) {
            this.newSchool = response.data;
            this.$modal.show("confirmAddSchoolmodal");
          } else {
            this.$notify({
              message: "Wrong School code",
              type: "warning",
            });
          }
        });
    },
    addOrganization() {
      ApiService.generalApi.addOrganization(this.newSchoolCode).then(() => {
        this.$notify({ type: "success", message: "Added!" });
        // this.getMySchools();
        this.getMySchools(true);
        ApiService.userApi.accountStatusRedirect(false, true);
        this.closeModal();
      });
    },
  },
  created() {
    this.$store.dispatch("loading", true);
    this.getMySchools();
    // setTimeout(() => {
    //   this.showPage = true;
    //   this.loading = false;
    // }, 400);
  },
};
</script>
<style lang="scss" scoped>
.currentSchoolRow {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
.schoolName {
  text-align: left;
}
.smallLogo {
  max-height: 50px;
}
h3 {
  font-size: 20px !important;
}
#mainContent {
  height: auto;
  min-height: 100%;
}
</style>
