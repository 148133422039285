<style lang="scss" scoped>
$primary-color: #21acf5;
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.table {
  border-collapse: collapse;
  padding: 0px;
  margin: auto;
  margin-bottom: 20px;
  td {
    padding: 0;
    margin: 0;
  }
  // margin: 10px;
}
#basic-addon1 {
  line-height: 25px;
}
#sendButton {
  // border-radius: 0px !important;
  font-size: 12px;
}
</style>
<style>
div#codeInputs input {
  width: 40px;
  height: 60px;
  margin-right: 20px;
  border-radius: 15px;
  padding: 0px !important;
  font-size: 30px;
  text-align: center;
  border: 2px solid black;
}
</style>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<template>
  <div class="form-row" style="position: relative">
    <div class="form-heading">Protecting Your School Network</div>
    <div class="col-xs-12">
      <div class="mialert lightGreen">
        <p>
          To help us verify your account,<br />
          please enter your email<br />
          to receive a verification code.
        </p>
      </div>
    </div>
    <div class="marginTop15">
      <table class="table" border="0" style="width: 90%">
        <tr>
          <td>
            <input
              type="text"
              v-model="email"
              class="form-control"
              placeholder="Email"
              data-cy="email_input"
            />
          </td>
          <div>
            <button
              class="btn btn-black !rounded-l-none rounded-r-lg !rounded-tl-none"
              id="sendButton"
              :disabled="!email || !email.includes('@')"
              style="
                margin-bottom: 0px !important;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
                height: 40px;
                width: 100%;
                padding: 0 12px;
              "
              @click="sendCode()"
              data-cy="send_code_btn"
            >
              Send Code
            </button>
          </div>
        </tr>
      </table>
    </div>
    <div class="row" v-if="codeSent">
      <div id="codeInputs" class="col-xs-12 text-center">
        <p>Enter verification code</p>
        <div>
          <input
            id="c1"
            ref="c1"
            name="c1"
            v-model="c1"
            data-cy="c1_input"
            autocomplete="off"
            @keypress="focusInput($event, 'c2')"
            @click="selectInputText('c1')"
            @paste="onPaste"
            type="number"
            pattern="[0-9]{1}"
            maxlength="1"
          />
          <input
            id="c2"
            ref="c2"
            v-model="c2"
            autocomplete="off"
            data-cy="c2_input"
            @keypress="focusInput($event, 'c3')"
            @keydown="backSpace($event, 'c1')"
            @click="selectInputText('c2')"
            @paste="onPaste"
            type="number"
            pattern="[0-9]{1}"
            maxlength="1"
          />
          <input
            id="c3"
            ref="c3"
            v-model="c3"
            autocomplete="off"
            data-cy="c3_input"
            @keypress="focusInput($event, 'c4')"
            @keydown="backSpace($event, 'c2')"
            @click="selectInputText('c3')"
            @paste="onPaste"
            type="number"
            pattern="[0-9]{1}"
            maxlength="1"
          />
          <input
            id="c4"
            ref="c4"
            v-model="c4"
            autocomplete="off"
            data-cy="c4_input"
            @keypress="focusInput($event, 'c5')"
            @click="selectInputText('c4')"
            @keydown="backSpace($event, 'c3')"
            @paste="onPaste"
            type="number"
            pattern="[0-9]{1}"
            maxlength="1"
          />
          <input
            id="c5"
            ref="c5"
            v-model="c5"
            autocomplete="off"
            data-cy="c5_input"
            @click="selectInputText($event, 'c5')"
            @keydown="backSpace($event, 'c4')"
            @keypress="focusInput($event, 'c5')"
            @paste="onPaste"
            type="number"
            pattern="[0-9]{1}"
            maxlength="1"
          />
        </div>
      </div>
    </div>
    <div v-if="numberExists" class="alert alert-warning">
      <div class="pull-left">
        <p>
          We already have an account registered to that mobile number. Please
          login below or enter a new number to create a new account.
        </p>
      </div>
      <div>
        <button class="btn btn-success" @click="redirect()">
          Click here to Login
        </button>
      </div>
    </div>
    <div>
      <div class="col-md-12 text-center padding30">
        <button
          v-if="code"
          id
          @click="submit()"
          :disabled="!code || code.length != 5"
          :class="{ disabled: !code || code.length != 5 }"
          class="btn btn-black mt-5"
          name="button"
          data-cy="continue_btn"
        >
          Continue
        </button>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "verification",
  data() {
    return {
      selection: {},
      codeSent: null,
      email: null,
      numberExists: false,
      showPage: true,
      loading: false,
      c1: "",
      c2: "",
      c3: "",
      c4: "",
      c5: "",
    };
  },
  computed: {
    code() {
      return this.c1 + this.c2 + this.c3 + this.c4 + this.c5;
    },
    currentEmail: function () {
      return this.$store.state.general.user.email;
    },
  },
  watch: {
    c1(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c1 = newVal;
      }
    },
    c2(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c2 = newVal;
      }
    },
    c3(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c3 = newVal;
      }
    },
    c4(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c4 = newVal;
      }
    },
    c5(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c5 = newVal;
      }
    },
  },
  methods: {
    focusInput($event, inputName) {
      this.filterKey($event);
      if (this.isInt($event.key)) {
        setTimeout(() => {
          this.$refs[inputName].focus();
          // this.$refs[inputName].select();
        }, 20);
      } else {
        $event.target.value = "";
      }
    },
    filterKey(e) {
      let previousValue = e.target.value;
      let key = e.keyCode || e.charCode;
      if (key != 8 && key != 46 && key != 91 && key != 86) {
        // Ignore backspace and delete
        if (
          // preventDefault if length of input is 4 and no text is selected in the input
          (String(e.target.value).length >= 4 &&
            e.target.selectionStart === e.target.selectionEnd) ||
          // preventDefault if entered a space or non-number
          !e.key.trim() ||
          isNaN(e.key)
        ) {
          // Prevent input if input length crosses 4,
          // or if input is not a number
          e.preventDefault();
          // Include below line only if you have no other event listeners on the element, or its parents
          e.stopImmediatePropagation();
          return;
        }
      }
    },
    backSpace(e, prevInputName) {
      let key = e.keyCode || e.charCode;
      if (key == 8 && e.target.value == "") {
        //
        this.$nextTick(() => {
          this[prevInputName] = "";
          this.$refs[prevInputName].focus();
          this.$refs[prevInputName].setSelectionRange(0, 0);
        });
      }
    },
    selectInputText(inputName) {
      // this.$refs[inputName].setSelectionRange(0, 0);
    },
    isInt(value) {
      return (
        !isNaN(value) &&
        (function (x) {
          return (x | 0) === x;
        })(parseFloat(value))
      );
    },
    onPaste(evt) {
      console.log("on paste", evt);
      const pastedText = evt.clipboardData.getData("text");
      const letters = pastedText.split("");
      letters.forEach((l, index) => {
        if (index < 5 && this.isInt(l)) {
          this["c" + (index + 1)] = l;
        }
      });
    },
    submit() {
      ApiService.userApi
        .validateVerificationCode(this.code, this.email)
        .then((response) => {
          this.loading = false;
          if (response.data.status == 1) {
            this.$router.push({ name: "congratulations" });
          } else {
            this.$notify({
              message: "Please check your code.",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            const error = err.response.data.error;
            const message =
              error == "code_expired"
                ? "Your code has expired"
                : "The code you have entered is incorrect.";
            this.$notify({
              message: message,
              type: "warning",
            });

            if (error == "code_expired") {
              this.codeSent = false;
            }
          }
          console.log("ERR", err.response.data);
        });
    },
    sendCode() {
      this.loading = true;

      ApiService.userApi
        .sendVerificationCode(this.email)
        .then((response) => {
          this.codeSent = true;
          this.loading = false;
          if (response.status == 200) {
            if (response.data.error) {
              this.numberExists = true;
            } else {
              this.$notify({
                message: "Code has been sent, please check your email",
                type: "success",
                duration: 5000,
              });
            }
          }
        })
        .catch(() => {
          // console.log("Error", error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("loading", false);
    this.$emit("progress-step", 4);
    this.email = this.currentEmail;
  },
};
</script>
