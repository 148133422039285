<template>
  <!-- Notice -->
  <div @click="noticeEvent('showNotice')">
    <div class="notice-header">
      <block-title @noticeEvent="noticeEvent" :message="message" />
      <div class="classLabel seeMoreLabel" v-if="message.user_type != 5">
        <span v-if="message.class_label">
          Class:
          <b>{{ message.class_label }}</b>
        </span>
        <span v-if="message.years && !message.class_label">
          Years:
          <b>{{ message.years }}</b>
        </span>
      </div>
    </div>
    <div class="notice-body" v-if="message.message != '<p></p>'">
      <div
        v-if="message.type == 'notice' || message.type == ''"
        class="blockText noticeText"
        v-html="message.message"
      ></div>
    </div>

    <!-- <div class="socialLinks" v-if="message.extras.showSocial">
    <div class="row">
      <a
        class="btn btn-link"
        target="_blank"
        href="https://www.instagram.com/homerunapp/?hl=en"
        @click="openUrl($event, 'https://www.instagram.com/homerunapp/?hl=en')"
      >
        <i class="fab fa-instagram"></i>
      </a>
      <a
        class="btn btn-link"
        target="_blank"
        href="https://twitter.com/HomeRunTeam?lang=en"
        @click="openUrl($event, 'https://twitter.com/HomeRunTeam?lang=en')"
      >
        <i class="fab fa-twitter"></i>
      </a>
      <a
        class="btn btn-link"
        target="_blank"
        href="https://www.facebook.com/HomeRunSchools/"
        @click="openUrl($event, 'https://www.facebook.com/HomeRunSchools/')"
      >
        <i class="fab fa-facebook-f"></i>
      </a>
      <a
        class="btn btn-link"
        target="_blank"
        href="https://www.linkedin.com/company/homerunapp/"
        @click="openUrl($event, 'https://www.linkedin.com/company/homerunapp/')"
      >
        <i class="fab fa-linkedin-in"></i>
      </a>
    </div>
  </div> -->

    <NoticeFooter @noticeEvent="noticeEvent" :message="message" />
  </div>
</template>
<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
import NoticeFooter from "@/components/noticeTypes/other/NoticeFooter";
export default {
  name: "notice",
  components: {
    BlockTitle,
    NoticeFooter,
  },
  props: {
    message: Object,
    // title: String,
    // icon: String
  },
  methods: {
    noticeEvent(type) {
      this.$emit("noticeEvent", {
        action: type,
        id: this.message.id,
        user_id: this.message.user_id,
      });
    },
  },
};
</script>
