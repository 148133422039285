<template>
  <div :class="styles.Launch">
    <div :class="styles.container" class="container">
      <div :class="styles.header">
        <div :class="styles['header-left']">
          <img
            v-show="schoolLogoUrl"
            :src="schoolLogoUrl"
            alt="logo"
            :class="styles['school-logo']"
          />

          <div :class="styles['header-info']">
            <div :class="styles['header-title']">{{ schoolName }}</div>
            <p :class="styles['header-text']">
              HomeRun is a secure digital platform that connects your school
              community,<br />
              enables better school travel, and create a cleaner, greener
              school.
            </p>
          </div>
        </div>
        <div :class="styles['header-right']">
          <inline-svg
            :src="require(`@/assets/flow/logo.svg`)"
            :class="styles.logo"
            width="70"
            height="74"
          />
          <vue-position-sticky :offsetTop="25" style="margin-top: 10px;">
            <a
              v-if="schoolLaunchCode"
              class="btn btn-black btn-black--md btn-big"
              :href="`/registration_mi/${schoolLaunchCode}`"
              >Sign Up Now</a
            >
          </vue-position-sticky>
        </div>
      </div>
      <div :class="styles.content">
        <div :class="[styles.column, styles['column-aqua']]">
          <div :class="styles.top">
            <inline-svg :src="require(`@/assets/launch/aqua-shape.svg`)" />
            <div :class="[styles.shape, styles['shape-aqua']]">
              <span :class="styles.title">Make life simple</span>
              <p>
                Streamline your school run, to give<br />
                you more time and peace of mind.
              </p>
            </div>
          </div>
          <div :class="[styles.bottom, styles['bottom-aqua']]">
            <div :class="styles['bottom-row']">
              <inline-svg :src="require(`@/assets/launch/people.svg`)" />
              <div :class="styles.box">
                Share the school run with other families: walking buses, bike
                buses and car pooling.
              </div>
            </div>
            <div :class="styles['bottom-row']">
              <div :class="styles.box">
                Pair your child with a travel buddy for safer independent
                travel.
              </div>
              <inline-svg :src="require(`@/assets/launch/students.svg`)" />
            </div>
            <div :class="styles['bottom-row']">
              <inline-svg :src="require(`@/assets/launch/save-money.svg`)" />

              <div :class="styles.box">Save money through journey sharing.</div>
            </div>
          </div>
        </div>

        <div :class="[styles.column, styles['column-pink']]">
          <div :class="styles.top">
            <inline-svg :src="require(`@/assets/launch/pink-shape.svg`)" />
            <div :class="[styles.shape, styles['shape-pink']]">
              <span :class="styles.title">Get connected</span>
              <p>
                Being part of a community<br />
                makes us all feel better.
              </p>
            </div>
          </div>
          <div :class="[styles.bottom, styles['bottom-pink']]">
            <div :class="styles['bottom-row']">
              <div :class="styles.box">
                Connect with other school families nearby, to create a local
                support network.
              </div>

              <inline-svg :src="require(`@/assets/launch/families.svg`)" />
            </div>
            <div :class="styles['bottom-row']">
              <inline-svg :src="require(`@/assets/launch/mobile-chat.svg`)" />
              <div :class="styles.box">
                Message other parents without having to share contact details.
                Playdates and birthday parties just got easier!
              </div>
            </div>
            <div :class="styles['bottom-row']">
              <div :class="styles.box">
                Get school updates in real-time (‘Football team running late-
                pick up at 5.30.’)
              </div>
              <inline-svg :src="require(`@/assets/launch/notifications.svg`)" />
            </div>
          </div>
        </div>

        <div :class="[styles.column, styles['column-green']]">
          <div :class="styles.top">
            <inline-svg :src="require(`@/assets/launch/green-shape.svg`)" />
            <div :class="[styles.shape, styles['shape-green']]">
              <span :class="styles.title">Feel good</span>
              <p>
                Make your children’s world<br />
                cleaner, greener and happier.
              </p>
            </div>
          </div>
          <div :class="[styles.bottom, styles['bottom-green']]">
            <div :class="styles['bottom-row']">
              <inline-svg :src="require(`@/assets/launch/travel.svg`)" />
              <div :class="styles.box">
                Less traffic means more children can walk and cycle, making them
                happier and healthier.
              </div>
            </div>
            <div :class="styles['bottom-row']">
              <div :class="styles.box">
                Cut congestion and air pollution around school with journey
                sharing.
              </div>
              <inline-svg :src="require(`@/assets/launch/car.svg`)" />
            </div>
            <div :class="styles['bottom-row']">
              <inline-svg :src="require(`@/assets/launch/eco-friendly.svg`)" />

              <div :class="styles.box">
                Combat climate change - fewer car journeys mean less C02.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import styles from "@/assets/stylesheets/launch.module.scss";
import ApiService from "@/services/ApiService";

export default {
  data: () => ({
    schoolLaunchCode: "",
    schoolLogoUrl: "",
    schoolName: "",
    styles,
  }),
  methods: {
    checkCode() {
      ApiService.generalApi
        .getSchoolInfoWithCode(this.$route.params.schoolLaunchCode)
        .then(({ status, data }) => {
          if (status === 200 && data.status !== 0) {
            this.schoolName = data.name;
            this.schoolLogoUrl = data.logo;
            this.schoolLaunchCode = this.$route.params.schoolLaunchCode;
          }
        });
    },
  },
  mounted() {
    this.checkCode();
  },
};
</script>
