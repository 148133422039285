<style lang="scss" scoped>
$primary-color: #9cc200;
$secondary-color: #008a93;

.v--modal {
  box-shadow: 2px 4px 5px #676767;
  padding: 10px;
  padding-top: 20px;
  border-radius: 10px;
  background: #b0cb24;
}

.wrapper {
  height: 100%;
  font-family: "Rubik", sans-serif;
}

.v--modal {
  border-radius: 8px !important;

  @media screen and (max-width: 767px) {
    width: 85% !important;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0;
  border-top: 0 !important;
}

.primary-text {
  color: $primary-color;
  font-weight: 500;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  margin: 20px auto;

  @media screen and (max-width: 767px) {
    margin: 15px 0;
  }

  button {
    margin-bottom: 20px;

    &:hover {
      opacity: 0.9;
    }
  }
}

.btn-primary {
  background: $primary-color;
  border-radius: 20px;
  border: 1px solid $primary-color;
  padding: 10px 35px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.btn-staff {
  background: $secondary-color;
  border-color: $secondary-color;
}

#parentModal {
  padding: 30px 20px;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 25px;
  background: url(~@/assets/launch/welcome-bg.png);
  background-size: cover;
}

.header {
  display: flex;
  align-items: flex-end;
  padding: 20px 0;

  img {
    border-radius: 50px;
    max-height: 74px;
    margin: 0;
  }

  @media screen and (min-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 40px;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    flex: 1;
    align-items: flex-start;
    justify-self: flex-start;
    margin-top: 15px;
  }
}

.middle-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;

  @media screen and (min-width: 767px) {
    margin: 0 auto;
    justify-content: flex-start;
    text-align: center;
  }
}

.btn:hover {
  color: #ffffff;
}

.school-name {
  color: #ffffff;
  margin-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;

  @media screen and (min-width: 767px) {
    font-weight: 500;
    margin: 15px 0 0;
  }
}

.welcome-text {
  font-weight: 400;
  font-size: 50px;
  color: #fff;
  margin: 5px 0;
  line-height: 60px;

  @media screen and (min-width: 767px) {
    font-size: 60px;
    margin: 0 auto 25px;
    text-align: center;
    line-height: 71px;
  }

  span {
    display: block;
  }
}

.instruction {
  color: #ffffff;
  margin: 0 0 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.alert.alert-info {
  background: #ffffff;
  border-radius: 50px;
  margin: 30px 0;
  padding: 7px 40px;
  font-weight: 400;
  font-size: 11px;
  color: #605e5e;
  text-align: center;
  letter-spacing: 0.02em;

  @media screen and (min-width: 767px) {
    margin: 0;
  }
}

.logo {
  margin: 0;
  justify-self: flex-end;

  @media screen and (min-width: 767px) {
    margin: 40px auto 10px;
  }
}

.modal {
  padding: 30px 20px;
}

.modal-close {
  color: $secondary-color;
  cursor: pointer;
}
</style>
<template>
  <div class="wrapper">
    <div
      class="mialert lightRed"
      v-if="codeExists === false"
      data-cy="wrong_code_text"
    >
      <p>Please check the link or contact School administrator.</p>
    </div>
    <div id="mainDiv" class="main">
      <div v-if="codeExists" class="middle-content">
        <div id="topLogo" class="header">
          <img :src="data.logo_url" alt data-cy="school-logo" />
          <p class="school-name" data-cy="school-name-text">
            {{ data.name }}
          </p>
        </div>

        <div class="content">
          <p class="welcome-text" data-cy="welcome-text">
            Welcome<br />to HomeRun
          </p>
          <p class="instruction">
            Log in via the links
            <br />appropriate to you below
          </p>

          <div class="action-buttons">
            <button
              class="btn-primary btn-parent"
              @click="redirectToParent()"
              data-cy="i-am-parent-btn"
            >
              I AM A PARENT
            </button>
            <button
              class="btn-primary btn-staff"
              @click="showModal()"
              data-cy="i-am-staff-btn"
            >
              I AM SCHOOL STAFF
            </button>
          </div>
        </div>
        <inline-svg :src="require('@/assets/logo-white.svg')" class="logo" />
      </div>
    </div>
    <modal
      name="staffModal"
      id="modalOverlay"
      :adaptive="true"
      :height="'auto'"
      transition="slide-fade"
    >
      <div id="modal" class="modal" data-cy="staff_modal">
        <span class="pull-right" @click="hideModal">
          <i
            class="modal-close fa-solid fa-xmark fa-2xl"
            @click="hideModal('journeyHelpModal')"
            data-cy="staff_modal_close_btn"
            aria-hidden="true"
          />
        </span>
        <h2 class="text-center" style="margin-top: 0px">
          <strong>Hi there!</strong>
        </h2>
        <div>
          <p class="text-center">
            If you do not know your school's code please ask your school office
            administrator.
          </p>
        </div>
        <h2
          style="font-size: 16px; padding-top: 10px; font-weight: bold"
          class="text-center"
        >
          Plese enter your Staff Verfication Code
        </h2>
        <div class="text-center">
          <input
            type="text"
            class="form-control rounded25"
            placeholder="Enter Staff code"
            data-cy="staff_code_input"
            v-model="staffCode"
            style="width: 200px; margin: 20px auto 0"
          />
        </div>
        <div
          class="row text-center padding30"
          style="padding-bottom: 0px; padding-top: 25px"
        >
          <div class="col-md-12">
            <button
              class="btn-primary btn-staff"
              @click="checkstaffCodeAndRedirect()"
              data-cy="staff_modal_continue_btn"
            >
              Verify now & continue
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "schoollink",
  components: {},
  data() {
    return {
      data: {},
      staffCode: "",
      codeExists: null,
    };
  },
  methods: {
    showModal() {
      this.$modal.show("staffModal");
    },
    hideModal() {
      this.$modal.hide("staffModal");
    },
    redirectToParent() {
      this.$router.push(
        "/welcomeParent/" +
          this.$route.params.schoolCode +
          "/" +
          this.slugify(this.data.name)
      );
    },
    checkstaffCodeAndRedirect() {
      this.checkCode(this.staffCode, true);
    },
    slugify(str) {
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
    checkCode(code, redirect) {
      code = code.trim();
      ApiService.generalApi
        .check_school_code(code)
        .then((response) => {
          if (response.status == 200 && response.data.status != 0) {
            this.codeExists = true;

            if (redirect) {
              if (response.data.staff == true) {
                this.$router.push(
                  "/welcomeStaff/" + code + "/" + this.slugify(this.data.name)
                );
              } else {
                this.$notify({
                  message:
                    "Incorrect code, please enter the staff verification code emailed to you by your school",
                  type: "warning",
                  duration: 5000,
                });
              }
            }
            this.data = response.data;
          } else {
            if (this.codeExists == null) {
              this.codeExists = false;
            }
            if (redirect) {
              this.$notify({
                message: "Wrong Code",
                type: "warning",
              });
            }
          }
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.checkCode(this.$route.params.schoolCode);
  },
};
</script>
