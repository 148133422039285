<style scoped>
@import "@glidejs/glide/dist/css/glide.core.min.css";
@import "@glidejs/glide/dist/css/glide.theme.min.css";
.my-swipe {
  height: calc(100vh - 140px);
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  /* background-color: #39a9ed; */
}
@media screen and (max-width: 767px) {

}
div.card {
  max-width: calc(100% - 20px);
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
  /* box-shadow: 0px 0px 4px #e3e3e3; */
  background: white;
  /* flex: 0.95; */
  overflow-y: auto;
  display: flex;
  align-items: center;
}
.glide__slide {
  border: 1px solid #dedede;
  height: calc(100vh - 580px);
  /* box-shadow: 0 5px 3px #ccc; */
  border-radius: 10px;
  max-height: 250px;
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: white; */
  background: #fff;
  padding: 30px 0;
  position: relative;
}
.glide__slide i.fa-question-circle {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #c3c4c5;
}
.glide__slide h3 {
  display: block;
  font-size: 14px;
  /* color: #1cae41 !important; */
}

.glide__slide .circle {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  margin-top: 7px;
  margin-bottom: 7px;
}
.glide__slide h3 {
  margin-top: 0px;
  /* color: #888; */
}
.glide__slide i.middleIcon {
  display: block;
  font-size: 30px;
  /* color: #21ae42; */
}
.glide__slide span.value {
  font-size: 16px;
  /* color: #21ae42; */
  font-weight: bold;
}

.smallDropdown .fa-caret-down {
  color: red;
}
#chart {
  height: 220px;
}
#carousel {
  padding-bottom: 7px;
}
span.bottomLabel {
  display: block;
}
#statsWrapper {
  display: flex;
  height: calc(100vh - 120px);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition-delay: 0.2s;
  transition: opacity 0.3s;
}
#statsWrapper.activePage {
  opacity: 1;
}
</style>
<style>
.stats_popover {
  height: auto !important;
  width: 190px;
  font-size: 12px;
  background: #edf9ff;
}
.ct-bar {
  fill: none;
  stroke-width: 8px;
  stroke: #21acf5 !important;
}
.ct-label {
  font-size: 0.65rem;
}
div.gradientCircle {
  background: -webkit-linear-gradient(left top, #8bdbe9 0%, #77adfe 100%);
  border-radius: 1000px;
  padding: 1px;
}
div.gradientCircle > div {
  border-radius: 1000px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gradientText {
  background: #8bdbe9;
  background: linear-gradient(to bottom, #8bdbe9 0%, #77adfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradientBg {
  background: rgb(139, 219, 233);
  background: linear-gradient(
    180deg,
    rgba(139, 219, 233, 1) 0%,
    rgba(119, 173, 254, 1) 100%
  );
  color: white;
  padding: 10px;
}
.borderRadius40 {
  border-radius: 40px;
}
.col_orange {
  color: #ffa621;
}
.col_lorange {
  background: #ffefd3;
}
.col_blue {
  color: #37d3d3;
}
.col_lblue {
  background: #a4f2ff;
}
.col_pink {
  color: #c867cf;
}
.col_lpink {
  background: #fed3ff;
}
.col_green {
  color: #1cae41;
}
.col_lgreen {
  background: #dcffe4;
}
#mainContent {
  background: white;
}
</style>
<template>
  <div
    id="mainContent"
    class="row"
    style="min-height: calc(100vh - 120px); padding-bottom: 0px"
  >
    <div class="clearfix">
      <HeaderTitle title="Your School Stats" />
    </div>
    <div id="statsWrapper" :class="loaded ? 'activePage' : ''">
      <div style="width: calc(100% - 20px)">
        <p class="colorBlue" style="font-size: 12px">
          View HomeRun statistics for your selected school
        </p>
        <div class="textCenter round5">
          <DropdownSelection
            keyProp="id"
            v-if="schools.length > 0 && school_id"
            single
            small
            class="smallDropdown"
            name="name"
            default_label="Select School"
            :blueTheme="true"
            :items="schools"
            :default_value="school_id"
            @updated="changeSchool"
            style="
              /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); */
              /* height: 30px !important; */
            "
          />
        </div>
      </div>
      <div class="card flex flex-col justify-around h-full max-h-80 py-2">
        <div style="display: flex; justify-content: center">
          <div class="column1">
            <div class="gradientCircle" style="height: 126px; width: 126px">
              <div style="height: 124px; width: 124px">
                <div>
                  <img
                    src="@/assets/users.png"
                    height="35px"
                    style="margin-top: -10px"
                  />
                  <div
                    class="gradientText"
                    style="font-weight: 900; font-size: 40px; line-height: 42px"
                  >
                    {{ parentsSignedUp }}
                  </div>
                  <div class="colorBlue" style="margin-top: -7px">
                    <small style="font-size: 11px">Parents signed up</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column2"
            style="
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              flex-direction: column;
            "
          >
            <div class="gradientCircle" style="height: 74px; width: 74px">
              <div style="height: 72px; width: 72px">
                <div>
                  <div
                    class="gradientText"
                    style="font-weight: 900; font-size: 20px; line-height: 20px"
                  >
                    {{ signedUpPercentage }}%
                  </div>
                  <div class="colorBlue" style="font-size: 10px">
                    of your school
                  </div>
                </div>
              </div>
            </div>
            <button
              @click="showModal('chartModal')"
              style="
                background: #c5f7fc;
                border: 0px;
                color: #1facf4;
                padding: 3px 10px;
                border-radius: 15px;
                font-size: 11px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
              "
              class="shadow"
            >
              See by school year
            </button>
          </div>
        </div>
        <!-- <i class="fad fa-users primaryColor" style="font-size: 40px"></i> -->
        <!-- <h2 style="margin-bottom: 5px; margin-top: 5px">
              <b class="primaryColor">{{ parentsSignedUp }}</b> parents signed
              up
            </h2> -->
        <!-- <button
              @click="showModal('chartModal')"
              type="text"
              plain
              class="primaryColor"
              style="
                margin-bottom: 5px;
                border: 0;
                background: none;
                text-decoration: underline;
              "
            >
              See by school year
            </button> -->
        <!-- <hr style="margin: 0 4px" /> -->
        <p style="padding: 12px; font-size: 12px" class="borderRadius40">
          Share the link below to invite <span v-if="isStaff">users</span>
          <span v-if="!isStaff">parents</span> to the
          <b>{{ school.name }}</b> network.
        </p>
        <p
          v-clipboard:copy="link"
          v-clipboard:success="onCopy"
          class="colorBlue"
          style="
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 25px;
            font-weight: bold;
            padding: 10px 12px;
          "
        >
          {{ link }}
        </p>
        <van-button
          type="info"
          class="btn round5"
          size="small"
          style="
            margin-top: 10px;
            font-weight: bold;
            border-radius: 15px !important;
            height: 32px;
            font-size: 15px;
            padding: 0px 50px !important;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
          "
          @click="shareLink"
        >
          Share <i class="far fa-share"></i>
        </van-button>
      </div>
      <modal
        name="chartModal"
        id="modalOverlay"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
      >
        <ModalHeader title @hideModal="hideModal('chartModal')" />
        <div>
          <p style="padding: 15px; text-align: center">{{ school.name }}</p>
          <div class="text-center" style="padding-bottom: 20px">
            <div>
              <div id="chart"></div>
              <div>Total pupils</div>
            </div>
          </div>
        </div>
      </modal>
      <div id="carousel" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <!-- <li class="glide__slide">
              <div>
                <Popover
                  v-model="popoverCo2"
                  trigger="click"
                  class="test"
                  :actions="[
                    {
                      text: 'here comes text asdfasdf akhlsdfkasdjf aksldfjaskdf asdfasdjfklasd fasd',
                      className: 'stats_popover',
                    },
                  ]"
                >
                  <template #reference>
                    <i class="fas fa-question-circle"></i>
                  </template>
                </Popover>
                <h3>Cars CO2</h3>
                <div class="circle">
                  <i class="fas fa-car middleIcon"></i>
                </div>
                <span id="co2" class="value"> </span>
              </div>
            </li> -->
            <li class="glide__slide">
              <div>
                <Popover
                  v-model="popoverSustainability"
                  trigger="click"
                  class="test"
                  :actions="[
                    {
                      text: 'The percentage of journeys taken through sustainable travel methods',
                      className: 'stats_popover',
                    },
                  ]"
                >
                  <template #reference>
                    <i class="fas fa-question-circle"></i>
                  </template>
                </Popover>
                <h3 class="col_green">Sustainable travel</h3>
                <div class="circle col_lgreen">
                  <i class="fas fa-leaf middleIcon col_green"></i>
                </div>
                <span id="sustainableJourneys" class="value col_green"></span>
                <span class="bottomLabel col_green">of all journeys</span>
              </div>
            </li>
            <li class="glide__slide">
              <div>
                <Popover
                  v-model="popoverActive"
                  trigger="click"
                  class="test"
                  :actions="[
                    {
                      text: 'The percentage of journeys taken by walking or cycling',
                      className: 'stats_popover',
                    },
                  ]"
                >
                  <template #reference>
                    <i class="fas fa-question-circle"></i>
                  </template>
                </Popover>
                <h3 class="col_blue">Active travel</h3>
                <div class="circle col_lblue">
                  <i class="fas fa-walking middleIcon col_blue"></i>
                </div>
                <span id="activeJourneys" class="value col_blue"></span>
                <span class="bottomLabel col_blue">of all journeys</span>
              </div>
            </li>
            <li class="glide__slide">
              <div>
                <Popover
                  v-model="popoverCarpool"
                  trigger="click"
                  class="test"
                  :actions="[
                    {
                      text: 'The percentage of car journeys that were shared with other families',
                      className: 'stats_popover',
                    },
                  ]"
                >
                  <template #reference>
                    <i class="fas fa-question-circle"></i>
                  </template>
                </Popover>
                <h3 class="col_orange">Car sharing</h3>
                <div class="circle col_lorange">
                  <i class="fas fa-cars middleIcon col_orange"></i>
                </div>
                <span id="carpoolJourneys" class="value col_orange"> </span>
                <span class="bottomLabel col_orange">of car journeys</span>
              </div>
            </li>
            <li class="glide__slide">
              <div>
                <Popover
                  v-model="popoverJourneySharing"
                  trigger="click"
                  class="test"
                  :actions="[
                    {
                      text: 'The percentage of users that have indicated they are open to journey sharing or finding travel buddies for their children',
                      className: 'stats_popover',
                    },
                  ]"
                >
                  <template #reference>
                    <i class="fas fa-question-circle"></i>
                  </template>
                </Popover>
                <h3 class="col_pink">Open to journey sharing</h3>
                <div class="circle col_lpink">
                  <i class="fas fa-link middleIcon col_pink"></i>
                </div>
                <span id="sharingJourneys" class="value col_pink"></span>
                <span class="bottomLabel col_pink">of users</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import HeaderTitle from "@/components/Header";
import Glide from "@glidejs/glide";
import { BarChart } from "chartist";
import "chartist/dist/index.css";
import DropdownSelection from "@/components/DropdownSelection";
import { Popover } from "vant";

export default {
  name: "myStats",
  components: {
    HeaderTitle,
    DropdownSelection,
    Popover,
  },
  data() {
    return {
      stats: [],
      glide: null,
      link: "",
      school_id: 0,
      school: {},
      schools: [],
      schoolStats: {},
      signedUpPercentage: 0,
      parentsSignedUp: 0,
      popoverCo2: false,
      popoverSustainability: false,
      popoverActive: false,
      popoverCarpool: false,
      popoverJourneySharing: false,
      loaded: false,
    };
  },
  methods: {
    getStats() {
      ApiService.userApi
        .getStats()
        .then((response) => {
          this.schoolStats = response.data.schoolStats;
          this.schools = response.data.schools;
          if (this.schools.length > 0) {
            this.changeSchool(this.schools[0].id);
          }
          this.loaded = true;
          this.$store.dispatch("loading", false);
        })
        .catch(function () {
          this.$store.dispatch("loading", false);
        });
    },
    showConversation(options) {
      this.showConversationModal(options.user_id);
    },
    hideModal(modal) {
      this.$modal.hide(modal);
    },
    changeSchool(id) {
      if (id == "all") {
        return;
      }
      this.school_id = id;
      const school = this.schools.filter((itm) => itm.id == id);
      try {
        if (this.glide) {
          this.glide.destroy();
        }
        this.school = school[0];
        this.link =
          "https://user.homerun-app.com/launch/" + school[0].launch_code;

        if (school[0].total_students && this.schoolStats[id].signed_students) {
          this.signedUpPercentage = Math.round(
            (this.schoolStats[id].signed_students / school[0].total_students) *
              100
          );
        } else {
          this.signedUpPercentage = 0;
        }

        const cardStats = {
          co2: this.schoolStats[id].other.totalco2,
          activeJourneys:
            Math.round(
              (this.schoolStats[id].other.activeJourneys /
                this.schoolStats[id].other.totalJourneys) *
                100
            ) + "%",
          sustainableJourneys:
            Math.round(
              (this.schoolStats[id].other.sustainableJourneys /
                this.schoolStats[id].other.totalJourneys) *
                100
            ) + "%",
          carpoolJourneys:
            Math.round(
              (this.schoolStats[id].other.carpoolJourneys /
                this.schoolStats[id].other.carJourneys) *
                100
            ) + "%",
          openToSharingJourneys:
            Math.round(
              (this.schoolStats[id].other.openToJourneys /
                this.schoolStats[id].other.totalJourneys) *
                100
            ) + "%",
        };
        try {
          document.getElementById("activeJourneys").textContent =
            cardStats.activeJourneys;
          document.getElementById("sustainableJourneys").textContent =
            cardStats.sustainableJourneys;
          document.getElementById("carpoolJourneys").textContent =
            cardStats.carpoolJourneys;
          document.getElementById("sharingJourneys").textContent =
            cardStats.openToSharingJourneys;

          this.parentsSignedUp =
            school[0].parentsCount +
            (school[0].parents2Count ? school[0].parents2Count : 0);
          this.initGlide();
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        alert(error);
      }
    },
    showModal(modal) {
      this.$modal.show(modal);
      const labels = [];
      const series = [];
      let highestNo = 10;

      this.schoolStats[this.school_id].years.forEach((itm) => {
        labels.push(itm.year);
        series.push(itm.count);
        if (itm.count > highestNo) {
          highestNo = itm.count;
        }
      });

      setTimeout(() => {
        new BarChart(
          "#chart",
          {
            labels: labels,
            series: [series],
          },
          {
            horizontalBars: true,
            seriesBarDistance: 10,
            high: highestNo,
            low: 0,
            axisY: {},
            axisX: {
              onlyInteger: true,
            },
          }
        );
      }, 300);
    },
    redirectTo(path) {
      this.$router.push(path);
    },
    onCopy() {
      this.$notify({
        type: "success",
        message: "Copied to your clipboard.",
      });
    },
    shareLink() {
      var payload = {
        title: "Share HomeRun school code",
        message: `You have been invited to join the ${this.school.name} HomeRun Network. Click the link to sign up and receive school travel information, share the burden of the school run with nearby parents, and help us become a greener school: ${this.link}.`,
        url: this.link,
      };
      if (navigator.share) {
        navigator.share({
          title: "HomeRun school link",
          text: payload.message,
          url: this.link,
        });
      } else {
        window.postMessage("share__" + JSON.stringify(payload), "*");
      }
    },
    initGlide() {
      this.glide = new Glide("#carousel", {
        type: "carousel",
        bound: true,
        perView: 4,
        focusAt: "center",
        breakpoints: {
          2400: {
            perView: 3.5,
          },
          800: {
            perView: 2,
          },
          480: {
            perView: 2,
          },
        },
      });

      this.glide.mount();
    },
  },
  computed: {
    isStaff: function () {
      return this.$store.state.general.user.is_staff;
    },
  },
  mounted() {
    if (this.$route.params.trigger) {
      this.shareLink();
    }
  },
  created() {
    this.$store.dispatch("loading", true);
    this.getStats();
  },
};
</script>
