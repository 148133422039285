<template>
  <div>
    <div></div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  data: () => ({}),
  methods: {
    checkCode() {
      ApiService.userApi
        .inviteDetails(this.$route.query.token)
        .then(({ status, data }) => {
          if (status === 200) {
            const schoolLaunchCode = this.$route.params.schoolLaunchCode;
            if (data.invitation_type == "MIS") {
              sessionStorage.setItem(
                "invitation_token",
                this.$route.query.token
              );
              this.$router.push("/signup/" + data.hash);
            } else {
              this.$router.push(
                "/inviteLink/" +
                  schoolLaunchCode +
                  "/school?token=" +
                  this.$route.query.token
              );
            }
          }
        })
        .catch(() => {
          alert(
            "This invite link has been used, please ask your partner for a new link."
          );
        });
    },
  },
  mounted() {
    this.checkCode();
  },
};
</script>
