<template>
  <div id="wrapper">
    <div>
      <form @submit.prevent class="row">
        <div class="col-xs-12">
          <div v-if="edit === false && currentAddress" class="current-address">
            {{ currentAddress.address_line_1 }}
            <i
              class="fa fa-circle-check"
              style="color: #03c200; padding-left: 5px"
            ></i>
          </div>
          <button
            @click="enableEditing"
            v-if="postcode && edit == false"
            class="pull-left btn btn-grey marginTop5"
          >
            Select New Home Address
          </button>

          <van-cell>
            <div class="form-select-wrapper">
              <Autocomplete
                v-if="!postcode || edit == true"
                class="form-select"
                data-cy="address_autocomplete_input"
                @place_changed="setPlace"
                :placeholder="placeholder"
                :options="{
                  componentRestrictions: {
                    country: internationalSearch ? [] : ['uk'],
                  },
                }"
              ></Autocomplete>
            </div>
          </van-cell>
        </div>
        <div
          v-if="(!postcode || edit == true) && address.lat"
          class="col-xs-12 primaryColor marker-info"
        >
          Please use the marker to locate your home
        </div>
      </form>
    </div>

    <GmapMap
      v-if="address.lat"
      v-show="address.lat"
      :center="centerPosition"
      :zoom="zoomLevel"
      style="width: 100%; height: 300px"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
    >
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="edit == true || !postcode"
        @click="centerPosition = marker.position"
        @dragend="dragEnd"
        style="z-index: 999"
      />
    </GmapMap>
  </div>
</template>
<script>
// import SubHeader from "@/components/SubHeader";
// import dbConnection from "@/services/dbConnection";
// import { Marker } from "vue2-google-maps/src/components/marker";
import { Autocomplete } from "vue2-google-maps";
// Vue.component('GmapMarker', GmapMarker)

export default {
  name: "addressFlow",
  components: {
    // SubHeader,
    // Map,
    Autocomplete,
    // Marker
    // GmapMap
    // VueCropper
  },
  props: {
    defaultMarker: Object,
    currentAddress: Object || String,
    postcode: String,
  },
  data() {
    return {
      centerPosition: { lat: 52.209865, lng: -0.118092 },
      zoomLevel: 6,
      marker: {},
      address: {},
      textInput: {},
      edit: false,
      placeholder: "Type your address here",
    };
  },
  watch: {
    postcode() {
      if (this.postcode) {
        // this.placeholder = this.postcode;
      }
    },
    defaultMarker() {
      this.setInitialMarker(this.defaultMarker);
    },
  },
  computed: {
    internationalSearch() {
      let vrnAvailable = true;
      const schools = this.$store.state.general.user_organizations;
      schools.forEach((itm) => {
        if (itm?.organization.vrn_available == 0) {
          vrnAvailable = false;
        }
      });

      return vrnAvailable == true ? false : true;
    },
  },
  methods: {
    setPlace(data) {
      if (!data.geometry) {
        return;
      }
      this.marker.position = {
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
      this.centerPosition = {
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
      this.zoomLevel = 17;
      this.$emit("newAddress", this.getAddress(data));
    },
    enableEditing() {
      this.edit = true;
    },
    setInitialMarker(marker) {
      this.marker.position = {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
      this.centerPosition = {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
      this.zoomLevel = 17;
    },
    getAddress(place) {
      var full_address = place.formatted_address.split(",");
      this.address = {
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
        street: place.address_components[0].long_name,
        city: place.address_components[1].long_name,
        country: place.address_components[3].long_name,
        address_line_1: full_address[0],
        address_line_2: full_address[1],
        postcode: place.address_components[0].short_name,
      };
      return {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        street: place.address_components[0].long_name,
        city: place.address_components[1].long_name,
        country: place.address_components[3].long_name,
        address_line_1: full_address[0],
        address_line_2: full_address[1],
        postcode: place.address_components[0].short_name,
      };
    },
    dragEnd(data) {
      let updateData = this.address;
      updateData.lat = data.latLng.lat();
      updateData.lng = data.latLng.lng();
      this.$emit("newAddress", JSON.parse(JSON.stringify(updateData)));
    },
  },
  mounted() {
    if (this.defaultMarker) {
      // setTimeout(() => {
      this.setInitialMarker(this.defaultMarker);
      // }, 400);
    }
    if (this.postcode) {
      // this.placeholder = this.postcode;
    }
  },
};
</script>
<style scoped>
.current-address {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  text-align: left;
}

.marker-info {
  font-size: 15px;
  font-weight: 400;
  padding: 15px;
}
</style>
