<template>
  <div>
    <div v-if="flow" class="flow-modal-header">
      <div class="modal-header">
        <span class="modal-title">{{ title }}</span>
        <i
          class="modal-close fa-solid fa-xmark fa-2xl"
          :class="{ float: float }"
          data-cy="hide_modal_btn"
          v-if="!hideClose && !float"
          @click="$emit('hideModal')"
        />
      </div>
    </div>
    <div v-else>
      <h1 class="text-black p-3">
        <button
          class="modalClose"
          v-if="!hideClose && !float"
          @click="$emit('hideModal')"
        >
          <span class="fas fa-times" :style="globalStyle"></span>
        </button>
        <button
          class="modalClose float"
          v-if="!hideClose && float"
          @click="$emit('hideModal')"
        >
          <span class="fas fa-window-close" :style="globalStyle"></span>
        </button>
        <i
          class="primaryColor close-icon"
          :class="icon"
          :style="globalStyle"
        ></i>
        {{ title }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "header",
  data() {
    return {
      globalStyle: {
        // color: "white"
      },
    };
  },
  props: {
    title: String,
    icon: String,
    color: String,
    float: Boolean,
    hideClose: Boolean,
    flow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    color: function (val) {
      this.globalStyle = { color: val + " !important" };
    },
  },
  created() {
    if (this.color) {
      this.globalStyle = { color: this.color + " !important" };
    }
  },
};
</script>

<style scoped>
.float {
  z-index: 99;
  right: 12px;
  top: 12px;
  position: fixed !important;
}
.close-icon {
  font-size: 18px;
}
button {
  float: right;
  background: none;
  border: none;
  outline: none;
  padding-left: 10px;
  /* padding-right: 10px; */
}
</style>
