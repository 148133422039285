<style></style>
<template>
  <!-- Info notice -->
  <div class="infoNotice">
    <div class="blockTitle clearfix">
      <span class="pull-left typeicon text-center">
        <i class="fas fa-info"></i>
      </span>
      <span
        class="username colorNavy"
        v-if="message.user_type != 8 && message.user_type != 9"
        >Info</span
      >
      <span v-if="message.important">
        <i class="fas fa-star colorTrump"></i>
      </span>
      <span v-if="message.is_new" class="newLabel colorTrump font-bold text-xs pl-1"> NEW</span>
      <button
        class="pull-right far fa-trash-alt closeNotice !m-0.5"
        @click="hideMessage()"
      ></button>
      <span class="typelabel" v-if="message.typeLabel">School Notice</span>

      <span class="timeAgo">
        <span am-time-ago="message.created_timestamp | amFromUnix"></span>
      </span>
    </div>
    <!-- ng-if='message.images.length > 1' -->
    <div class="notice-body">
      <p v-html="message.message"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "info",
  props: {
    message: Object,
  },
  methods: {
    hideMessage() {
      this.$emit("noticeEvent", {
        action: "hideInfoNotice",
        id: this.message.id,
        user_id: this.message.user_id,
      });
    },
  },
};
</script>
