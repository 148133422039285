<template>
  <div id="topMenu" class="top-menu" v-if="!native" style="min-height: 37px">
    <div id="inner container font-rubik">
      <div>
        <!-- {{ roles }} -->
        <div
          id="smwrapper"
          v-if="!native"
          class="col-xs-12 text-center flex justify-between"
          style="min-height: 18px"
        >
          <span class="flex items-center justify-start text-left">
            <img
              :key="userOrganization.id"
              v-for="userOrganization in uniqueUserOrganizations"
              class="sm mr-2 h-6"
              :src="userOrganization.organization.logo_url"
            />
          </span>
          <div class="flex" v-if="roles && roles.length > 1">
            <span
              :key="role.id"
              @click="setUserRoleId(role.user_role_id)"
              v-for="(role, index) in roles"
              class="accountButton"
              :class="{
                activeAccount: role.user_role_id == userRoleId,
                activeStaffAccount:
                  role.slug == 'STAFF' && role.user_role_id == userRoleId,
                roundedFirst: index == 0,
                roundedLast: index == roles.length - 1,
              }"
              >{{ role.slug }}</span
            >
          </div>
        </div>
        <div
          v-if="route !== 'home' && route !== 'menu'"
          id="mobileHeader"
          :class="theme"
          class="col-xs-12 text-center visible-xs-block"
        >
          <i
            @click="goBack"
            class="fas fa-chevron-left pull-left"
            style="line-height: 45px; font-size: 15px"
          ></i>
          <div style="line-height: 40px">
            <h3 style="font-size: 18px; margin: 0; line-height: 45px">
              {{ name }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const mapping = {
  invitePartner: "Invite My Partner",
  myChildren: "My Children",
  myChats: "School Messenger",
  myGroups: "My Groups",
  searchParents: "Search Parents",
  home: "Home",
  menu: "Menu",
  schoolRun: "School Travel Directory",
  schoolRunNew: "School Travel Directory",
  travelDirectory: "School Travel Directory",
  mySchools: "My Schools",
  settings: "Settings",
  contactus: "Contact Us",
  faqs: "FAQs",
  travelFeatures: "School Travel Directory",
  schoolRun_searchParents: "School Travel Directory",
  stats: "Your School Stats",
};
export default {
  name: "topbar",
  props: {
    route: String,
  },
  data() {
    return {
      name: "",
      theme: null,
      selectedUserRoleId: null,
    };
  },
  watch: {
    route: function (newVal) {
      this.setName(newVal);
    },
  },
  computed: {
    userOrganizations: function () {
      return this.$store.state.general.user_organizations;
    },
    native() {
      return this.$store.state.general.native;
    },
    roles() {
      return this.$store.state.general.roles;
    },
    userRoleId() {
      return this.$store.state.general.userRoleId;
    },
    uniqueUserOrganizations() {
      if (this.userOrganizations.length == 0) {
        return [];
      } else {
        return this.userOrganizations.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((el) => el.organization.id === obj.organization.id)
        );
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    setName(value) {
      this.name = mapping[value];
      const routeType = this.$route.params.type;
      if (
        this.name === "School Travel Directory" ||
        this.name === "Journey Share"
      ) {
        if (routeType === "staff_journey_share") {
          this.name = "Journey Share";
          this.theme = "greenTheme";
        } else if (routeType === "journey_share") {
          this.name = "Journey Share";
          this.theme = "greenTheme";
        } else {
          this.theme = "featuresTheme";
        }
      } else if (this.name === "Search Parents") {
        if (routeType === "journey_share") {
          this.name = "Journey Share";
          this.theme = "greenTheme";
        } else if (routeType === "staff_journey_share") {
          this.name = "Journey Share";
          this.theme = "greenTheme";
        } else if (routeType === "travel_buddy") {
          this.name = "Travel Buddies";
          this.theme = "orangeTheme";
        } else if (routeType === "parent") {
          this.name = "Parent Directory";
          this.theme = "parentsTheme";
        } else if (routeType === "staff") {
          this.name = "Staff Directory";
          this.theme = "staffTheme";
        } else {
          this.theme = null;
        }
      } else if (this.name === "School Messenger") {
        this.theme = "featuresTheme";
      } else if (this.name === "Settings") {
        this.theme = "blueTheme";
      } else if (this.name === "Your School Stats") {
        this.theme = "featuresTheme";
      } else {
        this.theme = null;
      }
    },
    setUserRoleId(newUserRoleId) {
      this.$store.dispatch("setUserRoleId", newUserRoleId);
      setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch("loading", false);
          this.$router.go(this.$router.currentRoute);
        });
      }, 50);
    },
  },
  mounted() {
    this.setName(this.$router.currentRoute.name);
    // this.selectedUserRoleId = this.selectedAccountTyp;
  },
};
</script>
<style scoped>
#topMenu {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 999;
  left: 0;
  right: 0;
  @apply shadow;
  @apply rounded-b-lg mx-[9px] px-2 py-2 md:py-1.5 w-auto md:m-0 md:w-full;
}
div#smwrapper {
  padding: 0;
  position: relative;
  max-width: 760px;
  margin: auto;
  float: none;
}
.accountButton.activeAccount {
  @apply bg-pink-dark;
}
.accountButton.activeStaffAccount {
  @apply bg-purple;
}
.accountButton {
  display: inline-block;
  padding: 5px 10px;
  background: #e0e0e0;
  color: #fff;
  font-weight: 900;
  font-size: 11px;
  font-weight: 500;
  /* border-radius: 0 15px 15px 0; */
  cursor: pointer;
}
.activeRole {
  font-weight: bold;
  font-size: 9px;
  font-weigth: 800;
  font-family: "Helvetica";
  position: absolute;
  color: #888;
  top: 3px;
  text-align: center;
  left: 0px;
  width: 100%;
}
.accountButton.roundedFirst {
  border-radius: 15px 0 0 15px;
}
.accountButton.roundedLast {
  border-radius: 0 15px 15px 0;
}
#mobileHeader {
  /* background: linear-gradient(90deg, #b0b000, #0180aa); */
  position: fixed;
  top: 0px;
  background: rgb(0, 20, 51);
  background: linear-gradient(
    135deg,
    rgba(0, 20, 51, 1) 0%,
    rgba(35, 204, 220) 100%
  );
  color: white;
}
div#mobileHeader.redTheme {
  background: rgb(145, 0, 92);
  background: linear-gradient(
    135deg,
    rgba(145, 0, 92, 1) 0%,
    rgba(255, 209, 228, 1) 100%
  );
}
div#mobileHeader.parentsTheme {
  background: #ff0092;
  background: linear-gradient(135deg, #c30d76, #ff9cc5);
}
div#mobileHeader.greenTheme {
  background: rgb(215, 241, 156);
  background: linear-gradient(75deg, #0a5800, #92bd2e);
}
div#mobileHeader.staffTheme {
  background: rgb(215, 241, 156);
  background: linear-gradient(135deg, #778821, #d6ea6d);
}
div#mobileHeader.blueTheme {
  background: rgb(0, 20, 51);
  background: linear-gradient(
    135deg,
    rgba(0, 20, 51, 1) 0%,
    rgba(35, 204, 220) 100%
  );
}
div#mobileHeader.featuresTheme {
  background: #fff;
  color: #888;
}
div#mobileHeader.orangeTheme {
  background: rgb(181, 109, 0);
  background: linear-gradient(
    135deg,
    rgba(181, 109, 0, 1) 0%,
    rgba(252, 229, 109, 1) 100%
  );
}

.top-menu {
  @apply bg-white px-0 mx-1 py-1 lg:my-0;
  @apply md:rounded-none shadow;
}
</style>
