<template>
  <!-- Notice with images -->
  <div class="relative"
    @click="noticeEvent(message.infoNotice ? 'showInfoNotice' : 'showNotice')"
  >
    <div class="notice-header">
      <block-title @noticeEvent="noticeEvent" :message="message" />
      <div class="notice-category bg-notice-theme">
        <i
          v-if="message.category === 'pta'"
          class="fat fa-user-group-simple"
        ></i>
        <i v-if="message.category === 'eco'" class="fat fa-leaf"></i>
        <i
          v-if="message.category === 'sports'"
          class="fat fa-field-hockey-stick-ball"
        ></i>
        <i v-if="message.category === 'general'" class="fat fa-newspaper"></i>
        <i v-if="message.category === 'travel'" class="fat fa-location-pin"></i>
        <div class="category-details">
          <div>
            <span class="uppercase"
              >{{ message.category === "sports" ? "Sports" : message.category }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="category-years">
      <span
        class="yearBlock pull-right"
        v-if="message.years && yearNames == ''"
      >
        Years:
        {{ message.years }}
      </span>
      <span
        class="yearBlock pull-right"
        v-if="message.years && yearNames != ''"
      >
        Years:
        {{ yearNames }}
      </span>
      <span
        class="yearBlock pull-right"
        v-if="!message.years && !message.class_label"
      >
        Years: All
      </span>
      <span class="yearBlock pull-right" v-if="message.class_label">
        {{ message.class_label }}
      </span>
    </div>
    <!-- v-if='message.images.length > 1' -->
    <div
      v-if="message.extras && message.extras.date"
      class="blockText noticeText eventMiddle notice-body"
      style="position: relative"
    >
      <p style="text-align: center; padding: 10px">
        &nbsp;&nbsp;
        <span style="letter-spacing: 1px">
          <b>{{ message.title }}</b>
        </span>

        <br />
        <b class="eventDate">
          <i class="far fa-calendar-alt" style="margin-bottom: 8px"></i>
          {{ message.extras.date }}
        </b>
      </p>
      <div class="insideRect"></div>
    </div>
    <div
      class="notice-body"
      v-if="message.message != '<p></p>' && message.message != ''"
    >
      <p v-html="message.message"></p>
    </div>

    <div
      class="row equal"
      v-if="
        message.images &&
        message.images.length > 2 &&
        (!message.extension || message.extension == 'gif')
      "
    >
      <div class="col-xs-7 nopadding">
        <div class="bigimg rounded-l-lg">
          <img :src="message.images[0]" />
          <i class="fa fa-picture-o" aria-hidden="true"></i>
        </div>
      </div>
      <div class="col-xs-5 nopadding">
        <div
          class="smallimg rounded-tr-lg"
          style="margin-bottom: 2px"
          :style="{ 'background-image': 'url(' + message.images[1] + ')' }"
        >
          <i class="fa fa-picture-o" aria-hidden="true"></i>
        </div>
        <div
          class="smallimg rounded-br-lg"
          :style="{ 'background-image': 'url(' + message.images[2] + ')' }"
        >
          <i class="fa fa-picture-o" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div
      class="row equal"
      v-if="
        message.images &&
        message.images.length == 2 &&
        (!message.extension || message.extension == 'gif')
      "
    >
      <div class="col-xs-6 nopadding">
        <div class="bigimg rounded-l-lg">
          <img :src="message.images[0]" />
          <i class="fa fa-picture-o" aria-hidden="true"></i>
        </div>
      </div>
      <div class="col-xs-6 nopadding">
        <div
          class="smallimg rounded-r-lg"
          style="height: 100%"
          :style="{ 'background-image': 'url(' + message.images[1] + ')' }"
        >
          <i class="fa fa-picture-o" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div
      v-if="
        message.images &&
        message.images.length == 1 &&
        (!message.extension || message.extension == 'gif')
      "
    >
      <div class="bigimg oneImage rounded-lg">
        <img :src="message.images[0]" />
        <i class="fa fa-picture-o" aria-hidden="true"></i>
      </div>
    </div>
    <!-- <div
      v-if="
        message.images &&
          message.images.length == 2 &&
          message.extension == 'gif'
      "
    >
      <div class="bigimg oneImage">
        <img :src="message.images[1]" />
        <i class="fas fa-circle gifPlayIcon">
          <span>GIF</span>
        </i>
      </div>
    </div> -->
    <div
      class="videoPoster"
      v-if="
        message.images &&
        message.images.length == 2 &&
        message.extension == 'mp4'
      "
      style="margin: -10px"
    >
      <img
        v-if="message.images[1]"
        :src="message.images[1]"
        style="width: 100%; height: auto; margin-bottom: 15px"
      />
      <i class="fas fa-play-circle videoPlayIcon"></i>
      <!-- <video width="100%" height="auto"  controls="true" preload="metadata" poster="{{ message.images[1] }}" style="max-height: 360px;">
      <source src="{{ message.images[0] }}" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'>
      Your browser does not support the video tag.
      </video>-->
    </div>
    <!-- <div class="socialLinks" v-if="message.extras.showSocial">
      <div class="row">
        <a
          class="btn btn-link"
          target="_blank"
          href="https://www.instagram.com/homerunapp/?hl=en"
          @click='openUrl($event, "https://www.instagram.com/homerunapp/?hl=en")'
        >
          <i class="fab fa-instagram"></i>
        </a>
        <a
          class="btn btn-link"
          target="_blank"
          href="https://twitter.com/HomeRunTeam?lang=en"
          @click='openUrl($event, "https://twitter.com/HomeRunTeam?lang=en")'
        >
          <i class="fab fa-twitter"></i>
        </a>
        <a
          class="btn btn-link"
          target="_blank"
          href="https://www.facebook.com/HomeRunSchools/"
          @click='openUrl($event, "https://www.facebook.com/HomeRunSchools/")'
        >
          <i class="fab fa-facebook-f"></i>
        </a>
        <a
          class="btn btn-link"
          target="_blank"
          href="https://www.linkedin.com/company/homerunapp/"
          @click='openUrl($event, "https://www.linkedin.com/company/homerunapp/")'
        >
          <i class="fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>-->
    <NoticeFooter
      :message="message"
      :user_id="user_id"
      @noticeEvent="noticeEvent"
    />
  </div>
</template>
<style lang="scss">
.notice-header {
  @apply relative flex justify-between items-start;
  @apply py-1.5 pb-1 mx-2;
}

.notice-category {
  @apply flex items-center justify-start;
  @apply bg-green rounded-3xl px-3 text-sm;
  @apply font-bold text-white;
  box-shadow: 0px 2.56761px 6.41904px rgba(0, 0, 0, 0.15);
  min-width: 85px;
  min-height: 20px;

  // @media (min-width: 767px) {
  //   min-width: 100px;
  // }
}

.notice-body {
  @apply text-black p-3 pb-4;
  @apply border-t border-gray-200 mx-2 py-3 px-1;
  font-size: 13px;

  p {
    @apply m-0;
  }
}

.category-details {
  @apply flex flex-col justify-between items-start;
  @apply text-white font-normal;
  @apply py-0.5 ml-2;
  letter-spacing: 0.02em;
  font-size: 10px;
}

.category-years {
  @apply text-xs block text-gray-400;
  margin-top: -2px;
  position: absolute;
  right: 10px;
  top: 35px;
}

.notice-theme-general {
  .bg-notice-theme {
    @apply bg-blue #{!important};
  }
}

.notice-theme-pta {
  .bg-notice-theme {
    @apply bg-pink #{!important};
  }
}

.notice-theme-sports {
  .bg-notice-theme {
    @apply bg-purple #{!important};
  }
}

.notice-theme-travel {
  .bg-notice-theme {
    @apply bg-orange #{!important};
  }
}

.notice-theme-eco {
  .bg-notice-theme {
    @apply bg-green #{!important};
  }
}

.notice-theme-poll {
  .bg-notice-theme {
    @apply bg-peach #{!important};
  }
}
</style>
<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
import NoticeFooter from "@/components/noticeTypes/other/NoticeFooter";
export default {
  name: "noticeimages",
  components: {
    BlockTitle,
    NoticeFooter,
  },
  data() {
    return {
      yearNames: "",
    };
  },
  props: {
    message: Object,
    user_id: Number,
  },
  methods: {
    noticeEvent(type) {
      this.$emit("noticeEvent", {
        action: type,
        id: this.message.id,
        user_id: this.message.user_id,
      });
    },
  },
  mounted() {
    if (
      this.message.years != "All" &&
      this.message.years != "" &&
      this.message.years != null
    ) {
      let yearNames = [];
      const yearLabels = this.$store.state.general.yearLabels;
      if (yearLabels) {
        const separateYears = this.message.years.split(",");
        separateYears.forEach((year) => {
          try {
            yearNames.push(
              yearLabels[parseInt(this.message.extras.school)][parseInt(year)]
            );
          } catch (error) {
            yearNames.push(year);
          }
        });
        this.yearNames = yearNames.join(", ");
      }
    }
  },
};
</script>
