<style></style>
<template>
  <div id="registrationMiWrapper">
    <div class="textCenter" style="min-height: 80px; position: relative">
      <MiFlowHeader
        page_title="Let’s get you signed up!"
        :logo="schoolInfo.logo"
      />
      <button
        class="blankButton"
        v-if="loggedIn"
        @click="logout"
        style="position: absolute; right: 0px; top: 18px"
      >
        <i class="fa-light fa-arrow-right-from-bracket"></i><br /><span
          class="colorGrey"
          >Logout</span
        >
      </button>
    </div>
    <div id="flexDiv" class="clearfix" style="min-height: 500px">
      <div v-if="accType == 'parent'">
        <p class="mialert lightGreen text-left">
          Please enter the <b>email address</b> that you use to receive emails
          from {{ schoolInfo.name }}. We will check if your email address is
          registered as a parent on the school's IT system.
        </p>
      </div>
      <div v-if="accType == 'staff'">
        <p class="mialert lightGreen text-left">
          Please enter your <b>staff email</b> address. We will check if your
          email address is registered as a staff member on the school's IT
          system.
        </p>
      </div>
      <div v-if="accType == 'both'">
        <p class="mialert lightGreen text-left">
          Please enter your <b>staff email</b> address. We will check if your
          email address is registered as a staff member on the school's IT
          system.
        </p>
        <p>
          If you use a separate email address to receive parent communications,
          you can enter this later.
        </p>
      </div>
      <div>
        <van-field
          id="text_input"
          name="Email"
          data-cy="text_input"
          :placeholder="placeholder"
          type="text"
          v-model="email"
          maxlength="50"
          class="roundInput"
        ></van-field>
      </div>
      <div class="row">
        <div class="col-xs-12 flowBottomButtonDiv" style="flex-grow: 0">
          <van-button
            type="info"
            data-cy="go_back_btn"
            class="greyBtn"
            @click="$router.go(-1)"
            plain
            >Go Back</van-button
          >
          <van-button
            type="info"
            name="button"
            data-cy="submit"
            class="blackBtn"
            :loading="loading"
            @click="submit()"
            :disabled="!email || !email.includes('@') || !email.includes('.')"
            >Continue</van-button
          >
        </div>
      </div>
      <div id="bottomLogo" class="text-center">
        <img
          src="@/assets/wireframe_logo.svg"
          style="vertical-align: baseline"
        />
      </div>
      <modal
        name="existingUserModal"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
        data-cy="email_already_exists_modal"
      >
        <div class="">
          <div
            class="col-xs-12"
            style="margin-bottom: 50px; margin-top: 50px; text-align: center"
          >
            <MiFlowHeader
              page_title="Oh! Have you already signed up?"
              :logo="null"
            />
            <p class="mialert lightRed">
              It looks like you've already got a HomeRun account. You'll need to
              login in order to join {{ schoolInfo.name }} network.
            </p>
          </div>
          <div class="text-center" style="margin-bottom: 50px">
            <hr style="margin-bottom: 50px" />
            <van-button type="info" @click="login()" class="blackBtn"
              >Login</van-button
            >
          </div>
        </div>
        <div class="col-xs-12">
          <van-button
            type="info"
            data-cy="email_already_exists_modal_close_btn"
            @click="hideModal('existingUserModal')"
            class="greyBtn"
            plain
            >Go Back</van-button
          >
        </div>
      </modal>
      <modal
        name="duplicateErrorModal"
        :adaptive="true"
        :height="'100%'"
        :scrollable="true"
      >
        <div
          data-cy="email_exists_other_acc_modal"
          style="
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
          "
          class="col-xs-12"
        >
          <div style="margin-bottom: 35px">
            <div>
              <p class="mialert lightRed">
                You cannot add that email address to your account because it is
                registered to a different user. <br /><br />Please email
                info@homerun-app.com if you believe you have two separate
                HomeRun accounts that you'd like to combine.
              </p>
            </div>
          </div>
          <div>
            <van-button
              type="info"
              data-cy="email_exists_other_acc_modal_close_btn"
              @click="hideModal('duplicateErrorModal')"
              class="greyBtn"
              plain
              >Go Back</van-button
            >
          </div>
        </div>
      </modal>
      <modal
        name="unrecognisedEmailModal"
        :adaptive="true"
        :height="'100%'"
        :scrollable="true"
      >
        <div
          data-cy="wrong_email_modal"
          style="
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
          "
        >
          <div class="col-xs-12">
            <div>
              <MiFlowHeader
                page_title="We can't validate your email address"
                :logo="null"
              />
              <p class="mialert lightRed">
                The email address <b>{{ email }}</b> has not been recognised as
                belonging to <span v-if="accType == 'parent'">a parent</span
                ><span v-if="accType == 'both'">a parent or staff member</span
                ><span v-if="accType == 'staff'">a staff member</span> at
                {{ schoolInfo.name }}. You can go back and try a different email
                address.<br /><br />
                If you are still having difficulty, please contact the school to
                confirm that the email address they have for you is correct and
                then let us know at info@homerun-app.com
              </p>
            </div>
          </div>
          <div class="col-xs-12 marginTop15">
            <van-button
              type="info"
              data-cy="wrong_email_modal_close_btn"
              @click="hideModal('unrecognisedEmailModal')"
              plain
              class="greyBtn"
              >Back</van-button
            >
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import MiFlowHeader from "@/components/registration_mi/MiFlowHeader.vue";

export default {
  name: "enterEmail",
  components: {
    MiFlowHeader,
    // Header,
    // ModalHeader,
  },
  data() {
    return {
      accType: "parent",
      existingUserModal: false,
      duplicateErrorModal: false,
      unrecognisedEmailModal: false,
      accountType: "",
      placeholder: "Parent Email Address",
      unvalidated: null,
      email: "",
      loading: false,
      schoolInfo: {
        name: "",
        logo: "",
      },
    };
  },
  computed: {
    loggedIn() {
      if (!localStorage.token) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    submit() {
      this.loading = true;
      let email = this.email.trim().toLowerCase();
      if (email.substr(email.length - 1) == ".") {
        email = email.substr(0, email.length - 1);
      }
      const data = {
        email: email,
        school_id: sessionStorage.getItem("school_id"),
        main_school_id: sessionStorage.getItem("main_school_id"),
        code: sessionStorage.getItem("schoolCode"),
        accType: this.accType,
      };
      ApiService.generalApi
        .checkEmail(data)
        .then((resp) => {
          const responseData = resp.data;
          const statusMessage = responseData.message;

          const registrationCodes = sessionStorage.getItem("registration_codes")
            ? JSON.parse(sessionStorage.getItem("registration_codes"))
            : [];

          if (
            responseData.staff &&
            (this.accType == "both" || this.accType == "staff") &&
            statusMessage == "verify_email"
          ) {
            if (
              !registrationCodes.find(
                (itm) => itm.email == email && itm.type == "staff"
              )
            ) {
              registrationCodes.push({
                email: email,
                type: "staff",
                school_id: sessionStorage.getItem("school_id"),
                code: null,
              });
            }
          }
          if (
            responseData.parent &&
            (this.accType == "both" || this.accType == "parent") &&
            statusMessage == "verify_email"
          ) {
            if (
              !registrationCodes.find(
                (itm) => itm.email == email && itm.type == "parent"
              )
            ) {
              registrationCodes.push({
                email: email,
                type: "parent",
                school_id: sessionStorage.getItem("school_id"),
                code: null,
              });
            }
          }

          sessionStorage.setItem(
            "registration_codes",
            JSON.stringify(registrationCodes)
          );

          if (statusMessage == "email_does_not_exist") {
            this.showModal("unrecognisedEmailModal");
          } else if (statusMessage == "already_registered") {
            this.showModal("existingUserModal");
          } else if (statusMessage == "belongs_to_other_account") {
            this.showModal("duplicateErrorModal");
          } else if (statusMessage == "verify_email") {
            sessionStorage.setItem("email", email);
            this.$router.push("/registration_mi/email_verification");
          } else if (
            statusMessage == "you_already_registered" ||
            statusMessage == "redirect_to_flow"
          ) {
            this.$notify({
              type: "success",
              message: "You already created account, redirecting!",
            });
            ApiService.userApi.accountStatusRedirect(true);
          }
        })
        .catch((e) => {
          alert(e);
          alert(e.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    hideModal(modalName) {
      this.$modal.hide(modalName);
    },
    login() {
      sessionStorage.setItem("redirect", "enter_email");
      this.$router.push("/login");
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
      this.$notify({
        type: "success",
        message: "You have been logged out",
      });
    },
  },
  mounted() {
    const currentAcctype = this.$router.currentRoute.params.type;
    this.accType = currentAcctype;
    if (this.accType == "both" || this.accType == "staff") {
      this.placeholder = "Staff Email Address";
    }
    if (sessionStorage.getItem("schoolName")) {
      this.schoolInfo.name = sessionStorage.getItem("schoolName");
      this.schoolInfo.logo = sessionStorage.getItem("logo");
    }
  },
};
</script>
