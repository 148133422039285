<template>
  <div id="mainContent" style="min-height: 100vh; margin-top: -20px">
    <div v-if="!page">
      <Header title="Email Subscription" style="padding-top: 20px" />
      <div class="comment" style="margin-top: 20px">
        <p>
          To help us improve our service, please tell us why you'd like to
          unsubscribe.
        </p>
        <div
          class="section round25"
          style="max-width: 400px; margin: auto; padding: 20px"
        >
          <van-radio-group v-model="selection.comment" direction="vertical">
            <van-radio
              name="I keep updated using the app"
              data-cy="keep_using_app"
              >I keep updated using the app</van-radio
            >
            <van-radio
              name="I receive too many emails"
              data-cy="too_many_emails"
              >I receive too many emails</van-radio
            >
            <van-radio
              name="The emails are not of interest to me"
              data-cy="not_interested"
              >The emails are not of interest to me</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <div>
        <br />
        <van-button
          type="info"
          class="blackBtn"
          @click="unsubscribe"
          name="button"
          :disabled="!selection.comment"
        >
          Unsubscribe
        </van-button>
      </div>
      <hr />
      <div style="display: none">
        <h1 style="font-size: 22px">Account Details</h1>
        <p>To manage your account please select a button below.</p>
        <button
          type="button"
          class="btn btn-default btn-sm"
          @click="page = 'manageAccount'"
          name="button"
        >
          Manage Account
        </button>
      </div>
    </div>
    <div v-if="page == 'manageAccount'">
      <h1 style="font-size: 22px">Manage Account</h1>
      <p>Please remove all my data from the HomeRun system</p>
      <button class="btn btn-sm btn-danger">Delete all my data</button>
    </div>
    <div v-if="page == 'updated'">
      <div class="panel panel-default">
        <h3>Your subscription preferences were updated!</h3>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
export default {
  data() {
    return {
      page: "",
      selection: {
        code: "",
        subscriptionType: "",
        comment: "",
      },
    };
  },
  components: {
    Header,
  },
  methods: {
    unsubscribe: function () {
      ApiService.userApi.unsubscribe(this.selection).then((response) => {
        this.page = "updated";
        this.data = response.data;
      });
    },
  },
  mounted() {
    this.selection.code = this.$route.params.string;
  },
};
</script>
<style scoped>
.bluebutton {
  background: #21acf5;
  color: white;
  border-radius: 20px;
  padding: 10px;
  max-width: 190px;
  margin: auto;
}

.midtext {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

label {
  width: 300px;
  text-align: left;
}
</style>
