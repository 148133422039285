var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.codeStatus == true || _vm.codeStatus === false)?_c('div',{attrs:{"id":"registrationMiWrapper"}},[(_vm.codeStatus == false)?_c('div',[_c('h2',[_vm._v("Wrong code")])]):_vm._e(),_c('div',{staticClass:"textCenter",staticStyle:{"min-height":"80px"}},[(_vm.schoolInfo)?_c('MiFlowHeader',{attrs:{"school_name":_vm.schoolInfo.name,"logo":_vm.schoolInfo.logo}}):_vm._e()],1),_vm._m(0),(_vm.codeStatus == true)?_c('div',{staticClass:"clearfix",staticStyle:{"min-height":"400px"},attrs:{"id":"flexDiv"}},[_c('transition',{attrs:{"name":"fade2","mode":"out-in"}},[(_vm.checkForExistingAccount)?_c('div',{staticStyle:{"min-height":"300px"},attrs:{"data-cy":"accountOptions"}},[_c('p',[_vm._v("Please select an option")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('van-button',{staticClass:"lightBlueBtn",staticStyle:{"width":"300px"},attrs:{"type":"info","data-cy":"new_account_btn","name":"button"},on:{"click":function($event){return _vm.changeState()}}},[_vm._v("I'm new to HomeRun")])],1),_c('div',{staticClass:"col-xs-12"},[_c('van-button',{staticClass:"lightGreenBtn",staticStyle:{"width":"300px"},attrs:{"type":"info","data-cy":"i_have_account_btn","name":"button"},on:{"click":function($event){return _vm.redirectToLogin()}}},[_vm._v("I already have a HomeRun Account")])],1)])]):_vm._e()]),_c('transition',{attrs:{"name":"fade2","mode":"out-in"}},[(
          _vm.schoolSelecionRequired &&
          _vm.checkForExistingAccount == false &&
          _vm.finishedTransition == true
        )?_c('div',[_c('p',{staticClass:"colorBlack",staticStyle:{"font-size":"18px"}},[_vm._v(" Mani has not provided any text so this will do. ")]),_c('div',{staticStyle:{"padding-left":"100px","margin-top":"40px","margin-bottom":"40px"}},[_c('van-radio-group',{attrs:{"data-cy":"school_selection"},model:{value:(_vm.school_index_selected),callback:function ($$v) {_vm.school_index_selected=$$v},expression:"school_index_selected"}},_vm._l((_vm.schools),function(school,index){return _c('van-radio',{key:school.id,staticStyle:{"margin-bottom":"15px"},attrs:{"name":index},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('i',{class:props.checked
                      ? 'fas fa-circle-dot fa-sm lgreenColor'
                      : 'fa-regular fa-circle-small'})]}}],null,true)},[_vm._v(" "+_vm._s(school.name)+" ")])}),1)],1),_c('div',{staticClass:"row flowBottomButtonDiv",staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"col-xs-12"},[_c('van-button',{staticClass:"blackBtn",attrs:{"type":"info","name":"button","data-cy":"submit","disabled":_vm.school_index_selected == null},on:{"click":_vm.selectSchool}},[_vm._v("Continue")])],1)])]):_vm._e()]),_c('transition',{attrs:{"name":"fade2","mode":"out-in"}},[(
          _vm.selectRoleRequired &&
          !_vm.schoolSelecionRequired &&
          _vm.finishedTransition == true
        )?_c('div',[_c('p',{staticClass:"colorBlack",staticStyle:{"font-size":"18px"}},[_vm._v(" To get started, please select"),_c('br'),_vm._v(" your role at the school. ")]),_c('div',{staticStyle:{"padding-left":"100px","margin-top":"40px","margin-bottom":"40px"}},[_c('van-radio-group',{model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}},[_c('van-radio',{staticStyle:{"margin-bottom":"15px"},attrs:{"name":"parent","data-cy":"parent"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('i',{class:props.checked
                      ? 'fas fa-circle-dot fa-sm lgreenColor'
                      : 'fa-regular fa-circle-small'})]}}],null,false,1810964078)},[_vm._v(" Parent ")]),_c('van-radio',{staticStyle:{"margin-bottom":"15px"},attrs:{"name":"staff","data-cy":"staff"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('i',{class:props.checked
                      ? 'fas fa-circle-dot fa-sm lgreenColor'
                      : 'fa-regular fa-circle-small'})]}}],null,false,1810964078)},[_vm._v(" Staff ")]),_c('van-radio',{staticStyle:{"margin-bottom":"15px"},attrs:{"name":"both","data-cy":"both"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('i',{class:props.checked
                      ? 'fas fa-circle-dot fa-sm lgreenColor'
                      : 'fa-regular fa-circle-small'})]}}],null,false,1810964078)},[_vm._v(" Both ")])],1)],1),_c('p',{staticClass:"mialert lightGray"},[_vm._v(" If your children have not yet started at the school, please still select "),_c('b',[_vm._v("Parent")]),_vm._v(". ")]),_c('div',{staticClass:"row flowBottomButtonDiv",staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"col-xs-12"},[_c('van-button',{staticClass:"blackBtn",attrs:{"type":"info","name":"button","data-cy":"submit","disabled":!_vm.accountType},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Continue")])],1)])]):_vm._e()]),_vm._m(1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"text-center",staticStyle:{"font-size":"48px !important"}},[_vm._v(" Welcome"),_c('br'),_vm._v("to HomeRun ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center",attrs:{"id":"bottomLogo"}},[_c('img',{staticStyle:{"vertical-align":"baseline"},attrs:{"src":require("@/assets/wireframe_logo.svg")}})])}]

export { render, staticRenderFns }