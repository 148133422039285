<template>
  <div>
    <div
      class="block cursorPointer flex items-center p-2 font-rubik"
      :class="{ 'self-block': parent.noChat }"
    >
      <div class="notice-avatar-container self-start">
        <img
          class="notice-avatar border-2 border-solid border-green-dark p-0"
          :src="parent.profile_photo"
          :class="expanded ? 'imgexpanded' : ''"
          alt
          @click="toggleExpand"
        />
      </div>
      <div class="middle flex-1 self-start">
        <!-- <div class="schoolName text-xs" v-if="show_school">
          {{ parent.school_names }}
        </div> -->
        <div class="name flex flex-wrap items-center">
          <span class="font-medium mr-2">
            {{ parent.first_name + " " + parent.last_name }}
            <!-- <span v-if="parent.first_name.length + parent.last_name.length > 16"
              >..</span
            > -->
          </span>
          <span v-if="parent.is_pta" class="role"> PTA </span>
          <div class="role" v-if="parent.staff_type">
            <span v-if="parent.staff_type == 1">Head Teacher</span>
            <span v-if="parent.staff_type == 2">Teacher</span>
            <span v-if="parent.staff_type == 3">School Office</span>
          </div>
        </div>

        <i
          v-if="
            (parent.journey_preference == 1 ||
              parent.journey_preference == 3) &&
            (page == 'journey_share' || page == 'parent' || page == 'staff')
          "
          class="fa fa-link inlineBlock"
        ></i>
        <i
          v-if="
            (parent.journey_preference == 2 ||
              parent.journey_preference == 3) &&
            (page == 'travel_buddy' || page == 'parent' || page == 'staff')
          "
          class="fa fa-child journeyIcon"
        ></i>
        <div class="childrenInfo" v-if="parent.years">
          Child<span v-if="parent.years.split(',').length > 1">ren</span> in
          Year<span v-if="parent.years.split(',').length > 1">s</span>
          <span>
            <strong> {{ parent.years }}</strong>
          </span>
        </div>

        <div
          class="text-xs text-gray"
          v-if="parent.journey_preference != 0 && !parent.noChat"
        >
          <span v-if="parent.distance <= 0.2">Under 0.2</span>
          <span v-if="parent.distance > 0.2">{{
            parent.distance.toFixed(2)
          }}</span>
          Miles from you
        </div>
      </div>
      <div class="right" v-if="parent.noChat">
        <i class="far fa-user fa-lg" />
        <div class="text-xs font-semibold">Me</div>
      </div>
      <div class="chat-row" v-if="!parent.noChat" @click="showConversation">
        <i class="far fa-message fa-lg"></i>
        <div class="text-xs font-normal">Message</div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
export default {
  name: "searchParentsItem",
  props: {
    parent: Object,
    page: String,
    expanded: Boolean,
    show_school: Boolean,
  },
  data() {
    return {
      // expanded: false
    };
  },
  methods: {
    toggleExpand() {
      this.$emit("expandImage", this.parent.id);
      // this.expanded = !this.expanded;
    },
    showConversation() {
      const userRoleId = this.parent.id;
      this.$emit("showConversation", this.parent.id);
    },
  },
};
</script>
<style lang="scss" scoped>
div.schoolName {
  width: 200px;
  @apply text-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inlineBlock {
  display: inline-block;
  margin-right: 5px;
}

.self-block {
  background: rgba(255, 204, 255, 0.25);
}

.right {
  @apply text-center text-pink-dark;
  min-width: 39px;
}

.role {
  @apply text-sm font-semibold;
  color: #cc33cc;
}

.chat-row {
  @apply text-center text-pink-dark;
}

.staff-theme {
  .self-block {
    background: rgba(204, 204, 255, 0.5);
  }

  .right,
  .role,
  .chat-row {
    @apply text-purple-light;
  }
}

.journey-theme {
  .selection-button {
    @apply bg-green-dark;
  }

  .self-block {
    @apply bg-green-dark bg-opacity-10;
  }

  .right,
  .role,
  .chat-row {
    @apply text-green-dark;
  }
}
</style>
