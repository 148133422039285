<style lang="scss" scoped>
.menu {
  margin-bottom: 20px;
}
input[type="image"] {
  border: 3px dashed #999;
  cursor: move;
  display: block;
  font-size: 0px;
  filter: alpha(opacity=0);
  min-height: 160px;
  min-width: 300px;
  opacity: 1;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  background: transparent;
  z-index: -99999999999999;
}
.custom-file-upload {
  border: 1px solid #ccc;
  background: #f0ad4e;
  color: white;
  display: inline-block;
  padding: 8px 32px;
  cursor: pointer;
  // position: absolute;
  // top: 83px;
  // left: 50%;
  z-index: 9999;
  font-size: 17px;
  // width:175px;
  border-radius: 5px;
  margin-left: 20px;
  // margin-left:-50.5px;
}
img.avatarImage {
  width: 60px;
  height: 60px;
  padding: 2px;
  border-radius: 30px;
}
img.avatarImage.active {
  border: 1px solid #21acf5;
}
button.active {
  background: #21acf5;
  color: white;
}
button.genderButton {
  background: #f0ad4e;
  color: white;
  opacity: 0.5;
  font-size: 13px;
}
button.genderButton.active {
  background: #f0ad4e;
  opacity: 1;
}
</style>
<template>
  <div id="wrapper">
    <div v-if="options.profilePicture">
      <ModalHeader title="Select profile picture" @hideModal="$emit('close')" />
      <div class="menu clearfix col-xs-12">
        <div class>
          <div class="col-xs-5 nopadding">
            <van-button
              type="info"
              size="small"
              @click="setType('upload')"
              data-cy="choosePictureButton"
              class="round25"
              :class="{ active: type == 'upload' }"
              >Upload Photo</van-button
            >
          </div>
          <div class="col-xs-2 text-center">
            <span
              class="colorGrey"
              style="margin-top: 5px; display: block; font-weight: bold"
              >or</span
            >
          </div>
          <div class="col-xs-5 nopadding text-right">
            <van-button
              type="info"
              size="small"
              @click="setType('avatarSelection')"
              data-cy="chooseAvatarButton"
              class="round25"
              :class="{ active: type == 'avatarSelection' }"
              >Choose Avatar</van-button
            >
          </div>
        </div>
      </div>
      <div v-if="type === 'avatarSelection'">
        <van-button
          @click="selectGender('f')"
          data-cy="femaleButton"
          :class="{ active: genderPrefix == 'f' }"
          class="btn genderButton"
          >Female avatars</van-button
        >
        <van-button
          @click="selectGender('m')"
          data-cy="maleButton"
          :class="{ active: genderPrefix == 'm' }"
          class="btn genderButton"
          >Male avatars</van-button
        >
        <div
          class="section avatars"
          v-if="genderPrefix"
          style="text-align: center; border-top-width: 0px"
        >
          <!-- <spn>n</spn> -->
          <img
            :key="$index"
            v-for="(n, $index) in numberRange"
            @click="selectAvatar(genderPrefix, n)"
            class="avatarImage"
            :class="{
              active:
                avatarSelection.gender === genderPrefix &&
                avatarSelection.id === n,
            }"
            :src="parents_avatars_url + genderPrefix + n + '.png'"
            alt
          />
        </div>
      </div>
      <div
        class="clearfix col-xs-12"
        v-if="type === 'upload'"
        style="clear: both"
      >
        <div v-if="!imgSrc" class="alert alert-mira clearfix round25">
          Upload an image below. Supported image types: jpg, png, bmp, gif
        </div>
        <div style="clear: both"></div>
        <label for="imgInp" class="custom-file-upload">
          <i class="fas fa-upload" style="font-size: 18px"></i> Choose image
        </label>
        <van-button
          v-if="imgSrc"
          @click="uploadPicture"
          data-cy="uploadPictureButton"
          type="info"
          class="pull-right"
          style="margin-right: 20px"
          >Save</van-button
        >
        <input
          id="imgInp"
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
          style="display: none"
        />
        <hr style="clear: both" />
        <vue-cropper
          ref="cropper"
          v-show="imgSrc"
          :src="imgSrc"
          :aspect-ratio="1 / 1"
          :cropmove="cropImage"
          alt=" "
        ></vue-cropper>
      </div>
      <div></div>
    </div>
    <div class="modal-footer" style="text-align: right">
      <van-button
        type="info"
        size="small"
        @click="$emit('close')"
        data-cy="close"
        class="round25"
        plain
        >Cancel</van-button
      >
      <van-button
        type="info"
        size="small"
        v-if="imgSrc"
        @click="uploadPicture"
        data-cy="submit_bottom"
        style="margin-left: 5px"
        class="round25"
        >Save</van-button
      >
    </div>
  </div>
</template>
<script>
// import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import dbConnection from "@/services/dbConnection";
import ApiService from "@/services/ApiService";
// uploadFile?blobImage

export default {
  name: "uploader",
  components: {
    // Header,
    ModalHeader,
    VueCropper,
  },
  props: {
    options: Object,
  },
  data() {
    return {
      type: null,
      genderPrefix: "f",
      parents_avatars_url:
        "https://s3-eu-west-1.amazonaws.com/home-run/parent_avatars/",
      numberRange: [],
      avatarSelection: {},
    };
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          this.$forceUpdate();
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setType(type) {
      this.type = type;
    },
    selectGender(genderPrefix) {
      this.genderPrefix = genderPrefix;
    },
    uploadPicture() {
      this.$store.dispatch("loading", true);
      this.$refs.cropper.getCroppedCanvas({ width: 300, height: 300 }).toBlob(
        (blob) => {
          const formData = new FormData();
          formData.append("blobImage", blob /*, 'example.png' */);
          dbConnection
            .post("api/uploadFile?blobImage", formData)
            .then((response) => {
              this.$parent.$emit("newImage", {
                url: response.data.url,
              });
              this.$store.dispatch("loading", false);
              this.$emit("close");
            })
            .catch(function (err) {
              this.$store.dispatch("loading", false);
              alert(JSON.stringify(err));
            });
        } /*, 'image/png' */
      );
    },
    selectAvatar(genderPrefix, id) {
      this.avatarSelection = {
        gender: genderPrefix,
        id,
        url: this.parents_avatars_url,
      };
      this.$parent.$emit("newAvatar", {
        gender: genderPrefix,
        id,
        url: this.parents_avatars_url,
      });

      var payload = {
        avatarId: id,
        gender: genderPrefix,
      };
      ApiService.userApi.changeParentPicture(payload);
      this.$emit("close");
    },
  },
  mounted() {
    for (var i = 1; i < 51; i++) {
      this.numberRange.push(i);
    }
  },
};
</script>
