<template>
  <div id="modalCreatePost" :class="`notice-theme-${category} font-rubik`">
    <div class="flex p-3 pb-0 items-baseline justify-between">
      <div class="create-post" :class="{ compact: category }">
        <div class="text-lg font-medium">
          Create A Post
          <inline-svg :src="require('@/assets/notice/create-post.svg')" />
        </div>
        <span class="info">
          Select what your post is about?
          <span
            class="text-green font-medium ml-1.5 cursor-pointer"
            @click="toggleExpandInfo"
            v-if="!expandInfo"
            >Learn more</span
          ></span
        >
      </div>
      <i
        class="fa fa-times close-icon fa-xl cursor-pointer mr-2"
        @click="$emit('close')"
      />
    </div>

    <div class="modal-body">
      <!-- <transition name="fade" mode="out-in"> -->
      <div v-if="!category" v-show="editing === false">
        <div
          class="button-container"
          :class="{
            expanded: expandInfo,
            show: temphide,
          }"
        >
          <div>
            <div
              class="circle eco"
              :class="{ expanded: expandInfo }"
              @click="changeCategory('eco', 'greenTheme')"
              data-cy="change_category_eco_btn"
            >
              <div class="inner-circle">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/notice/eco.svg')"
                    class="mb-2"
                  />
                  <span>ECO</span>
                </div>
              </div>
              <div class="learn-more" v-if="expandInfo">
                Promote your school’s Eco initiatives. HomeRun is the place to
                co-ordinate and raise awareness within your school community.
              </div>
            </div>
            <div
              class="circle sports"
              :class="{ expanded: expandInfo }"
              @click="changeCategory('sports', 'darkBlueTheme')"
              data-cy="change_category_sports_btn"
            >
              <div class="inner-circle">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/notice/sports.svg')"
                    class="mb-2"
                  />
                  <span>SPORTS <br />& CLUBS</span>
                </div>
              </div>
              <div class="learn-more" v-if="expandInfo">
                Share information about school sports and clubs, such as scores,
                fixtures, cancellations and ETAs from the coach.
              </div>
            </div>
            <div
              class="circle poll"
              :class="{ expanded: expandInfo }"
              @click="changeCategory('poll', 'greyTheme')"
              data-cy="change_category_poll_btn"
            >
              <div class="inner-circle">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/notice/poll.svg')"
                    class="mb-2"
                  />
                  <span>POLL</span>
                </div>
              </div>
              <div class="learn-more" v-if="expandInfo">
                Create a quick poll about anything school-related and see the
                anonymised results on your notice board.
              </div>
            </div>
          </div>

          <div>
            <div
              class="circle travel"
              :class="{ expanded: expandInfo }"
              @click="changeCategory('travel', 'orangeTheme')"
              data-cy="change_category_travel_btn"
            >
              <div class="inner-circle">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/notice/travel.svg')"
                    class="mb-2"
                  />
                  <span>SCHOOL<br />TRAVEL</span>
                </div>
              </div>
              <div class="learn-more" v-if="expandInfo">
                Share school travel information, let parents know your ETA on
                the way back from a sports trip, or post if a coach is running
                late.
              </div>
            </div>
            <div
              class="circle pta"
              :class="{ expanded: expandInfo }"
              @click="changeCategory('pta', 'redTheme')"
              data-cy="change_category_pta_btn"
            >
              <div class="inner-circle">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/notice/pta.svg')"
                    class="mb-2"
                  />
                  <span>PTA</span>
                </div>
              </div>
              <div class="learn-more" v-if="expandInfo">
                Promote your PTA activity and events. (Only user’s who have
                indicated that they are a member of the PTA can use this
                category).
              </div>
            </div>
            <div
              class="circle general"
              :class="{ expanded: expandInfo }"
              @click="changeCategory('general', 'blueTheme')"
              data-cy="change_category_general_btn"
            >
              <div class="inner-circle">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/notice/general.svg')"
                    class="mb-2"
                  />
                  <span>GENERAL</span>
                </div>
              </div>
              <div class="learn-more" v-if="expandInfo">
                Share general school information. From reminders to club news,
                keep parents informed - less emails, more engagement!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="category">
        <div
          @click="changeCategory(null)"
          class="category-display mb-3"
          data-cy="deselect_current_cat_btn"
        >
          <div v-if="category === 'pta'" class="bg-pink-dark">
            PTA
            <inline-svg
              :src="require('@/assets/notice/pta.svg')"
              height="22"
              class="inline fill-white ml-2"
            />
          </div>
          <div v-if="category === 'general'" class="bg-blue">
            General School Info
            <inline-svg
              :src="require('@/assets/notice/general.svg')"
              height="22"
              class="inline fill-white ml-2"
            />
          </div>
          <div v-if="category === 'eco'" class="bg-green">
            Eco
            <inline-svg
              :src="require('@/assets/notice/eco.svg')"
              height="22"
              class="inline fill-white ml-2"
            />
          </div>
          <div v-if="category === 'travel'" class="bg-orange">
            School Travel
            <inline-svg
              :src="require('@/assets/notice/travel.svg')"
              height="22"
              class="inline fill-white ml-2"
            />
          </div>
          <div v-if="category === 'poll'" class="bg-peach">
            Poll
            <inline-svg
              :src="require('@/assets/notice/poll.svg')"
              height="22"
              class="inline fill-white ml-2"
            />
          </div>
          <div v-if="category === 'sports'" class="bg-purple">
            Sports & Clubs
            <inline-svg
              :src="require('@/assets/notice/sports.svg')"
              height="22"
              class="inline fill-white ml-2"
            />
          </div>
        </div>
        <!-- <div class="text-center row" style="margin-bottom: 10px; display: none">
          <van-button
            @click="changeType('notice')"
            class="round25 typeButton"
            :class="newPost.type === 'notice' ? 'active' : ''"
            >Text/Photos</van-button
          >
          <van-button
            @click="changeType('event')"
            class="round25 typeButton"
            :class="newPost.type === 'event' ? 'active' : ''"
            >Event</van-button
          >
          <van-button
            @click="changeType('poll')"
            class="round25 typeButton"
            :class="newPost.type === 'poll' ? 'active' : ''"
            >Poll</van-button
          >
        </div> -->
        <div class="row" style="display: none" v-if="roles.length > 1">
          <div
            class="col-xs-4 form-label"
            style="font-weight: bold; padding-top: 9px"
            :class="{ colorRed: errors.indexOf('user_type') > -1 }"
          >
            Send as
          </div>
          <div class="col-xs-8">
            <button
              v-if="roles.indexOf(6) > -1"
              class="btn noBackground"
              @click="changeAccountType(6)"
              :class="{ accOn: newPost.user_type === 6 }"
            >
              <span>
                Parent
                <i
                  class="far fa-circle fsize15"
                  :class="{
                    'fas fa-circle': newPost.user_type === 6,
                    'fa-circle': newPost.user_type != 6,
                  }"
                ></i>
              </span>
            </button>
            <button
              v-if="roles.indexOf(7) > -1"
              class="btn noBackground"
              @click="changeAccountType(7)"
              :class="{ accOn: newPost.user_type === 7 }"
            >
              <span>
                PTA
                <i
                  class="far fa-circle fsize15"
                  :class="{
                    'fas fa-circle': newPost.user_type == 7,
                    'fa-circle': newPost.user_type != 7,
                  }"
                ></i>
              </span>
            </button>
            <button
              v-if="roles.indexOf(8) > -1"
              class="btn noBackground"
              @click="changeAccountType(8)"
              :class="{ accOn: newPost.user_type === 8 }"
            >
              <span>
                Head Teacher
                <i
                  class="far fa-circle fsize15"
                  :class="{
                    'fas fa-circle': newPost.user_type === 8,
                    'fa-circle': newPost.user_type !== 8,
                  }"
                ></i>
              </span>
            </button>
            <button
              v-if="roles.indexOf(9) > -1"
              class="btn noBackground"
              @click="changeAccountType(9)"
              :class="{ accOn: newPost.user_type === 9 }"
            >
              <span>
                Teacher
                <i
                  class="far fa-circle fsize15"
                  :class="{
                    'fas fa-circle': newPost.user_type === 9,
                    'fa-circle': newPost.user_type !== 9,
                  }"
                ></i>
              </span>
            </button>
            <button
              v-if="roles.indexOf(2) > -1"
              class="btn noBackground"
              @click="changeAccountType(2)"
              :class="{ accOn: newPost.user_type === 2 }"
            >
              <span>
                School Office
                <i
                  class="far fa-circle fsize15"
                  :class="{
                    'fas fa-circle': newPost.user_type == 2,
                    'fa-circle': newPost.user_type != 2,
                  }"
                ></i>
              </span>
            </button>
          </div>
        </div>

        <div class="mt-5">
          <div class="form-row">
            <div
              class="form-label"
              :class="{ colorRed: errors.indexOf('school') > -1 }"
            >
              Send to:
            </div>
            <div class="form-input">
              <DropdownSelection
                keyProp="id"
                single
                name="name"
                default_label="Select School"
                allSelectedLabel="All selected"
                class="selection-button school-selection-button mb-3"
                :class="!newPost.school && showErrors ? 'errorInput' : ''"
                :items="schools"
                :default_value="newPost.school"
                @updated="changeSchool"
                data-cy="select_school_selection_input"
              />
              <DropdownSelection
                keyProp="value"
                name="name"
                ref="years"
                class="selection-button mb-3"
                :class="
                  newPost.years.length === 0 && !newPost.group && showErrors
                    ? 'errorInput round25'
                    : ''
                "
                default_label="Select Years"
                :default_value="newPost.years"
                allSelectedLabel="All Years selected"
                :items="years"
                @updated="changeYear"
                data-cy="select_years_selection_input"
              />
            </div>
          </div>
          <div class="col-xs-12">
            <!-- <hr /> -->
          </div>
          <div class="row flex items-center mb-5" v-if="groups.length > 0">
            <div class="col-xs-6">
              <DropdownSelection
                keyProp="value"
                name="name"
                ref="years"
                class="selection-button"
                :class="
                  newPost.years.length === 0 && !newPost.group && showErrors
                    ? 'errorInput'
                    : ''
                "
                default_label="Select Years"
                :default_value="newPost.years"
                :items="years"
                @updated="changeYear"
              />
              <span
                style="position: absolute; right: -7px; top: 3px; color: #333"
                >or</span
              >
            </div>
            <div class="col-xs-6">
              <DropdownSelection
                keyProp="id"
                name="name"
                ref="groups"
                single
                class="selection-button"
                :class="
                  !newPost.group && newPost.years.length === 0 && showErrors
                    ? 'errorInput'
                    : ''
                "
                default_label="Select Group"
                :default_value="newPost.group"
                :items="groups"
                @updated="changeGroup"
              />
            </div>
          </div>
          <div
            class="col-xs-4 form-label"
            style="font-weight: bold; padding-top: 9px; display: none"
            :class="{ colorRed: errors.indexOf('school') > -1 }"
          >
            <!-- Send to Years -->
          </div>
          <div class="col-xs-8 col-sm-8 form-label" style="display: none">
            <van-button
              type="info"
              plain
              size="small"
              class="inputBorder yearsGroupModalButton"
              style="
                width: 100%;
                background: white !important;
                height: 35px;
                border-radius: 10px;
                font-size: 13px;
              "
              @click="showModal('yearsGroupsModal')"
              :class="{ empty: !yearsGroupLabel }"
            >
              {{ yearsGroupLabel ? yearsGroupLabel : "Select Years or Group" }}
            </van-button>
            <div v-show="showClassField" style="margin-top: 7px">
              <input
                type="text"
                class="form-control"
                v-model="newPost.class_label"
                placeholder="Type label here"
                maxlength="15"
              />
            </div>
          </div>
        </div>

        <div v-if="newPost.type !== 'poll'">
          <div class="form-row">
            <span
              class="form-label self-center"
              @click="newPost.event = !newPost.event"
              data-cy="create_event_btn"
              >Create as an Event?</span
            >
            <van-switch
              v-model="newPost.event"
              size="24px"
              :class="{ 'bg-notice-theme': newPost.event }"
            />
          </div>
          <div v-if="newPost.event" class="mt-5" data-cy="show_event_form">
            <!-- <transition name="slide-fade"> -->
            <div>
              <div class="form-row">
                <div
                  class="form-label self-center"
                  :class="{ colorRed: errors.indexOf('date') > -1 }"
                >
                  Date & Time
                </div>
                <div class="form-input">
                  <datetime
                    type="datetime"
                    class="selection-button"
                    v-model="$v.newPost.date.$model"
                    placeholder="Select Date & Time"
                    title="Event Date"
                    data-cy="event_date_input"
                    :class="
                      $v.newPost.date.$error && showErrors ? 'errorInput' : ''
                    "
                  ></datetime>
                </div>
              </div>
              <div class="form-row">
                <div
                  class="form-label self-center"
                  :class="{ colorRed: errors.indexOf('title') > -1 }"
                >
                  Event Title
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    data-cy="event_title_input"
                    :class="
                      $v.newPost.title.$error && showErrors ? 'errorInput' : ''
                    "
                    v-model.trim="$v.newPost.title.$model"
                    placeholder="Event Title"
                  />
                </div>
              </div>
            </div>
            <!-- </transition> -->
          </div>
        </div>
        <div
          v-show="
            newPost.type === 'NOTICE' ||
            newPost.type === 'noticeimages' ||
            newPost.type === 'EVENT'
          "
        >
          <div class="row mt-5">
            <div
              class="col-xs-8 form-label"
              :class="{ colorRed: errors.indexOf('message') > -1 }"
            >
              <span v-if="!newPost.event">Message</span>
              <span v-if="newPost.event">Event Description</span>
            </div>
          </div>
          <div class="row mt-2 mb-4">
            <div class="col-xs-12">
              <van-field
                v-model="$v.newPost.message.$model"
                data-cy="message_input"
                rows="2"
                autosize
                label
                type="textarea"
                placeholder="Enter message here"
                class="textarea"
                :class="
                  $v.newPost.message.$error && showErrors ? 'errorInput' : ''
                "
              />
            </div>
          </div>
        </div>

        <div v-if="newPost.type === 'POLL'">
          <div class="row" v-if="!showingDescription">
            <div
              class="col-xs-12 form-row"
              :class="{ colorRed: errors.indexOf('question') > -1 }"
            >
              <button
                class="btn-white btn-add"
                @click="showDesription()"
                data-cy="show_description_btn"
              >
                + Add a description (optional)
              </button>
            </div>
            <div class="col-xs-8 form-label"></div>
          </div>
          <div class="row" v-show="showingDescription">
            <div
              class="col-xs-12 form-label"
              :class="{ colorRed: errors.indexOf('question') > -1 }"
            >
              Description (optional)
              <br />
              <small>Char limit 300</small>
            </div>
          </div>
          <div class="row mt-2 mb-4" v-show="showingDescription">
            <div class="col-xs-12">
              <van-field
                v-model="$v.newPost.message.$model"
                data-cy="message_input"
                rows="2"
                class="textarea"
                autosize
                label
                type="textarea"
                placeholder="Message"
                maxlength="200"
                :class="
                  $v.newPost.message.$error && showErrors ? 'errorInput' : ''
                "
              />
            </div>
          </div>

          <div class="row my-2">
            <div
              class="col-xs-12 form-label"
              :class="{ colorRed: errors.indexOf('question') > -1 }"
            >
              Question
              <br />
              <small>Char limit 200</small>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-xs-12">
              <van-field
                v-model="$v.newPost.question.$model"
                class="textarea"
                rows="2"
                autosize
                label
                type="textarea"
                placeholder="Message"
                maxlength="200"
                :class="
                  $v.newPost.question.$error && showErrors ? 'errorInput' : ''
                "
              />
            </div>
          </div>

          <div
            :key="v.id"
            class="row mt-1"
            v-for="(v, $index) in $v.newPost.answers.$each.$iter"
          >
            <div class="col-xs-12">
              <div class="form-label mb-2">
                Answer {{ Number($index) + 1 }} <br /><small>
                  Char limit 80
                </small>

                <span
                  v-if="$index > 1"
                  class="text-grey text-sm pull-right cursor-pointer float-right"
                  @click="removeAnswer($index)"
                >
                  <i
                    class="fa fa-minus-circle text-grey"
                    style="font-size: 17px"
                  ></i>
                  Remove
                </span>
                <van-field
                  class="textarea"
                  v-model="v.answer.$model"
                  :class="v.answer.$error && showErrors ? 'errorInput' : ''"
                  rows="2"
                  x
                  autosize
                  label
                  type="textarea"
                  placeholder="Answer"
                  maxlength="80"
                />
              </div>
            </div>
          </div>
          <div v-if="newPost.answers.length < 6">
            <button class="btn-white btn-add my-2" @click="addNewAnswer()">
              + Add new Answer
            </button>
          </div>
        </div>

        <div
          class="row mt-6"
          v-show="newPost.type === 'NOTICE' || newPost.type === 'noticeimages'"
        >
          <div
            class="col-xs-8 form-label"
            :class="{ colorRed: errors.indexOf('images') > -1 }"
          >
            Add Images (optional)
            <br />
            <small>Max 10 attachments per post</small>
          </div>
          <div class="col-xs-12 flex items-end mt-1 attachments">
            <div id="imagesLoading" v-if="imagesLoading">
              <Loading size="34" color="#1989fa" />
            </div>
            <file-upload
              ref="upload"
              input-id="files"
              name="files"
              :add-index="0"
              :thread="3"
              :headers="{ Authorization: token }"
              accept="image/*, application/pdf"
              :value="files"
              :multiple="true"
              :max-count="5"
              :maximum="10"
              :extensions="['jpg', 'gif', 'png', 'pdf', 'jpeg', 'webp']"
              :post-action="uploadUrl"
              @input-file="inputFile"
            >
              <div class="btn-upload">
                <inline-svg
                  :src="require('@/assets/notice/add-image.svg')"
                  v-if="newPost.images.length < 10"
                />
                <span class="attachment-icon add-icon" />
              </div>
            </file-upload>
            <div class="image-previews">
              <div :key="image.id" v-for="(image, $index) in newPost.images">
                <div class="relative">
                  <img
                    v-if="image.url.indexOf('.pdf') !== -1"
                    src="@/assets/pdf_icon.png"
                  />
                  <img
                    v-if="image.url.indexOf('.pdf') === -1"
                    :src="image.url"
                  />
                  <span
                    class="attachment-icon remove-icon"
                    @click="removeImage($index)"
                  >
                    <i class="fa fa-trash text-grey"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        <div v-show="newPost.type" class="mt-3">
          <br />
          <div class="flex items-center">
            <Checkbox
              id="markAsImportant"
              color="#21acf5"
              v-model="newPost.importantNotice"
              data-cy="important_notice_checkbox"
              class="m-0 !w-14"
              :size="30"
            ></Checkbox>
            <span class="form-label">Mark as important?</span>
          </div>
          <br />
          <div class="flex items-center mt-3">
            <Checkbox
              id="markAsImportant"
              color="#21acf5"
              v-model="newPost.allow_comments"
              data-cy="allow_comments_checkbox"
              class="!w-14 m-0"
              :size="30"
            ></Checkbox>
            <span class="form-label">Allow comments?</span>
          </div>
        </div>
        <modal
          name="yearsGroupsModal"
          :adaptive="false"
          :height="600"
          :scrollable="true"
          style="overflow: scroll; z-index: 999"
        >
          <ModalHeader
            title="Select Audience"
            icon="fa fa-choice"
            @hideModal="hideModal('yearsGroupsModal')"
            style="color: #333; z-index: 999"
          />
          <div class="col-xs-12">
            <div class="row" style="margin-top: 10px; text-align: center"></div>
            <div class="modal-footer" style="text-align: right">
              <van-button
                type="info"
                size="small"
                @click="hideModal('yearsGroupsModal')"
                data-cy="close"
                class="round25"
                >Done</van-button
              >
              <div
                style="
                  margin-bottom: 10px;
                  font-size: 12px;
                  text-align: center;
                  margin-top: 10px;
                "
                class="alert alert-mira round25 row"
              >
                If you would like to send messages to smaller groups, please ask
                your school administrator to set them up on the HomeRun Admin
                Console
              </div>
            </div>
          </div>
        </modal>
        <div style="clear: both"></div>
        <div class="row actions-row" v-show="newPost.type">
          <button class="btn-grey" @click="$emit('close')">CANCEL</button>

          <button
            v-if="editing"
            class="btn-grey"
            @click="deletePost()"
            data-cy="delete_post_btn"
          >
            DELETE
          </button>

          <button
            class="btn-black"
            type="info"
            @click="submit"
            :disabled="$refs.upload && $refs.upload.active"
            data-cy="submit_btn"
          >
            <span v-if="!editing">POST</span>
            <span v-if="editing">Update Post</span>
          </button>
        </div>

        <div
          v-if="newPost.user_type == 6"
          class="mialert lightGray text-sm text-center font-normal"
          data-cy="delay_validation_message"
        >
          <p>
            There may be a small delay before messages are posted as they are
            moderated
          </p>
        </div>
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>
<style>
.notActive {
  opacity: 0.4;
}
</style>
<script>
import ApiService from "@/services/ApiService";
import ModalHeader from "@/components/ModalHeader";
import { api_url } from "@/domain";
import DropdownSelection from "@/components/DropdownSelection";

import { Loading } from "vant";
import VueUploadComponent from "vue-upload-component";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
// import Helper from "./logic/createPost";
import Checkbox from "vue-material-checkbox";
import { Datetime } from "vue-datetime";
import ImageCompressor from "compressorjs";
export default {
  name: "createpost",
  components: {
    ModalHeader,

    Checkbox,
    DropdownSelection,
    Loading,
    Datetime,
    "file-upload": VueUploadComponent,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      date: null,
      expandInfo: false,
      newPost: {
        type: null,
        category: null,
        user_type: null,
        title: null,
        question: null,
        message: null,
        years: [],
        group: null,
        answers: [{ answer: "" }, { answer: "" }],
        images: [],
        date: "",
        school: null,
        importantNotice: false,
        allow_comments: true,
        event: false,
      },
      minDate: new Date(2020, 5, 16),
      maxDate: new Date(2025, 10, 1),
      yearsGroupLabel: "",
      editing: false,
      roles: [],
      errors: [],
      groups: [],
      years: [],
      loaded: true,
      schools: null,
      showClassField: false,
      value: [],
      showingDescription: false,
      category: null,
      showCatsInOneColumn: false,
      temphide: false,
      theme: null,
      showYearsSeleciton: false,
      files: [],
      showErrors: false,
      imagesLoading: false,
    };
  },
  validations() {
    var validations = {
      newPost: {
        school: {
          required,
        },
      },
    };
    if (this.newPost.event) {
      validations.newPost.title = {
        required,
        minLength: minLength(4),
      };
      validations.newPost.date = {
        required,
      };
    }
    if (this.newPost.category != "poll") {
      validations.newPost.message = {
        required,
        minLength: minLength(1),
      };
    } else if (this.newPost.category === "poll") {
      validations.newPost.message = {
        maxLength: maxLength(2000),
      };
      validations.newPost.question = {
        maxLength: minLength(1),
        required,
      };
      validations.newPost.answers = {
        $each: {
          answer: {
            required,
            maxLength: maxLength(80),
          },
        },
      };
    }
    return validations;
  },
  methods: {
    showDesription() {
      this.showingDescription = true;
    },
    showYearsPopup() {
      this.showYearsSeleciton = !this.showYearsSeleciton;
    },
    selection(type) {
      this.newPost.selection = type;
    },
    showModal(name) {
      if (name === "yearsGroupsModal" && !this.newPost.school) {
        this.$notify({
          message: "Select a school first",
          type: "warning",
        });
        return;
      }
      this.$modal.show(name);
    },
    hideModal(name) {
      this.$modal.hide(name);
    },
    changeType(type) {
      this.newPost.type = type;
    },
    changeCategory(category) {
      if (category === "pta" && !this.is_pta) {
        this.$notify({
          message: "You do not have the PTA role",
          type: "warning",
        });
        return;
      }
      this.category = category;
      this.newPost.category = category;
      this.newPost.type = "NOTICE";
      if (!category) {
        this.newPost.type = null;
        this.theme = null;
        return;
      }
      if (category === "poll") {
        this.newPost.type = "POLL";
      }

      this.theme = "activeBg";
    },
    getMySchools() {
      ApiService.userApi.getUserOrganizations().then((response) => {
        if (response.data == "No Schools") {
          this.$notify({
            message: "You have no schools",
            type: "warning",
          });
        } else {
          this.schools = response.data.user_organizations
            .filter((itm) => itm.user_role_id == this.currentUserRoleId)
            .map((itm) => itm.organization);
          this.roles = this.rolesToNumbers(response.data.roles);

          this.newPost.user_type = this.roles[0];
          this.newPost.school = this.schools[0].id;

          this.loading = false;
          this.$store.dispatch("loading", false);
        }
      });
    },
    rolesToNumbers(roles) {
      // to match old way of doing
      let rolesNumbers = [];
      roles.forEach((element) => {
        if (element.slug == "PARENT") {
          rolesNumbers.push(6);
        } else if (element.slug == "STAFF") {
          rolesNumbers.push(9);
        }
      });
      return rolesNumbers;
    },
    // getSchools() {
    //   this.schools = [];
    //   ApiService.userApi.getUserSchools().then((response) => {
    //     this.roles = response.data.roles;
    //     this.years = [];
    //     this.schools = response.data.schools;

    //     if (this.schools.length == 1) {
    //       this.newPost.school = this.schools[0].id;
    //       this.newPost.schoolText = this.schools[0].name;
    //       this.years = this.schools[0].years;
    //     }
    //     this.newPost.user_type = this.roles[0];

    //     this.children = response.data.children;
    //   });
    // },
    changeSchool(school) {
      if (Array.isArray(school)) {
        school = school[0];
      }

      var $index = -1;
      this.schools.some(function (el, i) {
        if (el.id == school) {
          $index = i;
          return true;
        }
      });
      this.newPost.schoolText = this.schools[$index].name;
      this.newPost.school = this.schools[$index].id;
      this.newPost.yearText = "";
      this.newPost.year = null;
      this.years = this.schools[$index].years;
      console.log("this years", this.years);
      // this.groups = this.schools[$index].groups;
      this.groups = [];
    },
    changeYear(years) {
      this.newPost.years = years;
      this.newPost.group = null;
      this.yearsGroupLabel = years.length + " years selected";
      if (this.$refs.groups) {
        this.$refs.groups.clearSelection();
      }
    },
    changeGroup(group) {
      this.newPost.years = [];
      this.newPost.group = group;
      this.yearsGroupLabel = this.groups.find((x) => x.id == group).name;
      this.$refs.years.clearSelection();
    },
    changeAccountType(accountType) {
      this.newPost.user_type = accountType;
    },
    showClassFieldFunc() {
      this.showClassField = true;
    },
    addNewAnswer() {
      this.newPost.answers.push({ answer: "" });
    },
    removeAnswer(index) {
      this.newPost.answers.splice(index, 1);
    },
    toggleExpandInfo() {
      this.expandInfo = !this.expandInfo;
    },
    inputFile: function (newFile, oldFile) {
      if (newFile && !oldFile && !newFile.active) {
        // Get response data
        if (
          newFile.type.substr(0, 6) === "image/" &&
          newFile.type != "image/gif"
        ) {
          newFile.error = "compressor";
          new ImageCompressor(newFile.file, {
            quality: 0.8,
            maxHeight: 1200,
            success: (result) => {
              // Successfully deleted the error to write the new file
              this.$refs.upload.update(newFile, {
                file: result,
                error: "",
                size: result.size,
                type: result.type,
              });
              // alert('done');
              if (newFile.xhr) {
              }
              if (!this.$refs.upload.active) {
                // console.log("auto upload...");
                // this.$refs.upload.active = true;
              }
            },
            error: (e) => {
              this.$refs.upload.update(newFile, { error: e.message });
              alert("error");
            },
          });
        } else {
          // normal upload without compression
        }
      }

      if(newFile.error == 'extension'){
        this.$notify({
            message: "Unsupported file type",
            type: "warning",
          });
          this.imagesLoading = false;
      }
        
      if (
        newFile.xhr &&
        newFile.xhr.readyState === 4 &&
        newFile.active === false
      ) {
        //  Get the response status code
        let response = JSON.parse(newFile.xhr.response);
        if (response.length === 1) {
          this.newPost.images.push(response[0]);
        } else {
          // return;
        }
        if (this.newPost.images.length === 10) {
          this.$notify({
            message: "Maximum of 10 images are allowed per post",
            type: "warning",
          });
        }
        setTimeout(() => {
          if (this.$refs.upload.active === false) {
            this.imagesLoading = false;
          }
        }, 500);
      }
      // blob
      if (
        newFile &&
        newFile.file &&
        (!oldFile || oldFile.file !== newFile.file)
      ) {
        var URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (newFile.error == "") {
          this.imagesLoading = true;
          if (!this.$refs.upload.active) {
            this.$refs.upload.active = true;
          }
        }
      }
    },
    removeImage: function (index) {
      if (confirm("Are you sure ?")) {
        this.newPost.images.splice(index, 1);
      }
    },
    submit() {
      this.showErrors = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          message: "Please fill all the fields",
          type: "warning",
        });
        return;
      }

      let clone = JSON.parse(JSON.stringify(this.newPost));
      clone.message = clone.message.replace(/[\u2018\u2019]/g, "'");
      clone.user_role_id = this.currentUserRoleId;
      if (clone.question) {
        clone.title = clone.question;
      }
      if (!clone.event) {
        delete clone.event;
        delete clone.date;
      } else {
        clone.date = this.convertDate(clone.date);
      }

      if (clone.category === "poll") {
        delete clone.images;
      } else {
        delete clone.answers;
        delete clone.question;
      }
      this.$store.dispatch("loading", true);

      ApiService.generalApi
        .saveNewPost(clone)
        .then(() => {
          if (clone.id) {
          } else if (clone.user_type == 6) {
            this.$notify({
              type: "success",
              message: "Your Post sent for approval",
            });
          } else {
            this.$notify({
              type: "success",
              message: "Your Post have been saved!",
            });
          }
          this.$parent.$emit("refresh");
          this.$emit("close");
        })
        .catch((err) => {
          this.$store.dispatch("loading", false);
          alert(err);
        });
    },
    convertDate(date) {
      const MM = {
        Jan: "January",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jul: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
      };

      const xx = String(new Date(date)).replace(
        /\w{3} (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):[^(]+\(([A-Z]{3})\)/,
        function ($0, $1, $2, $3, $4, $5, $6) {
          return (
            MM[$1] +
            " " +
            $2 +
            ", " +
            $3 +
            " - " +
            ($4 % 12) +
            ":" +
            $5 +
            (+$4 > 12 ? "PM" : "AM") +
            " " +
            $6
          );
        }
      );
      return xx.split("GMT")[0];
    },
  },
  computed: {
    uploadUrl: function () {
      return api_url + "/api/uploadFile?notice";
    },
    token: function () {
      return "Bearer " + this.$store.state.general.token;
    },
    is_pta: function () {
      return this.$store.state.general.user.is_pta;
    },
    currentUserId: function () {
      return this.$store.state.general.user_id;
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
  },
  mounted() {
    const yearsAdded = [1, 0, 3, 4, 5, 6];
    let years = [];
    for (var y = 0; y < yearsAdded.length; y++) {
      var label = "Year " + yearsAdded[y];
      if (yearsAdded[y] == 0) {
        label = "Reception";
      } else if (yearsAdded[y] == -1) {
        label = "Nursery";
      }
      years.push({ value: yearsAdded[y], name: label });
    }

    this.getMySchools();
  },
};
</script>
<style lang="scss">
$circle-button-width: 119px;

#modalCreatePost {
  background: #ffffff;

  @media screen and (max-width: 767px) {
    min-height: 99vh;
  }

  &.activeBg {
    background: #fff;
  }

  hr {
    opacity: 0.2;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  img.uploadedImages {
    max-height: 80px;
    padding: 5px;
    border-radius: 10px;
    vertical-align: baseline;
    position: relative;
  }

  i.closeButton {
    position: absolute;
    right: -1px;
    top: -1px;
    font-size: 18px;
    color: white;
    background: red;
    border-radius: 15px;
  }

  .modal-body {
    padding: 0 20px 20px;
    overflow: auto;
    min-height: 490px;
  }

  .mainButtons {
    max-width: 340px;
    margin: auto;
    float: none;
  }

  .colorGrey {
    color: #a3a3a3;
  }

  button.infoButton {
    border-radius: 15px;
    background: #001854;
    border: 0;
    padding: 5px 10px;
    // padding: 5px 15px;
    font-size: 11px;
    color: white;
    margin-top: 1px;
  }

  #dateInput {
    padding: 5px;
  }

  .typeButton {
    border: 1px solid #ffffff;
    background: none;
    font-size: 12px;
    min-width: 100px;
    padding: 5px 10px;
    color: white;
    margin: 5px;
    height: 30px;

    &.activeBg {
      background: #001543;
      border-color: #001543;
    }
  }

  div.onecolumn div {
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }

  .round25 .multiselect__tags {
    border-radius: 25px;
  }

  .uploadButton {
    background: #f9f9f9;
    border-radius: 10px;
    border-color: #21acf5;
    border-width: 1px;
    padding: 15px;
    color: rgb(35, 164, 220);
    vertical-align: top;

    i {
      font-size: 25px;
      display: block;
    }
  }

  .error {
    text-align: center;
    padding-top: 2px;
  }

  #imagesLoading {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(255, 251, 255, 0.25);
    text-align: center;
    padding-top: 8%;
    z-index: 9999;
  }

  .yearsGroupModalButton.empty {
    color: #888 !important;
  }

  .create-post {
    @apply bg-black rounded-xl text-white px-3 py-2 mr-4 mb-4;
    flex: 2;

    svg {
      @apply inline fill-white float-right;
    }

    &.compact {
      @apply bg-white text-black mb-0;

      .info {
        @apply hidden;
      }

      svg {
        @apply fill-black float-none ml-4;
      }
    }
  }

  .info {
    font-size: 13px;
  }

  .circle {
    width: $circle-button-width;
    height: $circle-button-width;
    @apply relative border-2 rounded-full;
    @apply flex flex-row items-center justify-center;
    @apply text-sm mb-4 cursor-pointer;
    transition: all 0.3s ease;

    &.expanded {
      width: 320px;
      left: -30px;

      .learn-more {
        @apply block flex-1 text-sm opacity-100 cursor-pointer;
      }

      .inner-circle {
        @apply flex items-center justify-center rounded-full;
        width: $circle-button-width;
        height: $circle-button-width;
        position: absolute;
        left: 0;
        // transition: all 0.15s ease;
      }

      &:hover svg {
        @apply fill-white;
      }
    }

    &:not(.expanded) {
      @apply hover:text-white;
    }

    .learn-more {
      display: none;
      position: absolute;
      right: 10%;
      opacity: 0;
      width: 150px;
      text-align: left;
    }

    .icon {
      @apply flex flex-col flex-1 items-center;
      @apply font-medium;
    }

    &:not(.expanded):hover svg {
      @apply fill-white;
    }
  }

  .circle.eco {
    @apply text-green border-green;

    svg {
      @apply fill-green;
    }

    &:not(.expanded) {
      @apply hover:bg-green;

      svg {
        @apply fill-green;
      }
    }

    &.expanded:hover,
    &.expanded:focus {
      .inner-circle {
        @apply bg-green text-white;
        @apply border border-solid border-green;
      }
    }
  }

  .circle.travel {
    @apply text-orange border-orange;

    svg {
      @apply fill-orange;
    }

    &:not(.expanded) {
      @apply hover:bg-orange;

      svg {
        @apply fill-orange;
      }
    }

    &.expanded:hover,
    &.expanded:focus {
      .inner-circle {
        @apply bg-orange text-white;
        @apply border border-solid border-orange;
      }
    }
  }

  .circle.sports {
    @apply text-purple border-purple;

    svg {
      @apply fill-purple;
    }

    &:not(.expanded) {
      @apply hover:bg-purple;

      svg {
        @apply fill-purple;
      }
    }

    &.expanded:hover,
    &.expanded:focus {
      .inner-circle {
        @apply bg-purple text-white;
        @apply border border-solid border-purple;
      }
    }
  }

  .circle.pta {
    @apply text-pink border-pink;

    svg {
      @apply fill-pink;
    }

    &:not(.expanded) {
      @apply hover:bg-pink;

      svg {
        @apply fill-pink;
      }
    }

    &.expanded:hover,
    &.expanded:focus {
      .inner-circle {
        @apply bg-pink text-white;
        @apply border border-solid border-pink;
      }
    }
  }

  .circle.poll {
    @apply text-peach border-peach;

    svg {
      @apply fill-peach;
    }

    &:not(.expanded) {
      @apply hover:bg-peach;

      svg {
        @apply fill-peach;
      }
    }

    &.expanded:hover,
    &.expanded:focus {
      .inner-circle {
        @apply bg-peach text-white;
        @apply border border-solid border-peach;
      }
    }
  }

  .circle.general {
    @apply text-blue border-blue;

    svg {
      @apply fill-blue;
    }

    &:not(.expanded) {
      @apply hover:bg-blue;

      svg {
        @apply fill-blue;
      }
    }

    &.expanded:hover,
    &.expanded:focus {
      .inner-circle {
        @apply bg-blue text-white;
        @apply border border-solid border-blue;
      }
    }
  }

  .circle {
    &:not(.expanded):hover svg {
      @apply fill-white;
    }
  }

  .button-container {
    @apply flex flex-row flex-wrap justify-between;
    @apply w-64 m-auto mt-3;

    &.expanded {
      @apply flex-col items-center m-auto;
    }
  }

  .category-display div {
    @apply rounded-lg px-2 py-1.5;
    @apply flex items-center;
    @apply text-white text font-normal uppercase;
  }

  .textarea {
    @apply bg-white rounded-lg shadow;
    border: 0.5px solid #c2c2c2;
  }

  .btn-upload {
    @apply bg-white shadow rounded-lg mt-3;
    @apply flex items-center justify-center;
    width: 79px;
    height: 79px;
  }

  .attachment-icon {
    position: absolute;
    bottom: -7px;
    right: -7px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 0.5px solid #c2c2c2;
    border-radius: 50px;
    color: #000;
    font-weight: 300;
  }

  .add-icon {
    font-size: 32px;

    &:before {
      content: "+";
    }
  }

  .remove-icon {
    @apply text-base cursor-pointer;
  }

  .file-uploads {
    @apply overflow-visible;

    label {
      @apply cursor-pointer;
    }
  }

  .form-label {
    @apply text text-black font-normal mr-1;
    flex: 1;
  }

  .form-row {
    @apply w-full flex items-start my-3;

    .form-input {
      flex: 3;
    }

    input {
      @apply w-full px-3 py-2;

      &::placeholder {
        @apply text-grey;
      }
    }

    .vdatetime-input {
      @apply p-0;

      &::placeholder {
        @apply text-black;
      }
    }
  }

  .form-control {
    @apply relative w-full bg-white rounded-lg py-2 px-3;
    @apply text text-black font-normal;
    @apply shadow cursor-pointer;
    height: auto;
    border: 0.5px solid #c2c2c2;
  }

  .selection-button,
  .form-control {
    i {
      @apply hidden;
    }

    &.errorInput {
      background: #ffeded;
    }

    input,
    > button {
      @apply w-full text-left;
      @apply relative w-full bg-white rounded-lg py-2 px-3;
      @apply text text-black font-normal;
      @apply shadow cursor-pointer;
      height: auto;
      border: 0.5px solid #c2c2c2;

      &:before {
        content: "";
        position: absolute;
        width: 14px;
        height: 25px;
        top: 2px;
        right: 12px;
        background: url("@/assets/notice/caret-down.svg") no-repeat;
        pointer-events: none;
      }
    }

    .vdatetime-input {
      @apply px-3 py-2;
    }

    .van-button {
      @apply bg-black text-white #{!important};
    }
  }

  .school-selection-button hr {
    @apply hidden;
  }

  .van-switch,
  .van-switch__node {
    @apply shadow;
  }

  .m-chckbox--group {
    .m-chckbox--ripple,
    svg {
      display: none;
    }

    > div {
      display: flex;
      align-items: center;

      &:before {
        width: 30px;
        height: 30px;
        font-family: "vant-icon";
        font-size: 20px;
        content: "\e728";
        color: #fff;
        text-align: center;
        margin-top: 5px;
      }
    }
  }

  .m-chckbox--group {
    @apply rounded-full border-0 text-white shadow-md shadow-black/25 border border-gray-200;
  }

  .m-chckbox--container.active .m-chckbox--group {
    @apply bg-black border-black #{!important};
  }

  small {
    @apply text-grey font-normal;
  }

  .btn-add {
    @apply py-2 px-3 text-sm font-normal;
  }

  .actions-row {
    @apply flex items-center justify-center text-center mt-6;

    button {
      @apply mx-2;
    }
  }
}

.image-previews {
  @apply flex items-center justify-center;

  > div {
    flex: 1;
  }

  > div {
    @apply shadow rounded-lg ml-5;
    @apply flex items-center justify-center cursor-pointer;
    width: 79px;
    height: 79px;
  }

  img {
    @apply rounded-lg;
    width: 79px;
    height: 79px;
  }
}

.attachments {
  overflow-x: auto;
  padding-bottom: 20px;
}
</style>
