<style lang="scss" scoped>
$primary-color: #21acf5;
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.table {
  border-collapse: collapse;
  padding: 0px;
  margin: auto;
  margin-bottom: 20px;
  td {
    padding: 0;
    margin: 0;
  }
  // margin: 10px;
}
#basic-addon1 {
  line-height: 25px;
}
#sendButton {
  // border-radius: 0px !important;
  font-size: 12px;
}
</style>
<template>
  <div id="registrationMiWrapper" class="clearfix round40">
    <Header
      title="Protecting Your School Network"
      icon="fa fa-check-circle primaryColor"
      :showMobile="true"
    />
    <div class="col-xs-12">
      <div class="alert alert-mira round40">
        <p>
          To help us verify your account, please<br />
          enter your mobile phone number and<br />
          receive your 6 digit code.
          <br />
          <br />
          <strong>Your number will be kept private.</strong>
        </p>
      </div>
    </div>
    <div style="max-width: 400px; margin: auto">
      <table class="table" border="0" style="width: 90%">
        <tr>
          <td style="width: 50px">
            <span class="input-group-addon pull-left" id="basic-addon1"
              >+44</span
            >
          </td>
          <td>
            <input
              type="text"
              v-model="number"
              class="form-control"
              placeholder="Mobile number"
            />
          </td>
          <td style="width: 110px">
            <van-button
              id="sendButton"
              type="info"
              size="normal"
              class="btn btn-primary pull-right"
              style="
                margin-bottom: 0px !important;
                border-radius: 0px !important;
                height: 40px;
                width: 100%;
              "
              @click="sendCode()"
              >Send Code</van-button
            >
          </td>
        </tr>
      </table>
    </div>
    <div v-if="numberExists" class="alert alert-warning">
      <div class="pull-left">
        <p>We already have an account registered to that mobile number.</p>
      </div>
    </div>
    <div
      class
      style="
        margin: auto;
        max-width: 300px;
        margin-bottom: 14px;
        text-align: center;
      "
    >
      <input
        type="text"
        v-model="confirmationCode"
        class="form-control"
        :class="{ disabled: !codesent }"
        :disabled="!codesent"
        placeholder="Enter code"
        style="width: 140px; display: inline-block; text-align: center"
      />
    </div>
    <div class>
      <div class="col-md-12 text-center padding30">
        <van-button
          type="info"
          id
          @click="submit()"
          :disabled="!codesent"
          :class="{ disabled: !codesent }"
          class="btn primaryButton btn-lg"
          name="button"
          >Continue</van-button
        >
      </div>
    </div>
    <div style="margin-top: 20px;">
      <van-button type="info" @click="skip" plain>Skip</van-button>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";

export default {
  name: "verification",
  components: {
    Header,
  },
  data() {
    return {
      selection: {},
      codesent: null,
      confirmationCode: null,
      number: null,
      numberExists: false,
      showPage: true,
      loading: false,
    };
  },
  methods: {
    redirect() {
      // CommonDataService.logout(false);
      // $rootScope.menuChoice = null;
      // $rootScope.isStaff = false;
      // store.set("redirect", "children");
      // $state.go("login", { email: vm.number });
    },
    skip() {
      this.$router.push("/");
    },
    responsefilterNumber() {
      if (!this.number) {
        return false;
      }
      if (this.number.charAt(0) == 0) {
        return "+44" + this.number.substring(1);
      } else {
        return "+44" + this.number;
      }
    },
    submit() {
      // vm.loading = true;
      ApiService.userApi
        .validateVerificationCode(
          this.confirmationCode,
          this.responsefilterNumber()
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == 1) {
            // store.remove("lastState");
            // store.remove("registrationInProgress");
            // $state.go("congratulations");
            this.$router.push("/");
          } else {
            this.$notify({
              message: "Please check your code.",
              type: "warning",
            });
          }
        });
    },
    sendCode() {
      this.loading = true;

      ApiService.userApi
        .sendVerificationCode(this.responsefilterNumber())
        .then((response) => {
          this.codesent = true;
          this.loading = false;
          if (response.status == 200) {
            if (response.data.error) {
              this.numberExists = true;
            } else {
              this.$notify({
                message: "Code has been sent, please check your mobile phone",
                type: "success",
                duration: 5000,
              });
            }
          }
        })
        .catch(function () {
          this.loading = false;
        });
    },
    filterNumber() {
      if (!this.number) {
        return false;
      }
      if (this.number.charAt(0) == 0) {
        return "+44" + this.number.substring(1);
      } else {
        return "+44" + this.number;
      }
    },
    setCategory(category) {
      this.selection.category = category;
      this.$forceUpdate();
    },
  },
};
</script>
