<template>
  <div class="px-3" id="wrapper">
    <div class="flex pt-3 pb-2 items-baseline justify-between">
      <div class="text-lg font-medium">Report</div>
      <i
        class="fa fa-times close-icon fa-xl cursor-pointer mr-2"
        @click="$emit('close')"
      />
    </div>
    <p class="text-base mt-3">
      Why are you reporting this
      {{ params && params.subject == "user_id" ? "user" : "post" }} ?
    </p>

    <van-field
      v-model="text"
      label=""
      type="textarea"
      placeholder="Enter Message"
      rows="1"
      autosize
      class="p-0 mt-4 mb-2"
    />
    <div style="text-align: center; padding: 15px">
      <button class="btn-black sm" type="info" @click="submit">Submit</button>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  name: "report",
  props: {
    params: Object,
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    submit() {
      if (!this.text || this.text.length < 10) {
        this.$notify({
          type: "warning",
          message: "Please provide more feedback. Thank you.",
        });
        return;
      }
      const payload = {
        ...this.params,
        message: this.text,
      };
      ApiService.generalApi.reportUser(payload).then(() => {
        this.$notify({ type: "success", message: "Thank you!" });
        this.$emit("close");
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
#topConversation {
  height: 53px;
  border-bottom: 1px solid #cccccc;
}
#bottomConversation {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
}
button.close {
  margin: 0px !important;
  padding-right: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 12px !important;
}
ul#middleConversation {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  padding: 5px;
  padding-bottom: 140px;

  background: url(~@/assets/circles-light.png);
}
div#wrapper {
  height: 100%;
}
li.chatline {
  line-height: 25px;
  margin-bottom: 10px;
}
li.chatline .chatMessage {
  padding: 12px;
  border-radius: 5px;
  background: #ffffff;
  display: inline-block;
  text-align: left;
  margin-right: 50px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.07);
}
span.timeAgo {
  color: #9b9b9b;
  padding-right: 5px;
  padding-left: 5px;
}
li.myChatMessage {
  text-align: right !important;
}
li.myChatMessage .chatMessage {
  background: #ffeddd;
  margin-left: 50px;
  margin-right: 0px;
}
</style>
