<template>
  <div>
    <div class="alert alert-danger" style="margin-top: 40px">
      <p>
        <strong>
          Your App version is no longer supported. Please update your App to
          continue.
        </strong>
      </p>
    </div>
    <div class="col-xs-12 whiteBlock">
      <div class="row text-center padding30 text-center">
        <!-- <span class=''>Update your app using the links below</span> -->
        <div class="col-md-12">
          <div class="clearFix" style="clear: both">
            <div class="col-xs-6 col-sm-6" style="text-align: center">
              <a
                @click="
                  open(
                    'https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8'
                  )
                "
                target="_blank"
              >
                <img
                  style="border: 0; width: 100%; max-width: 150px"
                  src="https://s3-eu-west-1.amazonaws.com/home-run/other/apple-store.png"
                  alt="Apple app store"
                />
                <div class="midtext">
                  <span class="redColor">I have an iOS Phone</span>
                </div>
                <div class="bluebutton">Click here to update</div>
              </a>
            </div>
            <div class="col-xs-6 col-sm-6" style="text-align: center">
              <a
                @click="
                  open(
                    'https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&hl=en_GB'
                  )
                "
                target="_blank"
              >
                <img
                  style="border: 0; width: 100%; max-width: 150px"
                  src="https://s3-eu-west-1.amazonaws.com/home-run/other/google-play.png"
                  alt="Google app store"
                />
                <div class="midtext">
                  <span class="redColor">I have an Android Phone</span>
                </div>
                <div class="bluebutton">Click here to update</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    open: function (link) {
      window.postMessage("native_modal__" + link, "*");
    },
  },
};
</script>
<style>
.bluebutton {
  background: #21acf5;
  color: white;
  border-radius: 20px;
  padding: 10px;
  max-width: 190px;
  margin: auto;
}

.midtext {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

</style>
