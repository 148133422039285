<template>
  <div>
    <img :src="logo" alt="" class="shadow round" data-cy="logo" v-if="logo" />
    <img
      src="https://s3-eu-west-1.amazonaws.com/home-run/other/logo_small.png"
      alt=""
      data-cy="hr_logo"
      style="height: 50px; width: 50px"
      v-if="!logo && logo != null"
    />
    <h1 data-cy="title" v-if="school_name">
      {{ school_name }}
    </h1>
    <h1 data-cy="title" v-if="page_title" style="font-size: 30px !important">
      {{ page_title }}
    </h1>
  </div>
</template>
<style scoped>
div {
  text-align: center;
}
h1 {
  font-size: 22px;
  font-weight: 300;
  color: #1e1e1e;
}
img {
  font-size: 18px;
  height: 75px;
  width: 75px;
}
img.round {
  border-radius: 40px;
}
img.shadow {
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
}
button {
  float: right;
  background: none;
  border: none;
  outline: none;
  padding-left: 10px;
  /* padding-right: 10px; */
}
span {
  color: orange;
}
</style>
<script>
export default {
  name: "mi_flow_header",
  data() {
    return {
      globalStyle: {
        // color: "white"
      },
    };
  },
  props: {
    school_name: String,
    page_title: String,
    logo: String,
  },
  created() {
    if (this.color) {
      this.globalStyle = { color: this.color + " !important" };
    }
  },
};
</script>
