<style lang="scss" scoped>
.wrapper {
  @apply py-1;
}

ul {
  padding: 0;
}
button {
  width: 100%;
  text-align: left;
  padding-left: 20px;
  border-radius: 20px;
  margin-bottom: 6px;
  border: 0px;
  box-shadow: none;
  font-size: 14px;

  i {
    margin-right: 7px;
  }
}
li.active {
  button {
    background-color: #26a2ff;
    color: white;
  }
}
.wrapper ul {
  padding: 0 10px;
  padding-bottom: 10px;
  li .van-button__content {
    justify-content: start;
  }
}
.van-tag.pink {
  @apply bg-pink-dark;
}
</style>
<template>
  <div class="wrapper font-rubik">
    <div class="flex justify-between pt-2 p-3">
      <div class="text font-medium">Filter by Post Type</div>
      <i
        class="fa fa-times close-icon fa-xl mt-3 cursor-pointer"
        @click="$emit('close')"
      />
    </div>
    <ul style="list-style: none">
      <li
        :key="item.key"
        v-for="item in options"
        :class="item.key === selection.value ? 'active' : ''"
      >
        <van-button
          type="default"
          size="small"
          v-if="item.name"
          @click="clickEvent(item.key, item.name)"
        >
          <i :class="item.icon"></i>
          {{ item.name }}
          <van-tag
            round
            class="pink"
            type="warning"
            v-if="selection.currentStats[item.key]"
            size="small"
            >{{ selection.currentStats[item.key] }}</van-tag
          >
        </van-button>
        <hr v-if="!item.name" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "filterFeed",
  props: {
    selection: Object,
  },
  data() {
    return {
      options: [
        { name: "All", key: "all", icon: "fas fa-ellipsis-h" },
        { name: "New", key: "new", icon: "fas fa-rss-square" },
        {
          name: "User Guides",
          key: "userguide",
          icon: "fas fa-diamond-turn-right",
        },
        { name: "Important", key: "important", icon: "fas fa-star" },
        { name: "Private Messages", key: "chats", icon: "fas fa-envelope" },
        { name: "My Posts", key: "my", icon: "fas fa-user" },
        { name: "My Groups", key: "groups", icon: "fas fa-plus-square" },
        { separator: true, key: "sep" },
        {
          name: "General",
          key: "general",
          icon: "fas fa-newspaper",
          filterBy: "category",
        },
        {
          name: "PTA",
          key: "pta",
          icon: "fas fa-users",
          filterBy: "category",
        },
        { name: "Eco", key: "eco", icon: "fas fa-leaf", filterBy: "category" },
        {
          name: "Sports & Clubs",
          key: "sports",
          icon: "fas fa-futbol",
          filterBy: "category",
        },
        {
          name: "Travel",
          key: "travel",
          icon: "fas fa-car",
          filterBy: "category",
        },
        {
          name: "Events",
          key: "events",
          icon: "fas fa-calendar-week",
          filterBy: "category",
        },
        {
          name: "Polls",
          key: "polls",
          icon: "fas fa-chart-simple",
          filterBy: "category",
        },
      ],
      filterBy: "category",
    };
  },
  methods: {
    clickEvent(choice, name) {
      this.$parent.$emit("newFilterTypeSelected", {
        filterType: choice,
        filterName: name,
      });
      this.$emit("close");
      this.selection.value = choice;
    },
  },
};
</script>
