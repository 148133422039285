import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// import MintUI from "vant";
import VueTimeago from "vue-timeago";
import VModal from "vue-js-modal";
import VueZoomer from "vue-zoomer";
import ToggleButton from "vue-js-toggle-button";
import * as VueGoogleMaps from "vue2-google-maps";
import VueTextareaAutosize from "vue-textarea-autosize";
import Vuelidate from "vuelidate";
// import Loading from "vue-loading-overlay";
// Import stylesheet
// Init plugin
import VScrollLock from "v-scroll-lock";
import VueChatScroll from "vue-chat-scroll";
import vuePositionSticky from "vue-position-sticky";
import {
  Button,
  Form,
  Field,
  Cell,
  Switch,
  Tag,
  Notify,
  Radio,
  Dialog,
  RadioGroup,
  Locale,
  // Sticky
} from "vant";
import VueClipboard from "vue-clipboard2";
import "@/assets/bootstrap.min.css";
import "@/assets/tailwind.css";
import "@/assets/global.css";
// import "vue-multiselect/dist/vue-multiselect.min.css";
// You need a specific loader for CSS files
import { InlineSvgPlugin } from "vue-inline-svg";

import "vue-datetime/dist/vue-datetime.css";
import "vant/lib/index.css";
import enUS from "vant/lib/locale/lang/en-US";

Locale.use("en-US", enUS);

// Vue.use(Loading, {
//   color: "#21acf5",
//   width: 450,
//   height: 450,
//   canCancel: true
// });

Vue.use(Button);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Notify);
Vue.use(VueChatScroll);
Vue.use(Vuelidate);
Vue.use(VScrollLock);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCN94noyQX1JyljLIwnaWs3K9NpVK4whDc",
    libraries: "places",
  },
  installComponents: true,
});
Vue.use(ToggleButton);
// Vue.component("datetime", Datetime);
Vue.use(VueZoomer);
Vue.use(VueTextareaAutosize);
Vue.use(vuePositionSticky);
Vue.use(VueClipboard);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true });

Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
});

Vue.use(InlineSvgPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit("initialiseStore");
    Vue.prototype.$globalLoading = "test";
  },
  render: (h) => h(App),
}).$mount("#app");
