<template>
  <div id="mainContent" ref="mainCont" class="clearfix row font-rubik"
    :class="{ 'staff-theme': is_staff, 'journey-theme': journeyShare }">
    <div class="mt-1 md:mt-2 pt-0 pb-2 px-2 md:px-0 md:mx-3">
      <div class="text-center">
        <div class="text-xl text-black">
          {{ page }}
        </div>
      </div>
      <vue-position-sticky :offsetTop="native ? 0 : 45">
        <div class="search-options mx-1 md:mx-0 my-1 mb-3">
          <div class="clearfix row">
            <div class="col-xs-12 px-2">
              <div class="relative">
                <input name="searchString" :placeholder="['staff', 'staff_journey_share'].indexOf(this.type) > -1
                  ? 'Search for Staff'
                  : 'Search for Parents'
                  " class="form-control py-2" v-model="selection.searchString" />
                <i class="absolute top-2 right-3.5 fa fa-search text-grey" v-if="selection.searchString <= 1" />
                <i class="absolute top-4 right-4 fa fa-lg fa-times text-grey" v-if="selection.searchString.length >= 1"
                  @click="selection.searchString = ''" />
              </div>
            </div>
          </div>
          <div class="row">
            <div :class="type === 'travel_buddy' ? 'col-xs-6' : 'col-xs-12'" class="px-2">
              <DropdownSelection v-if="availableSchools.length > 0" keyProp="id" single name="name"
                default_label="Select School" class="selection-button school-selection-button mt-0.5" :class="type"
                :items="availableSchools" :default_value="getDefaultSchoolSelection" @updated="changeSchool"
                custom-caret />
            </div>
            <div class="col-xs-12 flex flex-wrap items-center mt-2 px-2" v-if="
              selection.school &&
              selection.school.years &&
              this.type != 'staff_journey_share' &&
              this.type != 'staff'
            ">
              <router-link to="/main/myChats" v-if="$route.params.type != 'journey_share'" class="flex-1">
                <button class="btn-black btn-back text-sm w-full relative">
                  <i class="fa fa-angle-left fa-lg text-white absolute top-4 left-1 md:left-2" />
                  <span>My Messages</span>
                </button></router-link>
              <router-link v-if="$route.params.type == 'journey_share'" to="/main/travelDirectory" class="flex-1">
                <button class="btn-black btn-back text-sm w-full relative">
                  <i class="fa fa-angle-left fa-lg text-white absolute top-4 left-1 md:left-2" />
                  <span>Journey Sharing</span>
                </button></router-link>

              <DropdownSelection v-if="
                type != 'travel_buddy' && type != 'parent' && type != 'staff'
              " keyProp="mode" name="name" single default_label="Select mode" default_value="journey_share"
                class="flex-1 selection-button ml-2" :class="type" :items="availableModes" @updated="changeMode"
                custom-caret />
              <DropdownSelection keyProp="value" name="name" default_label="Filter by Years"
                allSelectedLabel="All Years selected" default_value="all" class="flex-1 selection-button ml-2"
                :class="type" :items="availableYears" @updated="changeYear" custom-caret />
              <DropdownSelection keyProp="value" name="name" single default_label="Select Sorting" :default_value="$route.params.type === ('parent' || 'staff')
                ? 'alphabetical'
                : 'distance'
                " class="btn-black text-sm flex-1 mx-2" :class="type" :items="availableSorting"
                @updated="changeSorting" style="display: none" custom-caret />
              <!-- <multiselect
            class="smallSelect"
            :class="theme"
            v-model="selection.sortby"
            :options="availableSorting.map(type => type.value)"
            :custom-label="opt => availableSorting.find(x => x.value == opt).name"
            :close-on-select="true"
            :clear-on-select="true"
            :searchable="false"
            :show-labels="false"
            placeholder="Ordering"
            label="name"
            ></multiselect>-->
            </div>
          </div>

          <div style="clear: both"></div>
        </div>
      </vue-position-sticky>

      <div class="mialert lightGray border-0 mt-0 mb-4 py-2" v-if="
        $route.params.type == 'travel_buddy' &&
        (journey_preference === 0 || journey_preference === 1)
      ">
        <span class="text-black text-sm font-medium">
          You have selected that you are not open to
          <span v-if="$route.params.type == 'travel_buddy'" style="white-space: break-spaces">Travel Buddies</span>.
        </span>
        <button class="btn-black text-sm py-2 px-4 my-2" v-if="$route.params.type == 'travel_buddy'"
          @click="enableJourneyPreference('travel_buddy')">
          I am now open to looking for Travel Buddies
        </button>
      </div>
      <div class="mialert lightGray border-0 mt-0 mb-4 py-2" v-if="
        $route.params.type == 'journey_share' &&
        (journey_preference === 0 || journey_preference === 2)
      ">
        <span class="text-black text-sm font-medium">
          You have selected that you are not open to
          <span v-if="$route.params.type == 'journey_share'" style="white-space: break-spaces">Journey Sharing</span>.
        </span>
        <div>
          <button class="btn-black text-sm py-2 px-4 my-2" @click="enableJourneyPreference('journey_share')">
            I am now open to Journey Sharing
          </button>
        </div>
      </div>
      <transition-group name="slide-fade">
        <div class="searchlisting my-0" :key="parent.id" v-for="parent in parents">
          <SearchParentsItem @showConversation="showConversationModal" :show_school="selection.school.id == 'all'"
            @expandImage="expandImage" :page="page" :parent="parent" :expanded="parent.id === expandedImageId" />
        </div>
      </transition-group>
      <div class="text-center" v-if="showNextPage" style="opacity: 0.7">
        <button class="colorBlue btn btn-link" size="small" type="info" sty @click="showMore()">
          <b>Load more</b>
          <br />
          <i class="fas fa-angle-double-down"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchParentsItem from "@/components/SearchParentsItem";
// import Multiselect from "vue-multiselect";
import ApiService from "@/services/ApiService";
import Sticky from "vue-sticky-directive";
import Conversation from "@/components/modals/Conversation";
import DropdownSelection from "@/components/DropdownSelection";

export default {
  name: "searchParents",
  components: {
    SearchParentsItem,
    // Multiselect,
    DropdownSelection,
  },
  directives: { Sticky },
  data() {
    return {
      selection: {
        school: { id: null },
        year: "all",
        sortby: "distance",
        searchString: "",
      },
      availableSchools: [],
      availableYears: [],
      availableModes: [
        { mode: "journey_share", name: "Travel Modes" },
        { mode: "carpooling", name: "Carpooling only" },
      ],
      stickyOptions: {
        top: 45,
        left: 0,
        right: 0,
        // "z-index": 999
      },
      availableSorting: [],
      parents: [],
      parentsAll: [],
      choice: null,
      nextPage: 1,
      user_id: 0,
      parent_id: 0,
      page: "",
      type: "",
      showNextPage: false,
      expandedImageId: 0,
      showChat: false,
      journeyShare: false,
    };
  },
  watch: {
    "$route.params.type": function (type) {
      this.type = type;
      if (type === "parent") {
        this.theme = "green";
      } else {
        this.theme = "green";
      }
      if (type === "travel_buddy" || type === "journey_share" || type == "staff_journey_share") {
        this.getUserSchools();
      }
      this.setTitle(type);
      this.parentsAll = [];
      this.parents = [];
      this.reset();
      this.$nextTick(() => {
        setTimeout(() => {
          this.searchParents();
          // this.$forceUpdate();
        }, 300);
      });
    },
    "selection.searchString": function (val) {
      if (val.length >= 1) {
        this.resetAndSearch();
      }

      if (val.length === 0) {
        this.reset();
        this.searchParents();
      }
    },
  },
  computed: {
    journey_preference: function () {
      const roles = this.$store.state.general.roles;
      return roles.find((itm) => itm.user_role_id == this.currentUserRoleId)
        ?.journey_preference;
    },
    getDefaultSchoolSelection: function () {
      if (this.availableSchools.length == 1) {
        return this.availableSchools[0].id;
      } else if (this.availableSchools.length > 1) {
        if (this.selection.school && this.selection.school.id) {
          return this.selection.school.id;
        } else {
          return "all";
        }
      } else {
        return "all";
      }
    },
    is_staff() {
      const userRoleId = this.currentUserRoleId;
      const selectedRole = this.$store.state.general.roles.find(itm => itm.user_role_id == userRoleId);

      if (selectedRole && selectedRole.slug == 'STAFF') {
        return true;
      } else {
        return false;
      }
    },
    native() {
      return this.$store.state.general.native;
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
  },
  methods: {
    setTitle(type) {
      switch (type) {
        case "parent":
          this.page = "Parents Directory";
          break;
        case "staff":
          this.page = "Staff Directory";
          break;
        case "journey_share":
          this.page = "Journey Share";
          this.journeyShare = true;
          break;
        case "staff_journey_share":
          this.page = "Journey Share";
          this.journeyShare = true;
          break;
        case "travel_buddy":
          this.page = "Travel Buddies";
          this.journeyShare = true;
          break;
      }
    },
    onscroll: async function () {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;

      if (bottomOfPage || pageHeight < visible) {
        this.showMore();
      }
    },
    showMore() {
      if (this.parents.length < this.parentsAll.length) {
        var y = 0;
        for (var i = this.parentsOffset; i < this.parentsOffset + 20; i++) {
          if (this.parentsAll[i] != undefined) {
            y++;
            this.parents.push(this.parentsAll[i]);
          }
        }
        this.parentsOffset += 20;
        if (y == 20) {
          this.showNextPage = true;
        } else {
          this.showNextPage = false;
          this.$forceUpdate();
        }
      } else {
        this.showNextPage = false;
        this.$forceUpdate();
      }
    },
    changeSchool(id, noRefresh) {
      this.selection.year = "all";
      if (id === "all") {
        this.selection.school = null;
        this.selection.year = "all";
        return;
      }

      var selectedSchool = this.availableSchools.filter(function (item) {
        return item.id == id;
      })[0];
      let years = JSON.parse(JSON.stringify(selectedSchool.years));
      if (
        years.length === 0 ||
        (years[0].value != "all" && years[years.length - 1].value != "all")
      ) {
        // years.push({ value: "all", name: "All Years" });
        selectedSchool.years = years;
      }

      this.availableYears = years.reverse();
      this.selection.school = selectedSchool;
      this.changeYear("all");
      this.schoolLogo = this.availableSchools.filter(function (item) {
        return item.id == id;
      })[0].logo;
      if (!noRefresh) {
        this.resetAndSearch();
      }
    },
    changeYear(val) {
      if (this.selection.year.length != val.length) {
        this.selection.year = Array.isArray(val) ? [...val] : val;
        this.resetAndSearch();
      }
    },
    changeMode(val) {
      this.selection.mode = val;
      this.resetAndSearch();
    },
    expandImage(id) {
      if (id === this.expandedImageId) {
        this.expandedImageId = 0;
      } else {
        this.expandedImageId = id;
      }
      this.$forceUpdate();
    },
    changeSorting(val) {
      if (this.selection.sortby != val) {
        this.resetAndSearch();
      }
    },
    searchParents() {
      this.$store.dispatch("loading", true);
      this.searched = false;
      this.parentsAll = [];
      const yearLabels = this.$store.state.general.yearLabels;

      ApiService.userApi
        .searchParents(
          this.selection,
          this.$route.params.type,
          this.currentUserRoleId
        )
        .then((response) => {
          this.$store.dispatch("loading", false);
          this.searched = true;
          if (response.status == 200) {
            var users = response.data.users;
            const imageUrl = response.data.image_url;
            try {
              const schoolId = this.selection.school.id;
              let yearsSeparated = [];
              let yearsArray = [];
              for (var i = 0; i < users.length; i++) {
                if (users[i].years) {
                  yearsArray = [];
                  yearsSeparated = users[i].years.split(",");

                  yearsSeparated.forEach((year) => {
                    if (
                      yearLabels &&
                      yearLabels[schoolId] &&
                      yearLabels[schoolId].hasOwnProperty(year)
                    ) {
                      yearsArray.push(yearLabels[schoolId][year]);
                    } else {
                      yearsArray.push(year);
                    }
                  });

                  users[i].years = yearsArray.join(",");
                }

                if (users[i].id == this.currentUserRoleId) {
                  var obj = users[i];
                  obj.noChat = true;
                  users.splice(i, 1);
                  users.unshift(obj);
                }
              }
            } catch (error) {
              alert(error);
            }

            this.parentsAll = [];
            for (var y = 0; y < users.length; y++) {
              users[y].profile_photo =
                imageUrl +
                "/parent/profile_photo/100/" +
                users[y].profile_photo;
              this.parentsAll.push(users[y]);
            }
            this.showMore();
          }
          // loading(false);
        })
        .catch(function () {
          // loading(false);
        });
    },
    resetAndSearch() {
      if (
        this.selection.searchString.length > 1 ||
        this.selection.searchString == ""
      ) {
        this.reset();
        this.$nextTick(() => {
          this.$refs.mainCont.scrollTop = 0;
          // this.$refs.mainCont.$el.scrollTop = 0;
        });
        setTimeout(() => {
          window.scrollTo(0, 0);
          this.searchParents();
        }, 100);
      } else {
        this.$notify({
          message: "At least 2 characters required",
          type: "warning",
        });
        return;
      }
    },
    reset() {
      this.nextPage = 1;
      this.parents = [];
      this.parentsAll = [];
      this.parentsOffset = 0;
      this.showNextPage = false;
    },
    enableJourneyPreference(type) {
      var newJourneyPreference = null;
      if (type === "journey_share") {
        newJourneyPreference =
          this.$store.state.general.parent.journey_preference == 0 ? 1 : 3;
      } else if (type === "travel_buddy") {
        newJourneyPreference =
          this.$store.state.general.parent.journey_preference == 0 ? 2 : 3;
      }
      if (newJourneyPreference) {
        const payload = {
          user_role_id: this.currentUserRoleId,
          field: "journey_preference",
          value: newJourneyPreference,
        };

        ApiService.userApi.updateSingleUserRoleSetting(payload).then(() => {
          this.$store.dispatch("updateUserRoleSetting", payload);
        });
      }
    },
    showConversationModal(recipient_user_role_id) {
      this.$modal.show(
        Conversation,
        {
          data: {},
          other_user_role_id: recipient_user_role_id,
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          width: "100%",
          height: "100%",
          adaptive: true,
          class: "conversation-modal",
          // pivotY: 0
        }
      );
    },
    combineSchoolYears(schoolsData) {
      let years = [];
      let yearsUsed = [];
      schoolsData.forEach((element) => {
        element.years.forEach((yearObj) => {
          if (yearsUsed.indexOf(yearObj.value) === -1) {
            yearsUsed.push(yearObj.value);
            years.push(yearObj);
          }
        });
      });
      return years.sort((a, b) => {
        return a.value - b.value;
      });
    },
    getUserSchools() {
      ApiService.userApi
        .getUserOrganizations()
        .then((response) => {
          if (response.data == "No Schools") {
            this.$notify({
              message: "You have no schools",
              type: "warning",
            });
          } else {
            const allSchools = response.data.user_organizations
              .filter((itm) => itm.user_role_id == this.currentUserRoleId)
              .map((itm) => itm.organization);

            if (allSchools.length == 0) {
              return;
            }

            if (allSchools.length > 1) {
              const allYears = this.combineSchoolYears(allSchools);

              this.availableSchools = [
                { name: "All schools", id: "all", years: allYears },
                ...allSchools,
              ];
            } else {
              this.availableSchools = allSchools;
            }

            setTimeout(() => {
              if (this.availableSchools.length > 1) {
                this.$set(this.selection.school, "id", "all");
              } else if (this.availableSchools.length == 1) {
                this.changeSchool(this.availableSchools[0].id, true);
              }
              // this.changeSchool(this.availableSchools[0].id, true);
              this.$forceUpdate();
            }, 200);

            this.roles = response.data.roles;
          }
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
  },
  created() {
    this.type = this.$route.params.type;
    if (this.$route.params.type === "journey_share") {
      this.theme = "red";
    } else {
      this.theme = "green";
    }
    this.setTitle(this.type);

    setTimeout(() => {
      this.availableSorting = [
        { value: "alphabetical", name: "Alphabetical" },
        { value: "distance", name: "Distance" },
      ];
    }, 200);
    window.addEventListener("scroll", this.onscroll);
  },
  mounted() {
    this.type = this.$route.params.type;
    setTimeout(() => {
      if (this.type == "staff_journey_share" && !this.is_staff) {
        this.$router.push({
          name: "schoolRun_searchParents",
          params: { type: "journey_share" },
        });
        return;

      } else if (this.type == 'staff' && !this.is_staff) {
        this.$router.push({
          name: "schoolRun_searchParents",
          params: { type: "parent" },
        });
        return;
      } else if (this.type == 'journey_share' && this.is_staff) {
        this.$router.push({
          name: "schoolRun_searchParents",
          params: { type: "staff_journey_share" },
        });
        return;
      }
      this.getUserSchools();
    }, 200);

    this.user_id = this.$store.state.general.user_id;
    this.parent_id = this.$store.state.general.parent_id;
  },
  destroyed() {
    window.removeEventListener("scroll", this.onscroll);
  },
};
</script>
<style lang="scss" scoped>
.mint-button--primary.green {
  background: #0d839e !important;
}

.mint-button--primary.cherry {
  background: #c13390 !important;
}

.multiselect__single {
  img {
    max-width: 50px;
  }

  color: black;
  font-size: 10px !important;
}

.multiselect__element {
  span {
    font-size: 15px !important;
  }
}

.search-options {
  padding-bottom: 5px;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 997;

  input {
    @apply h-auto rounded-full border border-solid border-grey;
    @apply text-sm text-grey py-2 mb-1.5;
  }

  .fa {
    @apply text-grey;
  }
}

.vue-position-sticky .search-options {
  margin-top: -5px;
  padding-bottom: 5px;
  @apply rounded-b-lg lg:rounded-lg;
  background: rgb(255 255 255 / 0.7);
  @apply pt-3 md:pt-1;

  .school-selection-button {
    @apply mb-1.5;
  }
}

.top-block {
  margin-top: -30px;
}

.selection-button {
  @apply relative rounded-full bg-pink-dark text-sm text-white p-2 px-3;

  @media (max-width: 767px) {
    font-size: 11px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 25px;
    top: 2px;
    right: 15px;
    background: url("@/assets/notice/caret-down-white.svg") no-repeat;
    fill: white;
    pointer-events: none;

    @media (max-width: 767px) {
      right: 12px;
    }
  }

  .van-button--info {
    @apply bg-black text-white #{!important};
  }
}

.btn-back {
  @apply px-0 pl-1 sm:pl-3 sm:pr-1;
  font-size: 12px;

  @media (max-width: 767px) {
    font-size: 11px;
  }
}

.staff-theme {
  .selection-button {
    @apply bg-purple-light;
  }
}

.journey-theme {
  .selection-button {
    @apply bg-green-dark;
  }
}

.years {
  position: initial !important;
}
</style>
