<style lang="scss" scoped>
.single-comment {
  @apply flex flex-row;
  @apply px-3 mb-2;
}

.comment-box {
  @apply rounded-xl py-2 px-4 mx-2;
  @apply text-sm;
  width: 75%;
  background: rgba(194, 194, 194, 0.2);

  @media (max-width: 768px) {
    width: 90%;
  }
}

.author {
  @apply text-sm block font-semibold mb-2;
}

.like {
  @apply flex flex-row text-black mb-1;
}

.like i {
  @apply rounded-full p-1 text-white bg-black;
}
</style>
<template>
  <div class="single-comment">
    <img
      class="notice-avatar relative sm m-0 mt-1 border-notice-theme"
      :src="comment.author.profile_photo_url"
    />

    <div class="comment-box">
      <span class="author">
        <span v-if="!comment.author.staff_type" class="pr-1">{{
          comment.author.first_name
        }}</span
        ><span v-if="comment.author.staff_type"
          >{{ comment.author.salutation }} </span
        >{{ comment.author.last_name }}
      </span>
      <span class="text" v-html="comment.comment"></span>
      <div class="time-ago ml-0 mt-2 text-grey font-light">
        <timeago :datetime="comment.created_timestamp * 1000"></timeago>
        <i
          @click="removeMyComment()"
          v-if="user_id == comment.user_id"
          class="pull-right far fa-trash-alt ng-scope text-grey"
        ></i>
        <!-- end ngIf: $root.user_id == comment.user_id -->
      </div>
    </div>
    <div class="self-end" @click.stop="likeComment">
      <div class="like flex items-center">
        <span class="text-xs ml-2 mr-1">{{ comment.likes }}</span>
        <i class="far fa-thumbs-up" />
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import ApiService from "@/services/ApiService";

export default {
  name: "singleComment",
  props: {
    comment: Object,
    user_id: Number,
  },
  data() {
    return {};
  },
  methods: {
    likeComment() {
      var _payload = {
        comment_id: this.comment.id,
      };

      ApiService.generalApi.likeComment(_payload).then((data) => {
        if (data.data > 0) {
          this.comment.likes++;
        }
      });
    },
    removeMyComment() {
      this.$emit("removeMyComment", this.comment.id);
    },
  },
};
</script>
