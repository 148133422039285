<template>
  <div id="template">
    <transition name="fade">
      <div id="loading" v-show="loading" @click="tryStopLoading()">
        <span class="helper"></span>
        <span class="three-quarters-loader"></span>
      </div>
    </transition>
    <TopBar :route="$route.name" />
    <!-- <div style="position: absolute; background: red; z-index: 99;">
      {{ $route.name }}
    </div>-->
    <div id="main" class="container" :class="{ native: native }">
      <div class="col-md-4 hidden-sm hidden-xs">
        <DesktopMenu />
      </div>
      <div
        id="templateWrapper"
        class="col-md-8 col-xs-12 pt-8 md:pt-6 pb-0 px-0"
        :class="{ native: native }"
      >
        <Breadcrumb
          class="hidden-xs"
          :route="$route.name"
          :params="$route.params.type"
          style="display: none"
        />
        <transition name="fade2" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
      <!-- <div class="col-xs-12 visible-xs"> -->
      <!-- </div> -->
    </div>
    <MobileMenu />
  </div>
</template>

<script>
import DesktopMenu from "@/components/desktop/DesktopMenu";
import MobileMenu from "@/components/mobile/MobileMenu";
import TopBar from "@/components/TopBar";
import Breadcrumb from "@/components/Breadcrumb";
import ApiService from "@/services/ApiService";

export default {
  name: "maintemplate",
  components: {
    DesktopMenu,
    MobileMenu,
    TopBar,
    Breadcrumb,
  },
  computed: {
    native() {
      return this.$store.state.general.native;
    },
    loading() {
      return this.$store.state.general.loading;
    },
    userRoleId() {
      return this.$store.state.general.userRoleId;
    },
  },
  // async beforeRouteEnter(to, from, next) {
  //   const result = await ApiService.userApi.getUserInfo();
  //   console.log("main template mounted");
  //   this.$store.dispatch("updateUserInfo", result.data);
  //   next();
  // },
  methods: {
    async getUserInfo() {
      try {
        const result = await ApiService.userApi.getUserInfo();
        this.$store.dispatch("updateUserInfo", result.data);
      } catch (error) {
        if (error.response.status === 401) {
          if (this.$store.state.general.native) {
            window.postMessage("logout", "*");
          }
          this.$router.push({ name: "logout" });
        }
      }
    },
    tryStopLoading() {
      const timeAfter10s = Math.round(new Date().getTime() / 1000) - 10;
      if (timeAfter10s > this.$store.state.general.loading_started) {
        this.$store.dispatch("loading", false);
      }
    },
  },
  created() {
    this.getUserInfo();
    try {
      ApiService.userApi.accountStatusRedirect().catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          (err.response.data.error === "No user has been found" ||
            err.response.data.message == "Token has expired" ||
            err.response.data.message == "TokenInvalidException")
        ) {
          if (this.$store.state.general.native) {
            window.postMessage("logout", "*");
          } else {
            this.$router.push({ name: "logout" });
          }
        }
      });
    } catch (error) {
      alert(error);
    }
  },
};
</script>

<style lang="scss" scoped>
#template {
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #000;
  background: #f7f7f7;
}

#main {
  margin-top: 35px;
  background: #f7f7f7;
  max-width: 800px;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }

  &.noMargin {
    margin-top: 0px;
  }

  &.native {
    margin-top: 0px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  animation: 0;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  -webkit-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  position: relative;
  transform: translateX(7px);
  -webkit-transform: translateX(7px);
  opacity: 0;
}

#main.native {
  margin-top: 15px !important;
  .homefeed {
    padding-top: 5px !important;
  }
}

// .slide-fade-leave-active .vue-sticky-el {
</style>
<style>
#template .fade-leave-active {
  pointer-events: none;
}

.slide-fade-leave-active .vue-sticky-el {
  /* display: none !important; */
  position: absolute !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100% !important;
  opacity: 0;
  position: absolute;
  /* background: red; */
  /* opacity: 0.4 !important; */
}

.slide-fade-leave-active .vue-sticky-placeholder {
  width: 100% !important;
  height: 111px;
  opacity: 0;
  /* position: absolute; */
}

@media only screen and (min-width: 800px) {
  body {
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 992px) {
  div#template {
    min-height: calc(100vh - 45px);
  }
}
#templateWrapper.native {
  padding-top: 0px !important;
}
</style>
