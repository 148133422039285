<style>
.settings .mint-cell-text {
  /* font-weight: bold; */
  font-size: 14px;
}
.settings .small .mint-cell-text {
  font-size: 12px !important;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-right: 10px;
  display: inline-block;
  /* font-weight: normal; */
}
.settings .van-cell__title {
  text-align: left;
}
.settings .commonSettings .van-cell__title {
  font-weight: bold;
  line-height: 32px;
  font-size: 12px;
}
.settings .van-cell {
  padding: 5px;
}
.settings .mint-cell-wrapper {
  padding: 0px !important;
}
</style>
<style scoped>
.toggleDivs .van-cell__value {
  flex: 0.2;
}
.fa-pencil-alt span {
  display: block;
  font-size: 8px;
  margin-top: 2px;
  margin-left: -2px;
}
.setting label {
  text-align: left;
  display: block !important;
  padding-left: 0px;
  font-weight: 400;
}
.greyBg {
  /* background: #fbfbfb; */
  text-align: left;
  padding: 20px;
  padding-left: 0px;
}
.journeyLabel {
  font-weight: 500;
}
#mainContent {
  height: auto;
  min-height: 100%;
}
.commonSettings .van-cell__value {
  flex: 1.8;
}
@media only screen and (min-device-width: 355px) and (max-device-width: 812px) {
  .commonSettings .van-cell__value {
    flex: 2.2;
  }
  .flex50 .van-cell__value {
    flex: 1.7 !important;
  }
}
.flex50 .van-cell__value {
  flex: 1.8;
}
</style>
<template>
  <div id="mainContent" class="settings row">
    <!-- <Header title="My Settings" /> -->
    <Headerv2 title="My Settings" />
    <div class="col-xs-12 text-left">
      <div
        class="text-left w-20 h-16 mb-4 relative inline-block min-w-20 min-h-16 overflow-y-hidden"
      >
        <img
          v-if="profile_photo"
          @click="openParentAvatarModal()"
          alt
          :src="profile_photo"
          class="w-16 h-16 rounded-full min-w-16 min-h-16"
        />
        <i
          @click="openParentAvatarModal()"
          class="fas fa-pencil-alt parentBg rounded-full text-white w-5 h-5 absolute text-xs p-1 top-[24px] right-1.5"
          aria-hidden="true"
        >
        </i>
      </div>
      <span class="pl-2 text-sm align-top text-black leading-[68px]"
        >Edit my profile</span
      >
    </div>
    <div class="col-xs-12">
      <div class="commonSettings">
        <van-cell title="Salutation">
          <van-button
            @click="showModal('profileModal')"
            size="small"
            style="width: 100%"
            type="default"
            >{{ user.salutation }}</van-button
          >
        </van-cell>
        <van-cell title="First Name">
          <van-button
            @click="showModal('profileModal')"
            size="small"
            style="width: 100%"
            type="default"
            >{{ user.first_name }}</van-button
          >
        </van-cell>
        <van-cell title="Last Name">
          <van-button
            @click.stop="showModal('profileModal')"
            size="small"
            style="width: 100%"
            type="default"
            >{{ user.last_name }}</van-button
          >
        </van-cell>
        <van-cell title="Email">
          <van-button
            @click="showModal('profileModal')"
            size="small"
            style="width: 100%"
            type="default"
            >{{ user.email }}</van-button
          >
        </van-cell>
        <van-cell title="Mobile No." style="display: none">
          <van-button
            @click="showModal('mobileModal')"
            size="small"
            style="width: 100%"
            type="default"
            >{{ phone }}</van-button
          >
        </van-cell>
        <van-cell title="Password">
          <van-button
            @click="showModal('passwordModal')"
            size="small"
            style="width: 100%"
            type="default"
            >Edit Password</van-button
          >
        </van-cell>
        <van-cell title="Address">
          <van-button
            @click="openAddressModal"
            size="small"
            style="width: 100%"
            type="default"
          >
            {{ address.address_line_1 + " " + address.address_line_2 }}
          </van-button>
        </van-cell>
      </div>
      <hr class="mt-2" />
      <div
        style="margin-bottom: 20px"
        class="toggleDivs text-left"
        v-if="parentData.id"
      >
        <label class="greyBg text-md parentColor">Parent Options</label>
        <!-- <van-cell title="Are you member of PTA ?">
          <van-switch
            @change="updateField('parentData', 'is_pta', $event)"
            size="24"
            active-color="#d38992"
            inactive-color="#ffd6db"
            v-model="parentData.is_pta"
          ></van-switch>
        </van-cell> -->
        <van-cell
          title="Do you share your school journeys with other parents ?"
        >
          <van-switch
            v-model="parentData.sharing_journey"
            size="24"
            active-color="#d38992"
            inactive-color="#ffd6db"
            @change="updateField('parentData', 'sharing_journey', $event)"
          ></van-switch>
        </van-cell>
        <label class="marginTop15 journeyLabel">Journey Preferences</label>
        <van-cell
          class="small"
          title="I am open to being contacted by parents from my school network to discuss sharing school journeys."
        >
          <van-switch
            v-model="parentData.interestedInPooling"
            size="24"
            active-color="#d38992"
            inactive-color="#ffd6db"
            @change="updateField('parentData', 'interestedInPooling', $event)"
          ></van-switch>
        </van-cell>
        <van-cell
          class="small"
          title="I would be interested in finding suitable travel buddies to accompany my child on their independent journey to school."
        >
          <van-switch
            v-model="parentData.travelBuddies"
            size="24"
            active-color="#d38992"
            inactive-color="#ffd6db"
            @change="updateField('parentData', 'travelBuddies', $event)"
          ></van-switch>
        </van-cell>
        <van-cell
          class="small"
          title="I am not interested in either of the above."
        >
          <van-switch
            v-model="parentData.notInterestedInPooling"
            size="24"
            active-color="#d38992"
            inactive-color="#ffd6db"
            @change="
              updateField('parentData', 'notInterestedInPooling', $event)
            "
          ></van-switch>
        </van-cell>
      </div>
      <div
        v-if="staffData.journey_preference != null"
        class="toggleDivs text-left"
      >
        <label class="greyBg staffColor">Staff Options</label>
        <van-cell title="Staff role" class="flex50">
          <select
            class="form-control"
            v-model="staffData.staff_type"
            style="min-width: 200px"
            @change="updateField('staffData', 'staff_type')"
          >
            <option
              :value="null"
              :selected="staffData.staff_type === null"
            ></option>
            <!-- <option
              value="SUPPORT_STAFF"
              :selected="staffData.staff_type === 'SUPPORT_STAFF'"
            >
              Support staff
            </option>
            <option
              value="TEACHING_STAFF"
              :selected="staffData.staff_type === 'TEACHING_STAFF'"
            >
              Teaching staff
            </option> -->
            <option
              value="TEACHER"
              :selected="staffData.staff_type === 'TEACHER'"
            >
              Teacher
            </option>
            <option
              value="HEAD_TEACHER"
              :selected="staffData.staff_type === 'HEAD_TEACHER'"
            >
              Head Teacher
            </option>
            <option
              value="SCHOOL_OFFICE"
              :selected="staffData.staff_type === 'SCHOOL_OFFICE'"
            >
              School office
            </option>
          </select>
        </van-cell>
        <van-cell title="Allow parents to reply to conversations ?">
          <van-switch
            v-model="staffData.parents_reply"
            size="24"
            active-color="#009999"
            inactive-color="#a5e9e9"
            @change="updateField('staffData', 'parents_reply', $event)"
          ></van-switch>
        </van-cell>
        <van-cell title="Do you share your school journeys with other staff ?">
          <van-switch
            v-model="staffData.sharing_journey"
            size="24"
            active-color="#009999"
            inactive-color="#a5e9e9"
            @change="updateField('staffData', 'sharing_journey', $event)"
          ></van-switch>
        </van-cell>
        <label class="marginTop15 journeyLabel">Journey Preferences</label>
        <van-cell
          class="small"
          title="I am open to being contacted by staff members from my school network to discuss sharing school journeys."
        >
          <van-switch
            v-model="staffData.interestedInPooling"
            size="24"
            active-color="#009999"
            inactive-color="#a5e9e9"
            @change="updateField('staffData', 'interestedInPooling', $event)"
          ></van-switch>
        </van-cell>
        <van-cell class="small" title="I am not interested in journey sharing.">
          <van-switch
            v-model="staffData.notInterestedInPooling"
            size="24"
            active-color="#009999"
            inactive-color="#a5e9e9"
            @change="updateField('staffData', 'notInterestedInPooling', $event)"
          ></van-switch>
        </van-cell>
      </div>
      <div class="text-left">
        <label class="greyBg" style="color: #b0cb24; display: none"
          >Subscription Settings</label
        >
      </div>
    </div>
    <div class="clearfix"></div>
    <modal name="profileModal" :adaptive="true" :height="'auto'">
      <ModalHeader
        title="Edit Profile"
        @hideModal="hideModal('profileModal')"
      />
      <div class="loaded text-center">
        <div class="text-left col-xs-12" style="margin-bottom: 20px">
          <label>Salutation</label>
          <van-cell>
            <select
              class="form-control"
              slot="title"
              style="width: 150px"
              placeholder="Select your title"
              v-model="tempData.salutation"
            >
              <option>Mrs</option>
              <option>Mr</option>
              <option>Ms</option>
              <option>Miss</option>
              <option>Dr</option>
            </select>
          </van-cell>
          <label>First Name</label>
          <input
            class="form-control"
            v-model="tempData.first_name"
            type="text"
          />
          <label>Last Name</label>
          <input
            class="form-control"
            v-model="tempData.last_name"
            type="text"
          />
          <label>Email</label>
          <input class="form-control" v-model="tempData.email" type="email" />
        </div>

        <van-button
          type="info"
          plain
          @click="hideModal('profileModal')"
          style="margin-right: 20px"
          class="btn inversePrimaryButton"
          >Cancel</van-button
        >

        <van-button type="info" @click="saveProfileData" class="btn btn-primary"
          >Save</van-button
        >
        <div style="margin-bottom: 20px"></div>
      </div>
    </modal>
    <modal name="mobileModal" :adaptive="true" :height="'auto'">
      <ModalHeader title="Edit Mobile" @hideModal="hideModal('mobileModal')" />
      <div class="loaded text-left col-xs-12">
        <div class="alert alert-mira round25">
          Changing your current verified phone will require you to verify a new
          mobile number. We'll send you an sms with new verification code.
        </div>
        <div>Current phone: {{ tempData.phone }}</div>
        <br />
        <label for>Mobile No</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="New Mobile Number"
            name
            v-model="tempData.new_phone"
            value
          />
          <span class="input-group-addon" id="basic-addon2">
            <van-button
              type="info"
              size="small"
              class="btn btn-primary btn-sm"
              @click.stop="sendVerificationCode()"
              >Update Current Number</van-button
            >
          </span>
        </div>
        <br />
        <div class="form-group">
          <label for>Verification Code (sent via SMS)</label>
          <input
            type="text"
            size="10"
            :disabled="!tempData.sent"
            class="form-control"
            name
            v-model="tempData.verification_code"
            placeholder="Enter Verification Code"
            value
            style="width: 200px"
          />
        </div>
        <div class="text-center padding30">
          <div class="col-md-12 whiteBg">
            <van-button
              class="btn btn-primary btn-lg"
              :disabled="!tempData.sent"
              type="info"
              name="button"
              @click="verifyNumber"
              >Save</van-button
            >
          </div>
        </div>

        <van-button
          type="info"
          plain
          @click="hideModal('mobileModal')"
          style="margin-right: 20px"
          class="btn inversePrimaryButton"
          >Cancel</van-button
        >

        <!-- <van-button
          type="info"
          @click="saveProfileData"
          class="btn btn-primary"
          >Save</van-button
        >-->
        <div style="margin-bottom: 20px"></div>
      </div>
    </modal>
    <modal name="passwordModal" :adaptive="true" :height="'auto'">
      <ModalHeader
        title="Edit Password"
        @hideModal="hideModal('passwordModal')"
      />
      <div class="col-xs-12">
        <div class="form-group">
          <label for>Current Password</label>
          <input
            type="password"
            class="form-control"
            placeholder="Enter current password"
            name
            v-model="tempData.old_password"
            value
          />
        </div>
        <div class="form-group">
          <label for>New Password</label>
          <input
            type="password"
            class="form-control"
            placeholder="Enter new password"
            name
            v-model="tempData.password"
            value
          />
        </div>
        <div class="form-group">
          <label for>Confirm Password</label>
          <input
            type="password"
            class="form-control"
            name
            placeholder="Confirm new password"
            v-model="tempData.confirm_password"
            value
          />
        </div>
      </div>
      <div class="text-center" style="margin-bottom: 20px">
        <van-button
          type="info"
          plain
          @click="hideModal('passwordModal')"
          style="margin-right: 20px"
          class="btn inversePrimaryButton"
          >Cancel</van-button
        >

        <van-button
          type="info"
          :disabled="
            !tempData.password ||
            !tempData.confirm_password ||
            !tempData.old_password
          "
          @click="changePassword"
          class="btn btn-primary"
          >Save</van-button
        >
      </div>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import Uploader from "@/components/modals/Uploader";
import AddressWrapper from "@/components/modals/AddressWrapper";
import ModalHeader from "@/components/ModalHeader";
import Headerv2 from "@/components/Headerv2.vue";
// import { Switch } from 'vant';
// import { Cell } from 'vant';

export default {
  name: "settings",
  components: {
    Headerv2,
    ModalHeader,
  },
  data() {
    return {
      parentData: {},
      staffData: {},
      data: {},
      user: {},
      address: {},
      tempData: {},
      phone: "",
      profile_photo: null,
      // value: ""
    };
  },
  watch: {
    parentData: {
      deep: true,
      handler() {},
    },
  },
  methods: {
    changeValue() {},
    loadUserInfo() {
      ApiService.userApi.getUserInfo().then((response) => {
        if (response.status == 200) {
          const data = response.data;
          if (data.address) {
            this.currentAddress = data.address;
            this.address = data.address;
            this.address.lat = data.address.latitude;
            this.address.lng = data.address.longitude;
          }

          this.user = {
            salutation: data.salutation,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
          };

          data.roles.forEach((role) => {
            if (role.slug == "PARENT") {
              this.showParentOptions = true;
              this.parentData = role;
              if (role.sharing_journey) {
                this.parentData.sharing_journey = true;
              }
              if (this.parentData.journey_preference == 0) {
                this.parentData.notInterestedInPooling = true;
              } else if (this.parentData.journey_preference == 1) {
                this.parentData.interestedInPooling = true;
              } else if (this.parentData.journey_preference == 2) {
                this.parentData.travelBuddies = true;
              } else if (this.parentData.journey_preference == 3) {
                this.parentData.travelBuddies = true;
                this.parentData.interestedInPooling = true;
              }
            } else if (role.slug === "STAFF") {
              this.showStaffOptions = true;
              this.staffData = role;

              if (this.staffData.journey_preference == 0) {
                this.staffData.notInterestedInPooling = true;
              } else if (this.staffData.journey_preference == 1) {
                this.staffData.interestedInPooling = true;
              }
            }
          });
        }
      });
    },
    loadUserInfod() {
      this.$store.dispatch("loading", true);
      ApiService.userApi
        .getUserInfo()
        .then((response) => {
          if (response.status == 200) {
            // store.set("parentId", response.data.parent.id);
            this.$store.dispatch("loading", false);

            this.data = response.data;

            this.user = response.data.user;
            if (this.data.user && this.data.user.phone) {
              this.phone = this.data.user.phone;
            } else if (
              this.data.subUserParent &&
              this.data.subUserParent.phone
            ) {
              this.phone = this.data.subUserParent.phone;
            }

            if (this.data.user.is_staff == 0) {
              this.parentData = this.data.user;
              this.parentData.phone = this.data.user.phone;
              this.parentData.journey_preference =
                this.data.parent.journey_preference;
            }

            if (this.data.subUser && this.data.subUser.is_staff == 0) {
              this.parentData = this.data.subUser;
              this.parentData.phone = this.data.user.phone;
              this.parentData.journey_preference =
                this.data.subUserParent.journey_preference;
            }

            if (this.data.user.is_staff == 1) {
              this.staffData = this.data.user;
              this.staffData.journey_preference =
                this.data.parent.journey_preference;
              this.staffData.parents_reply = this.data.user.parents_reply
                ? true
                : false;
            }

            if (this.data.subUser && this.data.subUser.is_staff == 1) {
              this.staffData = this.data.subUser;
              this.staffData.journey_preference =
                this.data.subUserParent.journey_preference;
              this.staffData.parents_reply = this.data.subUser.parents_reply
                ? true
                : false;
            }

            if (this.parentData) {
              if (this.parentData.journey_preference == 0) {
                this.parentData.notInterestedInPooling = true;
                this.parentData.travelBuddies = false;
                this.parentData.interestedInPooling = false;
              } else if (this.parentData.journey_preference == 1) {
                this.parentData.interestedInPooling = true;
                this.parentData.travelBuddies = false;
                this.parentData.notInterestedInPooling = false;
              } else if (this.parentData.journey_preference == 2) {
                this.parentData.interestedInPooling = false;
                this.parentData.travelBuddies = true;
                this.parentData.notInterestedInPooling = false;
              } else if (this.parentData.journey_preference == 3) {
                this.parentData.travelBuddies = true;
                this.parentData.interestedInPooling = true;
                this.parentData.notInterestedInPooling = false;
              }
              // this.parentData.is_pta = data.user.is_pta
            }

            if (this.parentData) {
              this.parentData.is_pta = this.parentData.is_pta ? true : false;
              this.parentData.sharing_journey = this.parentData.sharing_journey
                ? true
                : false;
            }

            if (this.staffData) {
              if (this.staffData.journey_preference == 0) {
                this.staffData.notInterestedInPooling = true;
              } else if (this.staffData.journey_preference == 1) {
                this.staffData.interestedInPooling = true;
              } else if (this.staffData.journey_preference == 2) {
                this.staffData.travelBuddies = true;
              } else if (this.staffData.journey_preference == 3) {
                this.staffData.travelBuddies = true;
                this.staffData.interestedInPooling = true;
              }
            }

            var address = response.data.address;
            this.address = address;
          }
          // loading(false);
        })
        .catch((error) => {
          alert(error);
          // this.$notifyr.error("Token expired, please login");
          // this.$notifyr.error(response);
          this.$store.dispatch("loading", false);
        });
    },
    saveData(type) {
      var payloadData = this.filterPayloadData(type);
      ApiService.userApi
        .saveUserSettings(payloadData)
        .then(() => {
          this.loadUserInfo();
          // vm.closeModal();
          // loading(false);
        })
        .catch(function (response) {
          console.log(response.data);
          // loading(false);
        });
    },
    saveProfileData() {
      ApiService.userApi
        .saveUserSettings({ general: this.tempData })
        .then(() => {
          this.loadUserInfo();
          this.hideModal("profileModal");
        })
        .catch(function (response) {
          console.log(response.data);
        });
    },
    changePassword() {
      if (this.tempData.password != this.tempData.confirm_password) {
        this.$notify({
          message: "Password and confirmation has to match",
          type: "warning",
        });
        return;
      }
      this.$store.dispatch("loading", true);
      const payload = {
        password: this.tempData.old_password,
        new_password: this.tempData.password,
      };

      ApiService.userApi
        .updatePassword(payload)
        .then((response) => {
          if (response.status == 200) {
            this.hideModal("passwordModal");
            this.$notify({
              message: "Password has been updated succesfully",
              type: "success",
            });
          } else {
            this.$notify({
              message: response.data.error,
              type: "warning",
            });
          }
          this.$store.dispatch("loading", false);
        })
        .catch((error) => {
          // console.log(error.response);
          this.$notify({
            message: error.response.data.error[0],
            type: "warning",
          });
          this.$store.dispatch("loading", false);
        });
    },
    updateField(type, key, value) {
      let switchValue = value;
      if (
        type === "parentData" &&
        (key === "interestedInPooling" ||
          key === "travelBuddies" ||
          key === "notInterestedInPooling")
      ) {
        if (key === "notInterestedInPooling") {
          if (switchValue) {
            this.parentData.interestedInPooling = false;
            this.parentData.travelBuddies = false;
          }

          switchValue = 0;
        } else if (key === "interestedInPooling") {
          if (switchValue) {
            this.parentData.notInterestedInPooling = false;
            switchValue = this.parentData.travelBuddies ? 3 : 1;
          } else {
            switchValue = this.parentData.travelBuddies ? 2 : 0;
            if (!switchValue) {
              this.parentData.notInterestedInPooling = true;
            }
          }
        } else if (key === "travelBuddies") {
          if (switchValue) {
            this.parentData.notInterestedInPooling = false;
            switchValue = this.parentData.interestedInPooling ? 3 : 2;
          } else {
            switchValue = this.parentData.interestedInPooling ? 1 : 0;
            if (!switchValue) {
              this.parentData.notInterestedInPooling = true;
            }
          }
        }
        key = "journey_preference";
      } else if (
        type === "staffData" &&
        (key === "interestedInPooling" || key === "notInterestedInPooling")
      ) {
        if (key === "interestedInPooling") {
          if (switchValue) {
            this.staffData.notInterestedInPooling = false;
            switchValue = 1;
          } else {
            this.staffData.notInterestedInPooling = true;
            switchValue = 0;
          }
        } else {
          if (switchValue) {
            this.staffData.interestedInPooling = false;
            switchValue = 0;
          } else {
            this.staffData.notInterestedInPooling = true;
            switchValue = 1;
          }
        }
        key = "journey_preference";
      } else if (key === "staff_type") {
        switchValue = this.staffData.staff_type;
        // alert(switchValue);
      }
      const payloadData = {
        [type]: {
          [key]: switchValue,
        },
      };
      this.$forceUpdate();
      ApiService.userApi
        .saveUserSettings(payloadData)
        .then(() => {
          if (payloadData["parentData"] && key == "is_pta") {
            const params = {
              field: key,
              value: switchValue ? 1 : 0,
              type: "parent",
            };
            this.$store.dispatch("updateField", params);
          }
        })
        .catch(function (response) {
          console.log(response.data);
        });
    },
    filterPayloadData(type) {
      var payloadData = {};
      if (type === "parent") {
        payloadData.parentData = {};
        payloadData.parentData.is_pta = this.parentData.is_pta;
        if (
          this.parentData.travelBuddies &&
          this.parentData.interestedInPooling
        ) {
          payloadData.parentData.journey_preference = 3;
        } else if (this.parentData.travelBuddies) {
          payloadData.parentData.journey_preference = 2;
        } else if (this.parentData.interestedInPooling) {
          payloadData.parentData.journey_preference = 1;
        } else {
          payloadData.parentData.journey_preference = 0;
        }
        payloadData.parentData.sharing_journey =
          this.parentData.sharing_journey;
      } else if (type === "staff") {
        payloadData.staffData = {};
        payloadData.staffData.journey_preference = this.staffData
          .interestedInPooling
          ? 1
          : 0;
        payloadData.staffData.staff_type = this.staffData.staff_type;
        payloadData.staffData.parents_reply = this.staffData.parents_reply;
        payloadData.staffData.sharing_journey = this.staffData.sharing_journey;
        payloadData.staffData.staff_destination_id =
          this.staffData.staff_destination_id;
      }
      // else if (type === "phone") {
      // } else if (type === "common") {
      // }
      return payloadData;
    },
    openParentAvatarModal() {
      this.$modal.show(
        Uploader,
        {
          options: {
            profilePicture: true,
          },
        },
        {
          draggable: false,
          transition: "fadeDown",
          scrollable: true,
          height: "auto",
          adaptive: true,
          // pivotY: 0
        },
        {
          newAvatar: (payload) => {
            let image = "";
            if (payload.gender) {
              image = payload.url + payload.gender + payload.id + ".png";
            } else {
              image = payload.url;
            }
            this.profile_photo = image;
            this.$store.dispatch("updateAvatar", image);
          },
          newImage: (payload) => {
            this.profile_photo = payload.url;
            this.$store.dispatch("updateAvatar", payload.url);
          },
        }
      );
    },
    openAddressModal() {
      this.$modal.show(
        AddressWrapper,
        {
          defaultMarker: {
            lat: this.address.latitude,
            lng: this.address.longitude,
          },
          postcode: this.address.postcode,
          currentAddress: this.address,
        },
        {
          draggable: false,
          transition: "fadeDown",
          scrollable: true,
          height: "auto",
          adaptive: true,
          // pivotY: 0
        },
        {
          newAddress: (payload) => {
            ApiService.generalApi
              .saveAddress(payload, this.$store.state.general.parent.id)
              .then(() => {
                this.$notify({
                  message: "Address updated!",
                  type: "success",
                });
                this.loadUserInfo();
              });
          },
        }
      );
    },
    refreshImage() {
      ApiService.userApi.refreshImage("myProfilePicture").then((resp) => {
        this.profile_photo = resp.data.profile_photo;
      });
    },
    showModal(modalName) {
      if (modalName === "profileModal") {
        if (this.data.subUser && this.data.subUser.email.includes("@")) {
          this.tempData = {
            email: this.data.subUser.email,
            first_name: this.data.subUser.first_name,
            last_name: this.data.subUser.last_name,
            salutation: this.data.subUser.salutation,
          };
        } else {
          this.tempData = {
            email: this.user.email,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            salutation: this.user.salutation,
          };
          // alert(this.user.salutation);
        }
      }
      if (modalName === "mobileModal") {
        this.tempData = {
          phone: this.phone,
        };
      }

      this.$modal.show(modalName);
      return true;
    },
    hideModal(modalName) {
      this.$modal.hide(modalName);
    },
    sendVerificationCode() {
      ApiService.userApi
        .sendVerificationCode(this.filterNumber())
        .then((response) => {
          this.tempData.sent = true;
          if (response.status == 200) {
            if (response.data.error) {
              this.tempData.numberExists = true;
            } else {
              this.$notify({
                message: "Code has been sent, please check your mobile phone",
                type: "success",
              });
            }
          }
        });
    },
    verifyNumber() {
      ApiService.userApi
        .validateVerificationCode(
          this.tempData.verification_code,
          this.filterNumber()
        )
        .then(function (response) {
          if (response.data.status == 1) {
            this.$notify({
              message: "Updated",
              type: "success",
            });
          } else {
            this.$notify({
              message: "Please check your code",
              type: "warning",
            });
          }
        });
    },
    filterNumber() {
      if (!this.tempData.new_phone) {
        return false;
      }
      if (this.tempData.new_phone.charAt(0) == 0) {
        return "+44" + this.tempData.new_phone.substring(1);
      } else {
        return "+44" + this.tempData.new_phone;
      }
    },
  },
  mounted() {
    this.loadUserInfo();
    this.refreshImage();
    // this.parentData = this.$store.state.general.user;
  },
};
</script>
