<template>
  <!-- Chat block -->
  <div @click="noticeEvent('showChat')">
    <div class="notice-header">
      <block-title
        @noticeEvent="noticeEvent"
        :message="message"
        messenger-mode
      />
    </div>
    <div class="notice-body" v-if="messengerMode">
      <span>
        <b class="float-left pr-2" v-if="user_id == message.sender_user_id"
          >Me:</b
        >
        <p v-html="messagesHtml"></p>
      </span>
      <span v-if="message.unread > 0" class="pull-right colorTrump">
        <b>{{ message.unread }}</b>

        <i class="fas fa-envelope"></i>
      </span>
    </div>
  </div>
</template>

<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
export default {
  name: "chat",
  components: {
    BlockTitle,
  },
  props: {
    message: Object,
    messengerMode: Boolean,
    chat_room_id: Number,
    user_id: Number,
    // chat_room_id: Number,
  },
  computed: {
    messagesHtml: function () {
      let messageHtml = "<p>";
      this.message.message.forEach((row) => {
        messageHtml += "<b>" + row.name + ":</b>  " + row.message + "<br>";
      });
      return messageHtml + "</p>";
    },
  },
  methods: {
    noticeEvent(type) {
      this.$emit("noticeEvent", {
        action: type,
        id: this.message.user_id,
        chat_room_id: this.chat_room_id,
      });
    },
  },
};
</script>
