<template>
  <div
    id="template"
    :class="[!!isUserStaff ? 'staff-theme' : '', 'container flow']"
  >
    <div id="loading" v-if="loading" @click="tryStopLoading()">
      <span class="helper"></span>
      <span class="three-quarters-loader"></span>
    </div>
    <div id="main" class="row">
      <div class="term-bar" v-show="isProgressVisible">
        <ul class="progress">
          <li
            :class="{ completed: currentStep >= n }"
            :key="n"
            class="progress-step"
            role="progressbar"
            v-for="n in totalStep"
          ></li>
        </ul>
      </div>
      <transition name="fade2" mode="out-in">
        <router-view @progress-step="progressUpdate"></router-view>
      </transition>
      <div v-if="isProgressVisible">
        <inline-svg
          v-if="!isFlowComplete"
          class="logo"
          :src="require('@/assets/wireframe_logo.svg')"
          width="70"
          height="74"
        />
        <inline-svg
          v-else
          class="logo"
          :src="require('@/assets/wireframe_logo.svg')"
          width="70"
          height="74"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "flowtemplate",
  components: {},
  data() {
    return {
      currentStep: 1,
      totalStep: 5,
      isProgressVisible: true,
    };
  },
  computed: {
    getTermClass() {
      if (this.$store.state.general.term) {
        return "term-bar-" + this.$store.state.general.term;
      } else {
        return "";
      }
    },
    loading() {
      return this.$store.state.general.loading;
    },
    isUserStaff() {
      return (
        this.$store.state.general?.selectedAccountType === "staff" ||
        this.$store.state.user?.is_staff
      );
    },
    isFlowComplete() {
      // TODO: check if status can be read from the state
      return this.$router.currentRoute.name === "congratulations";
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == "termUpdate") {
        this.isProgressVisible = false;
      } else if (this.isProgressVisible == false) {
        this.isProgressVisible = true;
      }
    },
  },
  methods: {
    async getUserInfo() {
      try {
        const result = await ApiService.userApi.getUserInfo();
        this.$store.dispatch("updateUserInfo", result.data);
        ApiService.userApi.getAccountStatus().then((response) => {
          if (response.data.missing == "address") {
            this.$router.push({ name: "details" });
          } else if (response.data.missing == "children") {
            // alert('redirecting to children');
            this.$router.push({ name: "flowChildren" });
          } else if (response.data.missing == "journeys") {
            // alert('redirecting to journeys');
            this.$router.push({ name: "journeys" });
          } else if (response.data.missing == "verification") {
            this.$router.push({ name: "verification" });
          } else if (response.data.missing == "intro") {
            // $state.go("intro");
          } else if (response.data.missing == "school") {
            this.$router.push({ name: "flowSchools" });
          }
        });
      } catch (error) {
        if (error.response.status === 401 && this.$route.name !== "Register") {
          this.$router.push({ name: "login" });
        }
      }
    },
    async getUserInfoOnly() {
      const result = await ApiService.userApi.getUserInfo();
      this.$store.dispatch("updateUserInfo", result.data);
    },
    progressUpdate(value) {
      this.currentStep = value;
    },
    tryStopLoading() {
      const timeAfter10s = Math.round(new Date().getTime() / 1000) - 10;
      if (timeAfter10s > this.$store.state.general.loading_started) {
        this.$store.dispatch("loading", false);
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    const paths = [
      "/registration_mi/email/parent",
      "/registration_mi/email/staff",
      "/registration_mi/email_verification",
      "/registration_mi/new_account",
      "/flow/details",
      "/flow/flowChildren",
      "/flow/journeys",
      "/flow/verification",
      "/flow/congratulations",
    ];
    // for determining if going back or forward
    const toIndex = paths.indexOf(to.fullPath);
    const fromIndex = paths.indexOf(from.fullPath);
    if (toIndex === -1 || toIndex > fromIndex) {
      next();
    } else if (toIndex < fromIndex) {
      // going back
      // this.$dialog
      //   .confirm({
      //     title: "Alert",
      //     message:
      //       "Are you sure you want to go back, this page will not be saved!",
      //     cancelButtonText: "Cancel",
      //     confirmButtonText: "Go Back",
      //   })
      //   .then(() => {
      //     next();
      //   })
      //   .catch(() => {
      //     next(false);
      //   });
      if (
        confirm(
          "Are you sure you want to go back, this page will not be saved!"
        )
      ) {
        next();
      } else {
        next(false);
      }
      return;
    } else {
      next();
    }
  },
  created() {
    if (this.$store.state.general.token) {
      this.getUserInfoOnly();
    }
    if (this.$router.currentRoute.name === "termUpdate") {
      this.isProgressVisible = false;
    }
  },
};
</script>

<style lang="scss">
$parent-primary-color: #99cc00;
$staff-primary-color: #33cccc;

#template {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

.logo {
  position: relative;
  margin: 20px auto !important;
}

.flow {
  font-family: "Rubik", sans-serif;
  position: relative;
  max-width: 600px !important;
  margin: 0 auto;
  padding: 10px 25px;
  background: rgb(255, 255, 255);
  min-height: 100vh;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255) url(~@/assets/bg.png) no-repeat bottom right;
    background-size: cover;
    opacity: 0.5;
  }

  > .row {
    padding: 0 15px;

    @media (min-width: 768px) {
      padding: 0 25px;
    }
  }

  .fa {
    color: $parent-primary-color;
  }

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    min-width: 80px;
    background: #ebf5cc;
    border-radius: 50px;

    img {
      width: 100%;
      border-radius: 50px;
    }

    .user-icon {
      font-size: 30px;
      color: #ffffff;
    }

    .add-icon {
      position: absolute;
      bottom: 0;
      right: 5px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: white;
      border: 1px solid #99cc00;
      border-radius: 50px;
      color: #99cc00;
      font-size: 20px;
    }
  }

  .circle {
    background: #000000;
  }

  .van-field__label,
  .van-cell__title {
    flex: 2;
    font-weight: 400;
    text-align: left;
    color: #000;
    margin-right: 12px;

    @media screen and (min-width: 768px) {
      flex: 1;
    }
  }

  .van-checkbox__icon--checked .van-icon,
  .van-radio__icon--checked .van-icon {
    background-color: #99cc00 !important;
    border-color: #99cc00 !important;
  }

  .van-icon-success {
    font-size: 20px;
  }

  .form-select-wrapper {
    position: relative;
    width: 100%;
    color: #000;
  }

  .form-select-wrapper.half {
    max-width: max-content;
  }

  .form-select-wrapper:after {
    position: absolute;
    content: "";
    top: 10px;
    right: 10px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
  }

  .form-select,
  .van-field__control {
    height: 36px;
    position: relative;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 5px 10px;
    float: left;
    text-align: left;
    appearance: none;
  }

  .form-select {
    width: 100%;
    padding-right: 35px;
  }

  .van-field__label,
  .van-cell__title {
    font-weight: 400;
    text-align: left;
    color: #000;

    @media screen and (min-height: 740px) {
      flex: 1;
    }
  }

  .van-cell {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 0;
    margin: 0;
    background: none;
  }

  .van-cell:before {
    display: none;
  }

  .van-cell__value {
    flex: 3;
  }

  .van-cell--block {
    display: block;
  }

  .van-radio-group:not(.van-radio-group--horizontal) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .van-checkbox__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .van-checkbox {
      margin-bottom: 15px;
    }
  }

  .van-checkbox__icon {
    height: auto;
  }

  .van-checkbox__icon .van-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #666666;
  }

  .van-checkbox {
    align-items: flex-start;
  }

  .van-checkbox__label,
  .van-radio__label {
    color: inherit;
    margin-left: 12px;
    font-weight: 400;
    font-size: 14px;
  }

  .van-field.errorInput {
    background: none !important;
  }

  .errorInput.form-select,
  .errorInput.van-field__control,
  .errorInput .form-select,
  .errorInput .van-field__control {
    border-color: red;
  }

  .mialert {
    background: #f0f7d9;
    font-weight: 400;
    font-size: 13px;
    border-radius: 10px;
    line-height: 24px;
    letter-spacing: 0.02em;
    margin: 10px 0;
    padding: 10px;
    text-align: center;

    b,
    strong {
      font-weight: 500;
    }
  }

  .mialert p {
    margin: 0;
  }

  .text-danger {
    margin: 15px 0;
    padding: 0;
    color: #f4332f;
    font-weight: 500;
  }

  .van-radio {
    overflow: visible;
  }

  .van-radio__icon .van-icon,
  .m-chckbox--group {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    background: #ffffff;
    border: 1px solid #666666;
    border-radius: 50%;
  }

  .m-chckbox--container.active .m-chckbox--group {
    background: #99cc00;
    border-color: #99cc00;
  }

  .m-chckbox--group {
    .m-chckbox--ripple,
    svg {
      display: none;
    }

    > div {
      display: flex;
      align-items: center;

      &:before {
        width: 30px;
        height: 30px;
        font-family: "vant-icon";
        font-size: 20px;
        content: "\e728";
        color: #fff;
        text-align: center;
      }
    }
  }

  .fsize14 {
    font-weight: 400;
    font-size: 14px;
  }

  .van-radio__icon {
    height: auto;
  }
}

.slide-fade-enter-active {
  transition: all 0.15s ease;
}
.slide-fade-leave-active {
  animation: 0;
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
  -webkit-transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
  opacity: 0;
}

.progress {
  display: flex;
  margin: 20px -5px;
}

.progress-step {
  position: relative;
  width: 100%;
  height: 10px;
  flex: 1;
  background: #f5fae5;
  transition: all 0.15s ease;

  &:first-child {
    border-radius: 10px 0 0 10px;
  }

  &:last-child {
    border-radius: 0 10px 10px 0;
  }

  & + .progress-step {
    margin-left: 10px;
  }

  &.completed {
    background: $parent-primary-color;
  }
}

.link {
  display: block;
  text-align: center;
  background: none;
  border: 0;
  margin: 0 auto;
  color: $parent-primary-color;
  font-weight: 500;
}

.modal-flow {
  .modal-close {
    color: $parent-primary-color;
    cursor: pointer;
    z-index: 99;
    float: right;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
}

.staff-theme {
  &:before {
    background: rgb(255, 255, 255) url(~@/assets/flow/staff-bg.png) no-repeat
      bottom right;
  }

  .progress-step {
    background: #ebfafa;

    &.completed {
      background: $staff-primary-color;
    }
  }

  .fa,
  .link,
  .modal-close,
  .busicon active {
    color: $staff-primary-color;
  }

  .circle {
    // background: $staff-primary-color;
  }

  .avatar {
    background: #d6f5f5;

    .add-icon {
      border: 1px solid $staff-primary-color;
      border-radius: 50px;
      color: $staff-primary-color;
    }
  }

  .genderButton {
    background: $staff-primary-color;
  }

  .genderButton.active {
    background: #009999;
  }

  .mialert.lightGreen {
    background: #e0f7f7 !important;
  }
}
</style>
