<template>
  <div
    :key="message.id"
    class="block ignoreLinks font-rubik"
    :class="{ animateItem: animateIt, fadeOutDown: message.hide }"
  >
    <div
      class="text-grey bg-gray-50 text-sm awaitingApproval px-4"
      v-if="message.status == 'PENDING_REVIEW'"
    >
      Awaiting approval
    </div>
    <NoticeCombined
      v-if="message.type === 'NOTICE' || message.type === 'noticeEvent'"
      :message="message"
      :class="`notice-theme-${message.category}`"
      :user_id="user_id"
      @noticeEvent="noticeEvent"
    />
    <div v-if="message.type === 'empty'" style="padding: 10px">
      You have no posts in this category
    </div>
    <!-- <h1>{{ message.type }}</h1> -->
    <!-- <Notice
      v-if="message.type === 'NOTICE'"
      :message="message"
      class="notice-theme-general"
      @noticeEvent="noticeEvent"
    /> -->
    <!-- <NoticeImages
      v-else-if="message.type === 'noticeimages'"
      :message="message"
      class="notice-theme-general"
      @noticeEvent="noticeEvent"
    /> -->
    <Info
      v-else-if="message.type === 'INFO'"
      :message="message"
      class="notice-theme-general"
      @noticeEvent="noticeEvent"
    />
    <Dynamic
      v-else-if="message.type === 'DYNAMIC'"
      :message="message"
      class="notice-theme-general"
      @noticeEvent="noticeEvent"
    />
    <Chat
      v-else-if="message.type === 'chat'"
      :message="message"
      :user_id="parseInt(user_id)"
      :chat_room_id="parseInt(message.chat_room_id)"
      class="notice-theme-general"
      @noticeEvent="noticeEvent"
      messenger-mode
    />
    <Event
      v-else-if="message.type === 'EVENT'"
      :message="message"
      :class="`notice-theme-${message.category}`"
      @noticeEvent="noticeEvent"
    />
    <!-- <Article
      v-else-if="message.type === 'article'"
      class="blockwrapper"
      :class="'userType' + message.user_type"
      @noticeEvent="noticeEvent"
    /> -->
    <Poll
      v-else-if="message.type === 'POLL'"
      :message="message"
      :user_id="user_id"
      :class="`notice-theme-${message.category}`"
      @noticeEvent="noticeEvent"
    />
    <Safeguarding
      :message="message"
      v-else-if="message.type === 'BG_JOURNEY'"
    />
  </div>
</template>
<script>
// import Article from "./noticeTypes/Article";
import Chat from "./noticeTypes/Chat";
import Dynamic from "./noticeTypes/Dynamic";
import Event from "./noticeTypes/Event";
import Info from "./noticeTypes/Info";
// import NoticeImages from "./noticeTypes/NoticeImages";
import Notice from "./noticeTypes/Notice";
import NoticeCombined from "./noticeTypes/NoticeCombined";
import Poll from "./noticeTypes/Poll";
import ApiService from "@/services/ApiService";
import Safeguarding from "./noticeTypes/Safeguarding";

export default {
  name: "notice-main",
  components: {
    // Article,
    Chat,
    Dynamic,
    Event,
    Info,
    // NoticeImages,
    // Notice,
    Poll,
    Safeguarding,
    NoticeCombined,
  },
  props: {
    message: Object,
    user_id: Number,
    messengerMode: Boolean,
  },
  data() {
    return {
      animateIt: false,
      liked: false,
      // results: [{ type: "notice" }]
    };
  },
  methods: {
    redirect() {
      // alert('redirect ');
    },
    noticeEvent(options) {
      if (options.action === "showNotice") {
        this.$emit("noticeEvent", options);
      } else if (options.action === "showInfoNotice") {
        this.$emit("noticeEvent", options);
      } else if (options.action === "showChat") {
        // options.id = this.message.id;
        this.$emit("noticeEvent", options);
      } else if (options.action === "showChatUserId") {
        options.other_user_role_id = this.message.other_user_role_id;
        options.action = "showChat";
        this.$emit("noticeEvent", options);
      } else if (options.action === "noticeLike") {
        this.likeNotice(options);
      } else if (options.action === "hideNotice") {
        this.hideNotice(options);
      } else if (options.action === "hideInfoNotice") {
        this.hideInfoNotice(options);
      } else if (options.action === "showComments") {
        this.$emit("noticeEvent", options);
      }
    },
    likeNotice(options) {
      ApiService.generalApi.noticeLike(options.id).then(() => {
        if (this.liked === true) {
          return;
        }
        this.message.total_likes = parseInt(this.message.total_likes) || 0;
        this.message.total_likes += 1;
        this.liked = true;
      });
    },
    hideNotice(options) {
      ApiService.generalApi.hideNotification(options.id);
      this.$emit("noticeEvent", options);
    },
    hideInfoNotice(options) {
      ApiService.generalApi.hideInfoNotification(options.id);
      options.action = "hideNotice";
      this.$emit("noticeEvent", options);
    },
  },
};
</script>
