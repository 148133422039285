<template>
  <div class="bg">
    <div class="loginView" style="overflow: hidden" :style="mainStyleObject">
      <!-- <header id="header" class="text-center" v-show="showHeader">
      <img src="@/assets/logo-small.png" alt />
      </header>-->
      <div class="text-center">
        <img
          src="@/assets/logo-main.png"
          alt
          style="height: 80px; margin-bottom: 5px; margin-top: 10px"
        />
      </div>
      <p style="text-align: center">
        Your school community and journeys platform
      </p>
      <form
        id="loginForm"
        name="loginForm"
        :style="styleObject"
        @submit.prevent="submit"
      >
        <div>
          <div class="col-xs-12 text-center">
            <div
              v-if="registrationInProgress && school_name"
              class="alert alert-info"
              style="
                background: white;
                color: #e61180;
                font-family: 'Palanquin Dark';
                margin-bottom: 15px;
                box-shadow: 2px 2px 2px #ccc;
                border-radius: 15px;
              "
            >
              Log in to your existing HomeRun account to add&nbsp;
              <span style="color: black; font-weight: bold">{{
                school_name
              }}</span
              >&nbsp;to your profile
            </div>
          </div>
          <div class="text-center">
            <p style="padding-bottom: 0px">
              Please enter your registered
              <br />email/mobile number
            </p>
          </div>
          <div class="col-xs-12" style="margin-bottom: 10px">
            <van-field
              :label="emailLabel"
              placeholder="Email Or Mobile Number"
              type="text"
              name="email"
              required
              v-model="email"
            ></van-field>
            <div class="error" v-if="checkForErrors && !$v.email.required">
              Field is required
            </div>
            <div class="error" v-if="checkForErrors && !$v.email.minLength">
              Name must have at least
              {{ $v.email.$params.minLength.min }} letters.
            </div>

            <!-- <input
            name="email"
            class="form-control form-group"
            ng-model="vm.user.email"
            required
            placeholder="Email or Mobile Number"
            />-->
          </div>
          <div class="col-xs-12" v-if="codeSent">
            <van-field
              :label="tempCodeLabel"
              required
              name="code"
              placeholder="Code sent via SMS"
              type="text"
              v-model="tempCode"
            ></van-field>
            <div class="error" v-if="checkForErrors && !$v.tempCode.required">
              Field is required
            </div>
          </div>
          <div
            class="form-group text-center"
            style="padding-top: 30px; padding-bottom: 0px; clear: both"
          >
            <van-button
              @click.prevent="sendCode"
              v-if="!codeSent"
              type="info"
              :disabled="!email"
              >Send code via SMS</van-button
            >
            <van-button
              v-if="codeSent"
              type="info"
              :disabled="fieldsAreFilled()"
              >Login</van-button
            >
            <!-- <button type="submit" class="btn primaryButton" name="button">Login</button> -->
          </div>
        </div>
      </form>
      <div class="whiteBlock">
        <div class="row text-center padding30 text-center">
          <span>
            Having issues logging in?
            <br />Contact
            <a href="mailto:info@homerun-app.com">info@homerun-app.com</a>
          </span>
          <br />
          <span style="display: block; margin-top: 10px"
            >Download the app to get the full HomeRun service</span
          >
          <div class="col-md-12" style="margin-top: 25px">
            <div class="col-xs-6">
              <a
                href="https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8"
                target="_blank"
              >
                <img
                  src="@/assets/apple.png"
                  height="40px"
                  alt
                  style="margin-top: 10px"
                />
              </a>
            </div>
            <div class="col-xs-6">
              <a
                href="https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&hl=en_GB"
                target="_blank"
              >
                <img
                  src="@/assets/android.png"
                  height="40px"
                  alt
                  style="margin-top: 10px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "login",
  components: {},
  data() {
    return {
      checkForErrors: false,
      registrationInProgress: false,
      codeSent: false,
      email: "",
      tempCode: "",
      emailLabel: "",
      tempCodeLabel: "",
      styleObject: {},
      mainStyleObject: {},
    };
  },
  validations: {
    email: {
      required,
      minLength: minLength(4),
    },
    tempCode: {
      required,
    },
  },
  methods: {
    submit: function () {
      this.checkForErrors = true;
      if (!this.$v.$invalid) {
        this.$store.dispatch("attemptLogin", {
          password: "temp_sms_code",
          email: this.email,
          schoolCode: this.$store.state.general.schoolCode,
          // temp_login_code: this.tempCode,
        });
      }
    },
    forgotPassword: function () {
      this.$router.push({
        name: "forgotPassword",
        params: { email: this.email },
      });
    },
    fieldsAreFilled: function () {
      return this.email === "" || this.password === "";
    },
    sendCode: function () {
      ApiService.generalApi.sendTempCode(this.email).then((resp) => {
        this.codeSent = true;
        this.$notify({
          message: "Code has been sent to your phone.",
          type: "success",
        });
      });
    },
  },
  beforeMount() {
    window.postMessage("logout", "*");
  },
  mounted() {
    this.styleObject = {
      marginTop: "30px",
    };
    this.mainStyleObject = {
      height: window.innerHeight - 30 + "px",
    };

    if (this.$route.query) {
      this.registrationInProgress = true;
      this.school_name = this.$route.query.school_name;
    }
    if (window.innerWidth > 500) {
      this.emailLabel = "Email";
      this.tempCodeLabel = "SMS code";
    }
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
  },
};
</script>
<style scoped>
#header {
  padding: 12px;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  background: url(~@/assets/header1.png);
}
header img {
  height: 50px;
}
#loginForm {
  max-width: 700px;
  margin: auto;
  margin-top: 10px;
}
div.loginView {
  margin: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 3px #b9b9b9;
  padding: 5px;
  max-width: 400px;
  z-index: 999;
  /* margin: auto; */
}
div.mint-cell-title {
}
@media screen and (min-width: 430px) {
  div.loginView {
    margin-left: auto;
    margin-right: auto;
  }
}
div.bg {
  background-image: url(~@/assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 0;
}
</style>
