import dbConnection from "../dbConnection";

const staticContentApis = {
  submitFeedback: function (payload) {
    return dbConnection.post("api/users/feedback", payload);
  },

  faq: function () {
    return dbConnection.get("api/faq");
  },

  faqStaff: function () {
    return dbConnection.get("api/faqStaff");
  },

  about: function () {
    return dbConnection.get("api/about");
  },
};

export default staticContentApis;
