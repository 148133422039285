var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mainCont",staticClass:"clearfix row font-rubik",class:{ 'staff-theme': _vm.is_staff, 'journey-theme': _vm.journeyShare },attrs:{"id":"mainContent"}},[_c('div',{staticClass:"mt-1 md:mt-2 pt-0 pb-2 px-2 md:px-0 md:mx-3"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-xl text-black"},[_vm._v(" "+_vm._s(_vm.page)+" ")])]),_c('vue-position-sticky',{attrs:{"offsetTop":_vm.native ? 0 : 45}},[_c('div',{staticClass:"search-options mx-1 md:mx-0 my-1 mb-3"},[_c('div',{staticClass:"clearfix row"},[_c('div',{staticClass:"col-xs-12 px-2"},[_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selection.searchString),expression:"selection.searchString"}],staticClass:"form-control py-2",attrs:{"name":"searchString","placeholder":['staff', 'staff_journey_share'].indexOf(this.type) > -1
                ? 'Search for Staff'
                : 'Search for Parents'},domProps:{"value":(_vm.selection.searchString)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selection, "searchString", $event.target.value)}}}),(_vm.selection.searchString <= 1)?_c('i',{staticClass:"absolute top-2 right-3.5 fa fa-search text-grey"}):_vm._e(),(_vm.selection.searchString.length >= 1)?_c('i',{staticClass:"absolute top-4 right-4 fa fa-lg fa-times text-grey",on:{"click":function($event){_vm.selection.searchString = ''}}}):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"px-2",class:_vm.type === 'travel_buddy' ? 'col-xs-6' : 'col-xs-12'},[(_vm.availableSchools.length > 0)?_c('DropdownSelection',{staticClass:"selection-button school-selection-button mt-0.5",class:_vm.type,attrs:{"keyProp":"id","single":"","name":"name","default_label":"Select School","items":_vm.availableSchools,"default_value":_vm.getDefaultSchoolSelection,"custom-caret":""},on:{"updated":_vm.changeSchool}}):_vm._e()],1),(
            _vm.selection.school &&
            _vm.selection.school.years &&
            this.type != 'staff_journey_share' &&
            this.type != 'staff'
          )?_c('div',{staticClass:"col-xs-12 flex flex-wrap items-center mt-2 px-2"},[(_vm.$route.params.type != 'journey_share')?_c('router-link',{staticClass:"flex-1",attrs:{"to":"/main/myChats"}},[_c('button',{staticClass:"btn-black btn-back text-sm w-full relative"},[_c('i',{staticClass:"fa fa-angle-left fa-lg text-white absolute top-4 left-1 md:left-2"}),_c('span',[_vm._v("My Messages")])])]):_vm._e(),(_vm.$route.params.type == 'journey_share')?_c('router-link',{staticClass:"flex-1",attrs:{"to":"/main/travelDirectory"}},[_c('button',{staticClass:"btn-black btn-back text-sm w-full relative"},[_c('i',{staticClass:"fa fa-angle-left fa-lg text-white absolute top-4 left-1 md:left-2"}),_c('span',[_vm._v("Journey Sharing")])])]):_vm._e(),(
              _vm.type != 'travel_buddy' && _vm.type != 'parent' && _vm.type != 'staff'
            )?_c('DropdownSelection',{staticClass:"flex-1 selection-button ml-2",class:_vm.type,attrs:{"keyProp":"mode","name":"name","single":"","default_label":"Select mode","default_value":"journey_share","items":_vm.availableModes,"custom-caret":""},on:{"updated":_vm.changeMode}}):_vm._e(),_c('DropdownSelection',{staticClass:"flex-1 selection-button ml-2",class:_vm.type,attrs:{"keyProp":"value","name":"name","default_label":"Filter by Years","allSelectedLabel":"All Years selected","default_value":"all","items":_vm.availableYears,"custom-caret":""},on:{"updated":_vm.changeYear}}),_c('DropdownSelection',{staticClass:"btn-black text-sm flex-1 mx-2",class:_vm.type,staticStyle:{"display":"none"},attrs:{"keyProp":"value","name":"name","single":"","default_label":"Select Sorting","default_value":_vm.$route.params.type === ('parent' || 'staff')
              ? 'alphabetical'
              : 'distance',"items":_vm.availableSorting,"custom-caret":""},on:{"updated":_vm.changeSorting}})],1):_vm._e()]),_c('div',{staticStyle:{"clear":"both"}})])]),(
      _vm.$route.params.type == 'travel_buddy' &&
      (_vm.journey_preference === 0 || _vm.journey_preference === 1)
    )?_c('div',{staticClass:"mialert lightGray border-0 mt-0 mb-4 py-2"},[_c('span',{staticClass:"text-black text-sm font-medium"},[_vm._v(" You have selected that you are not open to "),(_vm.$route.params.type == 'travel_buddy')?_c('span',{staticStyle:{"white-space":"break-spaces"}},[_vm._v("Travel Buddies")]):_vm._e(),_vm._v(". ")]),(_vm.$route.params.type == 'travel_buddy')?_c('button',{staticClass:"btn-black text-sm py-2 px-4 my-2",on:{"click":function($event){return _vm.enableJourneyPreference('travel_buddy')}}},[_vm._v(" I am now open to looking for Travel Buddies ")]):_vm._e()]):_vm._e(),(
      _vm.$route.params.type == 'journey_share' &&
      (_vm.journey_preference === 0 || _vm.journey_preference === 2)
    )?_c('div',{staticClass:"mialert lightGray border-0 mt-0 mb-4 py-2"},[_c('span',{staticClass:"text-black text-sm font-medium"},[_vm._v(" You have selected that you are not open to "),(_vm.$route.params.type == 'journey_share')?_c('span',{staticStyle:{"white-space":"break-spaces"}},[_vm._v("Journey Sharing")]):_vm._e(),_vm._v(". ")]),_c('div',[_c('button',{staticClass:"btn-black text-sm py-2 px-4 my-2",on:{"click":function($event){return _vm.enableJourneyPreference('journey_share')}}},[_vm._v(" I am now open to Journey Sharing ")])])]):_vm._e(),_c('transition-group',{attrs:{"name":"slide-fade"}},_vm._l((_vm.parents),function(parent){return _c('div',{key:parent.id,staticClass:"searchlisting my-0"},[_c('SearchParentsItem',{attrs:{"show_school":_vm.selection.school.id == 'all',"page":_vm.page,"parent":parent,"expanded":parent.id === _vm.expandedImageId},on:{"showConversation":_vm.showConversationModal,"expandImage":_vm.expandImage}})],1)}),0),(_vm.showNextPage)?_c('div',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_c('button',{staticClass:"colorBlue btn btn-link",attrs:{"size":"small","type":"info","sty":""},on:{"click":function($event){return _vm.showMore()}}},[_c('b',[_vm._v("Load more")]),_c('br'),_c('i',{staticClass:"fas fa-angle-double-down"})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }