<template>
  <div>
    <div
      id="mainContent"
      class="mydetails clearfix row"
      style="border: none"
    >
      <div class="col-md-12">
        <Header title="My Groups" />
      </div>
      <div class="col-xs-12">
        <div class="alert alert-mira round40" style="margin-top: 15px">
          <p style="font-size: 14px; text-align: center">
            Join your school groups to receive relevant information and updates.
            Ask your school administrator to setup any groups that you want for
            your school.
          </p>
        </div>
      </div>
      <div class="col-xs-12 schoolBlock">
        <transition-group name="fade" tag="div">
          <div
            :key="group.id"
            class="row currentSchoolRow clearfix"
            v-for="group in groups"
          >
            <div class="col-xs-3">
              <img
                class="smallLogo"
                :src="group.logo"
                alt
                style="margin: 13px"
              />
            </div>
            <div class="col-xs-5" style="min-height: 70px; text-align: left">
              <span class="schoolName">
                <b>{{ group.name }}</b>
                <br />
                <p style="font-size: 12px">{{ group.description }}</p>
              </span>
            </div>
            <div class="col-xs-4" style="min-height: 70px">
              <span>{{ group.joined ? "Joined" : "Not joined" }}</span
              ><br />
              <van-switch
                v-model="group.joined"
                @change="toggle($event, group.id)"
                size="24px"
              />
              <!-- <span class="schoolName fa fa-edit textGradient"></span> -->
            </div>
          </div>
        </transition-group>
        <div class="clearfix" style="clear: both"></div>
        <p v-if="!loading && groups.length === 0">
          There are no groups setup for your school.
        </p>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
export default {
  name: "mySchools",
  components: {
    Header,
  },
  data() {
    return {
      groups: [],
      loading: true,
    };
  },
  methods: {
    getMyUserGroups() {
      ApiService.userApi.getUserGroups("all").then((response) => {
        this.groups = response.data.groups;
        this.$store.dispatch("loading", false);
      });
    },
    toggle(event, id) {
      const params = {
        id: id,
        action: event,
      };
      ApiService.userApi.updateUserGroup(params).then(() => {});
    },
  },
  mounted() {
    this.$store.dispatch("loading", true);
    this.getMyUserGroups();
    setTimeout(() => {
      this.showPage = true;
      this.loading = false;
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.currentSchoolRow {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
.schoolBlock {
  margin-bottom: 25px;
}
.schoolName {
  text-align: left;
}
.smallLogo {
  max-height: 50px;
}
h3 {
  font-size: 20px !important;
}
#mainContent {
  height: auto;
  min-height: 100%;
}
</style>
