<template>
  <div id="wrapper">
    <div id="topConversation" class="notice-title items-center py-1">
      <i class="fa fa-angle-left fa-2xl text-pink-dark mr-5 cursor-pointer" @click="$emit('close')"></i>

      <img :src="imageUrl" class="notice-avatar relative border-notice-theme" alt />

      <div class="notice-user-details flex-1">
        <span class="username">{{ chatTitle }}</span>
      </div>
      <button class="btn btn-link pull-right text-xs text-grey hover:text-gray-300 self-start" @click="report()">
        Report
      </button>
    </div>
    <ul id="middleConversation" v-chat-scroll="{ always: true, smooth: true }">
      <li :key="singleChatMessage.id" class="chatline" v-for="singleChatMessage in conversations" :class="{
        myChatMessage: myUserId == singleChatMessage.user_id,
      }">
        <!-- <strong v-if="myUserRoleId == singleChatMessage.user_role_id"></strong> -->
        <span class="chatMessage">
          <span v-html="singleChatMessage.message"></span>
          <span class="timeAgo flex items-center">
            <timeago :datetime="singleChatMessage.created_timestamp * 1000"></timeago>
            <i v-if="
              singleChatMessage.is_read == 0 &&
              myUserRoleId != singleChatMessage.user_role_id
            " class="far fa-envelop"></i>
            <i v-if="
              singleChatMessage.is_read == 1 &&
              myUserRoleId != singleChatMessage.user_role_id
            " class="far fa-envelope-open"></i>
          </span>
        </span>
      </li>
    </ul>
    <div id="bottomConversation" class="bg-white rounded-t-lg">
      <div>
        <div class="container" style="max-width: 600px">
          <div id="bottomChatDiv" class="row bottomChatDiv">
            <form @submit="sendConversation()" v-if="allow_parents_reply == 1" class="flex mx-5">
              <div class="flex-1 pr-4">
                <van-field v-model="newChatMessage" label="" type="textarea" placeholder="Type a message" rows="1"
                  :autosize="{ maxHeight: 100 }" @click="focusInput" ref="chatMessage" class="chat-textarea" />
                <!-- <textarea
                  @click="scrollToBottom"
                  class="form-control staticHeight"
                  v-model="newChatMessage"
                  placeholder="Type a message"
                ></textarea> -->
              </div>
              <div>
                <button @click.prevent="sendConversation"
                  class="rounded-full p-3 bg-pink-dark text-white flex items-center">
                  <i class="far fa-paper-plane" style="display: block; font-size: 18px"></i>
                </button>
              </div>
            </form>
            <div class="alert alert-info" v-if="allow_parents_reply == 0 && !isStaff">
              This user has selected not to enable replies from parents.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import ReportModal from "@/components/modals/ReportModal.vue";

export default {
  name: "conversationModal",
  props: {
    data: Object,
    chat_room_id: {
      type: Number,
      default: 0,
    },
    other_user_role_id: Number,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        parents_reply: 1,
        profile_photo_url: "",
      },
      chatRoomId: this.chat_room_id,
      allow_parents_reply: 0,
      // myUserRoleId: null,
      title: "",
      chatTitle: "",
      newChatMessage: "",
      showFriends: false,
      conversationAltered: false,
      imageUrl: null,
      conversations: [],
      chatRoom: {},
    };
  },
  computed: {
    isStaff: function () {
      const roles = this.$store.state.general.roles;
      if (roles.length > 0) {
        return (
          roles.find((itm) => itm.user_role_id == this.currentUserRoleId)
            .slug == "STAFF" ?? false
        );
      } else {
        return false;
      }
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
    myUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
    myUserId: function () {
      return this.$store.state.general.user_id;
    },
  },
  methods: {
    getConversations() {
      this.$store.dispatch("loading", true);
      this.imageUrl = undefined;
      ApiService.conversationsApi
        .getChatRoom(this.chat_room_id, this.other_user_role_id)
        .then((response) => {
          const data = response.data;
          this.conversations = data.conversations.reverse();
          this.user = data.user;
          this.imageUrl = data.image;
          this.chatTitle = data.title;
          this.chatRoom = data.chatRoom;
          this.allow_parents_reply = data.chatRoom.allow_parents_reply;
        })
        .catch(function () {
          // vm.loading = false;
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
    scrollToBottom() {
      const el = document.getElementById("wrapper");
      const middle = document.getElementById("middleConversation");

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        el.scrollTop = el.scrollHeight;
        middle.scrollTop = middle.scrollHeight;
      }
    },
    focusInput() {
      this.$refs["chatMessage"].focus();
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    },
    report() {
      const params = {
        reported_user_id: this.chat_room_id,
        subject: "user_id",
      };
      this.$modal.show(
        ReportModal,
        {
          params,
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          height: "auto",
          adaptive: true,
          pivotY: 0,
          reset: true,
          classes: "v--modal-box v--modal report-modal",
        }
      );
    },
    sendConversation() {
      let postData = {
        chat_room_id: this.chatRoomId,
        message: this.newChatMessage,
        other_user_role_id: this.other_user_role_id,
      };

      if (this.newChatMessage == "") {
        return;
      }

      let message = {
        created_timestamp: Math.round(new Date().getTime() / 1000),
        id: Math.random(),
        is_read: 0,
        message: this.newChatMessage.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        chat_room_id: this.chatRoomId,
        user_role_id: this.myUserRoleId,
        user_id: this.myUserId,
      };
      if (this.chatRoom && this.chatRoom.id) {
        message.chat_room_id = this.chatRoom.id;
        postData.chat_room_id = this.chatRoom.id;
      }
      this.$store.dispatch("setRefreshMessages", true);

      this.conversations.unshift(message);

      ApiService.conversationsApi.saveChat(postData).then((response) => {
        this.conversationAltered = true;
        if (response.status == 200) {
          if (!this.chatRoomId && response.data.chat_room_id) {
            this.chatRoomId = response.data.chat_room_id;
            this.chatRoom = {};
          }
        }
      });
      this.newChatMessage = "";
    },
    updateChat() {
      const chatRoomId =
        this.chatRoomId;

      if (!chatRoomId) return;

      ApiService.conversationsApi.updateChat(chatRoomId).then((response) => {
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            this.conversations.unshift(response.data[i]);
          }
        }
      });
    },
  },
  mounted() {
    this.intervalid1 = setInterval(this.updateChat, 5000);
    this.getConversations();
    this.focusInput();
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
  },
};
</script>
<style scoped>
#topConversation {
  @apply bg-white rounded-lg m-2 p-2;
  min-height: 55px;
}

#bottomConversation {
  padding-top: 15px;
  padding-bottom: 15px;
}

button.close {
  margin: 0px !important;
  padding-right: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 12px !important;
}

ul#middleConversation {
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;
  /* flex-wrap: wrap-reverse; */
  /* min-height: 100%; */
  padding: 5px 0.5rem;
  /* flex-shrink: 1; */
  flex: 1;
  /* padding-bottom: 140px; */
  background: linear-gradient(166.78deg,
      rgba(204, 51, 204, 0.05) 7.39%,
      rgba(216, 100, 114, 0.05) 100%),
    #ffffff;
}

div#wrapper {
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(166.78deg,
      rgba(204, 51, 204, 0.05) 7.39%,
      rgba(216, 100, 114, 0.05) 100%),
    #ffffff;
  @apply text-sm;
}

li.chatline {
  margin-bottom: 10px;
}

li.chatline .chatMessage {
  @apply shadow py-2 px-4 text-chat;
  border-radius: 20px;
  background: #ffffff;
  display: inline-block;
  text-align: left;
  margin-right: 50px;
}

span.timeAgo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @apply text-chat;
  padding-top: 1px;
  font-size: 8px;
}

li.myChatMessage {
  text-align: right !important;
}

li.myChatMessage .chatMessage {
  @apply rounded-xl text-sm text-chat;
  background: #fee6e6;
  margin-left: 50px;
  margin-right: 0px;
}

.chat-textarea {
  @apply bg-white;
  border: 0.5px solid #c2c2c2;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.fa-envelope-open {
  @apply text-pink-dark text-xs;
  line-height: 15px;
  margin-left: 7px;
}
</style>
