import axios from "axios";
import { api_url } from "../domain";
// You can use your own logic to set your local or production domain
const baseDomain = api_url;
// const baseDomain = "https://api.homerun-app.co.uk";
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(
  async (response) => {


    return response;
  },
  function (error) {
    if (error && error.response && error.response.data) {
      if (
        error.response.data.message == "Token has expired" ||
        error.response.data.message == "TokenInvalidException" ||
        error.response.data.error == "token_invalid"
      ) {
        // alert(error.response.data.error);
        localStorage.clear();
        document.location.href = "/logout";
      }
    }
    return Promise.reject(error);
  }
);

export default instance;