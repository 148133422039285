<template>
  <h1 class="hidden-xs" :class="{ show: showMobile }">
    <i class="primaryColor" :class="icon"></i>
    {{ title }}
  </h1>
</template>
<style scoped>
h1 {
  font-size: 22px;
  z-index: 999;
}
h1.show {
  display: block !important;
}
i {
  font-size: 20px;
}
</style>
<script>
export default {
  name: "header",
  props: {
    title: String,
    icon: String,
    showMobile: Boolean,
  },
};
</script>
