<template>
  <div class="passForgotView">
    <transition name="fade">
      <div id="loading" v-show="loading">
        <span class="helper"></span>
        <span class="three-quarters-loader"></span>
      </div>
    </transition>
    <div id="middle">
      <div id="forgotPassword">
        <div class="text-center">
          <img
            src="@/assets/logo-main.png"
            alt
            style="height: 80px; margin-bottom: 25px; margin-top: 20px"
          />
        </div>
        <div class="text-center">
          <van-button
            size="small"
            class="round25"
            style="background: white !important"
            type="info"
            plain
            @click.prevent="$router.go(-1)"
            >Go Back</van-button
          >
          <div class="alert" v-if="!showForm">
            If the email exists on our system, you will receive a reset password
            link at {{ email }}.
          </div>
        </div>
        <form name="forgotPassword" v-if="showForm">
          <div class="col-xs-12">
            <div class="text-center">
              <h1 style="font-size: 22px">Forgot Password</h1>
            </div>
            <div class="text-center">
              <p style="padding-bottom: 12px">
                Please enter your registered email below
                <br />and we will send you a reset password link.
              </p>
            </div>
            <div class="col-xs-12 marginTop5">
              <label for="">Email</label>
              <van-field
                label
                placeholder="Enter your email"
                type="text"
                required
                v-model="email"
              >
              </van-field>
              <div
                class="form-group text-center"
                style="padding-top: 30px; padding-bottom: 0px; clear: both"
              >
                <van-button
                  @click.prevent="sendEmail"
                  type="info"
                  :disabled="fieldsAreNotFilled"
                  >Send reset link via Email
                </van-button>
              </div>
            </div>
            <div
              class="col-xs-12 form-group text-center"
              style="padding-top: 30px; padding-bottom: 10px"
            ></div>
            <div style="clear: both"></div>
            <div
              class="row text-center padding30 text-center"
              style="margin-bottom: 20px"
            >
              <span class="font-weight-bold">
                <strong>Having issues logging in?</strong>
                <br />Contact
                <a href="mailto:info@homerun-app.com">info@homerun-app.com</a>
              </span>
              <br />
            </div>
          </div>
          <div style="clear: both"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "forgotPasswordViaCode",
  components: {
    // Header
  },
  data() {
    return {
      email: "",
      styleObject: {},
      codeSent: false,
      tempCode: "",
      password: "",
      password_confirm: "",
      codeCorrect: false,
      showForm: true,
    };
  },
  computed: {
    fieldsAreNotFilled: function () {
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        !this.email.includes(".")
      ) {
        return true;
      } else {
        return false;
      }
    },
    loading() {
      return this.$store.state.general.loading;
    },
  },
  methods: {
    sendEmail: function () {
      this.$store.dispatch("loading", true);
      ApiService.authApi
        .requestPasswordReset({ email: this.email })
        .then((resp) => {
          this.$store.dispatch("loading", false);
          this.$notify({
            message: "Email has been sent.",
            type: "success",
          });
          this.showForm = false;
        })
        .catch((err) => {
          this.$store.dispatch("loading", false);
          this.$notify({
            message: err.response.data.error,
            type: "danger",
          });
        });
    },
  },
  mounted() {
    this.styleObject = {
      // marginTop: (window.innerHeight - 500) / 2 + "px",
    };
    this.email = this.$route.params.email;
  },
};
</script>
<style scoped>
#header {
  padding: 12px;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  background: url(~@/assets/header1.png);
}

header img {
  height: 50px;
}

#forgotPassword {
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 25px #cdcdcd;
  border-radius: 10px;
}

label {
  text-align: left;
}

.marginTop5 {
  text-align: left;
}

div.passForgotView {
  background-image: url(~@/assets/bg_almost_white.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

div.passForgotView::before {
  opacity: 0.4;
}

.van-field {
  border: 1px solid #e2e2e2;
  /* box-shadow: 0px 0px 3px #bbb; */
}
</style>
