import dbConnection from "../dbConnection";

const generalActions = {
  login: function (data) {
    return dbConnection.post("api/login_new", data);
  },
  loginViaToken: function () {
    return dbConnection.post("api/loginViaToken", {});
  },
  getMessages: function getMessages() {
    return dbConnection.get("api/notifications");
  },
  getUnreadMessages: function (page, filterChoice, timestamp, userRoleId) {
    var query = "";
    query = "?page=" + page;
    if (filterChoice && filterChoice != "all") {
      query += "&filter=" + filterChoice;
    }
    if (timestamp || timestamp == 0) {
      query += "&timestamp=" + timestamp;
    }
    query + "&user_role_id=" + userRoleId
    return dbConnection.get("api/notifications/list" + query);
  },
  getSingleMessage: function (messageId) {
    return dbConnection.post("api/notifications/view/" + messageId);
  },
  getSingleInfoNotice: function (messageId) {
    return dbConnection.get("api/notifications/infoNotice/" + messageId);
  },
  getTrackedChildren: function () {
    return dbConnection.get("api/tracking/children");
  },
  getSchoolAddresses: function (schoolId) {
    return dbConnection.get("api/users/school_addresses/" + schoolId);
  },
  staffDestinationAddresses: function () {
    return dbConnection.get("api/users/staffDestinationAddresses");
  },
  reportUser: function (obj) {
    return dbConnection.post("api/users/reportUser", obj);
  },

  logout: function (full) {
    if (full) {
      localStorage.clear();
    }
    // $http.defaults.headers.common["Authorization"] = null;
  },

  saveBlobImage: function (payload) {
    return dbConnection.post("api/uploadFile?blob=true", payload);
  },

  deleteNotice: function (id) {
    return dbConnection.get("api/notifications/deleteNotice/" + id);
  },

  noticeLike: function (id) {
    return dbConnection.get("api/notifications/noticeLike/" + id);
  },

  saveComment: function (payload) {
    return dbConnection.post("api/users/comments/save", payload);
  },

  likeComment: function (payload) {
    return dbConnection.post("api/users/comments/like", payload);
  },

  removeComment: function (id) {
    if (confirm('Are you sure you want to delete this comment ?')) {
      return dbConnection.delete("api/users/comments/remove/" + id);
    }
  },

  dashboard: function () {
    return dbConnection.get("api/users/children/dashboard?type=week");
  },

  getMessagesStats: function (userRoleId) {
    return dbConnection.get("api/notifications/messagesStats?=user_role_id=" + userRoleId);
  },

  getSchoolLoginToken: function () {
    return dbConnection.get("api/users/getSchoolLoginToken");
  },

  hideNotification: function (id) {
    return dbConnection.get("api/hideNotification/" + id);
  },

  hideInfoNotification: function (id) {
    return dbConnection.delete("api/notifications/deleteInfoNotification/" + id);
  },

  pollAnswer: function (pollId, answerId, notificationId, textArea) {
    return dbConnection.post(
      "api/notifications/pollAnswer/" +
      pollId +
      "/" +
      answerId +
      "/" +
      notificationId +
      "/" +
      textArea
    );
  },

  dynamicNoticeAnswer: function (
    masterNoticeId,
    answerId,
    notificationId,
    textArea
  ) {
    return dbConnection.post(
      "api/notifications/dynamicNoticeAnswer/" +
      masterNoticeId +
      "/" +
      answerId +
      "/" +
      notificationId,
      { text: textArea }
    );
  },

  hideConversation: function (id) {
    return dbConnection.post("api/conversations/hideConversation/" + id, {
      test: true,
    });
  },

  hasPendingChildren: function () {
    return dbConnection.get("api/users/pendingChildren");
  },

  pendingJourneys: function () {
    return dbConnection.get("api/users/journeys/pendingJourneys");
  },

  isPendingJourneys: function () {
    return dbConnection.get("api/journey-profile/isPending");
  },

  getJourney: function (destination, origin) {
    return dbConnection.get(
      "api/journey-profile/destination?destination=" +
      destination.lat +
      "%2C" +
      destination.lng +
      "&origin=" +
      origin.lat +
      "%2C" +
      origin.lng +
      "&mode=&cummulative=1&alternatives=1"
    );
  },

  getParkingJourney: function (destination, origin, parking, isSubAccount) {
    var query =
      "api/journey-profile/destination/parking?destination=" +
      destination.lat +
      "%2C" +
      destination.lng +
      "&origin=" +
      origin.lat +
      "%2C" +
      origin.lng +
      "&parking=" +
      parking.lat +
      "%2C" +
      parking.lng;
    if (isSubAccount) {
      query += "?subaccount=true";
    }
    return dbConnection.get(query);
  },
  saveParking: function (payload) {
    return dbConnection.post("api/users/journeys/parking", payload);
  },

  saveJourney: function (payload) {
    return dbConnection.post("api/users/journeys/store", payload);
  },

  getCarData: function (registrationNo) {
    return dbConnection.post(
      "api/vehicles/searchVrn?registration=" + registrationNo
    );
  },

  saveCarData: function (payload) {
    return dbConnection.post("api/vehicles/store", payload);
  },

  check_school_code: function (code) {
    return dbConnection.get("api/registration/check_school_code/" + code);
  },

  getAddressFromPostcode: function (postcode) {
    if (postcode.length > 4)
      return dbConnection.get(
        "api/journey-profile/addresses-from-postcode?postcode=" + postcode
      );
  },

  saveAddress: function (addressObj, parentid) {
    return dbConnection.post(
      "api/users/address",
      addressObj
    );
  },

  getParentAddress: function (parentId) {
    return dbConnection.get("api/profile/" + parentId + "/address");
  },

  //   hasCode: function(code) {
  //     if (store.get("code")) {
  //       // alert(store.get('code') + 'I\'m getting code');
  //       //Do ajax request here, check if code is still valid
  //     }
  //   },

  _addSchool: function (code) {
    return dbConnection.get("api/addSchool/" + code);
  },
  addOrganization: function (code) {
    return dbConnection.post("api/users/addOrganization", { code: code });
  },
  removeOrganization: function (organizationId, user_role_id) {
    return dbConnection.post("api/users/removeOrganization", {
      organization_id: organizationId,
      user_role_id: user_role_id
    });
  },
  saveNewPost: function (data) {
    var payload = Object.assign({}, data);
    // var years = [];
    // // Flatten years to level only
    // for (var i = 0; i < payload.years.length; i++) {
    //   years.push(payload.years[i].level);
    // }
    // payload.years = years;
    return dbConnection.post("api/notifications/savePost", payload);
  },

  introState: function () {
    return dbConnection.get("api/users/introSeen");
  },

  sendTestNotification: function () {
    return dbConnection.get("api/users/sendTestNotification");
  },
  getTravelFeatures: function () {
    return dbConnection.get("api/users/travelFeatures");
  },
  transportSuggest: function (payload) {
    return dbConnection.post("api/users/feedback", payload);
  },
  sendTempCode: function (email) {
    return dbConnection.post("api/users/sendTempCode", { email });
  },
  getYearLabels: function (email) {
    return dbConnection.get("api/users/yearLabels");
  },
  // registration MI
  getSchoolInfoWithCode: function (code) {
    const data = { code: code };
    return dbConnection.post("api/registration_mi/get_school_with_code", data);
  },
  getUniqueLinkInfo: function (hash) {
    const data = { hash: hash };
    return dbConnection.post("api/registration_mi/check_unique_link", data);
  },
  addHash: function (hash, invitation_token) {
    const data = { hash: hash };
    if (invitation_token) {
      data.invitation_token = invitation_token;
    }
    return dbConnection.post("api/registration_mi/add_hash", data);
  },
  checkEmail: function (data) {
    return dbConnection.post("api/registration_mi/check_email", data);
  },
  verifyEmail: function (data) {
    return dbConnection.post("api/registration_mi/verify_email", data);
  },
  resendCode: function (data) {
    return dbConnection.post("api/registration_mi/resend_code", data);
  },
  registration: function (data) {
    return dbConnection.post("api/registration_mi/registration", data);
  },
};

export default generalActions;
