<style lang="scss" scoped>
menu {
  padding: 0;
  margin: 0;
}

/* #menu {
  display: none;
} */

#absoluteContentMobile {
  @apply bg-black;
}

@media (min-width: 768px) {
  #wrapper {
    height: 95%;
  }

  #wrapper .col-sm-9 {
    padding-top: 20px;
    height: 100%;
  }

  #absoluteContentMobile {
    height: 100%;
  }
}

@media (max-width: 768px) {
  #absoluteContentMobile {
    top: 0px;
    bottom: 0px;
    min-height: 100%;
    height: 100%;
  }
}

.whiteColor {
  color: #ffffff;
}

.whiteColor i {
  color: white;
}

#menu ul {
  list-style: none;
}
ul li.menupadding {
  /* padding-left: 60px !important; */
  /* font-size: 22px; */
}

#menu ul li {
  @apply text-base text-white font-light text-left;
}
#menu ul li i {
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  text-align: center;
  margin-right: 20px;
  min-width: 50px;
}

#menu ul li span.sp {
  vertical-align: top;
  margin-top: 7px;
  margin-left: 4px;
  display: inline-block;
}

#menu ul li {
  margin-top: 8px;
}

#menu ul li:hover {
  background: none;
  @apply text-peach;
}

@media only screen and (max-width: 360px) {
  #profileMenu {
    margin-top: -10px !important;
  }

  #menu ul li {
    margin-top: 1%;
    line-height: 27px;
  }

  #menu ul li i {
    font-size: 22px;
  }
}
span.name,
span.last_name {
  font-size: 17px;
  line-height: 22px;
}

#absoluteContentMobile {
  min-height: 75vh;
  height: calc(100vh - 41px - 64px - 35px);
  margin-bottom: 15px;
  @apply shadow;
}
.native #absoluteContentMobile {
  height: calc(100vh - 64px - 28px);
}

.menuBg {
  align-items: center;

  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.menu-avatar {
  @apply relative m-auto mb-4;
  width: 70px;
  height: 70px;

  &:before {
    content: "";
    bottom: -3px;
    right: 0px;
    width: 19px;
    height: 19px;
    @apply absolute rounded-full;
    background: #d86472 url("@/assets/menu/pen.svg") center no-repeat;
    z-index: 6;
  }

  img {
    @apply relative border border-solid border-white p-1 rounded-full border-2;
    @apply m-auto mb-6;
    z-index: 5;
  }
}
</style>
<template>
  <div id="absoluteContentMobile" class="row mx-2 rounded-lg py-5">
    <div
      id="menu"
      class="menuPage bg-black rounded-lg px-10 font-rubik"
      style="z-index: 0; position: relative"
    >
      <div
        id="profileMenu"
        class="flex flex-col items-center py-2 md:py-4 mb-3 md:mb-4"
      >
        <div>
          <div
            class="menu-avatar"
            @click="goto('settings')"
            data-cy="settings_btn"
          >
            <img
              class="img-circle"
              :src="profile_photo"
              height="70"
              width="70"
              alt
              data-cy="profile_pic"
              style="height: 70px; min-height: 70px"
            />
          </div>

          <span class="text-lg text-white" data-cy="first_name_text">{{
            userInfo.first_name
          }}</span>
        </div>
      </div>
      <nav class="flex items-center m-auto">
        <i class="fa-light fa-pen"></i>
        <ul class="active menuBg">
          <li
            class="menupadding whiteColor"
            @click="goto('settings')"
            data-cy="settings_btn"
          >
            <i class="fal fa-pen-to-square"></i>
            <span class="sp">My Settings</span>
          </li>
          <li
            class="menupadding whiteColor"
            @click="goto('myChildren')"
            data-cy="my_children_btn"
          >
            <i class="fal fa-child"></i>
            <span class="sp">My Children</span>
          </li>
          <li
            class="menupadding whiteColor"
            @click="goto('mySchools')"
            data-cy="my_schools_btn"
          >
            <i class="fal fa-school"></i>
            <span class="sp">My Schools</span>
          </li>
          <!-- <li class="menupadding whiteColor" @click="goto('myGroups')">
            <span class="img">
              <i
                class="far fa-plus-square"
                style="font-size: 29px; padding-top: 12px; padding-left: 2px"
              ></i>
            </span>
            <span class="sp">My Groups</span>
          </li> -->
          <li
            v-show="is_staff === 0"
            class="menupadding whiteColor"
            @click="goto('invitePartner')"
            data-cy="invite_partner_btn"
          >
            <i class="fal fa-user-group"></i>
            <span class="sp">Invite My Partner</span>
          </li>
          <li
            class="menupadding whiteColor"
            @click="goto('faqs')"
            data-cy="faq_btn"
          >
            <i class="fal fa-comments-question"></i>
            <span class="sp">FAQs</span>
          </li>
          <!-- <li class="menupadding whiteColor" ng-if='$root.native' @click='vm.sendTestNotification()'><i class="fas fa-cog"></i> <span class="sp">Send Test Notification</span></li> -->
          <li
            class="menupadding whiteColor"
            v-if="native"
            @click="openPermissions()"
          >
            <i class="fal fa-cog"></i>
            <span class="sp">App Permissions</span>
          </li>
          <li
            class="menupadding whiteColor"
            @click="goto('contactus')"
            data-cy="contact_us_btn"
          >
            <i class="fal fa-envelope"></i>
            <span class="sp">Contact Us</span>
          </li>
          <li
            class="menupadding whiteColor"
            @click="goto('logout')"
            data-cy="logout_btn"
          >
            <i class="fal fa-arrow-up-left-from-circle"></i>
            <span class="sp">Logout</span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "menu",
  components: {},
  data() {
    return {
      selectedAccountType: "parent",
      height: 0,
      profile_photo: null,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.general.user;
    },
    native() {
      return this.$store.state.general.native;
    },
    is_staff() {
      return this.roleType == "STAFF" ? 1 : 0;
    },
    roleType: function () {
      return this.$store.state.general.roles.find(
        (role) => this.currentUserRoleId == role.user_role_id
      )?.slug;
    },
  },
  methods: {
    goto(path) {
      if (path == "logout" && this.$store.state.general.native) {
        window.postMessage("logout", "*");
      } else {
        this.$router.push({ name: path });
      }
    },
    openPermissions() {
      if (this.$store.state.general.native) {
        window.postMessage("openPermissions", "*");
      }
    },
    refreshImage() {
      ApiService.userApi.refreshImage("myProfilePicture").then((resp) => {
        this.profile_photo = resp.data.profile_photo;
      });
    },
  },
  mounted() {
    this.refreshImage();
  },
  created() {
    this.height = window.innerHeight - (this.native ? 54 : 95);
  },
};
</script>
