<style></style>
<template>
  <div id="registrationMiWrapper">
    <div class="textCenter" style="min-height: 80px">
      <MiFlowHeader v-if="schoolInfo" :school_name="schoolInfo.name" :logo="schoolInfo.logo" />
    </div>
    <div id="flexDiv" v-if="codeStatus == false" style="min-height: calc(100vh - 150px)">
      <h2>
        Invalid address, please make sure you have entered your sign-up link
        correctly
      </h2>
      <h2 style="font-size: 55px !important" class="text-left">
        Welcome<br />to HomeRun
      </h2>
      <p>
        Can't sign up? Contact HomeRun Support at
        <a href="mailto:info@homerun-app.com">info@homerun-app.com</a>
      </p>
    </div>
    <div id="flexDiv" class="clearfix" style="min-height: 400px" v-if="codeStatus == true">
      <!-- <h2 data-cy="title">{{ schoolInfo.name || "-" }}</h2> -->
      <h2 style="font-size: 55px !important">Welcome<br />to HomeRun</h2>
      <transition name="fade2" mode="out-in">
        <div data-cy="accountOptions" v-if="checkForExistingAccount && loggedIn === false">
          <p v-if="newAccountAvailable">Select an option</p>
          <p v-if="!newAccountAvailable">
            It looks like you already have an account with us, press the button
            below to log in, to add this school to your account.
          </p>
          <div class="row">
            <div class="col-xs-12">
              <van-button type="info" v-if="newAccountAvailable" data-cy="new_account_btn" name="button"
                class="lightBlueBtn" style="width: 300px" @click="redirectToRegistration()">I'm new to
                HomeRun</van-button>
            </div>
            <div class="col-xs-12">
              <van-button type="info" data-cy="i_have_account_btn" name="button" class="lightGreenBtn"
                style="width: 300px;text-wrap: balance;" @click="redirectToLogin()"><span
                  v-if="!newAccountAvailable">Sign in to continue</span><span v-if="newAccountAvailable">I already have
                  a HomeRun Account</span></van-button>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade2" mode="out-in">
        <div v-if="
          schoolSelecionRequired &&
          checkForExistingAccount == false &&
          finishedTransition === true
        ">
          <p style="font-size: 18px" class="colorBlack">
            Mani has not provided any text so this will do.
          </p>
          <div style="padding-left: 100px; margin-top: 40px; margin-bottom: 40px">
            <van-radio-group v-model="school_index_selected" data-cy="school_selection">
              <van-radio :key="school.id" :name="index" style="margin-bottom: 15px" v-for="(school, index) in schools">
                {{ school.name }}
                <template #icon="props">
                  <i :class="props.checked
                      ? 'fas fa-circle-dot fa-sm lgreenColor'
                      : 'fa-regular fa-circle-small'
                    "></i>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
          <div class="row flowBottomButtonDiv" style="margin-top: 30px">
            <div class="col-xs-12">
              <!-- <van-button type="info" @click="$router.go(-1)" plain>Go Back</van-button> -->
              <van-button type="info" name="button" data-cy="submit" class="blackBtn" @click="selectSchool"
                :disabled="school_index_selected == null">Continue</van-button>
            </div>
          </div>
        </div>
      </transition>
      <div v-if="loggedIn === true">
        <van-button type="info" data-cy="add_hash_btn" name="button" class="lightBlueBtn" @click="addHash()">Add {{
          schoolInfo.name }} to my account</van-button>
      </div>
      <div id="bottomLogo" class="text-center">
        <img src="@/assets/wireframe_logo.svg" style="vertical-align: baseline" />
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import MiFlowHeader from "@/components/registration_mi/MiFlowHeader.vue";
import ErrorMessages from "@/helpers/ErrorMessages";

export default {
  name: "startingPage",
  components: {
    MiFlowHeader,
    // Header,
    // ModalHeader,
  },
  data() {
    return {
      schoolName: "Test school",
      accountType: "",
      // logo: "https://home-run-dev.s3.eu-west-2.amazonaws.com/council/schools/photo/300/school_1564407035.png",
      logo: null,
      codeStatus: null,
      schoolInfo: {
        name: "",
        logo: "",
      },
      checkForExistingAccount: false,
      loggedIn: null,
      loading: true,
      schools: [],
      schoolSelecionRequired: null,
      selectRoleRequired: false,
      school_index_selected: null,
      finishedTransition: true,
      newAccountAvailable: false,
    };
  },
  computed: {
    tokenExists() {
      if (!localStorage.token) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    selectSchool() {
      this.schoolSelecionRequired = false;
      const school = this.schools[this.school_index_selected];
      sessionStorage.setItem("school_id", school.id);
      sessionStorage.setItem("schoolName", school.name);
      sessionStorage.setItem("logo", school.logo_url);
      this.redirectToRegistration();
    },
    redirectToLogin() {
      sessionStorage.setItem("redirect", "registration_mi/onboarding");
      this.$router.push("/login");
    },
    redirectToRegistration() {
      if (this.schoolSelecionRequired == true) {
        this.finishedTransition = false;
        this.checkForExistingAccount = false;
        setTimeout(() => {
          this.finishedTransition = true;
          this.selectRole = true;
        }, 350);
      } else {
        this.$router.push("/registration_mi/new_account");
      }
    },
    getUniqueLinkInfo(hash) {
      ApiService.generalApi
        .getUniqueLinkInfo(hash)
        .then((resp) => {
          const data = resp.data;
          this.newAccountAvailable = !data.account_exists;
          this.codeStatus = true;
          sessionStorage.setItem("title", resp.data.title);
          sessionStorage.setItem("first_name", resp.data.first_name);
          sessionStorage.setItem("last_name", resp.data.last_name);
          sessionStorage.setItem("hash", data.hash);
          sessionStorage.setItem("main_school_id", data.main_school_id);

          if (data.multiple_schools == false) {
            this.schoolInfo = data.schoolInfo;
            this.schoolSelecionRequired = false;
            sessionStorage.setItem("logo", data.schoolInfo.logo);
            sessionStorage.setItem("school_id", data.main_school_id);
            sessionStorage.setItem("schoolName", data.schoolInfo.name);
          } else if (data.multiple_schools == true) {
            this.schoolSelecionRequired = true;
            this.selectRoleRequired = false;
            this.schools = data.schools;
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data.error == "ACCOUNT_ALREADY_EXISTS"
          ) {
            this.$router.push("/login?exists=true");
          }
          this.codeStatus = false;
        });
    },
    addHash() {
      const hash = sessionStorage.getItem("hash");
      const invitation_hash = sessionStorage.getItem("invitation_token");
      ApiService.generalApi
        .addHash(hash, invitation_hash)
        .then(() => {
          sessionStorage.setItem("redirect", "/flow/details");
          this.$router.push("/redirect");
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            const error = err.response.data.error;
            this.$notify({
              type: "danger",
              message: ErrorMessages[error],
            });
          }
        });
    },
    async getUserInfo() {
      try {
        const result = await ApiService.userApi.getUserInfo();
        if (result) {
          this.loggedIn = true;
          ApiService.userApi.getAccountStatus().then((response) => {
            if (response.data.missing == "address") {
              this.$router.push({ name: "details" });
            } else if (response.data.missing == "children") {
              this.$router.push({ name: "flowChildren" });
            } else if (response.data.missing == "journeys") {
              this.$router.push({ name: "journeys" });
            } else if (response.data.missing == "verification") {
              this.$router.push({ name: "verification" });
            } else if (response.data.missing == "school") {
              this.$router.push({ name: "flowSchools" });
            }
          });
        }
      } catch (error) {
        this.checkForExistingAccount = true;
        this.loggedIn = false;
      }
    },
  },
  mounted() {
    const hash = this.$route.params.hash;
    this.getUniqueLinkInfo(hash);
    if (this.tokenExists) {
      this.getUserInfo();
    } else {
      this.loggedIn = false;
      this.checkForExistingAccount = true;
    }
  },
};
</script>
<style scoped>
.van-radio-group i {
  width: 15px;
  font-size: 24px;
  color: #bdbdbd;
  margin-top: -2px;
  padding: 0px 1px;
}

.lgreenColor {
  color: #9cc200 !important;
}

.fa-circle-dot {
  font-size: 15px !important;
}
</style>
