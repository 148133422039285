import dbConnection from "../dbConnection";

const conversationApis = {
  getChatRoom: function (chatRoomId, otherUserRoleId) {
    if (!otherUserRoleId) otherUserRoleId = null;
    return dbConnection.get("api/chats/chatRoom/" + chatRoomId + "/" + otherUserRoleId);
  },

  updateChat: function (chatRoomId) {
    return dbConnection.get("api/chats/checkChatRoomforUpdate/" + chatRoomId);
  },
  saveChat: function (payload) {
    return dbConnection.post("api/chats/saveChat", payload);
  },

  getAllChatRooms: function () {
    return dbConnection.get("api/chats/getAllChatRooms");
  },
};

export default conversationApis;
