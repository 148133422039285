<template>
  <!-- Children BG location -->
  <div class="block safeguarding" @click="redirectJourney(message.student_id)">
    <block-title :message="message" />
    <!-- <div class="blockTitle clearfix">
      Safeguarding active
      <span class="timeAgo">
        <span
          am-time-ago="message.created_timestamp | amFromUnix"
          style="color: #FFFFFF"
        ></span>
      </span>
    </div>-->
    <div
      class="mapImage"
      style="position: relative; float: right; margin-right: 20px"
    >
      <img class="mapImage" :src="imgUrl" alt />
      <img
        :src="message.profile_photo"
        alt
        class="img-circle"
        style="
          position: absolute;
          z-index: 65;
          top: 25%;
          left: 25%;
          border: 1px solid #bbe;
          margin-left: 20px;
        "
      />
    </div>
    <p style="margin-left: 10px">
      <span class="name fsize15">{{ message.first_name }}</span> sharing
      location with you
      <br />
      <span class="hidden-xs" style="display: block; margin-top: 10px"
        >Last update: {{ message.time_ago }}</span
      >
      <span class="hidden-xs" style="display: block"
        >Battery: {{ parseInt(message.battery * 100) }}%</span
      >
      <span class="visible-xs-block" style="margin-top: 8px">
        <small>Last location: {{ message.time_ago }}</small>
      </span>
      <span class="visible-xs-block">
        <small>Battery: {{ parseInt(message.battery * 100) }}%</small>
      </span>
    </p>

    <div style="clear: both"></div>
  </div>
</template>
<style scoped>
.safeguarding {
  background: url(~@/assets/bannerGreenbg1.jpg);
}
</style>
<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
export default {
  name: "article",
  components: {
    BlockTitle,
  },
  props: {
    message: Object,
    // title: String,
    // icon: String
  },
  methods: {
    redirectJourney() {
      if (this.$store.state.general.native) {
        window.postMessage("showJourneys", "*");
      } else {
        alert("This feature is only available on the smartphone app");
      }
    },
  },
  computed: {
    imgUrl: function () {
      return (
        "https://maps.googleapis.com/maps/api/staticmap?center=" +
        this.message.lat +
        "," +
        this.message.long +
        "&zoom=15&size=400x250&key=AIzaSyBeguURx-JXrhMHtcTlTomH_Xmk_9YWkjc"
      );
    },
  },
};
</script>
