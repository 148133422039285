<style scoped>
td.comment {
  padding: 7px 0px;
  /* background: #f5f5f5; */
  /* border: 1px solid #CCCCCC; */
  /* margin-left: 45px; */
  border-radius: 15px;
  /* box-shadow: 0px 0px 3px #bbb; */
}

td.tdimage {
  width: 55px;
  text-align: center;
}

td.buttons {
  text-align: center;
}
.notice-avatar {
  border: 0.5px solid #c2c2c2;
}

.comment-textarea {
  @apply w-full text-sm text-black;
}

.comment-textarea-wrapper {
  @apply flex-1 bg-white py-2 px-6 mx-2 rounded-xl;
  border: 0.5px solid #c2c2c2;
}

.comment-input-container {
  @apply w-full flex items-center;
  @apply rounded-b-xl p-3 mt-1 mb-2;
  background: rgba(194, 194, 194, 0.2);
}
</style>
<template>
  <div class="comment-input-container" @click.stop>
    <img
      class="notice-avatar relative border-notice-theme mx-0"
      :src="profile_photo"
    />
    <div class="comment-textarea-wrapper">
      <textarea-autosize
        class="comment-textarea"
        placeholder="Enter new comment"
        :minHeight="25"
        v-model="comment"
      ></textarea-autosize>
    </div>

    <div class="buttons">
      <button
        @click="postNewComment"
        class="btn-black uppercase text-xs !px-5"
        :disabled="fieldsAreFilled()"
      >
        Post
      </button>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import ApiService from "@/services/ApiService";
export default {
  name: "newComment",
  props: {
    id: Number,
    noticeUserId: Number,
  },
  data() {
    return {
      expanded: false,
      comment: "",
      profile_photo: null,
    };
  },
  computed: {
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
  },
  methods: {
    likeComment() {
      alert("Like comment");
    },
    removeMyComment() {
      // alert("testas");
    },
    fieldsAreFilled() {
      return this.comment == "";
    },
    refreshImage() {
      ApiService.userApi.refreshImage("myProfilePicture").then((resp) => {
        this.profile_photo = resp.data.profile_photo;
      });
    },
    postNewComment() {
      if (this.comment === "") {
        this.$notify({
          type: "warning",
          message: "Comment is required to proceed",
        });
        return;
      }
      var _payload = {
        comment: this.comment,
        notice_id: this.id,
        user_role_id: this.currentUserRoleId,
      };
      if (this.id) {
        _payload.notice_id = this.id;
      }

      if (this.noticeUserId) {
        _payload.noticeUserId = this.noticeUserId;
      }

      this.$store.dispatch("loading", true);

      ApiService.generalApi
        .saveComment(_payload)
        .then((data) => {
          this.$notify({ type: "success", message: "Comment posted!" });
          this.$store.dispatch("loading", false);
          this.comment = "";
          this.$emit("newCommentAdded", data.data);
        })
        .catch(() => {
          this.$store.dispatch("loading", false);
        });
    },
  },
  mounted() {
    this.refreshImage();
  },
};
</script>
