<template>
  <div></div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "initial",
  components: {},
  mounted() {
    if (!localStorage.token) {
      // this.name = localStorage.name;
      this.$router.push("/login");
    } else {
      this.$router.push("/main/home");
    }
  },
};
</script>
