<template>
  <div>
    <div v-if="showPopup" class="overlay" @click="togglePopup"></div>
    <button
      id="selectionButton"
      @click.stop="togglePopup"
      class="w-full text-left"
      :class="{ small: small, medium: medium, blueTheme: blueTheme }"
    >
      <span v-if="selection.length === 0">
        {{ default_label }}
      </span>
      <span v-if="selection.length === 1 && single">{{
        selection_name.length > 30
          ? selection_name.substring(0, 45) + "..."
          : selection_name
      }}</span>
      <span
        v-if="
          selection.length && !single >= 1 && selection.length != items.length
        "
        >{{ selection.length }} selected</span
      >
      <span
        v-if="
          allSelectedLabel &&
          selection.length == items.length &&
          selection.length > 1
        "
        >{{ allSelectedLabel }}</span
      >
      <i
        v-if="!customCaret"
        class="icon fas fa-caret-down"
        :class="{ small: small, medium: medium }"
      ></i>
    </button>
    <div
      class="years"
      style="position: relative"
      @click="showPopup = !showPopup"
    >
      <div
        v-if="showPopup"
        class="text-center"
        style="
          position: absolute;
          background: white;
          color: black;
          width: 100%;
          z-index: 999;
          border-radius: 10px;
          padding: 10px;
          padding-left: 0px;
          padding-right: 0px;
          box-shadow: 2px 2px 12px #bbbbbb;
        "
      >
        <div v-if="!single">
          <div
            class="text-left"
            style="padding-left: 25px; padding-bottom: 10px; font-size: 13px"
            @click.stop="selectAll"
          >
            SELECT ALL
          </div>
          <hr class="hr-divider" />
        </div>
        <div
          :key="item[keyProp]"
          v-for="item in items"
          class="text-left item"
          :class="selection.indexOf(item[keyProp]) > -1 ? 'active' : ''"
          @click.stop="toggleSelection(item[keyProp], item[name])"
        >
          <span class="check fas fa-check"></span>
          <span class="">{{ item[name] }}</span>
        </div>
        <div v-if="!single">
          <hr />
          <button @click.stop="togglePopup" class="btn-black sm text-sm mt-1">
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
div.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.05);
  z-index: 9;
}

div.item {
  padding: 6px 5px;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  /* padding-left: 20px; */
}

span.check {
  opacity: 0;
  min-width: 22px;
  text-align: center;
}

div.active {
  background: #dbf5fc;
}

div.active span.check {
  opacity: 1;
  color: #26a2ff;
}

.icon {
  position: absolute;
  right: 25px;
  top: 15px;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

button.small {
  padding: 6px !important;
}

.small.icon {
  top: 10px;
}

button.medium {
  padding: 4px !important;
  height: 35px;
}

.medium.icon {
  top: 12px;
}

.journey_share .years {
  @media (max-width: 767px) {
    position: initial !important;
    margin-left: -10px;
  }
}
</style>
<script>
export default {
  name: "dropdownSelection",
  data() {
    return {
      selection: [],
      showPopup: false,
      selection_name: "",
    };
  },
  props: {
    default_label: String,
    allSelectedLabel: String,
    single: Boolean,
    small: Boolean,
    medium: Boolean,
    items: Array,
    keyProp: String,
    name: String,
    blueTheme: Boolean,
    default_value: [String, Number, Array],
    customCaret: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    items: function (items) {
      if (items && this.default_value) {
        if (Array.isArray(this.default_value)) {
          this.default_value.forEach((item) => {
            this.toggleSelection(item);
          });
        } else {
          if (this.default_value == "all" && !this.single) {
            // this.selectAll();
          } else {
            this.toggleSelection(this.default_value);
          }
        }
      }
    },
    default_value: function () {

    },
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    selectAll() {
      this.selection = this.items.map((item) => {
        return item[this.keyProp];
      });
      this.$emit("updated", this.selection);
    },
    clearSelection() {
      this.selection = [];
    },
    toggleSelection(id, name) {
      if (this.selection.indexOf(id) != -1) {
        this.selection = this.selection.filter(function (value) {
          return value != id;
        });
        if (this.selection.length === 0 && this.single) {
          this.selection = ["all"];
        }
      } else {
        if (this.single) {
          this.selection = [id];
        } else {
          this.selection.push(id);
        }
      }

      if (this.single && this.selection.length === 1) {
        if (name) {
          this.selection_name = name;
        } else {
          if (!this.items) {
            return;
          }

          let current = this.items.filter((value) => {
            return value[this.keyProp] == this.selection[0];
          })[0];
          this.selection_name = current[this.name];
        }
        this.showPopup = false;
      }

      this.$emit("updated", this.selection);
      this.$forceUpdate();
    },
  },
  mounted() {
    if (this.default_value && this.items.length > 0) {
      this.$nextTick(() => {
        if (Array.isArray(this.default_value)) {
          this.default_value.forEach((item) => {
            this.toggleSelection(item);
          });
        } else {
          if (this.default_value == "all" && !this.single) {
            // this.selectAll();
          } else {
            this.toggleSelection(this.default_value);
          }
        }
      });
    }
  },
};
</script>
