import Vue from "vue";
import VueRouter from "vue-router";

import Initial from "../views/guest/Home.vue";
import Login from "../views/guest/Login.vue";
import LoginWithCode from "../views/guest/LoginWithCode.vue";
import Logout from "../views/Logout.vue";
import NativeLogin from "../views/NativeLogin.vue";
import AutoLogin from "../views/AutoLogin.vue";
import Unsubscribe from "../views/Unsubscribe.vue";
import ForgotPassword from "../views/guest/ForgotPassword.vue";
import ChangePassword from "../views/guest/ChangePassword.vue";
import NotFound from "../views/NotFound.vue";
import Download from "../views/Download.vue";
import NewTermLink from "../views/NewtermLink.vue";

import Home from "../views/main/Home.vue";
import BlankRedirect from "../views/main/BlankRedirect.vue";
import Features from "../views/main/Features.vue";
import SchoolRun from "../views/main/SchoolRun.vue";
import MyChildren from "../views/main/MyChildren.vue";

import Menu from "../views/main/Menu.vue";

import MainTemplate from "../views/MainTemplate.vue";

import Upgrade from "../views/Upgrade.vue";
import SearchUsers from "../views/main/SearchUsers.vue";
import MySchools from "../views/main/MySchools.vue";
import MyGroups from "../views/main/MyGroups.vue";
import InvitePartner from "../views/main/InvitePartner.vue";
import MyChats from "../views/main/MyChats.vue";
import Stats from "../views/main/Stats.vue";
import Faqs from "../views/main/Faqs.vue";
import ContactUs from "../views/main/ContactUs.vue";
import Settings from "../views/main/Settings.vue";

import Register from "../views/flow/Register.vue";
import TermUpdate from "../views/flow/TermUpdate.vue";
import Details from "../views/flow/Details.vue";
import FlowChildren from "../views/flow/FlowChildren.vue";
import Journeys from "../views/flow/Journeys.vue";
import Verification from "../views/flow/Verificationv2.vue";
import Congratulations from "../views/flow/Congratulations.vue";
import RedirectToAppstore from "../views/flow/RedirectToAppstore.vue";

import FlowTemplate from "../views/FlowTemplate.vue";

import SchoolLink from "../views/guest/SchoolLink.vue";
import SchoolAccountLink from "../views/guest/SchoolAccountLink.vue";

import StartingPage from "../views/registration_mi/StartingPage.vue";
import UniqueLinkStartingPage from "../views/registration_mi/UniqueLinkStartingPage.vue";
import EnterEmail from "../views/registration_mi/EnterEmail.vue";
import EmailVerification from "../views/registration_mi/EmailVerification.vue";
import NewAccount from "../views/registration_mi/NewAccount.vue";
import MobilePhone from "../views/registration_mi/MobilePhone.vue";
import Launch from "../views/Launch.vue";
import InviteParentLinkRedirect from "../views/InviteParentLinkRedirect.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "guesthome",
    component: Initial,
  },
  {
    path: "/login/:email?",
    name: "login",
    component: Login,
  },
  {
    path: "/loginWithCode/:email?",
    name: "loginWithCode",
    component: LoginWithCode,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/nativeLogin/:redirectTo/:randomString?",
    name: "nativeLogin",
    component: NativeLogin,
  },
  {
    path: "/l",
    name: "autoLogin",
    component: AutoLogin,
  },
  {
    path: "/unsubscribe/:string",
    name: "unsubscribe",
    component: Unsubscribe,
  },
  {
    path: "/forgotPassword/:email?",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/changePassword/:token",
    name: "changePassword",
    component: ChangePassword,
  },
  {
    path: "/upgrade",
    name: "upgrade",
    component: Upgrade,
  },
  {
    path: "/redirect",
    name: "redirect",
    component: BlankRedirect,
  },
  {
    path: "/download",
    name: "download",
    component: Download,
  },
  {
    path: "/new-term",
    name: "newTerm",
    component: NewTermLink,
  },
  {
    path: "/main",
    name: "MainTemplate",
    component: MainTemplate,
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "schoolRun_new",
        name: "schoolRunNew",
        component: SchoolRun,
      },
      {
        path: "travelDirectory",
        name: "travelDirectory",
        component: SchoolRun,
      },
      {
        path: "schoolRun",
        name: "schoolRun",
        component: Features,
      },
      {
        path: "schoolRun/:type",
        name: "schoolRun_searchParents",
        component: SearchUsers,
      },
      {
        path: "myChildren",
        name: "myChildren",
        component: MyChildren,
      },
      {
        path: "mySchools",
        name: "mySchools",
        component: MySchools,
      },
      {
        path: "myGroups",
        name: "myGroups",
        component: MyGroups,
      },
      {
        path: "invitePartner",
        name: "invitePartner",
        component: InvitePartner,
      },
      {
        path: "myChats",
        name: "myChats",
        component: MyChats,
      },
      {
        path: "stats/:trigger?",
        name: "stats",
        component: Stats,
      },
      {
        path: "searchParents/:type?",
        name: "searchParents",
        component: SearchUsers,
      },
      {
        path: "faqs",
        name: "faqs",
        component: Faqs,
      },
      {
        path: "menu",
        name: "menu",
        component: Menu,
      },
      {
        path: "contactus",
        name: "contactus",
        component: ContactUs,
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
      },
    ],
  },
  {
    path: "/flow",
    name: "flowTemplate",
    component: FlowTemplate,
    children: [
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "termUpdate",
        name: "termUpdate",
        component: TermUpdate,
      },
      {
        path: "details",
        name: "details",
        component: Details,
      },
      {
        path: "schools",
        name: "flowSchools",
        component: MySchools,
      },
      {
        path: "flowChildren",
        name: "flowChildren",
        component: FlowChildren,
      },
      {
        path: "journeys",
        name: "journeys",
        component: Journeys,
      },
      {
        path: "verification",
        name: "verification",
        component: Verification,
      },
      {
        path: "congratulations",
        name: "congratulations",
        component: Congratulations,
      },
      {
        path: "redirectToAppstore",
        name: "redirectToAppstore",
        component: RedirectToAppstore,
      },
    ],
  },
  {
    path: "/welcome/:schoolCode/:schoolName?",
    name: "welcomeLink",
    props: true,
    component: SchoolLink,
  },
  {
    path: "/welcomeParent/:schoolCode/:schoolName",
    name: "welcomeParentLink",
    component: SchoolAccountLink,
  },
  {
    path: "/welcomeStaff/:schoolCode/:schoolName",
    name: "welcomeStaffLink",
    component: SchoolAccountLink,
  },
  {
    path: "/parentInviteLink/:schoolCode/:schoolName",
    name: "parentInviteLink",
    component: SchoolAccountLink,
  },
  {
    path: "/inviteLink/:schoolCode/:schoolName",
    name: "parentInviteLink2",
    component: SchoolAccountLink,
  },
  {
    path: "/registration_mi/email/:type",
    name: "registrationEmailPage",
    component: EnterEmail,
  },
  {
    path: "/registration_mi/email_verification",
    name: "registrationVerificationPage",
    component: EmailVerification,
  },
  {
    path: "/registration_mi/mobile_phone",
    name: "registrationMobilePage",
    component: MobilePhone,
  },
  {
    path: "/registration_mi/new_account",
    name: "registrationNewAccount",
    component: NewAccount,
  },
  {
    path: "/signup/:hash",
    name: "registrationUniqueLinkStartingPage",
    component: UniqueLinkStartingPage,
  },
  {
    path: "/registration_mi/:schoolCode/:schoolName?",
    name: "registrationStartingPage",
    component: StartingPage,
  },
  {
    path: "/launch/:schoolLaunchCode",
    name: "launch",
    component: Launch,
  },
  {
    path: "/invite-parent-link-redirect/:schoolLaunchCode?/:name",
    name: "InviteParentLinkRedirect",
    component: InviteParentLinkRedirect,
  },
  {
    path: "/invite-parent-link-redirect//:name?",
    name: "InviteParentLinkRedirect",
    component: InviteParentLinkRedirect,
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
