<template>
  <div class="transport-poll-wrapper">
    <div class="modal-header flex items-center justify-between">
      {{ params.category || "not specified" }}
      <i
        class="fa fa-times close-icon fa-xl text-black cursor-pointer"
        @click="$emit('close')"
      />
    </div>

    <div class="text-center my-5">
      <p class="travel-explanation">
        {{ params.explanation }}
      </p>

      <textarea
        name="text"
        placeholder="Enter description"
        rows="3"
        cols="80"
        class="form-control rounded-2xl mt-6 py-3 px-4"
        maxlength="500"
        v-model="text"
      ></textarea>
    </div>

    <div>
      <label class="fsize14 font-semibold"
        >I would like a school run service from my area:</label
      >
      <van-radio-group
        v-model="serviceToRunFromMyArea"
        direction="vertical"
        class="marginTop15"
      >
        <van-radio name="both ways" style="color: white !important"
          >Both ways</van-radio
        >
        <van-radio name="AM only">AM only</van-radio>
        <van-radio name="PM only">PM only</van-radio>
      </van-radio-group>
      <label class="fsize14 marginTop15"
        >My maximum budget per school year is:</label
      >
      <van-radio-group
        v-model="maximumBudged"
        direction="vertical"
        class="marginTop15"
      >
        <van-radio name="500" data-cy="parent_pta_yes">up to £500</van-radio>
        <van-radio name="1000" data-cy="parent_pta_yes">up to £1000</van-radio>
        <van-radio name="1500" data-cy="parent_pta_no">up to £1500</van-radio>
        <van-radio name="2500" data-cy="parent_pta_no">up to £2500</van-radio>
      </van-radio-group>
    </div>
    <div class="my-5 text-center">
      <button class="btn-black uppercase" @click="submit">Submit</button>
    </div>
    <p class="travel-disclaimer">
      Your anonymised suggestions may be shared with your school and transport
      operators to provide solutions that match your requirements.
    </p>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  name: "transportPoll",
  props: {
    params: Object,
  },
  data() {
    return {
      serviceToRunFromMyArea: "0",
      maximumBudged: "0",
      text: "",
    };
  },

  methods: {
    submit() {
      if (!this.text || this.text.length < 10) {
        this.$notify({
          type: "warning",
          message: "Please provide more feedback. Thank you.",
        });
        return;
      }
      const payload = {
        ...this.params,
        message: this.text,
        budget: this.maximumBudged,
        service: this.serviceToRunFromMyArea,
      };
      ApiService.generalApi.transportSuggest(payload).then(() => {
        this.$notify({
          type: "success",
          message: "Thank you! Your travel suggestion has been submitted",
        });
        this.$emit("close");
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.transport-poll-wrapper {
  .van-radio__icon {
    line-height: 2em !important;
  }
  #topConversation {
    height: 53px;
    border-bottom: 1px solid #cccccc;
  }
  #bottomConversation {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #f8f8f8;
    border-top: 1px solid #e5e5e5;
  }
  button.close {
    margin: 0px !important;
    padding-right: 15px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 12px !important;
  }
  ul#middleConversation {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    padding: 5px;
    padding-bottom: 140px;

    background: url(~@/assets/circles-light.png);
  }
  li.chatline {
    line-height: 25px;
    margin-bottom: 10px;
  }
  li.chatline .chatMessage {
    padding: 12px;
    border-radius: 5px;
    background: #ffffff;
    display: inline-block;
    text-align: left;
    margin-right: 50px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.07);
  }
  span.timeAgo {
    color: #9b9b9b;
    padding-right: 5px;
    padding-left: 5px;
  }
  li.myChatMessage {
    text-align: right !important;
  }
  li.myChatMessage .chatMessage {
    background: #ffeddd;
    margin-left: 50px;
    margin-right: 0px;
  }

  .van-radio__label {
    @apply text-white text-sm font-normal;
  }

  .van-icon {
    background: white;
  }

  .van-radio-group--vertical .van-radio {
    margin-bottom: 8px;
  }

  .van-radio__icon--checked .van-icon {
    background-color: black !important;
    border-color: black !important;
  }

  .van-icon-success {
    color: white;
  }
}
</style>
