<template>
  <div class="clearfix">
    <div class="col-sm-12">
      <div class="form-heading">
        Congratulations!
        <br />Your HomeRun account is ready for you!
      </div>
      <div class="download-app">
        <p>
          All you need to do is<br /><b>Download the App</b><br />
          using the
          <span v-if="getMobileOperatingSystem == 'unknown'">QR code</span
          ><span v-if="getMobileOperatingSystem != 'unknown'">links</span>
          below<br />
          and sign in with<br />
          your credentials.
        </p>
        <VueQrcode
          v-if="getMobileOperatingSystem == 'unknown'"
          :value="'https://user.homerun-app.com/flow/redirectToAppstore'"
          :options="{ width: 180, margin: 1 }"
        ></VueQrcode>
        <div
          class="download-button-container"
          v-if="getMobileOperatingSystem !== 'unknown'"
        >
          <a
            v-if="getMobileOperatingSystem === 'iOS'"
            href="https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8"
            target="_blank"
          >
            <img src="@/assets/apple.png" height="40" />
            <button class="btn btn-black marginTop10">Download</button>
          </a>
          <a
            v-if="getMobileOperatingSystem === 'Android'"
            href="https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&hl=en_GB"
            target="_blank"
          >
            <img src="@/assets/android.png" height="40" />
            <button class="btn btn-black marginTop10">Download</button>
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-12" style="margin-top: 30px; display: none">
      <h3>
        <img
          src="@/assets/logo-small.png"
          style="height: 25px; margin-top: -10px"
        />&nbsp;&nbsp; Download the App
      </h3>
      <hr />
    </div> -->
    <div style="clear: both"></div>
    <div class="col-md-12 clearfix">
      <div class="mialert lightGray">
        Can't access UK iOS or Android app stores?
        <br />
        You can still access some of our features by
        <br />
        logging into our
        <a
          @click="finish()"
          data-cy="finish_btn"
          class="link"
          style="display: inline"
        >
          website.
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "congratulations",
  components: { VueQrcode },
  data() {
    return {};
  },
  methods: {
    finish() {
      this.$router.replace({ name: "home" });
    },
  },
  computed: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
  },
  mounted() {
    this.$store.dispatch("loading", false);
    this.$emit("progress-step", 5);
  },
};
</script>

<style lang="scss" scoped>
.alert.alert-info {
  background: none !important;
}
$primary-color: #21acf5;
.smallImg {
  display: inline-block;
}

span.im {
  vertical-align: top;
  line-height: 55px;
  margin-left: 5px;
}

div.toggle {
  margin-top: 10px;
}

#wrapper {
  max-width: 600px;
}

.bluebutton {
  background: #21acf5;
  color: white;
  border-radius: 20px;
  padding: 10px;
  max-width: 190px;
  margin: auto;
  font-weight: bold;
  font-size: 13px;
}

.midtext {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.download-app {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(~@/assets/flow/congratulations-bg.svg) no-repeat
    center;
  background-size: 100%;
  margin: 30px auto;
  font-size: 15px;
  color: #ffffff;
  text-align: center;

  @media screen and (min-width: 330px) {
    width: 333px;
    height: 313px;
    font-size: 18px;
  }

  p {
    display: block;
    margin: 40px 0 0;
  }
}

.staff-theme .download-app {
  background: transparent url(~@/assets/flow/congratulations-bg-staff.svg)
    no-repeat center;
}

.download-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;

  a {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  a:hover {
    text-decoration: none;
  }

  .btn {
    padding: 10px 30px;
  }

  svg {
    margin-bottom: 20px;
  }
}
</style>
