<template>
  <div class="passForgotView">
    <transition name="fade">
      <div id="loading" v-show="loading">
        <span class="helper"></span>
        <span class="three-quarters-loader"></span>
      </div>
    </transition>
    <div id="middle">
      <div id="changePassword">
        <div class="text-center">
          <img
            src="@/assets/logo-main.png"
            alt
            style="height: 80px; margin-bottom: 25px; margin-top: 20px"
          />
        </div>
        <div v-if="validResetToken == false" style="padding: 20px">
          Something went wrong, please check your link or request a new password
          change.
        </div>
        <form
          name="changePassword"
          :style="styleObject"
          @submit.prevent="submit"
          v-if="showForm"
        >
          <div class="col-xs-12">
            <div class="text-center">
              <h1 style="font-size: 22px">Change your Password</h1>
            </div>
            <div class="text-center">
              <p style="padding-bottom: 12px">
                Please enter your new password.
              </p>
            </div>
            <div class="col-xs-12 marginTop5">
              <div class="marginTop5" v-if="showForm">
                <label for="">New Password</label>
                <van-field
                  label
                  placeholder="New Password"
                  type="password"
                  required
                  v-model="password"
                ></van-field>
              </div>
              <div
                class="redColor"
                v-if="this.password.length > 0 && this.password.length < 8"
              >
                Minimum 8 characters required
              </div>
              <div class="marginTop5" v-if="showForm">
                <label for="">Confirm Password</label>
                <van-field
                  label
                  placeholder="Confirm Password"
                  type="password"
                  required
                  v-model="password_confirm"
                >
                </van-field>
              </div>
              <div
                class="redColor"
                v-if="
                  this.password.length > 7 &&
                  this.password != this.password_confirm
                "
              >
                Passwords are not matching
              </div>
              <div
                class="form-group text-center"
                style="padding-top: 30px; padding-bottom: 0px; clear: both"
              >
                <van-button type="info" :disabled="fieldsAreNotFilled"
                  >Submit
                </van-button>
                <!-- <button type="submit" class="btn primaryButton" name="button">Login</button> -->
              </div>
            </div>
            <div
              class="col-xs-12 form-group text-center"
              style="padding-top: 30px; padding-bottom: 10px"
            >
              <!-- <van-button type="info" :disabled="fieldsAreNotFilled()"
            >Send Link</van-button
          > -->
              <!-- <button type="submit" class="btn primaryButton" name="button">Login</button> -->
            </div>
            <div style="clear: both"></div>
            <div
              class="row text-center padding30 text-center"
              style="margin-bottom: 20px"
            >
              <br />
            </div>
          </div>
          <div style="clear: both"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "changePassword",
  components: {
    // Header
  },
  data() {
    return {
      email: "",
      styleObject: {},
      showForm: false,
      token: "",
      password: "",
      password_confirm: "",
      validResetToken: null,
    };
  },
  computed: {
    fieldsAreNotFilled: function () {
      if (
        this.password === "" ||
        this.password_confirm != this.password ||
        this.password.length < 8
      ) {
        return true;
      } else {
        return false;
      }
    },
    loading() {
      return this.$store.state.general.loading;
    },
  },
  methods: {
    submit: function () {
      if (this.password != this.password_confirm) {
        alert("Password and confirm password don't match!");
        return;
      }
      this.$store.dispatch("loading", true);
      ApiService.authApi
        .changePassword({
          password: this.password,
          token: this.token,
        })
        .then(() => {
          this.$store.dispatch("loading", false);
          this.$notify({
            type: "success",
            message: "Your password has been changed",
          });
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          this.$store.dispatch("loading", false);
          this.$notify({
            type: "danger",
            message: "Your link has expired, please request another link",
          });
          this.validResetToken = false;
          this.showForm = false;
        });
    },
  },
  mounted() {
    this.token = this.$route.params.token;
    this.$store.dispatch("loading", true);
    ApiService.authApi
      .passResetTokenCheck({ token: this.token })
      .then((resp) => {
        this.validResetToken = true;
        this.showForm = true;
      })
      .catch(() => {
        this.validResetToken = false;
      })
      .finally(() => {
        this.$store.dispatch("loading", false);
      });
  },
};
</script>
<style scoped>
#header {
  padding: 12px;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  background: url(~@/assets/header1.png);
}

header img {
  height: 50px;
}

#changePassword {
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 25px #cdcdcd;
  border-radius: 10px;
}

label {
  text-align: left;
}

.marginTop5 {
  text-align: left;
}

div.passForgotView {
  background-image: url(~@/assets/bg_almost_white.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

div.passForgotView::before {
  opacity: 0.4;
}

.van-field {
  border: 1px solid #e2e2e2;
  /* box-shadow: 0px 0px 3px #bbb; */
}
</style>
