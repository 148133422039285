<style scoped>
.van-radio-group i {
  width: 15px;
  font-size: 24px;
  color: #bdbdbd;
  margin-top: -2px;
  padding: 0px 1px;
}
.lgreenColor {
  color: #9cc200 !important;
}
.fa-circle-dot {
  font-size: 15px !important;
}
</style>
<template>
  <div
    id="registrationMiWrapper"
    v-if="codeStatus == true || codeStatus === false"
  >
    <div v-if="codeStatus == false">
      <h2>Wrong code</h2>
    </div>
    <div class="textCenter" style="min-height: 80px">
      <MiFlowHeader
        v-if="schoolInfo"
        :school_name="schoolInfo.name"
        :logo="schoolInfo.logo"
      />
    </div>
    <h2 style="font-size: 48px !important" class="text-center">
      Welcome<br />to HomeRun
    </h2>
    <div
      id="flexDiv"
      class="clearfix"
      style="min-height: 400px"
      v-if="codeStatus == true"
    >
      <!-- <h2 data-cy="title">{{ schoolInfo.name || "-" }}</h2> -->
      <transition name="fade2" mode="out-in">
        <div
          data-cy="accountOptions"
          style="min-height: 300px"
          v-if="checkForExistingAccount"
        >
          <p>Please select an option</p>
          <div class="row">
            <div class="col-xs-12">
              <van-button
                type="info"
                data-cy="new_account_btn"
                name="button"
                class="lightBlueBtn"
                @click="changeState()"
                style="width: 300px"
                >I'm new to HomeRun</van-button
              >
            </div>
            <div class="col-xs-12">
              <van-button
                type="info"
                data-cy="i_have_account_btn"
                name="button"
                class="lightGreenBtn"
                style="width: 300px"
                @click="redirectToLogin()"
                >I already have a HomeRun Account</van-button
              >
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade2" mode="out-in">
        <div
          v-if="
            schoolSelecionRequired &&
            checkForExistingAccount == false &&
            finishedTransition == true
          "
        >
          <p style="font-size: 18px" class="colorBlack">
            Mani has not provided any text so this will do.
          </p>
          <div
            style="padding-left: 100px; margin-top: 40px; margin-bottom: 40px"
          >
            <van-radio-group
              v-model="school_index_selected"
              data-cy="school_selection"
            >
              <van-radio
                :key="school.id"
                :name="index"
                style="margin-bottom: 15px"
                v-for="(school, index) in schools"
              >
                {{ school.name }}
                <template #icon="props">
                  <i
                    :class="
                      props.checked
                        ? 'fas fa-circle-dot fa-sm lgreenColor'
                        : 'fa-regular fa-circle-small'
                    "
                  ></i>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
          <div class="row flowBottomButtonDiv" style="margin-top: 30px">
            <div class="col-xs-12">
              <!-- <van-button type="info" @click="$router.go(-1)" plain>Go Back</van-button> -->
              <van-button
                type="info"
                name="button"
                data-cy="submit"
                class="blackBtn"
                @click="selectSchool"
                :disabled="school_index_selected == null"
                >Continue</van-button
              >
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade2" mode="out-in">
        <div
          v-if="
            selectRoleRequired &&
            !schoolSelecionRequired &&
            finishedTransition == true
          "
        >
          <p style="font-size: 18px" class="colorBlack">
            To get started, please select<br />
            your role at the school.
          </p>
          <div
            style="padding-left: 100px; margin-top: 40px; margin-bottom: 40px"
          >
            <van-radio-group v-model="accountType">
              <van-radio
                name="parent"
                data-cy="parent"
                style="margin-bottom: 15px"
              >
                Parent
                <template #icon="props">
                  <i
                    :class="
                      props.checked
                        ? 'fas fa-circle-dot fa-sm lgreenColor'
                        : 'fa-regular fa-circle-small'
                    "
                  ></i>
                </template>
              </van-radio>
              <van-radio
                name="staff"
                data-cy="staff"
                style="margin-bottom: 15px"
              >
                Staff
                <template #icon="props">
                  <i
                    :class="
                      props.checked
                        ? 'fas fa-circle-dot fa-sm lgreenColor'
                        : 'fa-regular fa-circle-small'
                    "
                  ></i>
                </template>
              </van-radio>
              <van-radio name="both" data-cy="both" style="margin-bottom: 15px">
                Both
                <template #icon="props">
                  <i
                    :class="
                      props.checked
                        ? 'fas fa-circle-dot fa-sm lgreenColor'
                        : 'fa-regular fa-circle-small'
                    "
                  ></i>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
          <p class="mialert lightGray">
            If your children have not yet started at the school, please still
            select <b>Parent</b>.
          </p>
          <div class="row flowBottomButtonDiv" style="margin-top: 30px">
            <div class="col-xs-12">
              <!-- <van-button type="info" @click="$router.go(-1)" plain>Go Back</van-button> -->
              <van-button
                type="info"
                name="button"
                data-cy="submit"
                class="blackBtn"
                @click="submit()"
                :disabled="!accountType"
                >Continue</van-button
              >
            </div>
          </div>
        </div>
      </transition>
      <div id="bottomLogo" class="text-center">
        <img
          src="@/assets/wireframe_logo.svg"
          style="vertical-align: baseline"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import MiFlowHeader from "@/components/registration_mi/MiFlowHeader.vue";

export default {
  name: "startingPage",
  components: {
    MiFlowHeader,
    // Header,
    // ModalHeader,
  },
  data() {
    return {
      schoolName: "Test school",
      accountType: "",
      logo: "https://home-run-dev.s3.eu-west-2.amazonaws.com/council/schools/photo/300/school_1564407035.png",
      codeStatus: null,
      schoolInfo: {
        name: "",
        logo: "",
      },
      schools: [],
      schoolSelecionRequired: null,
      checkForExistingAccount: true,
      selectRoleRequired: false,
      school_index_selected: null,
      finishedTransition: true,
    };
  },
  computed: {
    loggedIn() {
      if (!localStorage.token) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    submit() {
      this.$router.push("/registration_mi/email/" + this.accountType);
      sessionStorage.setItem("accType", this.accountType);
    },
    changeState() {
      this.checkForExistingAccount = false;
      this.finishedTransition = false;
      setTimeout(() => {
        this.selectRoleRequired = true;
        this.finishedTransition = true;
      }, 400);
    },
    redirectToLogin() {
      sessionStorage.setItem("redirect", "registration_mi");
      this.$router.push("/login");
    },
    getSchoolInfo(code) {
      ApiService.generalApi
        .getSchoolInfoWithCode(code)
        .then((resp) => {
          const data = resp.data;
          this.codeStatus = true;
          sessionStorage.setItem("schoolCode", code);
          sessionStorage.setItem("main_school_id", data.main_school_id);
          if (data.multiple_schools == false) {
            this.schoolInfo = data;
            this.schoolSelecionRequired = false;
            sessionStorage.setItem("logo", data.logo);
            sessionStorage.setItem("school_id", data.main_school_id);
            sessionStorage.setItem("schoolName", data.name);
          } else if (data.multiple_schools == true) {
            this.schoolSelecionRequired = true;
            this.selectRoleRequired = false;
            this.schools = data.schools;
          }
        })
        .catch((e) => {
          this.codeStatus = false;
        });
    },
    selectSchool() {
      const school = this.schools[this.school_index_selected];
      sessionStorage.setItem("school_id", school.id);
      sessionStorage.setItem("schoolName", school.name);
      sessionStorage.setItem("logo", school.logo_url);
      this.finishedTransition = false;
      this.schoolSelecionRequired = false;
      setTimeout(() => {
        this.finishedTransition = true;
        this.selectRoleRequired = true;
      }, 400);
    },
  },
  mounted() {
    const schoolCode = this.$route.params.schoolCode;
    this.getSchoolInfo(schoolCode);
    if (this.loggedIn) {
      this.checkForExistingAccount = false;
      this.selectRoleRequired = true;
      ApiService.userApi.getAccountStatus().then((response) => {
        if (response.data.missing == "address") {
          this.$router.push({ name: "details" });
        } else if (response.data.missing == "children") {
          this.$router.push({ name: "flowChildren" });
        } else if (response.data.missing == "journeys") {
          this.$router.push({ name: "journeys" });
        } else if (response.data.missing == "verification") {
          this.$router.push({ name: "verification" });
        } else if (response.data.missing == "school") {
          this.$router.push({ name: "flowSchools" });
        }
      });
    }
  },
};
</script>
