<style lang="scss">
@import "./stylesheets/Home.scss";

.homefeed {
  margin: -20px 30px 0;
}
</style>
<template>
  <div class="homefeed row relative px-4 pb-16 md:pb-0 pt-0 font-rubik">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      loading-text="Loading..."
      loosing-text="Pull to refresh"
      pulling-text="Pull to refresh"
    >
      <div class="feed-top-block">
        <vue-position-sticky :offsetTop="native ? 0 : 38">
          <FeedFilterAndCreation
            v-show="$route.name == 'home'"
            @openCreatePostModal="showCreateNewPostModal"
            @openFilterModal="showFilterFeedModal"
            :data="filterData"
            :currentStats="currentStats"
            data-cy="feed_filter_and_creation_div"
          />
          <button
            class="refresh-button invisible md:visible"
            @click="onRefresh"
            data-cy="refresh_feed_btn"
          >
            <i class="fas fa-sync-alt text-grey hover:text-gray-300"></i>
          </button>
        </vue-position-sticky>
      </div>

      <div
        id="downloadApp"
        class="mx-2 lg:mx-0 my-3 mb-1 md:mb-3 text-left"
        v-if="showDownloadBanner && native == false"
        data-cy="show_download_hr_app"
      >
        <div class="blockTitle ml-3.5" style="line-height: 25px">
          <span style="vertical-align: bottom" class="text-[17px]"
            >Download the HomeRun App! </span
          ><span
            class="pull-right fas fa-times closeNotice text-2xl -mt-2"
            @click="hideDownloadBanner()"
            data-cy="close_download_app"
            style="color: white"
          ></span>
        </div>
        <div>
          <p class="text-sm ml-6 mr-6">
            Lots of features incl. '<strong>View my Child's Journey</strong>'
            only available on the <strong>FREE</strong> Smartphone App!
          </p>
          <div class="mt-2 text-left ml-6">
            <a
              href="https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8"
              target="_blank"
              ><img
                src="https://s3-eu-west-1.amazonaws.com/home-run/other/apple-store.png"
                alt=""
                style="height: 35px; margin-right: 7px" /></a
            ><a
              href="https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&amp;hl=en_GB"
              target="_blank"
              ><img
                src="https://s3-eu-west-1.amazonaws.com/home-run/other/google-play.png"
                alt=""
                style="height: 35px; margin-left: 7px; margin-right: 0px"
            /></a>
            <img
              src="@/assets/logo-small.png"
              class="float-right rounded-full bg-black p-1 mr-2 mt-2"
              style="height: 30px; min-width: auto"
            />
          </div>
        </div>
      </div>
      <div
        v-if="noSchools === true"
        class="alert alert-mira"
        data-cy="no_school_registered_message"
      >
        No schools registered<br /><br />
        <van-button
          type="info"
          size="small"
          class="font-bold round25"
          @click="goto('/main/mySchools')"
          data-cy="redirect_to_my_schools"
          >Click here to add Schools</van-button
        >
      </div>
      <div
        :key="trackedChild.id"
        v-for="trackedChild in trackedChildren.liveChildren"
        class="block liveJourney"
        @click="redirectJourney(trackedChild.student_id)"
      >
        <div class="blockTitle">Live-journey</div>
        <div
          class="mapImage"
          style="position: relative; float: left; margin-right: 20px"
        >
          <img class="mapImage" :src="trackedChild.mapUrl" alt="" />
          <img
            :src="trackedChild.child.profile_photo_url"
            alt=""
            class="img-circle"
            style="
              position: absolute;
              z-index: 999;
              top: 25%;
              left: 25%;
              border: 1px solid #bbe;
            "
          />
        </div>
        <p>
          <span class="name fsize15">{{ trackedChild.child.first_name }}</span>
          is sharing a journey with you
        </p>

        <div style="clear: both; padding: 5px"></div>
      </div>
      <div class="clearfix"></div>
      <transition-group name="slide-fade" tag="div" data-cy="main_notices">
        <div :key="item.id" v-for="item in orderedNotices" class="px-2 md:px-0">
          <notice-main
            @noticeEvent="noticeEvent"
            :message="item"
            :user_id="currentUserId"
            :user_role_id="currentUserRoleId"
          />
        </div>
      </transition-group>
      <div v-if="showMoreButton" class="text-center" data-cy="show_more_btn">
        <button
          class="btn btn-link colorBlue rounded25"
          style="color: white"
          @click="showMore()"
        >
          <b>Load more</b>
          <br />
          <i class="fas fa-angle-double-down"></i>
        </button>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import NoticeMain from "@/components/NoticeMain";
import FeedFilterAndCreation from "@/components/FeedFilterAndCreation";
import SingleNotice from "@/components/modals/SingleNotice.vue";
import CreatePost from "@/components/modals/CreatePost.vue";
import PostComments from "@/components/modals/PostComments.vue";
import Conversation from "@/components/modals/Conversation.vue";
import FilterFeed from "@/components/modals/FilterFeed.vue";
import ApiService from "@/services/ApiService";
import HomeLogic from "./logic/home";
import { PullRefresh } from "vant";

export default {
  name: "home",
  components: {
    "notice-main": NoticeMain,
    FeedFilterAndCreation,
    "van-pull-refresh": PullRefresh,
    // infiniteLoading: InfiniteLoading
    // InfiniteLoading
  },
  data() {
    return {
      uniqueNotificationIds: [],
      filterData: {
        filterChoiceLabel: "",
        filterChoice: "all",
      },
      currentStats: {
        lastViewed: 0,
      },
      message: "",
      stickyOptions: {
        top: this.native ? 0 : 45,
        left: 0,
        right: 0,
      },
      feed_timestamp: 0,
      pollIdSelected: 0,
      currentPage: 0,
      showMoreButton: false,
      notifications: [],
      showDownloadBanner: true,
      isLoading: false,
      lastRefreshed: 0,
      trackedChildren: [],
      noSchools: false,
    };
  },
  watch: {
    "$route.query.refresh": function () {
      this.onRefresh();
    },
  },
  methods: {
    showPost(noticeId) {
      // this.$globalLoading = this.$loading.show();
      this.$store.dispatch("loading", true);
      let notice, comments;
      ApiService.generalApi
        .getSingleMessage(noticeId)
        .then((response) => {
          this.$store.dispatch("loading", false);
          let filtered = HomeLogic.singleNotice(response);
          notice = filtered.notice;
          if (notice.message[0] != "<") {
            notice.message = "<p>" + notice.message + "</p>";
          }
          comments = filtered.comments;
          this.$modal.show(
            SingleNotice,
            {
              noticeId,
              notice,
              comments,
            },
            {
              draggable: false,
              transition: "slide-in",
              scrollable: true,
              height: "auto",
              adaptive: false,
              pivotY: 0,
              reset: true,
              classes: "post-modal v--modal-box v--modal",
            }
          );
        })
        .catch(() => {});
    },
    showInfoNotice(id) {
      this.$store.dispatch("loading", true);
      let notice, comments, noticeId;
      ApiService.generalApi
        .getSingleInfoNotice(id)
        .then((response) => {
          this.$store.dispatch("loading", false);
          let filtered = HomeLogic.singleNotice(response);
          notice = filtered.notice;
          if (notice.message[0] != "<") {
            notice.message = "<p>" + notice.message + "</p>";
          }
          comments = [];
          this.$modal.show(
            SingleNotice,
            {
              noticeId,
              notice,
              comments,
            },
            {
              draggable: false,
              transition: "slide-in",
              scrollable: true,
              height: "auto",
              adaptive: false,
              pivotY: 0,
              reset: true,
              classes: "post-modal v--modal-box v--modal",
            }
          );
        })
        .catch(() => {});
    },
    openNativeWebModal(event) {
      if (this.$store.state.general.native) {
        event.preventDefault();
      }
    },
    showCreateNewPostModal() {
      this.$modal.show(
        CreatePost,
        {},
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          height: "auto",
          adaptive: false,
          pivotY: 0,
          clickToClose: false,
          classes: "post-modal v--modal-box v--modal",
          // width="100%" height="100%"
          // pivotY: 0
        },
        {
          refresh: () => {
            // this.feed_timestamp = 0;
            this.currentPage = 0;
            this.getUnreadMessages();
          },
        }
      );
    },
    showConversationModal(chatRoomId) {
      this.$modal.show(
        Conversation,
        {
          data: {},
          chat_room_id: chatRoomId,
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          width: "100%",
          height: "100%",
          adaptive: true,
          // pivotY: 0
        },
        {
          "before-close": (event) => {
            if (this.$store.state.general.should_refresh_messages == true) {
              this.$store.dispatch("setRefreshMessages", false);
              this.getUnreadMessages();
            }
          },
        }
      );
    },
    showCommentsModal(noticeId) {
      let notice, comments;
      this.$store.dispatch("loading", true);
      ApiService.generalApi
        .getSingleMessage(noticeId)
        .then((response) => {
          this.$store.dispatch("loading", false);
          let filtered = HomeLogic.singleNotice(response);
          notice = filtered.notice;
          comments = filtered.comments;
          this.$modal.show(
            PostComments,
            {
              noticeId,
              notice,
              comments,
            },
            {
              draggable: false,
              transition: "slide-in",
              scrollable: true,
              height: "auto",
              adaptive: true,
              pivotY: 0,
              reset: true,
              classes: "post-modal v--modal-box v--modal",
            }
          );
        })
        .catch(() => {});
    },
    showFilterFeedModal() {
      this.$modal.show(
        FilterFeed,
        {
          selection: {
            value: this.filterData.filterChoice,
            currentStats: this.currentStats,
          },
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          height: "auto",
          width: "280px",
        },
        {
          newFilterTypeSelected: (event) => {
            this.filterData.filterChoice = event.filterType;
            this.filterData.filterChoiceLabel = event.filterName;
            this.feed_timestamp = 0;
            this.currentPage = 0;
            this.getUnreadMessages();
            window.scrollTo(0, 0);
            console.log(this.filterData.filterChoice);
          },
        }
      );
    },
    getMessagesStats() {
      var dateTime = Date.now();
      var newTimestamp = Math.floor(dateTime / 1000);

      if (this.feed_timestamp == undefined) {
        this.feed_timestamp = 0;
      }

      if (parseInt(newTimestamp) - 300 > parseInt(this.feed_timestamp)) {
        this.feed_timestamp = newTimestamp;
      } else {
        // alert('yeahaa');
        return;
      }

      ApiService.generalApi
        .getMessagesStats(this.currentUserRoleId)
        .then((response) => {
          this.currentStats = response.data;
          if (
            this.currentStats.per_notice_type.length > 0 ||
            this.currentStats.per_type.length > 0
          ) {
            this.currentStats.unread = true;
          }
          this.currentStats.office = this.getSingleStat(
            "per_type",
            "user_type",
            2
          );
          this.currentStats.events = this.getSingleStat(
            "per_notice_type",
            "type",
            "event"
          );
          this.currentStats.teachers = this.getSingleStat(
            "per_type",
            "user_type",
            9
          );
          this.currentStats.head_teacher = this.getSingleStat(
            "per_type",
            "user_type",
            8
          );
          this.currentStats.pta_parents =
            this.getSingleStat("per_type", "user_type", 6) +
            this.getSingleStat("per_type", "user_type", 7);
          this.currentStats.polls = this.getSingleStat(
            "per_notice_type",
            "category",
            "poll"
          );
          this.currentStats.eco = this.getSingleStat(
            "per_notice_type",
            "category",
            "eco"
          );
          this.currentStats.pta = this.getSingleStat(
            "per_notice_type",
            "category",
            "pta"
          );
          this.currentStats.general = this.getSingleStat(
            "per_notice_type",
            "category",
            "general"
          );
          this.currentStats.travel = this.getSingleStat(
            "per_notice_type",
            "category",
            "travel"
          );
        });
    },
    getSingleStat(category, field, typeId) {
      var value = 0;

      for (var i = 0; i < this.currentStats[category].length; i++) {
        if (this.currentStats[category][i][field] == typeId) {
          value = this.currentStats[category][i].count;
        }
      }

      return value;
    },
    hideDownload() {
      localStorage.setItem("hidedownload", true);
      this.hideDownloadHomerun = true;
    },
    infiniteHandler($state) {
      this.showMore($state);
      setTimeout(() => {
        // $state.loaded();
      }, 300);
    },
    onRefresh() {
      const currentTime = Math.round(new Date().getTime() / 1000);

      if (currentTime - 30 < this.lastRefreshed) {
        this.isLoading = false;
        return true;
      }
      this.lastRefreshed = Math.round(new Date().getTime() / 1000) - 40;
      this.currentPage = 0;
      this.showMoreButton = false;
      this.getUnreadMessages();
      this.isLoading = false;
    },
    showMore() {
      if (
        this.loadingFeed ||
        this.notifications.length == 0 ||
        this.showMoreButton == false
      )
        return; //
      this.currentPage++;
      this.getUnreadMessages(true);
    },
    getUnreadMessages(addToFeed, $state) {
      // hideLoading
      // if (!hideLoading) {
      // }
      if (addToFeed !== true) {
        this.$store.dispatch("loading", true);
      }

      if (!addToFeed) {
        this.notifications = [];
      }
      // this.loadingFeed = true;
      if (!this.filterData.currentStats) {
        this.filterData.currentStats = {};
      }
      ApiService.generalApi
        .getUnreadMessages(
          this.currentPage,
          this.filterData.filterChoice,
          this.currentStats.lastViewed
        )
        .then((response) => {
          // setTimeout(() => {
          this.$store.dispatch("loading", false);
          // }, 5000);
          // this.$loading.hide();
          if (response.data.page) {
            this.currentPage = response.data.page;
          }

          if (response.data.notification.length > 19) {
            this.showMoreButton = true;
          } else {
            this.showMoreButton = false;
          }

          this.bucket_url = response.data.bucket_url;
          this.messages = response.data;
          for (
            var i = 0, len = response.data.notification.length;
            i < len;
            i++
          ) {
            response.data.notification[i].extras =
              response.data.notification[i].extras != ""
                ? JSON.parse(response.data.notification[i].extras)
                : [];
            if (
              this.currentStats &&
              this.currentStats.lastViewed <
                response.data.notification[i].created_timestamp
            ) {
              response.data.notification[i].is_new = 1;
            }
            if (response.data.bucket_url) {
              response.data.notification[i].bucket_url =
                response.data.bucket_url + "/";
            }
            if (response.data.notification[i].images) {
              var imagesAndPdfs =
                response.data.notification[i].images.split(",");
              var images = [];
              var pdfs = [];
              for (var y = 0; y < imagesAndPdfs.length; y++) {
                if (imagesAndPdfs[y].indexOf("pdf") !== -1) {
                  pdfs.push(imagesAndPdfs[y]);
                } else {
                  images.push(imagesAndPdfs[y]);
                }
              }

              response.data.notification[i].images = images;
              if (pdfs.length > 0) {
                response.data.notification[i].pdfs = pdfs;
              }
            }
            // response.data.notification[i].extras = {};
          }
          var _notifications = [];
          _notifications = response.data.notification;

          if (response.data.chats) {
            for (var g = 0, glen = response.data.chats.length; g < glen; g++) {
              response.data.chats[g].type = "chat";
              if (response.data.chats[g].is_staff) {
                response.data.chats[g].user_type = "staff";
              } else {
                response.data.chats[g].user_type = "user";
              }
              // response.data.chats[y].id = 'chat' + y;
              _notifications.push(response.data.chats[g]);
            }
          }
          if (addToFeed == true) {
            for (var m = 0; m < _notifications.length; m++) {
              if (
                this.uniqueNotificationIds.indexOf(_notifications[m].id) == -1
              ) {
                this.uniqueNotificationIds.push(_notifications[m].id);
                this.notifications.push(_notifications[m]);
              }
            }
          } else {
            this.uniqueNotificationIds = [];
            _notifications.map((obj) => {
              this.uniqueNotificationIds.push(obj.id);
            });
            this.notifications = _notifications;
            if (_notifications.length == 0) {
              _notifications.push({
                id: 0,
                type: "empty",
              });
            }
            this.refreshTrackedChildren();
          }

          if (response.data.notification.length > 19) {
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
          //
          this.loaded = 1;
          setTimeout(function () {
            this.loadingFeed = false;
          }, 400);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push({ name: "login" });
          }
          this.$store.dispatch("loading", false);
          this.showMoreButton = true;
          if (error.response.data.error == "No schools") {
            this.showMoreButton = false;
            this.noSchools = true;
          }
          this.loadingFeed = false;
          if (this.currentPage > 0) {
            this.currentPage--;
          }

          // $state.go('login');
          // if(error.status == -1){
          //   CommonDataService.logout();
          //   if(error.data.error == 'token_not_provided' || error.data.error == 'user_not_found' ){
          //     $state.go('login');
          //   }
          //
          //   loading(false);
          // }
        });
    },
    refreshTrackedChildren() {
      if (
        this.filterData.filterChoice != "all" &&
        this.filterData.filterChoice != ""
      ) {
        return;
      }
      ApiService.generalApi.getTrackedChildren().then((resp) => {
        this.trackedChildrenIds = [];
        resp.data.liveChildren.map((obj) => {
          obj.mapUrl =
            "https://maps.googleapis.com/maps/api/staticmap?center=" +
            obj.lat +
            "," +
            obj.long +
            "&zoom=15&size=400x250&key=AIzaSyBeguURx-JXrhMHtcTlTomH_Xmk_9YWkjc";
          this.trackedChildrenIds.push(obj.student_id);
        });
        this.trackedChildren = resp.data;

        this.childrenLastPositions = [];
        // Last Children positions
        if (this.trackedChildren.lastPositions) {
          for (var i = 0; i < resp.data.lastPositions.length; i++) {
            resp.data.lastPositions[i].type = "BG_JOURNEY";
            resp.data.lastPositions[i].id =
              "c" + resp.data.lastPositions[i].student_id;
            if (
              this.trackedChildrenIds.indexOf(
                resp.data.lastPositions[i].student_id
              ) == -1
            ) {
              this.childrenLastPositions.push(resp.data.lastPositions[i]);
            }
          }
        }

        // If array already created add to existing array
        if (this.notifications) {
          if (this.childrenLastPositions) {
            var tempArray = JSON.parse(JSON.stringify(this.notifications));
            for (
              var y = 0, ylen = this.childrenLastPositions.length;
              y < ylen;
              y++
            ) {
              // this.childrenLastPositions[y].created_timestamp += 3600;
              tempArray.push(this.childrenLastPositions[y]);
            }
            setTimeout(() => {
              this.notifications = [];
              this.notifications = tempArray;
            }, 400);
          }
        }
      });
    },
    noticeEvent(options) {
      if (options.action === "showNotice") {
        this.showPost(options.id);
      } else if (options.action === "showInfoNotice") {
        this.showInfoNotice(options.id);
      } else if (options.action === "showChat") {
        this.showConversationModal(options.chat_room_id);
      } else if (options.action === "hideNotice") {
        var currentIndex;
        for (var i = 0; i < this.notifications.length; ++i) {
          if (this.notifications[i].id == options.id) {
            currentIndex = i;
            break;
          }
        }
        this.notifications.splice(currentIndex, 1);
      } else if (options.action === "showComments") {
        this.showCommentsModal(options.id);
      }
      console.log(options);
    },
    hideDownloadBanner() {
      this.showDownloadBanner = false;
    },
    goto(route) {
      this.$router.push(route);
    },
    onscroll: async function () {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;

      if (bottomOfPage || pageHeight < visible) {
        this.showMore();
        console.log("At the bottom");
      }
    },
    redirectJourney() {
      if (this.$store.state.general.native == true) {
        window.postMessage("showJourneys", "*");
      } else {
        alert("This feature is only available on the smartphone app");
      }
    },
  },
  computed: {
    orderedNotices: function () {
      return [...this.notifications].sort(
        (a, b) => b.created_timestamp - a.created_timestamp
      );
    },
    native() {
      return this.$store.state.general.native;
    },
    currentUserId: function () {
      return this.$store.state.general.user_id;
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
    schools: function () {
      return this.$store.state.general.available_schools;
    },
  },
  created() {
    this.getMessagesStats();
    this.getUnreadMessages();
  },
  mounted() {
    window.addEventListener("scroll", this.onscroll);
    this.lastRefreshed = Math.round(new Date().getTime() / 1000);
    if (!this.$store.state.general.getYearLabels) {
      ApiService.generalApi.getYearLabels().then((resp) => {
        this.$store.dispatch("setYearLabels", resp.data);
      });
    }
    // Indicator.open();
    // this.targetElement = document.querySelector("#modals-container");
  },
  destroyed() {
    window.removeEventListener("scroll", this.onscroll);
  },
};
</script>
