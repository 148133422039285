<style lang="scss" scoped>
$parent-primary-color: #99cc00;
$staff-primary-color: #33cccc;

.menu {
  margin-bottom: 10px;
}
input[type="image"] {
  border: 3px dashed #999;
  cursor: move;
  display: block;
  font-size: 0px;
  filter: alpha(opacity=0);
  min-height: 160px;
  min-width: 300px;
  opacity: 1;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  background: transparent;
  z-index: -99999999999999;
}
img.avatarImage {
  width: 60px;
  height: 60px;
  padding: 2px;
  border-radius: 30px;
}
img.avatarImage.active {
  border: 1px solid #21acf5;
}

.genderButton {
  background: $parent-primary-color;
  color: white;
  font-size: 13px;
  margin: 20px 10px;
  padding: 10px 20px;
  border-radius: 50px;
  border: 0;
}

.genderButton.active {
  background: #009900;
}

.avatar-gender-selection {
  margin-top: 10px;
  text-align: center;

  btn {
    margin: 25px 0;
  }
}

.btn-custom-file-upload {
  display: inline-flex;
  align-items: center;
  margin: 15px 0;

  i {
    margin-right: 10px;
  }
}

.btn-submit {
  margin: 20px;
  float: right;
}
</style>
<template>
  <div :class="options.className">
    <div v-if="options.profilePicture" data-cy="avatar_uploader_modal">
      <ModalHeader
        flow
        title="Select profile picture"
        @hideModal="$emit('close')"
      />
      <div class="menu clearfix col-xs-12 text-center">
        <div class>
          <div class="col-xs-12 nopadding">
            <button
              class="btn btn-black"
              @click="setType('upload')"
              data-cy="choosePictureButton"
              :class="{ active: type == 'upload' }"
              v-if="type == 'avatarSelection' || type === null"
            >
              Upload a Photo <span v-if="type != null">Instead</span>
            </button>
          </div>
          <div class="col-xs-12 text-center" v-if="type === null">
            <div class="colorGrey" style="margin: 10px">or</div>
          </div>
          <div
            class="col-xs-12 nopadding"
            v-if="type == 'upload' || type === null"
          >
            <button
              class="btn btn-grey"
              @click="setType('avatarSelection')"
              data-cy="chooseAvatarButton"
              :class="{ active: type == 'avatarSelection' }"
            >
              Choose an Avatar <span v-if="type != null">Instead</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="type === 'avatarSelection'" class="avatar-gender-selection">
        <button
          @click="selectGender('f')"
          data-cy="femaleButton"
          :class="{ active: genderPrefix == 'f' }"
          class="btn genderButton"
        >
          Female avatars
        </button>
        <button
          @click="selectGender('m')"
          data-cy="maleButton"
          :class="{ active: genderPrefix == 'm' }"
          class="btn genderButton"
        >
          Male avatars
        </button>
        <div
          class="section avatars"
          data-cy="avatars_section"
          v-if="genderPrefix"
          style="text-align: center"
        >
          <!-- <spn>n</spn> -->
          <img
            :key="$index"
            v-for="(n, $index) in numberRange"
            @click="selectAvatar(genderPrefix, n)"
            class="avatarImage"
            :class="{
              active:
                avatarSelection.gender === genderPrefix &&
                avatarSelection.id === n,
            }"
            :src="parents_avatars_url + genderPrefix + n + '.png'"
            alt
          />
        </div>
      </div>
      <div class="text-center" v-if="type === 'upload'" style="clear: both">
        <div v-if="!imgSrc" class="mialert lightGreen">
          Upload an image below. <br />Supported image types: jpg, png, bmp, gif
        </div>
        <label
          for="imgInp"
          v-if="!imgSrc"
          class="btn-custom-file-upload btn greyBtn van-button van-button--info van-button--normal van-button--plain"
        >
          <i class="fas fa-upload"></i> Choose image
        </label>

        <input
          id="imgInp"
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
          style="display: none"
        />
        <div class="form-action-row" v-if="imgSrc" style="position: relative">
          <!-- <button class="btn btn-grey" @click="$emit('close')" data-cy="close">
            Cancel
          </button> -->
          <label
            for="imgInp"
            class="btn-custom-file-upload btn greyBtn van-button van-button--info van-button--normal van-button--plain"
          >
            <i class="fas fa-upload"></i> Choose image
          </label>
          <button
            class="btn btn-black btn-submit"
            @click="uploadPicture"
            data-cy="submit_bottom"
          >
            Save
          </button>
        </div>
        <div style="max-width: 230px; margin: auto">
          <vue-cropper
            ref="cropper"
            v-show="imgSrc"
            :src="imgSrc"
            :aspect-ratio="1 / 1"
            :cropmove="cropImage"
            alt=" "
          ></vue-cropper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalHeader from "@/components/ModalHeader";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import dbConnection from "@/services/dbConnection";
import ApiService from "@/services/ApiService";

export default {
  name: "uploader",
  components: {
    ModalHeader,
    VueCropper,
  },
  props: {
    options: Object,
  },
  data() {
    return {
      type: "avatarSelection",
      genderPrefix: "f",
      parents_avatars_url:
        "https://s3-eu-west-1.amazonaws.com/home-run/parent_avatars/",
      numberRange: [],
      avatarSelection: {},
      imgSrc: null,
    };
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          this.$forceUpdate();
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setType(type) {
      this.type = type;
    },
    selectGender(genderPrefix) {
      this.genderPrefix = genderPrefix;
    },
    uploadPicture() {
      this.$store.dispatch("loading", true);
      this.$refs.cropper.getCroppedCanvas({ width: 300, height: 300 }).toBlob(
        (blob) => {
          const formData = new FormData();
          formData.append("blobImage", blob /*, 'example.png' */);
          dbConnection
            .post("api/uploadFile?blobImage", formData)
            .then((response) => {
              this.$parent.$emit("newImage", {
                url: response.data.url,
              });
              this.$store.dispatch("loading", false);
              this.$emit("close");
            })
            .catch(function (err) {
              this.$store.dispatch("loading", false);
              alert(JSON.stringify(err));
            });
        } /*, 'image/png' */
      );
    },
    selectAvatar(genderPrefix, id) {
      this.avatarSelection = {
        gender: genderPrefix,
        id,
        url: this.parents_avatars_url,
      };
      this.$parent.$emit("newAvatar", {
        gender: genderPrefix,
        id,
        url: this.parents_avatars_url,
      });

      var payload = {
        avatarId: id,
        gender: genderPrefix,
      };
      ApiService.userApi.changeParentPicture(payload);
      this.$emit("close");
    },
  },
  mounted() {
    for (var i = 1; i < 51; i++) {
      this.numberRange.push(i);
    }
  },
};
</script>
