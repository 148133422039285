<style lang="scss">
#mobileHeader {
  display: none !important;
}

.conversation-modal {
  @apply z-chat #{!important};

  .v--modal-box {
    width: 95% !important;
  }

  .v--modal-box-overlay {
    @apply pb-20 lg:pb-2;
  }
}

#mainContent {
  height: auto;
  min-height: 100%;
}
</style>
<template>
  <div
    id="mainContent"
    class="clearfix row font-rubik"
    style="min-height: calc(100vh - 100px)"
  >
    <div class="mt-1 md:mt-2 pt-0 pb-2 px-2 md:px-3">
      <div class="text-center">
        <div class="text-xl text-black">School Messenger</div>
        <div class="mt-2 mb-0 md:mb-3">
          <button
            class="btn bg-pink-dark text-sm text-white rounded-full py-1.5 px-3 mb-2"
            @click="redirectTo('searchParents/parent')"
          >
            Parent Directory
            <inline-svg
              :src="require('@/assets/notice/address-book.svg')"
              class="inline-block ml-2"
            />
          </button>
          <button
            v-if="roleType == 'STAFF'"
            class="btn bg-purple-light rounded-full text-white text-sm px-2 py-1.5 ml-4"
            @click="redirectTo('searchParents/staff')"
          >
            <img
              src="@/assets/bigMenu/network.png"
              class="inline-block h-4 mr-2"
              alt
            />
            Staff Directory
          </button>
        </div>
      </div>
      <div
        v-if="chats.length == 0 && loaded"
        style="margin-top: 15px"
        class="alert alert-info"
      >
        <span>
          <div class="alert alert-mira">
            <p class="font-bold">
              You have no messages. Use the parent or staff directory buttons
              above to find users on your school network and start a
              conversation.
            </p>
          </div>
        </span>
      </div>
      <!-- Message block -->
      <transition-group name="slide-fade" tag="div" class>
        <div
          class="ignoreLinks my-0"
          :key="singleChat.id"
          v-for="singleChat in sortedChats"
        >
          <notice-main
            :message="singleChat"
            :user_id="currentUserId"
            :user_role_id="currentUserRoleId"
            @noticeEvent="showConversation"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import NoticeMain from "@/components/NoticeMain";
import ApiService from "@/services/ApiService";
import Conversation from "@/components/modals/Conversation.vue";

export default {
  name: "myChats",
  components: {
    NoticeMain,
  },
  data() {
    return {
      chats: [],
      // roleType: false,
      bucket_url: "",
      user_id: 0,
      loaded: false,
    };
  },
  methods: {
    getUnreadMessages() {
      this.$store.dispatch("loading", true);
      ApiService.conversationsApi
        .getAllChatRooms()
        .then((response) => {
          this.bucket_url = response.data.bucket_url;
          this.$store.dispatch("loading", false);
          this.loaded = true;
          if (response.data.chats) {
            this.chats = [];
            // vm.chats = response.data.chats
            for (var y = 0, ylen = response.data.chats.length; y < ylen; y++) {
              response.data.chats[y].type = "chat";
              if (response.data.chats[y].is_staff) {
                response.data.chats[y].user_type = "staff";
              } else {
                response.data.chats[y].user_type = "user";
              }
              this.chats.push(response.data.chats[y]);
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("loading", false);
          this.loaded = true;
        });
    },
    showConversation(options) {
      this.showConversationModal(options.chat_room_id);
    },
    showConversationModal(chat_room_id) {
      this.$modal.show(
        Conversation,
        {
          data: {},
          chat_room_id: chat_room_id,
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          width: "100%",
          height: "100%",
          adaptive: true,
          class: "conversation-modal",
          // pivotY: 0
        },
        {
          "before-close": () => {
            this.getUnreadMessages();
          },
        }
      );
    },
    redirectTo(path) {
      this.$router.push(path);
    },
  },
  computed: {
    roleType: function () {
      return this.$store.state.general.roles.find(
        (role) => this.currentUserRoleId == role.user_role_id
      )?.slug;
    },
    currentUserId: function () {
      return this.$store.state.general.user_id;
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
    sortedChats: function () {
      return [...this.chats].sort(
        (a, b) => b.created_timestamp - a.created_timestamp
      );
    },
  },
  created() {
    this.user_id = parseInt(this.$store.state.general.user_id);
    this.getUnreadMessages();
  },
};
</script>
