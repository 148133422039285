<template>
  <div class="bg">
    <div class="loginView" style="overflow: hidden" :style="mainStyleObject">
      <div class="text-center">
        <img
          src="@/assets/logo-main.png"
          alt
          style="height: 80px; margin-bottom: 5px; margin-top: 10px"
        />
      </div>
      <h1
        style="text-align: center; font-size: 14px !important; margin-top: 3px"
      >
        Your school community and journeys platform
      </h1>
      <form
        id="loginForm"
        name="loginForm"
        :style="styleObject"
        @submit.prevent="submit"
      >
        <div>
          <div class="col-xs-12 text-center">
            <div
              v-if="registrationInProgress && school_name"
              class="alert alert-info"
              style="
                background: white;
                color: #e61180;
                font-family: 'Palanquin Dark';
                margin-bottom: 15px;
                box-shadow: 2px 2px 2px #ccc;
                border-radius: 15px;
              "
            >
              Log in to your existing HomeRun account to add&nbsp;
              <span style="color: black; font-weight: bold">{{
                school_name
              }}</span
              >&nbsp;to your profile
            </div>
          </div>
          <div class="text-center">
            <p style="padding-bottom: 0px">
              Please enter your registered
              <br />email & password
            </p>
          </div>
          <div class="col-xs-12" style="margin-bottom: 10px">
            <van-field
              :label="emailLabel"
              placeholder="Email"
              data-cy="email_input"
              type="text"
              name="email"
              required
              v-model="email"
            ></van-field>
            <div class="error" v-if="checkForErrors && !$v.email.required">
              Field is required
            </div>
            <div class="error" v-if="checkForErrors && !$v.email.minLength">
              Name must have at least
              {{ $v.email.$params.minLength.min }} letters.
            </div>
          </div>
          <div class="col-xs-12">
            <van-field
              :label="passwordLabel"
              required
              name="password"
              placeholder="Password"
              data-cy="password_input"
              type="password"
              v-model="password"
            ></van-field>
            <div class="error" v-if="checkForErrors && !$v.password.required">
              Field is required
            </div>
          </div>
          <div
            class="form-group text-center"
            style="padding-top: 30px; padding-bottom: 0px; clear: both"
          >
            <van-button
              type="info"
              :disabled="fieldsAreFilled()"
              :loading="loading"
              loading-text="Login"
              data-cy="submit_btn"
              >Login</van-button
            >
          </div>
          <div class="form-group text-center">
            <van-button
              @click.prevent="forgotPassword"
              data-cy="forgot_password_btn"
              type="info"
              class="round25"
              plain
              size="small"
              style="background: white !important"
              >Forgot password ?</van-button
            >
          </div>
        </div>
      </form>
      <div class="whiteBlock">
        <div class="row text-center padding30">
          <span>
            Having issues logging in?
            <br />Contact
            <a href="mailto:info@homerun-app.com">info@homerun-app.com</a>
          </span>
          <br />
          <span style="display: block; margin-top: 10px"
            >Download the app to get the full HomeRun service</span
          >
          <div class="col-md-12" style="margin-top: 15px">
            <div class="col-xs-6">
              <a
                href="https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8"
                target="_blank"
              >
                <img
                  src="@/assets/apple.png"
                  height="40px"
                  alt
                  style="margin-top: 10px; height: 40px"
                />
              </a>
            </div>
            <div class="col-xs-6">
              <a
                href="https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&hl=en_GB"
                target="_blank"
              >
                <img
                  src="@/assets/android.png"
                  height="40px"
                  alt
                  style="margin-top: 10px; height: 40px"
                />
              </a>
            </div>
          </div>
          <div class="col-xs-12 text-center" style="padding-top: 8px">
            <a href="https://www.homerun-app.com/privacy" target="_blank"
              >Privacy Policy</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "login",
  components: {},
  data() {
    return {
      checkForErrors: false,
      registrationInProgress: false,
      password: "",
      email: "",
      emailLabel: "",
      passwordLabel: "",
      styleObject: {},
      mainStyleObject: {},
    };
  },
  computed: {
    loading: function () {
      return this.$store.state.general.loading;
    },
  },
  validations: {
    email: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
    },
  },
  methods: {
    submit: function () {
      this.$store.dispatch("loading", false);
      this.checkForErrors = true;
      if (!this.$v.$invalid) {
        this.$store.dispatch("attemptLogin", {
          password: this.password,
          email: this.email,
          schoolCode: this.$store.state.general.schoolCode,
          temp_login_code: this.tempCode,
        });
      }
    },
    forgotPassword: function () {
      this.$store.dispatch("loading", false);
      this.$router.push({
        name: "forgotPassword",
        params: { email: this.email },
      });
    },
    redirectToLoginWithCode: function () {
      this.$router.push({
        name: "loginWithCode",
      });
    },
    fieldsAreFilled: function () {
      return this.email === "" || this.password === "";
    },
  },
  beforeMount() {
    window.postMessage("logout", "*");
  },
  mounted() {
    this.$store.dispatch("loading", false);
    this.styleObject = {
      marginTop: "30px",
    };
    this.mainStyleObject = {
      height: window.innerHeight - 30 + "px",
    };

    if (this.$route.query) {
      this.registrationInProgress = true;
      this.school_name = this.$route.query.school_name;
    }
    if (window.innerWidth > 500) {
      this.emailLabel = "Email";
      this.passwordLabel = "Password";
    }
    if (this.$route.params.email) {
      this.email = this.$route.query.email;
    }
    if (this.$route.query.exists) {
      this.$notify({
        message:
          "This registration link has already been used, please sign into your account here",
        type: "primary",
        duration: 5000,
      });
    }
  },
};
</script>
<style scoped>
#header {
  padding: 12px;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  background: url(~@/assets/header1.png);
}

header img {
  height: 50px;
}

#loginForm {
  max-width: 700px;
  margin: auto;
  margin-top: 10px;
}

div.loginView {
  margin: 15px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 25px #cdcdcd;
  padding: 5px;
  max-width: 400px;
  z-index: 999;
  max-height: 630px;
  border-radius: 10px;
  /* margin: auto; */
}

div.mint-cell-title {
}

@media screen and (min-width: 430px) {
  div.loginView {
    margin-left: auto;
    margin-right: auto;
  }
}

div.bg {
  background-image: url(~@/assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.van-field {
  border: 1px solid #e2e2e2;
  /* box-shadow: 0px 0px 3px #bbb; */
}
</style>
