import Vue from "vue";
import Vuex from "vuex";
import general from "./general";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  // namespaced: true,
  strict: true,
  modules: {
    general,
  },
  plugins: [createPersistedState()],
});
