<template>
  <div></div>
</template>

<script>
export default {
  name: "logout",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {
    this.$store.dispatch("logout");
    this.$router.replace("login");
    this.$notify({
      type: "success",
      message: "You have been logged out",
    });
  },
};
</script>
