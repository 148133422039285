<style lang="scss" scoped>
$primary-color: #21acf5;
</style>
<template>
  <div class="clearfix">
    <MyChildren origin="flow" @redirect="redirectionEvent" />
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
// import Header from "@/components/Header";
import MyChildren from "@/views/main/MyChildren";

export default {
  name: "flowChildren",
  components: {
    // Header,
    MyChildren,
  },
  data() {
    return {
      selection: {},
    };
  },
  methods: {
    setCategory(category) {
      this.selection.category = category;
      this.$forceUpdate();
    },
    redirectionEvent(routeName) {
      this.$router.push({ name: routeName });
    },
  },
  mounted() {
    this.$emit("progress-step", 3);
  },
};
</script>
