const LOGGED_IN = "LOGGED_IN";
const UPDATE_USER_INFO = "UPDATE_USER_INFO";
const LOADING = "LOADING";
const REFRESH_MESSAGES = "REFRESH_MESSAGES";
const PARENT2_TOKEN = "PARENT2_TOKEN";
const SCHOOL_CODE = "SCHOOL_CODE";
const LOGGED_OUT = "LOGGED_OUT";
const SET_TOKEN = "SET_TOKEN";
const SET_NEW_TERM = "SET_NEW_TERM";
const MOBILE_LOGIN = "MOBILE_LOGIN";
const SET_AS_NATIVE = "SET_AS_NATIVE";
const UPDATE_AVATAR = "UPDATE_AVATAR";
const UPDATE_JOURNEY_PREFERENCE = "UPDATE_JOURNEY_PREFERENCE";
const UPDATE_USER_ROLE_SETTING = "UPDATE_USER_ROLE_SETTING";
const TERM_UPDATED = "TERM_UPDATED";
const UPDATE_FIELD = "UPDATE_FIELD";
const UPDATE_SCHOOLRUN_CACHE = "UPDATE_SCHOOLRUN_CACHE";
const SET_YEAR_LABELS = "SET_YEAR_LABELS";
const SET_SHOW_CONGRATS_PAGE = "SET_SHOW_CONGRATS_PAGE";
const AVATARS_URL = "https://s3-eu-west-1.amazonaws.com/home-run/";
const SET_USER_ROLE_ID = "https://s3-eu-west-1.amazonaws.com/home-run/";

export {
  LOGGED_IN,
  UPDATE_USER_INFO,
  LOADING,
  REFRESH_MESSAGES,
  PARENT2_TOKEN,
  SCHOOL_CODE,
  LOGGED_OUT,
  SET_TOKEN,
  SET_NEW_TERM,
  MOBILE_LOGIN,
  SET_AS_NATIVE,
  UPDATE_AVATAR,
  UPDATE_JOURNEY_PREFERENCE,
  UPDATE_USER_ROLE_SETTING,
  TERM_UPDATED,
  UPDATE_FIELD,
  UPDATE_SCHOOLRUN_CACHE,
  SET_YEAR_LABELS,
  SET_SHOW_CONGRATS_PAGE,
  AVATARS_URL,
  SET_USER_ROLE_ID,
};
