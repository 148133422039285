<template>
  <div id="filterAndCreation" class="filter-creation">
    <button
      class="btn-black btn-thin font-medium btn-create"
      @click="openNewEditPostModal"
      data-cy="create_post_btn"
      style="background: #ff3399 !important; border-color: #ff3399"
    >
      <div class="flex-1 pl-2">
        <span>Create a Post</span>
      </div>
      <inline-svg
        :src="require('@/assets/notice/create-post.svg')"
        class="inline fill-white"
      />
    </button>
    <button
      class="btn-white btn-thin font-medium flex items-center !px-5 bg-white"
      id="filter"
      @click="openFilterModal"
      data-cy="filter_dropdown_button"
    >
      <span>{{ data.filterChoiceLabel || "Filter By" }}</span>
    </button>
  </div>
</template>
<style lang="scss" scoped>
.filter {
  /* position: -webkit-sticky; */
  /* position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  top: 55px;*/
  padding: 3px;
  background: rgba(255, 255, 255, 0.7);
  min-width: 360px;
  margin-bottom: 3px;
  /* margin-top: 3px; */
  /* width: 100%; */
}

#filterAndCreation div.col-md-8 {
  width: auto;
}
#newindicator {
  width: 19px;
  height: 19px;
  display: inline-block;
  position: absolute;
  border: 2px solid;
  background: #e61380;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  top: -3px;
  right: -10px;
}

.filter-creation {
  @apply flex items-center justify-center bg-white pt-3 pb-2 mt-1;
  @apply rounded-lg py-1.5 px-2 sm:px-20;
  background: rgb(255 255 255 / 0.7);

  @media screen and (max-width: 767px) {
    z-index: 999;
    position: relative;
  }
}

.vue-position-sticky .filter-creation {
  margin-top: -5px;
  border-radius: 0 0 10px 10px;

  @media screen and (max-width: 767px) {
    padding-top: 13px;
  }
}

.btn-create {
  @apply flex items-center font-medium pl-0 pr-3 mr-2;

  flex: 2;
  @media screen and (max-width: 767px) {
    flex: 2;
  }
}
</style>
<script>
export default {
  name: "feedFilterCreation",
  props: {
    data: Object,
    currentStats: Object,
  },
  methods: {
    openNewEditPostModal() {
      this.$emit("openCreatePostModal");
    },
    openFilterModal() {
      this.$emit("openFilterModal");
    },
  },
};
</script>
