<style lang="scss" scoped></style>
<template>
  <div id="wrapper">
    <div class="row">
      <form @submit.prevent>
        <div class="col-xs-12">
          <div v-if="edit === false && currentAddress" style="text-align: left">
            {{ currentAddress.address_line_1 }}
            <i
              class="fa-sharp fa-solid fa-circle-check"
              style="color: #03c200; padding-left: 10px"
            ></i>
          </div>
          <van-button
            @click="enableEditing"
            v-if="postcode && edit == false"
            type="info"
            size="small"
            plain
            style="margin-bottom: 5px; float: left"
            >Select New Home Address</van-button
          >
          <Autocomplete
            v-if="!postcode || edit == true"
            class="introInput form-control"
            @place_changed="setPlace"
            :placeholder="placeholder"
            :options="{
              componentRestrictions: {
                country: internationalSearch ? [] : ['uk'],
              },
            }"
          ></Autocomplete>
        </div>
        <div
          v-if="(!postcode || edit == true) && address.lat"
          class="col-xs-12 primaryColor"
          style="padding: 5px; text-align: center"
        >
          Please use the marker
          <br />to locate your home
        </div>
      </form>
    </div>

    <GmapMap
      v-show="address.lat"
      :center="centerPosition"
      :zoom="zoomLevel"
      style="width: 100%; height: 300px"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
    >
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="edit == true || !postcode"
        @click="centerPosition = marker.position"
        @dragend="dragEnd"
        style="z-index: 999"
      />
    </GmapMap>
  </div>
</template>
<script>
// import SubHeader from "@/components/SubHeader";
// import dbConnection from "@/services/dbConnection";
// import { Marker } from "vue2-google-maps/src/components/marker";
import { Autocomplete } from "vue2-google-maps";
// Vue.component('GmapMarker', GmapMarker)

export default {
  name: "address",
  components: {
    // SubHeader,
    // Map,
    Autocomplete,
    // Marker
    // GmapMap
    // VueCropper
  },
  props: {
    defaultMarker: Object,
    currentAddress: Object,
    postcode: String,
  },
  data() {
    return {
      centerPosition: { lat: 52.209865, lng: -0.118092 },
      zoomLevel: 6,
      marker: {},
      address: {},
      textInput: {},
      edit: false,
      placeholder: "Start typing your address here",
    };
  },
  watch: {
    postcode() {
      if (this.postcode) {
        // this.placeholder = this.postcode;
      }
    },
    defaultMarker() {
      this.setInitialMarker(this.defaultMarker);
    },
  },
  computed: {
    internationalSearch() {
      const vrnAvailable = true;
      const schools = this.$store.state.general.available_schools;
      schools.forEach((itm) => {
        if (itm?.school.vrn_available == 0) {
          vrnAvailable = false;
        }
      });

      if (this.$store.state.general.subAccount) {
        const subAccSchools =
          this.$store.state.general.subAccount.available_schools;
        subAccSchools.forEach((itm) => {
          if (itm?.school.vrn_available == 0) {
            vrnAvailable = false;
          }
        });
      }

      return vrnAvailable == true ? false : true;
    },
  },
  methods: {
    setPlace(data) {
      if (!data.geometry) {
        return;
      }
      this.marker.position = {
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
      this.centerPosition = {
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
      this.zoomLevel = 17;
      this.$emit("newAddress", this.getAddress(data));
    },
    enableEditing() {
      this.edit = true;
    },
    setInitialMarker(marker) {
      this.marker.position = {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
      this.centerPosition = {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
      this.zoomLevel = 17;
    },
    getAddress(place) {
      var full_address = place.formatted_address.split(",");
      this.address = {
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
        street: place.address_components[0].long_name,
        city: place.address_components[1].long_name,
        country: place.address_components[3].long_name,
        address_line_1: full_address[0],
        address_line_2: full_address[1],
        postcode: place.address_components[0].short_name,
      };
      return {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        street: place.address_components[0].long_name,
        city: place.address_components[1].long_name,
        country: place.address_components[3].long_name,
        address_line_1: full_address[0],
        address_line_2: full_address[1],
        postcode: place.address_components[0].short_name,
      };
    },
    dragEnd(data) {
      let updateData = this.address;
      updateData.lat = data.latLng.lat();
      updateData.lng = data.latLng.lng();
      this.$emit("newAddress", JSON.parse(JSON.stringify(updateData)));
    },
  },
  mounted() {
    if (this.defaultMarker) {
      // setTimeout(() => {
      this.setInitialMarker(this.defaultMarker);
      // }, 400);
    }
    if (this.postcode) {
      // this.placeholder = this.postcode;
    }
  },
};
</script>
