import { render, staticRenderFns } from "./StartingPage.vue?vue&type=template&id=5f2c0e38&scoped=true&"
import script from "./StartingPage.vue?vue&type=script&lang=js&"
export * from "./StartingPage.vue?vue&type=script&lang=js&"
import style0 from "./StartingPage.vue?vue&type=style&index=0&id=5f2c0e38&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2c0e38",
  null
  
)

export default component.exports