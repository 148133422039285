var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrapper"}},[_c('div',[_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"col-xs-12"},[(_vm.edit === false && _vm.currentAddress)?_c('div',{staticClass:"current-address"},[_vm._v(" "+_vm._s(_vm.currentAddress.address_line_1)+" "),_c('i',{staticClass:"fa fa-circle-check",staticStyle:{"color":"#03c200","padding-left":"5px"}})]):_vm._e(),(_vm.postcode && _vm.edit == false)?_c('button',{staticClass:"pull-left btn btn-grey marginTop5",on:{"click":_vm.enableEditing}},[_vm._v(" Select New Home Address ")]):_vm._e(),_c('van-cell',[_c('div',{staticClass:"form-select-wrapper"},[(!_vm.postcode || _vm.edit == true)?_c('Autocomplete',{staticClass:"form-select",attrs:{"data-cy":"address_autocomplete_input","placeholder":_vm.placeholder,"options":{
                componentRestrictions: {
                  country: _vm.internationalSearch ? [] : ['uk'],
                },
              }},on:{"place_changed":_vm.setPlace}}):_vm._e()],1)])],1),((!_vm.postcode || _vm.edit == true) && _vm.address.lat)?_c('div',{staticClass:"col-xs-12 primaryColor marker-info"},[_vm._v(" Please use the marker to locate your home ")]):_vm._e()])]),(_vm.address.lat)?_c('GmapMap',{directives:[{name:"show",rawName:"v-show",value:(_vm.address.lat),expression:"address.lat"}],staticStyle:{"width":"100%","height":"300px"},attrs:{"center":_vm.centerPosition,"zoom":_vm.zoomLevel,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},[_c('GmapMarker',{staticStyle:{"z-index":"999"},attrs:{"position":_vm.marker.position,"clickable":true,"draggable":_vm.edit == true || !_vm.postcode},on:{"click":function($event){_vm.centerPosition = _vm.marker.position},"dragend":_vm.dragEnd}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }