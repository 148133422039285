<template>
  <div id="wrapper text-white">
    <div class="modal-header flex items-center justify-between">
      {{ params.category || "not specified" }}
      <i
        class="fa fa-times close-icon fa-xl text-black cursor-pointer"
        @click="$emit('close')"
      />
    </div>

    <div class="text-center my-5">
      <p class="travel-explanation">
        {{ params.explanation }}
      </p>

      <textarea
        name="text"
        placeholder="Enter description"
        rows="3"
        cols="80"
        class="form-control rounded-2xl mt-6 py-3 px-4"
        maxlength="500"
        v-model="text"
      ></textarea>
    </div>
    <div class="mt-4 mb-5 text-center">
      <button class="btn-black uppercase" @click="submit">Submit</button>
    </div>

    <p class="travel-disclaimer">
      We may share your anonymised suggestions with local authorities and your
      school.
    </p>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  name: "transportSuggest",
  props: {
    params: Object,
  },
  data() {
    return {
      serviceToRunFromMyArea: null,
      maximumBudged: null,
      text: "",
    };
  },

  methods: {
    submit() {
      if (!this.text || this.text.length < 10) {
        this.$notify({
          type: "warning",
          message: "Please provide more feedback. Thank you.",
        });
        return;
      }
      const payload = {
        ...this.params,
        message: this.text,
      };
      ApiService.generalApi.transportSuggest(payload).then(() => {
        this.$notify({
          type: "success",
          message: "Thank you! Your travel suggestion has been submitted",
        });
        this.$emit("close");
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#topConversation {
  height: 53px;
  border-bottom: 1px solid #cccccc;
}
#bottomConversation {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
}
button.close {
  margin: 0px !important;
  padding-right: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 12px !important;
}
ul#middleConversation {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  padding: 5px;
  padding-bottom: 140px;

  background: url(~@/assets/circles-light.png);
}
div#wrapper {
  height: 100%;
}
li.chatline {
  line-height: 25px;
  margin-bottom: 10px;
}
li.chatline .chatMessage {
  padding: 12px;
  border-radius: 5px;
  background: #ffffff;
  display: inline-block;
  text-align: left;
  margin-right: 50px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.07);
}
span.timeAgo {
  color: #9b9b9b;
  padding-right: 5px;
  padding-left: 5px;
}
li.myChatMessage {
  text-align: right !important;
}
li.myChatMessage .chatMessage {
  background: #ffeddd;
  margin-left: 50px;
  margin-right: 0px;
}
</style>
