<template>
  <div>
    <div
      v-if="showLinks"
      class="flex items-center content-center justify-center"
      style="height: 100vh"
    >
      <div class="flex justify-evenly">
        <a
          href="https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8"
          target="_blank"
          class="px-5 text-center"
        >
          <img src="@/assets/apple.png" height="40" class="h-10" /><br />
          <button class="btn btn-black marginTop10">Download</button>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&hl=en_GB"
          target="_blank"
          class="px-5 text-center"
        >
          <img src="@/assets/android.png" height="40" class="h-10" /><br />
          <button class="btn btn-black marginTop10">Download</button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newtermLink",
  components: {},
  data() {
    return {
      showLinks: false,
    };
  },
  methods: {
    init() {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        location.replace("scs.homerun://");
        setTimeout(function () {
          if (!document.webkitHidden) {
            location.replace("https://user.homerun-app.com/");
          }
        }, 25);
      } else if (
        navigator.userAgent.match(/android/i) ||
        navigator.userAgent.match(/Android/i)
      ) {
        window.location.replace(
          "intent://app/SplashScreen#Intent;scheme=app_;package=schoolrun.com.schoolrun;end"
        );
        setTimeout(function () {
          if (!document.webkitHidden) {
            location.replace("https://user.homerun-app.com/");
          }
        }, 250);
      } else {
        // this.showLinks = true;
        location.replace("https://user.homerun-app.com/");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
