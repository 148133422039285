<template>
  <div class="bottom-menu container visible-xs visible-sm">
    <nav>
      <ul>
        <li :class="{ active: isNoticesActive }" @click="showSection('home')">
          <i class="fal fa-rss"></i>
          <span>Notices</span>
        </li>
        <li
          class="travel"
          :class="{ active: isTravelActive }"
          @click="showSection('schoolRun')"
        >
          <i class="fal fa-car-bus"></i>
          <span>{{ showNewFeatures ? "School Run" : "Travel" }}</span>
        </li>
        <li
          class="messages"
          :class="{ active: isMessengerActive }"
          @click="showSection('myChats')"
        >
        <i class="fal fa-messages"></i>
          <span>Messenger</span>
        </li>
        <li
          class="stats"
          :class="{ active: isStatsActive }"
          @click="showSection('stats')"
        >
        <i class="fal fa-chart-simple"></i>
          <span>Stats</span>
        </li>
        <li
          class="menu"
          :class="{ active: isMenuActive }"
          @click="showSection('menu')"
        >
        <i class="fal fa-bars"></i>
          <span>Menu</span>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: "mobileMenu",
  data() {
    return {
      sectionIcon: "",
    };
  },
  methods: {
    showSection(routeName) {
      if (routeName === "schoolRun") {
        routeName = this.$store.state.general.show_new_features
          ? "travelDirectory"
          : routeName;
      }
      if (routeName === "members") {
        if (this.accType === "parent") {
          this.$router.push({
            name: "searchParents",
            params: { type: "parent" },
          });
        } else {
          this.$router.push({ name: "myChats" });
        }
      } else {
        if (this.$route.name == routeName) {
          this.$forceUpdate();
        } else {
          this.$router.push({ name: routeName });
        }
      }
    },
  },
  computed: {
    accType: function () {
      return this.$store.state.general.selectedAccountType;
    },
    is_staff() {
      return this.$store.state.general.user.is_staff;
    },
    showNewFeatures() {
      return this.$store.state.general.show_new_features;
    },
    isTravelActive() {
      return (
        this.$route.name === "schoolRun" ||
        this.$route.name === "schoolRunNew" ||
        this.$route.name === "travelDirectory" ||
        this.$route.name === "schoolRun_searchParents"
      );
    },
    isNoticesActive() {
      return this.$route.name === "home";
    },
    isMessengerActive() {
      return (
        this.$route.name === "searchParents" || this.$route.name === "myChats"
      );
    },
    isStatsActive() {
      return this.$route.name === "stats";
    },
    isMenuActive() {
      return this.$route.name === "menu";
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-menu {
  @apply fixed bg-black rounded-t-xl text-white flex;
  @apply left-0 right-0 overflow-hidden;
  @apply z-mobile-menu;
  bottom: -2px;

  ul {
    @apply flex items-center justify-around;
    @apply text-xs text-white;

    li {
      @apply flex items-center flex-col text-white;
      @apply py-3;
    }

    li i {
      font-size: 20px;
      padding-bottom: 6px;
    }

    li svg {
      @apply mb-2;
    }

    li.active,
    li:hover,
    li:hover svg {
      @apply text-green;
    }

    li svg {
      @apply fill-white;
    }

    li.active svg {
      @apply fill-green;
    }
  }

  .travel.active {
    @apply text-purple-light;

    svg {
      @apply fill-purple-light;
    }
  }

  .messages.active {
    @apply text-pink-dark;

    svg {
      @apply fill-pink-dark;
    }
  }

  .stats.active {
    @apply text-blue;

    svg {
      @apply fill-blue;
    }
  }

  .menu.active {
    @apply text-peach;

    svg {
      @apply fill-peach;
    }
  }
}
</style>
