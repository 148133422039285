<style lang="scss" scoped>
#wrapper {
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 100vw;
}
#bgCover {
  height: 100vh;
  margin: -10px -25px;
  font-family: "Rubik";
  text-align: center;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    margin: -10px -15px;
  }
}

#imageOverlay {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(~@/assets/bglines.png) no-repeat bottom right;
  background-size: cover;
  // background-size: 250% auto;
  opacity: 0.5;
}
#bgCover.autumn {
  background: #6666cc;
}
#bgCover.summer {
  background: #ff9900;
}
#bgCover.spring {
  background: #cc33cc;
}
#mainLogo {
  padding-top: 10px;
  border-bottom: 1px solid #ffcdae;
  padding-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>
<template>
  <div
    id="bgCover"
    :class="term"
    class="flex flex-col justify-around"
    style="position: relative"
  >
    <div id="imageOverlay"></div>
    <div class="flex flex-col justify-around items-center h-full max-h-[700px]">
      <div id="logos" class="text-center">
        <img
          :key="school.id"
          v-for="school in schools"
          :src="school.logo"
          class="avatar !inline-block"
          alt
          style="margin-right: 10px"
        />
      </div>
      <h1
        class="!text-6xl !leading-[4.25rem] tracking-wide text-white font-normal"
        data-cy="term_header"
      >
        <span v-if="term == 'autumn'"
          >Autumn <br />
          Term</span
        >
        <span v-if="term == 'spring'"
          >Spring <br />
          Term</span
        >
        <span v-if="term == 'summer'"
          >Summer <br />
          Term</span
        >
      </h1>

      <p class="text-white text-xl">
        Welcome to the new term<br />
        <!-- and your new year group -->
      </p>

      <div
        class="bg-white p-4 rounded-lg max-w-[400px] min-w-[250px] tracking-wide"
      >
        Please take a moment to
        <br />ensure the App is set up to <br />your desired preferences....
      </div>
      <van-button
        class="btn-black !px-10 !border-none"
        @click="next()"
        data-cy="continue_btn"
        >Continue</van-button
      >
    </div>
    <div>
      <inline-svg
        class="logo"
        style="fill: white"
        :src="require('@/assets/wireframe_logo.svg')"
        width="70"
        height="74"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "termUpdate",
  data() {
    return {
      selection: {},
      schools: [],
    };
  },
  computed: {
    term() {
      return this.$store.state.general.term;
    },
  },
  methods: {
    getSchools() {
      ApiService.userApi.getUserSchools().then((response) => {
        if (response.data == "No Schools") {
          this.$notify({
            message: "You have no schools",
            type: "warning",
          });
        } else {
          this.schools = response.data.schools;
          this.$forceUpdate();
        }
      });
    },
    next() {
      this.$router.push("details");
    },
  },
  mounted() {
    var term = "";
    var date = new Date();
    var month = date.getMonth();

    if (month >= 7) {
      term = "autumn";
    } else if (month >= 3) {
      term = "summer";
    } else {
      term = "spring";
    }
    this.$store.dispatch("setTerm", term);
    this.getSchools();
  },
};
</script>
