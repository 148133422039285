<style lang="scss" scoped></style>
<template>
  <div id="wrapper">
    <ModalHeader title="Address selection" @hideModal="$emit('close')" />
    <div class="col-xs-12" style="margin-bottom: 15px">
      {{ currentAddress.address_line_1 }} {{ currentAddress.address_line_2 }}
    </div>
    <div class="col-xs-12">
      <Address
        :defaultMarker="defaultMarker"
        :postcode="postcode"
        @newAddress="setAddress"
      />
    </div>
    <div style="clear: both"></div>
    <div class="text-center clearfix" style="margin: 10px">
      <van-button @click="save()" size="normal" type="info">Save</van-button>
    </div>
  </div>
</template>
<script>
import Address from "./Address";
import ModalHeader from "@/components/ModalHeader";

export default {
  name: "address",
  components: {
    Address,
    ModalHeader,
  },
  props: {
    currentAddress: Object,
    defaultMarker: Object,
    postcode: String,
  },
  data() {
    return {
      address: {},
    };
  },
  methods: {
    newAddress() {},
    setAddress(data) {
      this.address = data;
      // this.$emit("newAddress", data);
    },
    save() {
      this.$parent.$emit("newAddress", this.address);
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>
