<template>
  <div></div>
</template>
<script>
// import { json } from 'body-parser';
import jwtDecodefrom from "jwt-decode";
export default {
  name: "nativeLogin",
  components: {},
  data() {
    return {};
  },
  methods: {
    onMessage(data) {
      try {
        const currentRoute = this.$router.currentRoute.name;
        var jsonData =
          typeof data.data != "undefined" ? JSON.parse(data.data) : data;



        if (typeof jsonData.menu != "undefined") {
          if (jsonData.menu == "social" && currentRoute == "home") {
            // CommonDataService.setToken(jsonData.token);
            if (jsonData && jsonData.token) {
              this.$store.dispatch("setToken", jsonData.token);
              try {
                const decoded = jwtDecodefrom(jsonData.token);
                if(decoded.user_role_id){
                  this.$store.dispatch("setUserRoleId", decoded.user_role_id);
                }
              } catch (error) {
                
              }
            }
            // $rootScope.$broadcast("reloadHome", { refreshCount: true });
          } else if (jsonData.menu == "social") {
            // $rootScope.$broadcast("refreshStatusCount");
          } else if (jsonData.menu == "journeys") {
            // $state.go("main.trackMyChildren", { childId: 0 });
          }
        } else if (typeof jsonData.data != "undefined") {
          if (jsonData.type == "imageUploaded") {
            // $rootScope.$broadcast("imageUploaded", {
            //   id: jsonData.id,
            //   url: jsonData.imageUrl
            // });
          }
        } else {
          this.$store.dispatch("loginViaMobileApp", jsonData);
          try {
            const decoded = jwtDecodefrom(jsonData.token);
            if(decoded.user_role_id){
              this.$store.dispatch("setUserRoleId", decoded.user_role_id);
            }
          } catch (error) {
            
          }
          this.$store.dispatch("setToNative", true);

          var versionArray = [];
          if (jsonData.version) {
            versionArray = jsonData.version.split(".");
          }

          if (
            !jsonData.version ||
            (jsonData.version &&
              versionArray.length > 0 &&
              parseInt(versionArray[0]) < 7)
          ) {
            setTimeout(() => {
              this.$router.push({ name: "upgrade" });
            }, 50);
          } else {
            setTimeout(() => {
              this.$router.push({ name: "home" });
            }, 100);
          }
        }
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    // window.messageFromNative = data => {
    //   this.onMessage(data);
    // };
  },
};
</script>
