<style scoped>
div.question {
  font-weight: strong;
  font-size: 15px;
  color: #333;
  background: #f9f9f9;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #dfdfdf;
  transition: 0.3s all ease;
  position: relative;
  padding-right: 20px;
  cursor: pointer;
}

div.question span {
  position: absolute;
  right: 8px;
  transition: 0.4s all ease;
}

div.wrapper.active .question {
  background: #ffffff;
  border-bottom: 1px solid #ccc;
  color: #21acf5;
}

div.wrapper.active .question span {
  transform: rotate(90deg);
}

div.wrapper {
  transition: 1s all ease;
}

div.wrapper .answer {
  height: 0;
  overflow: hidden;
  transition: 0.6s all ease;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  opacity: 0;
}

div.wrapper.active .answer {
  height: auto;
  /*margin: 10px;*/
  padding-top: 20px;
  padding-bottom: 20px;
  /* padding-left: 10px; */
  opacity: 1;
  text-align: left;
}
#mainContent {
  min-height: 600px;
}
</style>
<template>
  <div id="mainContent" class="mobilePadding clearfix">
    <div class="">
      <!-- <Header title="FAQ's" /> -->
      <Headerv2 title="FAQ's" />
      <div
        :key="$index"
        v-show="getAccountType != 'staff' || (!is_staff && !getAccountType)"
        v-for="(single, $index) in data.items"
        class="col-xs-12"
      >
        <div
          class="wrapper mb-1.5"
          :class="{ active: opened == $index }"
          @click="openAnswer($index)"
        >
          <div
            class="alert-mira !py-3 cursor-pointer"
            style="text-align: left; font-size: 14px"
          >
            {{ single.question }}
            <span>
              <i class="fa fa-chevron-right"></i>
            </span>
          </div>
          <div
            class="answer bg-red-100 rounded-md"
            v-html="single.answer"
          ></div>
        </div>
      </div>

      <div
        :key="$index"
        v-show="getAccountType == 'staff' || (is_staff && !getAccountType)"
        class="mb-1.5"
        v-for="(single, $index) in staffData.items"
      >
        <div
          class="wrapper"
          :class="{ active: opened == $index }"
          @click="openAnswer($index)"
        >
          <div
            class="alert-mira !py-3 cursor-pointer"
            style="text-align: left; font-size: 14px"
          >
            {{ single.question }}
            <span>
              <i class="fa fa-chevron-right"></i>
            </span>
          </div>
          <div
            class="answer bg-red-100 rounded-md text-black"
            v-html="single.answer"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ApiService from "@/services/ApiService";
import Headerv2 from "@/components/Headerv2";
export default {
  name: "features",
  components: { Headerv2 },
  data() {
    return {
      data: [],
      staffData: [],
      opened: -1,
    };
  },
  computed: {
    getAccountType() {
      return this.$store.getters.selectedAccountType;
    },
    is_staff() {
      return this.$store.state.general.user.is_staff;
    },
  },
  methods: {
    openAnswer: function (id) {
      this.opened = id;
    },
  },
  mounted() {
    this.$store.dispatch("loading", true);

    if (
      (this.is_staff && !this.getAccountType) ||
      this.getAccountType === "staff"
    ) {
      ApiService.staticContentApi.faqStaff().then((response) => {
        this.staffData = response.data;
        this.$store.dispatch("loading", false);
      });
    } else {
      ApiService.staticContentApi.faq().then((response) => {
        this.data = response.data;
        this.$store.dispatch("loading", false);
      });
    }
  },
};
</script>
