import dbConnection from "../dbConnection";
import Router from "../../router";

const userApis = {
  profileRelAddresses: function () {
    return dbConnection.get("api/profile/getAddresses");
  },

  unsubscribe: function (payload) {
    return dbConnection.post("api/unsubscribe", payload);
  },

  // getStatus: function () {
  //   return dbConnection.get("api/users/status");
  // },
  getAccountStatus: function (refresh) {
    const addUrl = refresh ? "refresh=true" : "";
    return dbConnection.get("api/users/accountStatus?" + addUrl);
  },

  getAddressesAndChildren: function () {
    return dbConnection.get("api/profile/getAddressesAndChildren");
  },

  currentUserData: function () {
    return dbConnection.get("api/users/currentUserData");
  },

  createInvitation: function (obj) {
    return dbConnection.post("api/users/invitations/createParentInvitation", obj);
  },

  allInvitations: function () {
    return dbConnection.get("api/users/invitations/all");
  },

  cancelInvitation: function (id) {
    return dbConnection.get("api/users/invitations/cancelInvitation/" + id);
  },

  updateProfile: function (obj) {
    return dbConnection.post("api/users/updateProfile", obj);
  },

  updatePassword: function (obj) {
    return dbConnection.post("api/users/updatePassword", obj);
  },
  saveNewChildren: function (obj) {
    return dbConnection.post("api/addNewChildren", obj);
  },
  saveNewChild: function (obj) {
    return dbConnection.post("api/parent/child/store", obj);
  },
  syncMultiSchools: function (obj) {
    return dbConnection.get("api/parent/syncMultiSchools");
  },

  forgotPassword: function (postObj) {
    return dbConnection.post("api/password/email", postObj);
  },
  changePassword: function (postObj) {
    return dbConnection.post("api/users/changePassword", postObj);
  },

  changeChildPicture: function (postObj, childId) {
    return dbConnection.post(
      "api/child/" + childId + "/changePicture",
      postObj
    );
  },

  getUserInfo: function () {
    return dbConnection.get("api/users/userInfo");
  },
  sendVerificationCode: function (email) {
    return dbConnection.post("api/sendVerificationCode", { email });
  },

  validateVerificationCode: function (code, email) {
    const payload = { code: code, email: email };
    return dbConnection.post("api/validateVerificationCode", payload);
  },

  isValidated: function () {
    return dbConnection.get("api/isValidated");
  },

  removeChild: function (childId) {
    return dbConnection.delete("api/parent/child/removeChild/" + childId);
  },

  saveJourneyPreferences: function (id) {
    var payload = { value: id };
    return dbConnection.post("api/users/saveJourneyPreferences", payload);
  },

  inviteDetails: function (token) {
    return dbConnection.get("api/users/inviteDetails/" + token);
  },

  saveUserSettings: function (payload) {
    return dbConnection.post("api/users/userSettings", payload);
  },
  updateSingleUserRoleSetting: function (payload) {
    return dbConnection.post("api/users/updateSingleUserRoleSetting", payload);
  },

  termUpdated: function () {
    return dbConnection.get("api/users/termUpdated");
  },

  changeParentPicture: function (postObj) {
    return dbConnection.post("api/users/changePicture", postObj);
  },

  check_parent_code: function (code, parentId) {
    return dbConnection.get("api/check_parent_code/" + code + "/" + parentId);
  },

  saveChildren: function (payload) {
    return dbConnection.post("api/children/confirm", payload);
  },

  hasActiveChildren: function () {
    return dbConnection.get("api/users/hasActiveChildren");
  },

  registerUser: function (payload) {
    return dbConnection.post("api/registration/register", payload);
  },

  getUser: function (userId) {
    return dbConnection.get("api/users/" + userId);
  },
  getParentSchools: function (params, parentToken) {
    var url = "api/users/parentSchools";
    if (params) {
      url += "?" + params;
    }

    if (parentToken) {
      url += url.includes("?")
        ? "&token=" + parentToken
        : "?token=" + parentToken;
    }
    return dbConnection.get(url);
  },
  getUserSchools: function (params, parentToken) {
    var url = "api/users/parentSchools";
    if (params) {
      url += "?" + params;
    }

    if (parentToken) {
      url += url.includes("?")
        ? "&token=" + parentToken
        : "?token=" + parentToken;
    }
    return dbConnection.get(url);
  },
  getUserOrganizations: function (params, parentToken) {
    var url = "api/users/getUserOrganizations";
    if (params) {
      url += "?" + params;
    }

    if (parentToken) {
      url += url.includes("?")
        ? "&token=" + parentToken
        : "?token=" + parentToken;
    }
    return dbConnection.get(url);
  },
  getUserGroups: function () {
    return dbConnection.get("api/users/groups");
  },
  updateUserGroup: function (params) {
    return dbConnection.post("api/users/group", params);
  },
  accountStatusRedirect: function (redirectToMain, refresh) {
    return new Promise((resolve, reject) => {
      this.getAccountStatus(refresh)
        .then(function (response) {
          if (response.data.missing == "newterm") {
            Router.push("/flow/termUpdate");
          } else if (response.data.missing == "address") {
            Router.push("/flow/details");
          } else if (response.data.missing == "children") {
            Router.push("/flow/flowChildren");
          } else if (response.data.missing == "journeys") {
            Router.push("/flow/journeys");
          } else if (response.data.missing == "verification") {
            Router.push("/flow/verification");
          } else if (redirectToMain) {
            Router.push("/main/home");
          } else if (response.data.missing == "intro") {
            // Router.push("/intro");
          } else if (response.data.missing == "school") {
            Router.push({ name: "flowSchools" });
          }
          resolve(true);
        })
        .catch((err) => {
          // NavigationDuplicated
          // reject(err);
        });

      // if (path == "logout" && this.$store.state.general.native) {
      //   window.postMessage("logout", "*");
      // } else {
      //   this.$router.push({ name: path });
      // }
    });
    // .catch((err) => {
    //   return err;
    //   console.log('err', err.response);
    //   alert(err.data.error);
    // });
  },
  refreshImage: function (type) {
    return dbConnection.post("api/users/refreshImage", { type });
  },
  getStats: function () {
    return dbConnection.get("api/users/stats");
  },
  removeParentSubAccount: function () {
    return dbConnection.delete("api/removeParentSubAccount");
  },
  searchParents: function (selection, type, userRoleId) {
    if (!selection.searchString) {
      selection.searchString = "";
    }

    if (selection.mode) {
      if (type == "staff_journey_share" && selection.mode == "carpooling") {
        type = "staff_carpooling";
      }
      if (type == "journey_share" && selection.mode == "carpooling") {
        type = "carpooling";
      }
    }

    var url =
      "api/users/searchUsers?page=0&search=" +
      selection.searchString +
      "&school=" +
      selection.school.id +
      "&type=" +
      type +
      "&user_role_id=" +
      userRoleId;

    if (selection.sortby) {
      if (selection.sortby == "alphabetical") {
        url = url + "&sort=" + selection.sortby;
      }
    }

    if (selection.year || selection.year == 0) {
      url = url + "&year=" + selection.year;
    }

    return dbConnection.get(url);
  },
};

export default userApis;
