<template>
  <div>
    <ul id="nav" style="position: fixed">
      <li id="profileMenu" class="desktopMenu pb-4">
        <router-link data-cy="desktop_settings_button" to="/main/settings">
          <div class="smallImg pull-left">
            <img class="img-circle" alt :src="userImage" />
          </div>
          <div style="vertical-align: middle; font-weight: bold">
            <span class="name">{{ userInfo.first_name }}</span>
            <br />
            <span class="last_name">{{ userInfo.last_name }}</span>
            <br />
            <span
              style="
                font-size: 11px;
                display: inline-block;
                color: #5e5e5e;
                margin-top: 4px;
              "
              >My Settings</span
            >
          </div>
          <div style="clear: both"></div>
        </router-link>
      </li>
      <li>
        <hr class="mb-3" />
      </li>
      <li>
        <router-link data-cy="desktop_home_button" to="/main/home"
          ><i class="fas fa-rss-square" aria-hidden="true"></i>Notice
          Board</router-link
        >
      </li>
      <li>
        <router-link
          data-cy="desktop_school_messenger_button"
          to="/main/myChats"
          ><i class="fad fa-comments" aria-hidden="true"></i>School
          Messenger</router-link
        >
      </li>
      <!-- <li v-if="!is_staff">
        <router-link
          data-cy="desktop_journey_share_button"
          to="/main/searchParents/journey_share"
          ><i class="fas fa-route" aria-hidden="true"></i>Journey
          Share</router-link
        >
      </li> -->
      <!-- <li v-if="!is_staff">
        <router-link
          data-cy="desktop_travel_buddies_button"
          to="/main/searchParents/travel_buddy"
          ><i class="fas fa-user-friends" aria-hidden="true"></i>Travel
          Buddies</router-link
        >
      </li> -->
      <!-- <li v-if="is_staff">
        <router-link
          data-cy="desktop_staff_journey_share_button"
          to="/main/searchParents/staff_journey_share"
          ><i class="fas fa-route" aria-hidden="true"></i>Journey
          Share</router-link
        >
      </li> -->
      <li v-if="showNewFeatures">
        <router-link
          data-cy="desktop_features_button"
          to="/main/travelDirectory"
          ><i class="fad fa-taxi-bus" aria-hidden="true"></i>Travel
          Directory</router-link
        >
      </li>
      <li v-if="!showNewFeatures">
        <i class="fas fa-taxi-bus" aria-hidden="true"></i>
        <router-link data-cy="desktop_features_button" to="/main/schoolRun"
          >School Run</router-link
        >
      </li>
      <li>
        <router-link data-cy="desktop_my_children_button" to="/main/myChildren"
          ><i class="fad fa-children" aria-hidden="true"></i>My
          Children</router-link
        >
      </li>
      <li>
        <router-link data-cy="desktop_my_schools_button" to="/main/mySchools"
          ><i class="fas fa-school" aria-hidden="true"></i>My
          Schools</router-link
        >
      </li>
      <!-- <li>
        <router-link data-cy="desktop_my_groups_button" to="/main/myGroups"
          ><i class="fas fa-layer-group" aria-hidden="true"></i>My
          Groups</router-link
        >
      </li> -->
      <li v-show="is_staff === 0">
        <router-link
          data-cy="desktop_invite_parent_button"
          to="/main/invitePartner"
          ><i class="fad fa-user-plus" aria-hidden="true"></i>Invite My
          Partner</router-link
        >
      </li>
      <li>
        <router-link data-cy="desktop_faq_button" to="/main/faqs"
          ><i class="fas fa-question-circle" aria-hidden="true"></i
          >FAQs</router-link
        >
      </li>
      <li>
        <router-link data-cy="desktop_contact_us_button" to="/main/contactus"
          ><i class="fas fa-envelope" aria-hidden="true"></i>Contact
          Us</router-link
        >
      </li>
      <li>
        <router-link data-cy="desktop_logout_button" to="/logout"
          ><i
            class="fa-light fa-arrow-right-from-bracket"
            aria-hidden="true"
          ></i
          >Logout</router-link
        >
      </li>
    </ul>
  </div>
</template>
<style scoped>
ul {
  list-style: none;
}
li {
  line-height: 33px;
  padding-left: 5px;
  font-size: 15px;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
}
li a {
  color: rgb(94, 94, 94);
}
li i {
  color: #8ca1ac;
  width: 40px;
  vertical-align: middle;
  font-size: 18px;
}
ul li a.router-link-active,
ul li a.router-link-active i {
  color: #248ec6 !important;
}
#profileMenu {
  margin-top: 40px;
  line-height: 18px;
}
#profileMenu img {
  height: 50px !important;
  width: 50px !important;
  max-height: 50px;
  margin-right: 10px;
  margin-left: 0;
  border: 1px solid #ffffff;
}
ul li img {
  max-height: 30px;
  display: inline;
  width: 55px;
  margin-left: -15px;
}
ul li hr {
  border-color: #dcdcdc;
}
</style>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  computed: {
    userImage: function () {
      return this.$store.state.general.user.profile_photo_url;
    },
    userInfo() {
      return this.$store.state.general.user;
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
    is_staff() {
      return this.roleType == "STAFF" ? 1 : 0;
    },
    roleType: function () {
      return this.$store.state.general.roles.find(
        (role) => this.currentUserRoleId == role.user_role_id
      )?.slug;
    },
    showNewFeatures() {
      return this.$store.state.general.show_new_features;
    },
  },
});
</script>
