<template>
  <div class="blockwrapper bg-opacity-10" :class="[open ? 'open' : '']">
    <div @click="toggle" :style="{ background: item.color }" :class="[disabled ? ' disabled' : '']" class="top">
      <span v-if="disabled && isStaff" class="parentOnlyLabel">Parent Only</span>
      <span v-if="disabled && !isStaff" class="parentOnlyLabel">Staff Only</span>
      <span style="max-width: 170px">{{ item.category }}</span>

      <i class="fa fa-2xl fa-angle-right text-white" :class="{ 'rotate-90': open }"></i>
    </div>
    <div class="content px-2" :style="{ background: item.bgColor }">
      <div class="innerContent">
        <div class="featureBlock" v-for="(block, index) in item.blocks.filter((itm) =>
          isStaff ? !itm.parentOnly || itm.staffOnly === 1 : !itm.staffOnly
        )" :key="index">
          <div class="blockContent flex shadow-lg p-3 mb-2 rounded-lg bg-white">
            <div class="leftImageDiv flex-grow-0 flex-shrink-0 shadow-lg">
              <img v-if="block.image" :src="block.image" class="leftImage" />
            </div>
            <div class="text-left">
              <p class="text-sm text-chat" v-if="block.text.length > 150 && showBlocks.indexOf(index) === -1">
                {{ block.text.substr(0, 150) }}...
                <span @click="showBlock(index)" :style="{ color: item.color }"
                  class="p-0.5 px-1.5 rounded-md text-white font-bold text-xs">See more</span>
              </p>
              <p class="text-sm text-chat" v-if="showBlocks.indexOf(index) > -1 || block.text.length < 150">
                {{ block.text }}
              </p>
              <button v-if="block.buttonRoute" class="select-button" :style="{ background: item.color }"
                @click="redirect(block.buttonRoute)">
                {{ block.buttonLabel }}
              </button>
              <button v-if="native && block.link" @click="openUrl(block.link)" class="select-button"
                :style="{ background: item.color }">
                {{ block.linkLabel }}
              </button>
              <a v-if="!native && block.link" :href="block.link" target="_blank"
                class="text-xs text-white  mt-0 p-1 px-2 rounded-lg no-underline" :style="{ background: item.color }">{{
                  block.linkLabel }}</a>
            </div>
          </div>
        </div>
        <div>
          <p v-if="item.modalIntroText" class="text-sm text-chat py-2">
            {{ item.modalIntroText }}
          </p>
          <button class="select-button" :style="{ background: item.color }" v-if="item.modal_type > 0"
            @click="showPoll">
            <span v-if="!item.modalButtonLabel">Suggest
              {{ item.modal_type === 1 ? "Improvements" : "Service" }}</span>
            <span v-if="item.modalButtonLabel">{{ item.modalButtonLabel }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const itemClassnames = {
  1: "bg-green-dark",
  2: "bg-green",
  3: "bg-blue",
  4: "bg-purple",
  5: "bg-orange",
  6: "bg-blue-dark",
  7: "bg-pink",
};

export default {
  name: "travel-feature",
  props: {
    item: Object,
    disabled: Number,
    isStaff: Number,
    native: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
      showBlocks: [],
      itemClassnames,
    };
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      localStorage.setItem(
        "modal-classname",
        this.getItemStyleClass(this.item.order)
      );

      this.$emit("toggled", this.item.id);
      // setTimeout(() => {
      this.open = !this.open;
      // }, 10);
    },
    close() {
      this.open = false;
    },
    redirect(to) {
      if (to === "redirectJourney") {
        this.$emit("action", {
          action: "redirectJourney",
        });
      } else {
        this.$router.push(to);
      }
    },
    showPoll() {
      this.$emit("action", {
        category: this.item.category,
        action: "show_poll",
        modal_type: this.item.modal_type,
        explanation: this.item.modal_explanation,
        color: this.item.color
      });
    },
    openUrl(url) {
      window.postMessage("native_modal__" + url, "*");
      return;
    },
    showBlock(index) {
      this.showBlocks.push(index);
    },
    getItemStyleClass(itemOrder) {
      return itemClassnames[itemOrder ?? 1];
    },
  },
};
</script>
<style scoped>
.select-button {
  @apply rounded-full;
}

div.blockwrapper {
  /* height: auto; */
  /* height: 50px; */
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0 4px 4px #c3c3c3;
  border-radius: 25px;
  /* transition: all 0.3s linear; */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

div.blockwrapper.open {
  height: auto;
  /* max-height: 10000px; */
  /* overflow: auto; */
}

div.content {
  /* opacity: 0.2; */
  margin-top: 0px;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: all 0.3s linear;
}

div.blockwrapper.open div.content {
  margin-top: -5px;
  opacity: 1;
  border-radius: 5px;
  grid-template-rows: 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border: 1px solid #e6e6e6; */
}

div.content .innerContent {
  overflow: hidden;
}

div.top {
  color: white;
  height: 50px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  z-index: 99;
  cursor: pointer;
  overflow: hidden;
  /* C3C3C3    4-blur       X-0         Y-4 */
}

div.top.disabled {
  background: #f0f0f0 !important;
  color: #bebebe;
}

img.chevron {
  transform: rotateZ(90deg);
  transition: all 0.3s ease;
  height: 35px;
  position: absolute;
  right: 10px;
}

img.chevron.active {
  transform: rotateZ(180deg);
}

img.chevron.grey {
  filter: invert(20%);
}

div.top span.parentOnlyLabel {
  width: 60px;
  position: absolute;
  left: 10px;
  font-size: 10px;
}

div.top i {
  position: absolute;
  right: 20px;
  top: 25px;
  color: white;
}

div.leftImageDiv {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  margin-left: 5px;
  /* float: left; */
  display: inline-block;
  border-radius: 25px;
  overflow: hidden;
}

img.leftImage {
  width: 45px;
  height: 45px;
  /* padding: 9px; */
  object-fit: contain;
  vertical-align: top;
  /* border-radius: 25px; */
  border: 0;
  overflow: hidden;
}

div.blockContent {
  text-align: left;
  @apply text-chat;
}

div.blockContent:hover {
  /* background: rgba(255, 255, 255, 0.2); */
}

.textDiv {
  width: calc(100% - 70px);
  display: inline-block;
  vertical-align: top;
}

p {
  white-space: pre-line;
  padding: 5px;
}

p.modalIntroText {
  margin-bottom: 0px;
  padding: 10px 25px;
}

.van-button--info.van-button--plain.linkButton {
  font-weight: bold;
  border: none !important;
  color: #337ab7 !important;
  border-bottom: 1px solid #337ab7 !important;
  border-radius: 0px !important;
  /* padding-bottom: 3px !important; */
  height: 22px;
  padding: 10px 0px !important;
  font-size: 12px;
  vertical-align: top;
  margin-bottom: 5px !important;
}

.select-button {
  @apply rounded-full px-8 py-2 mt-2 mb-1;
  @apply text-sm text-white font-medium uppercase;
}
</style>
