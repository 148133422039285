<style lang="scss" scoped>
ul {
  padding: 0;
}
</style>
<template>
  <div id="wrapper" class="pb-2 font-rubik">
    <div class="modal-header flex items-center justify-between p-3">
      <div class="modal-title">Comments</div>
      <i
        class="fa fa-times close-icon fa-xl cursor-pointer"
        @click="$emit('close')"
      />
    </div>
    <div v-if="notice.allow_comments">
      <!-- <SectionTitle title="Comments" /> -->
      <NewCommentBox :id="notice.id" @newCommentAdded="newCommentAdded" />
      <div
        class="text-base font-medium text-grey-300 mx-3 my-4"
        v-if="comments.length === 0"
      >
        Be the first to comment on this post
      </div>

      <SingleComment
        :key="comment.id"
        :user_id="user_id"
        :comment="comment"
        @removeMyComment="removeMyComment"
        v-for="comment in comments"
      />
    </div>
  </div>
</template>
<script>
import SingleComment from "@/components/noticeTypes/other/SingleComment";
import NewCommentBox from "@/components/noticeTypes/other/NewCommentBox";
import ApiService from "@/services/ApiService";

export default {
  name: "postComments",
  components: {
    NewCommentBox,
    SingleComment,
  },
  props: {
    noticeId: Number,
    comments: Array,
    notice: Object,
  },
  data() {
    return {
      newComment: "",
    };
  },
  methods: {
    clickEvent(choice, name) {
      this.$parent.$emit("newFilterTypeSelected", {
        filterType: choice,
        filterName: name,
      });
      this.$emit("close");
      this.selection.value = choice;
    },
    submitComment() {
      if (this.newComment == "") {
        this.$notify({
          type: "warning",
          message: "Comment is required to proceed",
        });
        return;
      }
      var _payload = {
        comment: this.newComment,
        notice_id: this.noticeId,
        user_role_id: this.currentUserRoleId,
      };

      ApiService.generalApi
        .saveComment(_payload)
        .then((data) => {
          // loading(false);
          this.newComment = "";
          this.comments.unshift(data.data);
          this.returnData.comments++;
        })
        .catch(function () {
          // loading(false);
        });
    },
    removeMyComment(id) {
      var index;
      for (var i = 0; i < this.comments.length; i++) {
        if (this.comments[i].id == id) {
          index = i;
        }
      }
      ApiService.generalApi.removeComment(id).then(() => {
        this.comments.splice(index, 1);
      });
    },
    newCommentAdded(data) {
      this.comments.unshift(data);
    },
  },
  computed: {
    user_id: function () {
      return parseInt(this.$store.state.general.user_id);
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
  },
};
</script>
