export default {
  singleNotice: function (response) {
    var notice = response.data;
    let comments = [];
    if (response.data.extras) {
      notice.extras = JSON.parse(response.data.extras);
    }

    try {
      if (notice.images && notice.images.length > 0) {
        var images = [];
        var pdfs = [];
        var allAttachments = JSON.parse(JSON.stringify(response.data.images));
        for (var y = 0; y < allAttachments.length; y++) {
          if (allAttachments[y].type === "pdf") {
            pdfs.push(allAttachments[y]);
          } else {
            images.push(allAttachments[y]);
          }
        }
        notice.images = images;
        notice.pdfs = pdfs;
      }
    } catch (error) {
      alert(error);
    }

    if (
      response.data.type === "noticeimages" &&
      notice.images.length > 0 &&
      response.data.images[0].type == "mp4"
    ) {
      // video.addSource('mp4', 'https://www.w3schools.com/html/mov_bbb.mp4');
    }

    if (response.data.comments) {
      comments = response.data.comments;
    }
    return {
      notice: notice,
      comments: comments,
    };
  },
};
