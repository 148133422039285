<style scoped></style>
<template>
  <div></div>
</template>

<script>
// import ApiService from "@/services/ApiService";

export default {
  name: "redirecttoappstore",
  mounted() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=schoolrun.com.schoolrun&hl=en_GB";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        "https://itunes.apple.com/gb/app/homerun/id1223558677?mt=8";
    } else {
      // alert('should redirect back to desktop');
      this.$router.replace({ name: "congratulations" });
    }
  },
};
</script>
